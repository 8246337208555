import React from "react";
import keyGenerator from "../../util/keyGenerator";
import editElementForKey from "../../util/editElementForKey";
import idFirestoreFormatGenerator from "../../util/IDFirestoreFormatGenerator";
import DynamicInputField from "./DynamicInputField.js";
import { Form, Row, Col, Input, Button, Select } from "antd";
import "./form.css";
const { Option } = Select;
function DynamicForm({
  doc,
  master,
  fields,
  onDeleteMaster,
  onEdit,
  parentObject
}) {
  const newFieldData = () => {
    let docCopy = JSON.parse(JSON.stringify(doc));
    let masterCopy = JSON.parse(JSON.stringify(master));

    docCopy.data.push({
      key: keyGenerator(),
      field: "",
      value: "",
      type: ""
    });

    onEdit(editElementForKey(masterCopy, doc.key, docCopy));
  };

  const newChildren = type => {
    let docCopy = JSON.parse(JSON.stringify(doc));
    let masterCopy = JSON.parse(JSON.stringify(master));
    let id = "";
    if (type === "payments" && doc.level === 2) {
      id = idFirestoreFormatGenerator();
    }
    if (type === "commissions" && doc.level === 2) {
      id = "values";
    }
    docCopy.children.push({
      key: keyGenerator(),
      level: doc.level + 1,
      field: "",
      data: [],
      children: [],
      id: id
    });
    if (type === "payouts" && doc.level === 1) {
      let newData = docCopy.data.map(data => {
        return {
          key: keyGenerator(),
          field: data.field,
          value: data.value,
          type: data.type
        };
      });
      docCopy.children[0].id = "trafficPayOutLight";
      docCopy.children[0].children.push({
        key: keyGenerator(),
        level: 3,
        fields: [],
        data: newData,
        children: [],
        id: idFirestoreFormatGenerator()
      });
    }
    if (type === "payments" && doc.level === 3) {
      let newData = docCopy.data.map(data => {
        return {
          key: keyGenerator(),
          field: data.field,
          value: data.value,
          type: data.type
        };
      });
      docCopy.children[0].id = "trafficPaymentLight";
      docCopy.children[0].children.push({
        key: keyGenerator(),
        level: 5,
        fields: [],
        data: newData,
        children: [],
        id: idFirestoreFormatGenerator()
      });
    }

    onEdit(editElementForKey(masterCopy, doc.key, docCopy));
  };

  const deleteChildren = key => {
    let masterCopy = JSON.parse(JSON.stringify(master));
    let parentObjectCopy = JSON.parse(JSON.stringify(parentObject));

    parentObjectCopy.children = parentObjectCopy.children.filter(
      item => item.key != key
    );

    onEdit(editElementForKey(masterCopy, parentObject.key, parentObjectCopy));
  };

  const deleteFieldData = key => {
    let docCopy = JSON.parse(JSON.stringify(doc));
    let masterCopy = JSON.parse(JSON.stringify(master));

    docCopy.data = docCopy.data.filter(item => item.key != key);

    onEdit(editElementForKey(masterCopy, doc.key, docCopy));
  };

  const deleteMaster = key => {
    onDeleteMaster(key);
  };

  const onChangeInput = (data, value, field) => {
    let masterCopy = JSON.parse(JSON.stringify(master));
    let params = { ...data, [field]: value };

    onEdit(editElementForKey(masterCopy, data.key, params));
  };

  const onChangeSelectOfField = (data, value) => {
    data.value = "";

    let masterCopy = JSON.parse(JSON.stringify(master));
    let params = { ...data, ...value };

    onEdit(editElementForKey(masterCopy, data.key, params));
  };

  return (
    <div className="box-master">
      <div className="box-level-1">
        <Row align="bottom" type="flex">
          <Col span={14}>
            <Row>
              <label htmlFor="">Level: {doc.level}</label>
            </Row>
            <Row type="flex">
              <Col span={14}>
                {(fields.type === "payments" && doc.level == 2) ||
                (fields.type === "commissions" && doc.level == 2) ? (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option && option.props
                        ? option.props.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    onChange={e => onChangeInput(doc, e, "id")}
                    value={doc.id}
                  >
                    <Option value="">Selecciona una colección</Option>
                    {fields.collections.map((item, index) => (
                      <Option value={item.field} key={index}>
                        {" "}
                        {item.field}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    type="text"
                    value={
                      doc.level == 4 && fields.type == "payments"
                        ? (doc.id = "trafficPaymentLight")
                        : doc.level == 2 && fields.type == "payouts"
                        ? (doc.id = "trafficPayOutLight")
                        : doc.id
                    }
                    disabled={
                      (doc.level > 2 && fields.type == "payments") ||
                      (doc.level == 2 && fields.type == "payouts") ||
                      (doc.level == 1 && fields.type == "methodsPay") ||
                      (doc.level > 2 && fields.type == "commissions")
                    }
                    placeholder="ID"
                    onChange={e => onChangeInput(doc, e.target.value, "id")}
                  />
                )}
              </Col>
              <Col span={3}>
                {/*<Button
                  type="primary"
                  disabled={doc.id}
                  onClick={() =>
                    onChangeInput(doc, idFirestoreFormatGenerator(), "id")
                  }
                >
                  ID
                </Button>*/}
              </Col>
              <Col span={5}>
                {doc.level % 2 !== 0 && (
                  <Button type="primary" onClick={() => newFieldData()}>
                    + field
                  </Button>
                )}
              </Col>
              <Col span={2}>
                {doc.level != 1 ? (
                  <Button type="danger" onClick={() => deleteChildren(doc.key)}>
                    -
                  </Button>
                ) : (
                  <Button type="danger" onClick={() => deleteMaster(doc.key)}>
                    -
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {doc.data.map((data, index) => (
          <div key={data.key} style={{ marginTop: "10px" }}>
            <Row>
              <Col span={9}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option && option.props
                      ? option.props.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  value={data.field}
                  onChange={value => {
                    onChangeSelectOfField(
                      data,
                      fields.fields.find(i => i.field == value)
                    );
                  }}
                >
                  <Option value="">Selecciona un valor</Option>
                  {fields.fields.map((item, index) => (
                    <Option value={item.field} key={index}>
                      {" "}
                      {item.field}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={11} offset={1}>
                <DynamicInputField
                  data={data}
                  fields={fields.fields}
                  type={data.type}
                  onChangeInput={onChangeInput}
                />
              </Col>
              <Col span={2} offset={1}>
                <Button type="danger" onClick={() => deleteFieldData(data.key)}>
                  -
                </Button>
              </Col>
            </Row>
          </div>
        ))}

        <Row>
          <Col span={24}>
            <div className="box-level-2">
              {doc.children
                ? doc.children.map((children, index) => (
                    <Col span={24} key={index}>
                      <DynamicForm
                        parentObject={doc}
                        doc={children}
                        master={master}
                        fields={fields}
                        onEdit={onEdit}
                      />
                    </Col>
                  ))
                : null}
            </div>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={6}>
                {(doc.level < 5 && fields.type == "payments") ||
                (doc.level < 3 && fields.type == "commissions") ||
                (doc.level < 2 && fields.type == "payouts") ||
                (doc.level < 1 && fields.type == "methodsPay") ? (
                  <Button
                    type="primary"
                    onClick={() => newChildren(fields.type)}
                  >
                    + {doc.level % 2 !== 0 ? "colección" : "doc"}
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default DynamicForm;
