import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";

var config;
if (process.env.REACT_APP_ENVIRONMENT === "prod") {
  // eslint-disable-next-line
  //if (1 === 1) {
  config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "zippy-payments-4beb0.firebaseapp.com",
    databaseURL: "https://zippy-payments-4beb0.firebaseio.com",
    projectId: "zippy-payments-4beb0",
    storageBucket: "zippy-payments-4beb0.appspot.com",
    messagingSenderId: "944078477966",
    appId: "1:944078477966:web:a7c7ab6de5af70e73987df"
  };
} else {
  config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "xbet-7367a.firebaseapp.com",
    databaseURL: "https://xbet-7367a.firebaseio.com",
    projectId: "xbet-7367a",
    storageBucket: "xbet-7367a.appspot.com",
    messagingSenderId: "452326899648",
    appId: "1:452326899648:web:bb4125b6ceda0d8a"
  };
}

firebase.initializeApp(config);

const auth = firebase.auth();
const database = firebase.database();
const storage = firebase.storage();
const db = firebase.firestore();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  database,
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  firebase,
  storage,
  db
};
