import React from "react";
import keyGenerator from "../../util/keyGenerator";
import idFirestoreFormatGenerator from "../../util/IDFirestoreFormatGenerator";
import DynamicForm from "./DynamicForm";
import { Button, Row, Col } from "antd";
import "./form.css";

function FormMaster({ title = "", data = [], onChange, fields = {} }) {
  const newDoc = () => {
    let id = "";
    if (fields.type === "methodsPay") {
      id = idFirestoreFormatGenerator();
    }
    onChange([
      ...data,
      ...[
        {
          key: keyGenerator(),
          level: 1,
          id: id,
          data: [],
          children: []
        }
      ]
    ]);
  };

  const setEdit = data => {
    onChange(data);
  };

  return (
    <>
      <div className="box-master">
        <h3>{title}</h3>
        <Row>
          <Col span={24}>
            {data.map((doc, index) => (
              <div key={index} className="box-level-2">
                <DynamicForm
                  doc={doc}
                  master={data}
                  fields={fields}
                  onEdit={setEdit}
                  onDeleteMaster={key =>
                    onChange(data.filter(item => item.key != key))
                  }
                />
              </div>
            ))}
          </Col>
          <Col span={12}>
            <Button type="primary" onClick={newDoc}>
              + Doc
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default FormMaster;
