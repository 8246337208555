import React from "react";
import { Row, Col, Card, Table, Input, DatePicker, Badge, Button ,Modal,Tooltip} from "antd";
import _, { first } from "lodash";
import NumberFormat from "react-number-format";
import currencyNumberFormat from "../../../util/currencyNumberFormat"
import moment from "moment-timezone";
import ReactExport from "react-export-excel";
import Auxiliary from "../../../util/Auxiliary";
import API from "../../../util/api";
import copy from "copy-to-clipboard";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import { AllHtmlEntities } from "html-entities";
/* import {
setDateRange
} from "../../../appRedux/actions/Setting";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux"; */
const entities = new AllHtmlEntities();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { RangePicker } = DatePicker;
const pagination = { position: "bottom" }; //bottom top both none

class Dynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bordered: false,
      loading: false,
      pagination,
      size: "middle", // default middle small
      expandedRowRender: props.expandedRowRender,
      title: undefined,
      showHeader: true,
      footer: () => (
        <div>
          Suma:{" "}
        { <NumberFormat
            value={"$" + parseFloat(this.state.sumDataList).toFixed(2)}
            displayType={"text"}
            thousandSeparator={","}
            decimalSeparator={"."}
            prefix={"$"}
          />}
  {/*  {currencyNumberFormat(parseFloat(this.state.sumDataList).toFixed(2),this.state.country)} */}

        </div>
      ),
      rowSelection: false, // {} or false
      scroll: undefined,
      searchFilter: "",
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      timeZone: "America/Santiago"
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      dataList: nextProps.dataList,
      sumDataList: _.sumBy(nextProps.dataList, item => Number(item.quantity)),
      country:this.props.country
    });
  }

  filter = searchValue => {

    if (searchValue === "") {
      this.setState({
        dataList: this.props.dataList,
        sumDataList: _.sumBy(this.props.dataList, item => Number(item.quantity))
      });
    } else {
      try {
        const filter = _.filter(
          this.props.dataList,
          Transfer =>
            (Transfer.name? Transfer.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1: "")  ||
            (Transfer.email? Transfer.email.toLowerCase().indexOf(searchValue.toLowerCase()) > -1: "")||
            (Transfer.documentId? Transfer.documentId.toLowerCase().indexOf(searchValue.toLowerCase()) > -1: "")  ||
            (Transfer.quantity ? Transfer.quantity.toString().indexOf(searchValue.toLowerCase()) > -1: "")  ||
            (Transfer.commerceReqId ? Transfer.commerceReqId.toString().indexOf(searchValue.toLowerCase()) > -1 : "") ||
            (Transfer.TransferId ? Transfer.TransferId.toLowerCase().indexOf(searchValue.toLowerCase()) >-1 : "") ||
            (Transfer.operationCode? Transfer.operationCode.toLowerCase().indexOf(searchValue.toLowerCase()) > -1: "") ||
            (Transfer.prepaidCode? Transfer.prepaidCode.toString().toLowerCase().indexOf(searchValue.toLowerCase()) > -1: "") ||
            (Transfer.adminCallBack? Transfer.adminCallBack.toLowerCase().indexOf(searchValue.toLowerCase()) > -1: "") ||
            (Transfer.collection? Transfer.collection.toLowerCase().indexOf(searchValue.toLowerCase()) > -1: "")||
            (Transfer.rut? Transfer.rut.toLowerCase().indexOf(searchValue.toLowerCase()) > -1: "")
        );
        this.setState({
          dataList: filter,
          sumDataList: _.sumBy(filter, item => Number(item.quantity))
        });
      } catch (err){
        console.log("Error en el  filtro "+err);
      }


    }
  };

  updateSearchFilter(evt) {
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }

  onChangeRange = (dates, dateStrings) => {
    let firstDay = moment(dateStrings[0], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .toDate();
    let lastDay = moment(dateStrings[1], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .endOf("day")
      .toDate();
    this.props.updateQuery(firstDay, lastDay);



  };

  onTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  onTimeZoneChange = zone => {
    console.log("zone", "=>", zone);
    this.setState({
      timeZone: zone
    });
  };


  dateFormat = (data) => {
    if (data.dateRequest != "") {

      return moment(new Date(data.dateRequest._seconds * 1000).toISOString()).toDate().toLocaleString("es-CL", {
        timeZone: this.state.timeZone,
        timeZoneName: "short"
      })
    }
    else {
      return ""
    }
  }

toNumber=(valor)=>
{
 console.log("col.quantity",valor);
 console.log("toNumber",Number(valor));

 const formatter = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
 /*  minimumFractionDigits: 0 */
})
console.log("formatter",formatter.format(valor));

return formatter.format(valor);

}
hideModalStatus = () => {
  this.setState({
    visibleModalStatus: false,
 /*    messageModal: "",
    recordStatus: [] */
  });
 
};

  showModalStatus = async record => {
    this.setState({
      visibleModalStatus: true,
    });
    let parametros = {
      "zippyId": record.id,

    }
    console.log("parametros", parametros);
    await API.utils.getOperationairtm(parametros).then(cons => {
      console.log("getOperationairtm", cons)
      if (cons.status !== 404) {
        this.setState({
          statusOperation: cons, zippyId: record.id,
        })
      }
      else {
        this.setState({
          statusOperation: ""
        })
      }
    });
};

onCopyClipBoard = record => {
  console.log(record);
  copy(record);
};
  render() {

    this.columns = [
      {
        title: "Date Request",
        dataIndex: "dateRequest",
        key: "dateRequest",
        width: 200,
        render: dateRequest => (
          <span>{moment(new Date(dateRequest._seconds * 1000).toISOString()).toDate().toLocaleString("es-CL", {
            timeZone: this.state.timeZone,
            timeZoneName: "short"
          })}</span>
        ),
        sorter: (a, b) => a.dateRequest._seconds - b.dateRequest._seconds,
        sortOrder:
          this.state.sortedInfo.columnKey === "dateRequest" &&
          this.state.sortedInfo.order
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 150,
        render: name => entities.decode(name)
      },
      {
        title: "Document Id",
        dataIndex: "rut",
        key: "rut",
        width: 150,
        render: rut => formatRut(rut)
      },
      {
        title: "Amount",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
       /*  render: text => this.toNumber(text) */
        render: text => (
          <div style={{ float: "right" }}>
            <NumberFormat
              value={text}
              displayType={"text"}
               thousandSeparator={","}
              decimalSeparator={"."}
              prefix={"$"}
            />


          </div>
        )
      },
      {
        title: `ID ${this.props.merchant.name}`,
        dataIndex: "commerceReqId",
        key: "commerceReqId",
        width: 100
      },
      {
        title: "Zippy ID",
        dataIndex: "id",
        key: "id",
        width: 100
      }
      ,
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        width: 100
      }
    ];

    this.columnsActions = [
  
      {
        title: "Status",
        /* key: "action", */
        key: "8",
        width: 100,
        align: 'center',
        render: (text, record) => (
          <span>
            <div onClick={() => this.showModalStatus(record)}>
            <Tooltip title={`Get Status in ${record.collection} `}>
                <Button type=""  >
                  GetStatus</Button>
              </Tooltip>
            </div>
          </span>
        )
      }
    ];
    return (
      <Card
        title={this.props.title }
        extra={
          <Auxiliary>


            TimeZone: {this.state.timeZone}{" "}
            <i
              className={`flag flag-24 gx-mr-2 flag-ru`}
              onClick={this.onTimeZoneChange.bind(this, "Europe/Moscow")}
            ></i>
            <i
              className={`flag flag-24 gx-mr-2 flag-cl`}
              onClick={this.onTimeZoneChange.bind(this, "America/Santiago")}
            ></i>
            <i
              className={`flag flag-24 gx-mr-2 flag-pe`}
              onClick={this.onTimeZoneChange.bind(this, "America/Lima")}
            ></i>
          </Auxiliary>
        }
      >
        <div className="components-table-demo-control-bar">
          <Row>

            <Col xl={15} lg={15} md={15} sm={24} xs={24} className="gx-pr-md-2">

              <RangePicker
                className="gx-mb-3 gx-w-50"
                format="DD/MM/YYYY"
                ranges={{
                  All: [
                    moment("01-01-2019", "MM-DD-YYYY").tz(this.state.timeZone),
                    moment().tz(this.state.timeZone)
                  ],
                  Today: [
                    moment().tz(this.state.timeZone),
                    moment().tz(this.state.timeZone)
                  ],
                  Yesterday: [
                    moment()
                      .tz(this.state.timeZone)
                      .subtract(1, "days"),
                    moment()
                      .tz(this.state.timeZone)
                      .subtract(1, "days")
                  ],
                  "This week": [
                    moment()
                      .tz(this.state.timeZone)
                      .startOf("isoweek"),
                    moment()
                      .tz(this.state.timeZone)
                      .endOf("isoweek")
                  ],
                  "Last 7 Days": [
                    moment()
                      .tz(this.state.timeZone)
                      .subtract(6, "days"),
                    moment().tz(this.state.timeZone)
                  ],
                  "Last Month": [
                    moment()
                      .tz(this.state.timeZone)
                      .startOf("month")
                      .subtract(1, "months"),
                    moment()
                      .tz(this.state.timeZone)
                      .endOf("month")
                      .subtract(1, "months")
                  ],
                  "This Month": [
                    moment()
                      .tz(this.state.timeZone)
                      .startOf("month"),
                    moment()
                      .tz(this.state.timeZone)
                      .endOf("month")
                  ]
                }}
                onChange={this.onChangeRange}
                defaultValue={this.props.defaultValue}
              />

            </Col>
            <Col xl={9} lg={9} md={9} sm={24} xs={24} className="gx-pr-md-2">
              <Input
                placeholder="Buscar...."
                onChange={this.updateSearchFilter.bind(this)}
              ></Input>
            </Col>
          </Row>
        </div>

        <Table
          className="gx-table-responsive"
          {...this.state}
        /*   columns={this.props.title === "Transfer Validated" ? this.props.columns || this.columns : this.columnsNotOk}
          */
          columns={
            this.props.typeUser !== "merchant"
              ? (this.columns = this.columns.concat(this.columnsActions))
                : this.columns
          }

          dataSource={this.state.dataList}
          onChange={this.onTableChange}
          rowKey="id"
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50", "100"]
          }}
        />

        <ExcelFile filename={this.props.filename}>
          <ExcelSheet data={this.state.dataList} name="Hoja 1">
            <ExcelColumn label="Date" value={col => this.dateFormat(col)} />
            <ExcelColumn label="Name" value={col => entities.decode(col.name)} />
            <ExcelColumn label="Rut" value="documentId" />
          {/*   <ExcelColumn label="Amount" value={col => Number(col.quantity)} /> */}
            <ExcelColumn label="Amount" value={col => col.quantity} />
            <ExcelColumn label="ID Zippy" value="id" />
            <ExcelColumn label="Operation Code" value="operationCode" />
            <ExcelColumn
              label={`ID ${this.props.merchant.name}`}
              value={col => col.commerceReqId}
            />
            <ExcelColumn label="Commision" value="commision" />
            <ExcelColumn label="IVA" value="iva" />
            <ExcelColumn label="Tot Commision" value="totCommision" />
            <ExcelColumn label="Total" value="total" />
          </ExcelSheet>
        </ExcelFile>
        <Modal
          title={`Get Operation Code AIRTM`}
          // icon: <i class="icon icon-exclamation"></i>
          visible={this.state.visibleModalStatus}
          onCancel={this.hideModalStatus}
         /*  bodyStyle={{ backgroundColor: "#ffccc7" }} */
          onOk={this.handleSubmitStatus}
          footer={[
          
          ]}
        >
          <p> Zippy Id :<b> {this.state.zippyId?this.state.zippyId:""}</b> </p>
          <p> Status  :<b> {this.state.statusOperation?  this.state.statusOperation.data.status==="confirmed"?"Aprobado":"Pendiente":""}</b> </p>
          <p> Operation Code :<b>{this.state.statusOperation?this.state.statusOperation.data.airtmOperationId:""}</b> <span className="gx-link" onClick={() => this.onCopyClipBoard(this.state.statusOperation.data.airtmOperationId)}><i  class="icon icon-copy"/></span> </p>
        </Modal>
      </Card>
    );
  }
}

export default Dynamic;

/* const mapStateToProps = ({ settings }) => {
  const {
    dataRange
  } = settings;
  return {
    dataRange
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, {
    setDateRange,

  })
)(Dynamic); */
