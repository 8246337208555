import {
  FETCH_START,
  FETCH_SUCCESS,
  GET_All_INVOICE_SUCCESS
} from "../../constants/ActionTypes";
import { firebase } from "../../firebase/firebase";
const db = firebase.firestore();

export const onGetAllInvoice = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });

    db.collectionGroup("invoices")
      .get()
      .then(snapshot => {
        let data = snapshot.docs.map(doc => {
          return doc.data();
        });
        console.log("DATA", "=>", data);
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: GET_All_INVOICE_SUCCESS,
          payload: data
        });
      });
  };
};

export const onAddInvoice = invoice => {
  return dispatch =>
    db
      .collection("commerces")
      .doc(invoice.id)
      .collection("invoices")
      .add(invoice);
};

export const onUpdateInvoice = (id, contact) => {
  // return dispatch =>
  //   database
  //     .ref("/contact")
  //     .child(id)
  //     .update(contact);
};

export const onDeleteInvoice = id => {
  // return dispatch =>
  //   database
  //     .ref("/contact")
  //     .child(id)
  //     .remove();
};
