import React from 'react';

export const SantanderIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 99.94 94.92">
    <path
      fill="#dc0000"
      d="M0 66.6c.09-.59.17-1.17.29-1.75.58-2.85 1.89-5.35 3.68-7.62 2.1-2.67 4.68-4.82 7.51-6.66 3.8-2.47 7.9-4.32 12.19-5.75 1.4-.47 2.83-.86 4.25-1.29.1-.03.2-.05.33-.08 0 .1.02.17.02.25-.14 4.28.95 8.22 3.09 11.92 3.89 6.73 7.72 13.49 11.57 20.24 1.2 2.11 2.38 4.24 3.62 6.33 1.23 2.07 2.14 4.26 2.62 6.63.01.06.03.12.06.25.14-.24.25-.42.36-.6 1.61-2.65 2.67-5.49 3.04-8.57.56-4.78-.23-9.3-2.64-13.49-3.74-6.52-7.53-13.02-11.29-19.54-.91-1.58-1.88-3.13-2.56-4.83-1.29-3.2-1.81-6.52-1.56-9.96.27-3.66 1.43-7.03 3.38-10.13.02-.03.05-.06.09-.12.15.61.27 1.2.45 1.77.6 1.97 1.48 3.8 2.51 5.57 2.23 3.83 4.44 7.68 6.66 11.51 3.01 5.21 6.03 10.42 9.04 15.63.78 1.35 1.56 2.71 2.34 4.07 1.44 2.5 1.85 3.54 2.69 6.83.06-.06.11-.11.14-.16 1.73-2.79 2.85-5.8 3.26-9.05.59-4.76-.29-9.26-2.66-13.43-3.54-6.23-7.17-12.41-10.74-18.62-.92-1.6-1.88-3.19-2.68-4.85-1.48-3.08-2.15-6.36-2.05-9.77.12-4.05 1.3-7.79 3.46-11.23.01-.02.03-.03.08-.09.03.14.06.24.08.34.53 2.4 1.5 4.63 2.73 6.76 2.96 5.11 5.91 10.22 8.86 15.34 2.08 3.6 4.15 7.22 6.25 10.81 1.49 2.55 2.45 5.29 2.85 8.21.08.56.12 1.12.15 1.68.01.21.09.3.3.35 4.04 1.07 7.97 2.47 11.72 4.32 3.7 1.83 7.16 4.04 10.14 6.94 2.1 2.05 3.85 4.34 4.99 7.06 1.1 2.61 1.55 5.32 1.15 8.14-.33 2.4-1.23 4.6-2.53 6.64-1.77 2.8-4.11 5.07-6.74 7.05-3.42 2.57-7.19 4.5-11.15 6.07-3.93 1.55-7.98 2.68-12.12 3.52-3.23.65-6.48 1.09-9.76 1.35-1.81.14-3.63.19-5.44.29-.14 0-.29.03-.43.05h-3.38c-.19-.02-.37-.05-.56-.05-4.96-.13-9.89-.62-14.77-1.58-4.41-.87-8.72-2.08-12.89-3.77-3.66-1.48-7.15-3.28-10.33-5.63-2.54-1.87-4.81-4-6.6-6.62-1.44-2.1-2.46-4.38-2.88-6.9-.09-.56-.15-1.13-.22-1.7v-2.07z"
    ></path>
  </svg>
)
export const BancoChileIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 89.11 97.9">
    <path
      fill="#161e64"
      d="M7.43 92.81C22.39 78.72 39.49 74.34 59.35 82.1c.08-1.39.21-2.5.21-3.6.01-20.42 0-40.84 0-61.26 0-.76.04-1.53-.07-2.29-.32-2.36-2.22-3.24-4.13-1.77-2.22 1.71-3.38 3.97-3.37 6.91.07 12.02.06 24.04-.01 36.07 0 1.25-.25 2.84-1.02 3.7-5.76 6.41-13.75 9.68-20.81 14.53 2.6-3.37 1.95-7.14 1.95-10.79.01-11.55.07-23.09-.05-34.64-.03-2.43.69-4.23 2.53-5.71 1.48-1.2 2.81-2.62 4.39-3.69 8.4-5.67 16.78-11.38 25.32-16.85C69.07-.36 71.03.07 74.53 4.55c3.65 4.66 7.22 9.4 10.56 14.28 3.86 5.64 3.54 11.53-2.75 15.92-3.61 2.52-7.31 4.9-10.95 7.37-.95.64-1.91 1.29-2.76 2.05-1.55 1.4-1.26 2.53.77 2.95 2.44.5 4.92.78 7.37 1.19 7.35 1.24 11.84 6.23 12.21 13.71.14 2.86-.07 5.73.1 8.58.18 3-1.06 5.17-3.45 6.78C75.88 83.96 66.1 90.5 56.35 97.1c-1.54 1.04-2.89 1.04-4.51.13-1.99-1.13-4.13-2-6.2-2.99-11.54-5.57-23.4-5.83-35.5-2.14-.9.27-1.82.48-2.73.71zm55.69-42.22c-.05 1.09-.12 1.94-.12 2.79 0 9.24-.01 18.49 0 27.73 0 3.83 1.36 5.02 5.82 4.92.05-.98.14-2 .14-3.01.01-8.38.01-16.77 0-25.16 0-3.97-1.39-5.76-5.85-7.26zm.08-7.31c2.54-1.81 4.92-2.99 6.56-4.83 1.19-1.34 2.15-3.66 1.9-5.34-.82-5.54-4.46-9.61-8.46-14.21v24.38zM23.91 12.52c1.57.66 2.83 2.02 4.24 3.06-1.06 1.27-1.93 2.81-3.23 3.77-4.62 3.4-9.38 6.62-14.1 9.87-1.88 1.3-2.69 2.92-2.66 5.3.12 9.92.05 19.84.05 29.76 0 3.49.46 7.06-2.16 10.12 6.07-3.82 11.83-7.86 17.68-11.76 1.56-1.03 2.07-2.17 2.06-3.98-.08-10.3.24-20.62-.16-30.9-.19-4.87 1.93-7.44 5.71-9.88 7.2-4.65 14.13-9.72 20.64-14.24-2.98-.47-6.29-.61-9.34-1.55C26.58-2.85 12.67 1.18 0 11.97c8.28-2.5 16.17-2.68 23.91.55z"
    ></path>
  </svg>
)