import React from "react";
import Widget from "components/Widget/index";
import { Col, Row } from "antd";
import LineIndicator from "./LineIndicator";
import NumberFormat from "react-number-format";

const PortfolioMx = props => {
  let porcentajeCashin = (props.cashin * 100) / props.totalNeto;
  let porcentajeCashout = (props.cashout * 100) / props.totalNeto;
  let porcentajePctC = (props.pctC * 100) / props.totalNeto;
  let porcentajePctD = (props.pctD * 100) / props.totalNeto;
  return (
    <Widget>
      <h2 className="h4 gx-mb-3">Commissions</h2>
      <Row>
        <Col lg={12} md={12} sm={12} xs={24}>
          <div className="ant-row-flex">
            <h2 className="gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium">
              <NumberFormat
                value={parseFloat(props.totalNeto).toFixed(0)}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
              />
            </h2>
            {/* <h4 className="gx-pt-2 gx-chart-up">
              64% <i className="icon icon-menu-up gx-fs-sm" />
            </h4> */}
          </div>
          <p className="gx-text-grey">Neto</p>
          <div className="ant-row-flex">
            <h2 className="gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium">
              <NumberFormat
                value={parseFloat(props.totalIva).toFixed(0)}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
              />
            </h2>
            {/* <h4 className="gx-pt-2 gx-chart-up">
              64% <i className="icon icon-menu-up gx-fs-sm" />
            </h4> */}
          </div>
          <p className="gx-text-grey">IVA</p>
          <div className="ant-row-flex">
            <h2 className="gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium">
              <NumberFormat
                value={
                  Number(parseFloat(props.totalNeto).toFixed(0)) +
                  Number(parseFloat(props.totalIva).toFixed(0))
                }
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
              />
            </h2>
            {/* <h4 className="gx-pt-2 gx-chart-up">
              64% <i className="icon icon-menu-up gx-fs-sm" />
            </h4> */}
          </div>
          <p className="gx-text-grey">Total</p>
          {/* <div className="ant-row-flex gx-mb-3 gx-mb-md-2">
            <Button className="gx-mr-2" type="primary">
              Deposit
            </Button>
            <Button className="gx-btn-cyan">Withdraw</Button>
          </div>

          <p className="gx-text-primary gx-pointer gx-d-none gx-d-sm-block gx-mb-1">
            <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle" />{" "}
            Add New Wallet
          </p> */}
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <div className="gx-site-dash">
            <h5 className="gx-mb-3">Distribution</h5>
            <ul className="gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0">
              <li>
                <LineIndicator
                  width={porcentajeCashin + "%"}
                  title="CashIn"
                  title2=<NumberFormat
                    value={parseFloat(props.cashin).toFixed(0)}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                  />
                  title3=<NumberFormat
                    value={parseFloat(props.ivaCashin).toFixed(0)}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                  />
                  color="primary"
                  value={parseFloat(porcentajeCashin).toFixed(2) + "%"}
                />
              </li>
              <li>
                <LineIndicator
                  width={porcentajePctC + "%"}
                  title="Pct C"
                  title2={"$" + parseFloat(props.pctC).toFixed(0)}
                  title3={"$" + parseFloat(props.ivapctC).toFixed(0)}
                  color="pink"
                  value={parseFloat(porcentajePctC).toFixed(2) + "%"}
                />
              </li>
              <li>
                <LineIndicator
                  width={porcentajePctD + "%"}
                  title="Pct D"
                  title2={"$" + parseFloat(props.pctD).toFixed(0)}
                  title3={"$" + parseFloat(props.ivaPctD).toFixed(0)}
                  color="orange"
                  value={parseFloat(porcentajePctD).toFixed(2) + "%"}
                />
              </li>
              <li>
                <LineIndicator
                  width={porcentajeCashout + "%"}
                  title="CashOut"
                  title2={"$" + parseFloat(props.cashout).toFixed(0)}
                  title3={"$" + parseFloat(props.ivaCashout).toFixed(0)}
                  color="orange"
                  value={parseFloat(porcentajeCashout).toFixed(2) + "%"}
                />
              </li>
            </ul>
            {/* <p className="gx-text-primary gx-pointer gx-d-block gx-d-sm-none gx-mb-0 gx-mt-3">
              <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle" />{" "}
              Add New Wallet
            </p> */}
          </div>
        </Col>
      </Row>
    </Widget>
  );
};

export default PortfolioMx;
