import React from "react";
import { Row, Col, Select, DatePicker } from "antd";

const { RangePicker } = DatePicker;
const { Option } = Select;

const dateFormat = "YYYY/MM/DD";

const FormMerchant = ({ formik, keyObject, filterAgregatorType }) => {
  return (
    <>
      <Row gutter={[16, 20]}>
        <Col xs={24}>
          <RangePicker
            style={{ width: "100%" }}
            onChange={value =>
              formik.setFieldValue(`${keyObject}.rangeDate`, value, true)
            }
            value={formik.values[keyObject].rangeDate}
            format={dateFormat}
          />
          <p className="p-error">
            {formik.errors[keyObject] &&
              formik.touched[keyObject] &&
              formik.errors[keyObject].rangeDate &&
              formik.touched[keyObject].rangeDate &&
              formik.errors[keyObject].rangeDate}
          </p>
        </Col>
        <Col xs={24} lg={12}>
          <Select
            placeholder="Select at least one merchant"
            onChange={value =>
              formik.setFieldValue(`${keyObject}.commerceId`, value, true)
            }
            value={formik.values[keyObject].commerceId}
          >
            <Option value="" disabled>
              -- Merchans --
            </Option>
            <Option value="2019CL1xbet-8k3y">1xBet</Option>
            <Option value="2020Payretailers7g21">PayRetailers</Option>
            {/* <Option value="2020cestelar-3j9s">CEstelar</Option> */}
            {/* <Option value="2020dw-6d9w">WD</Option> */}
            <Option value="2020e-Management2u5i">E-Management</Option>
            <Option value="2021juegaloCom-9n3u">JuegaloCom</Option>
            <Option value="2020juegalopro-7j7g">JuegaloPRO</Option>
            <Option value="2020techsolutions22gf">Techsolutions</Option>
            <Option value="2021abudantia-2m5i">Abudantia</Option>
            {/* <Option value="starka-ltd">Starka</Option> */}
          </Select>
          <p className="p-error">
            {formik.errors[keyObject] &&
              formik.touched[keyObject] &&
              formik.errors[keyObject].commerceId &&
              formik.touched[keyObject].commerceId &&
              formik.errors[keyObject].commerceId}
          </p>
        </Col>
        <Col xs={24} lg={12}>
          <Select
            placeholder="Select at least one country"
            onChange={value => {
              formik.setFieldValue(`${keyObject}.country`, value, true);
            }}
            value={formik.values[keyObject].country}
          >
            <Option value="" disabled>
              -- Contries --
            </Option>
            <Option value="CL">Chile</Option>
            <Option value="PE">Peru</Option>
          </Select>
          <p className="p-error">
            {formik.errors[keyObject] &&
              formik.touched[keyObject] &&
              formik.errors[keyObject].country &&
              formik.touched[keyObject].country &&
              formik.errors[keyObject].country}
          </p>
        </Col>
        <Col xs={24} lg={12}>
          <Select
            placeholder="Select at least one aggregator"
            onChange={value => {
              formik.setFieldValue(`${keyObject}.aggregatorType`, "", true);
              formik.setFieldValue(`${keyObject}.aggregator`, value, true);
            }}
            value={formik.values[keyObject].aggregator}
          >
            <Option value="" disabled>
              -- Aggregators --
            </Option>
            <Option value="cashin">Cashin</Option>
            <Option value="cashout">Cashout</Option>
            <Option value="payku">Payku</Option>
            <Option value="kushki">Kushki</Option>
            <Option value="monnet">Monnet</Option>
            <Option value="alps">Alps</Option>
            <Option value="saftypay">Saftypay</Option>
            <Option value="skinback">Skinback</Option>
            <Option value="airtm">airtm</Option>
          </Select>
          <p className="p-error">
            {formik.errors[keyObject] &&
              formik.touched[keyObject] &&
              formik.errors[keyObject].aggregator &&
              formik.touched[keyObject].aggregator &&
              formik.errors[keyObject].aggregator}
          </p>
        </Col>
        <Col xs={24} lg={12}>
          <Select
            placeholder="Select at least one aggregator type"
            onChange={value =>
              formik.setFieldValue(`${keyObject}.aggregatorType`, value, true)
            }
            value={formik.values[keyObject].aggregatorType}
          >
            <Option value="" disabled>
              -- Aggregator Types --
            </Option>
            {formik.values[keyObject].aggregator
              ? filterAgregatorType[formik.values[keyObject].aggregator].map(
                  (option, index) => (
                    <Option
                      key={`${keyObject}-key-aggregator-type-${index}`}
                      value={option}
                    >
                      {option}
                    </Option>
                  )
                )
              : null}
          </Select>
          <p className="p-error">
            {formik.errors[keyObject] &&
              formik.touched[keyObject] &&
              formik.errors[keyObject].aggregatorType &&
              formik.touched[keyObject].aggregatorType &&
              formik.errors[keyObject].aggregatorType}
          </p>
        </Col>
      </Row>

      <style jsx="true">{`
        .p-error {
          position: absolute;
          color: red;
          font-size: 12px;
        }
      `}</style>
    </>
  );
};

export default FormMerchant;
