import { Spin } from "antd";
import React from "react";
import Widget from "../../Widget";

const AggregatorInformation = ({ data }) => {
  return (
    <>
      <Widget>
        <div className="aggregator-information">
          <div className="header-aggregator-information">
            <span className="header-aggregator-information__name">
              {data.aggregator ? data.aggregator || "Aggregator" : "Aggregator"}
            </span>
          </div>
          <ul className="aggregator-information__ul">
            <li className="aggregator-information__li green">
              <span className="aggregator-information__price">
                {data.commerceCommission
                  ? data.commerceCommission.fijo || 0
                  : 0}
                %
              </span>
              <span className="aggregator-information__description">
                Cobramos
              </span>
            </li>
            <li className="aggregator-information__li red">
              <span className="aggregator-information__price">
                {data.aggregatorCommission
                  ? data.aggregatorCommission.fijo || 0
                  : 0}
                %
              </span>
              <span className="aggregator-information__description">
                Nos cobran
              </span>
            </li>
          </ul>
        </div>
      </Widget>

      <style jsx="true">{`
        .header-aggregator-information__name {
          display: block;
          font-size: 1.2rem;
          color: black;
        }

        .aggregator-information__ul {
          list-style: none;
          padding: 0;
          margin: 0;
          margin-top: 1em;
          display: flex;
          gap: 1em 3em;
        }

        .aggregator-information__li > span {
          display: block;
        }

        .aggregator-information__price {
          font-size: 1rem;
        }

        .aggregator-information__li.green .aggregator-information__price {
          color: #089e08;
        }

        .aggregator-information__li.red .aggregator-information__price {
          color: #ff4242;
        }
        .aggregator-information__description {
          color: #aaa;
        }
      `}</style>
    </>
  );
};

export default AggregatorInformation;
