import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  Alert,
  Row,
  Col,
  Select,
  Spin,
  Collapse
} from "antd";
import Widget from "../Widget/index";
import Api from "../../util/api";
import { connect } from "react-redux";
import { firebase } from "../../firebase/firebase";
import moment from "moment-timezone";
import {
  format as formatRut
} from "rut.js";
import { AllHtmlEntities } from "html-entities";
import NumberFormat from "react-number-format";
import "./stylesCartola.css";

const FormItem = Form.Item;
const db = firebase.firestore();
const entities = new AllHtmlEntities();
const { Panel } = Collapse;
class Callback extends Component {
  state = {
    RecordValues: this.props.RecordValues,
    timeZone: "America/Santiago",
    dateRequest: "",
    colorStatus: "rgb(8,5,5,0.13489145658263302)",
    spin: false,
    Limit: 45000,
    ErrorLimit: false,
  };

  componentDidMount = async e => {
    this.getLimit();
/*     this.resetAll(); */
    this.getCommerces();
  };
  componentWillReceiveProps(nextProps) {
    console.log("recibe props");
   
    this.setState({
      RecordValues: nextProps.RecordValues,
    });
   
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      resp: "",
      spin: true
    });
    let validateOperationCode = {
      operationCodeBankisValid: true,
      OperationCodeisValid: true,
      operationcode: "",
    };


    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let validateCommerce = true;
        if (this.state.RecordValues.commerceId !== "2020juegalo-5n2q") {
          validateCommerce = await this.validateCommerceReqId(values.commerceReqId);
        }

        if (validateCommerce) {
          let operationCodeBank = "";
          let operationCode = "";

          validateOperationCode = await this.validateOperationCode(operationCodeBank, operationCode);
          if (validateOperationCode.operationCodeBankisValid && validateOperationCode.OperationCodeisValid) {
            let payload = {
              "commerceId": values.merchant,
              "commerceReqId": values.commerceReqId,
              "quantity": this.state.RecordValues.amount,
              "rut": this.state.RecordValues.rut,
              "email": values.email,
              "name": values.name,
              "adminValidateManual": this.props.authUser,
              "operationCode": this.state.RecordValues.bankOperationCode,
              "bankOperationCode": this.state.RecordValues.bankOperationCode
            };
            console.log("payload", payload);
            // LLAMAR A LA API
            await Api.utils
              .validateManual(payload)
              .then(async resp => {
                console.log("resp", resp);
                if (resp.status === "ok") {
                  this.setState({
                    resp: {
                      message:
                        `${resp.description} Zippy Id: ${resp.zippyId}`
                    }
                  });
                  // this.saveUserCallBack(idZippy, this.props.authUser, "12/12/12");
                  //   this.updateQuery(idZippy);
                  //  await this.deleteAdminApproval(idZippy);
                  //  setTimeout(() => {
                  //   this.resetAll();
                  //  }, 1000); 
                } else {
                  this.setState({
                    resp: {
                      message:
                        `${resp.description}`
                    }
                  });
                }
              });
          } else {

            this.setState({
              spin: false
            });

            if (validateOperationCode.operationCodeBankisValid === false && validateOperationCode.OperationCodeisValid === false) {

              this.setState({
                resp: {
                  message:
                    "Operation Code duplicate!. " + operationCode + ", Please check again that the code is correct"
                },
              });
            }
            else if (validateOperationCode.operationCodeBankisValid === false && validateOperationCode.OperationCodeisValid) {
              this.setState({
                resp: {
                  message:
                    "Operation Code Bank duplicate!. " + operationCodeBank + ", but there is an operation code with a different date than the one you are trying to enter :" + validateOperationCode.operationCode
                },
              });
            }
          }
        } else {
          this.setState({
            spin: false
          });
          this.setState({
            resp: {
              message:
                "Commerce Request ID duplicate!. " + values.commerceReqId
            },
            /*  ErrorLimit: true */
          });
        }
      }
      else {
        this.setState({
          spin: false
        });
      }
    });
  };

  validateCommerceReqId = async (commerceReqId) => {
    let isValid = false
    console.log("validateCommerceReqId")
    if (commerceReqId) {
      await db
        .collection("transfers")
        .where("commerceReqId", "==", commerceReqId)
        .where("code", "==", 0)
        .get()
        .then(async (snapshot) => {
          let resFirestore = snapshot.docs.map((doc) => {
            return doc.data()
          })
          if (resFirestore.length === 0) {
            isValid = true
          }
        })
        .catch((err) => {
          console.log("Error getting documents VALIDATING commerceReqId", err)
        })
    }

    return isValid
  }

  validateOperationCode = async (operationCodeBank, OperationCode) => {
    let resp = {
      operationCodeBankisValid: true,
      OperationCodeisValid: true,
      operationcode: ""
    };
    //debugger;
    let resFirestore;
    //bankOperationCode
    if (OperationCode) {
      await db
        .collection("transfers")
        .where("bankOperationCode", "==", operationCodeBank)
        .where("code", "==", 0)
        .get()
        .then(async (snapshot) => {
          resFirestore = snapshot.docs.map((doc) => {
            console.log("data", doc.data());

            return doc.data()
          })
          //console.log("esto es  la data ", resFirestore.length, resFirestore[0].bankOperationCode, resFirestore.operationCode);
          if (resFirestore.length === 0) {
            resp = {
              operationCodeBankisValid: true,
              OperationCodeisValid: true,
              operationcode: ""
            }
          }
          else {
            if (resFirestore[0].operationCode !== OperationCode) {
              resp = {
                operationCodeBankisValid: false,
                OperationCodeisValid: true,
                operationcode: resFirestore[0].operationCode
              }
            }
            else {
              resp = {
                operationCodeBankisValid: false,
                OperationCodeisValid: false,
                operationcode: ""
              }
            }
          }
        })
        .catch((err) => {
          console.log("Error getting documents VALIDATING Operation Code", err)
        })
    }
    return resp
  }

  resetTransactionInfo = () => {
    this.setState({
      ErrorLimit: false,
      RecordValues: [],
      spin: false,
    });
  };

  resetAll = () => {
    this.props.form.setFieldsValue({
      ["merchant"]: "",
      ["name"]: "",
      ["email"]: "",
      ["commerceReqId"]: ""
    });

    this.setState({
      RecordValues: [],
      dateRequest: "",
      colorStatus: "rgb(8,5,5,0.13489145658263302)",
      status: "",
      merchant: "",
      spin: false,

      ErrorLimit: false,
      Resend: false
    });
  };

  saveUserCallBack = async (id, user, date) => {
    var myTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
    console.log("saveUserCallBack", myTimestamp);

    var transfer = db.collection("transfers");
    let updateData = {};

    updateData = {
      user,
      adminCallBackDate: myTimestamp,
    };
    await transfer
      .doc(id)
      .update(
        updateData
      )
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(err => {
        console.log("ERROR IN saveUserCallBack", err);
      });
  };

  getLimit = async e => {
    await db
      .collection("parametrosGenerales")
      .doc("callback")
      .get()
      .then(doc => {
        this.setState({
          Limit: doc.data().limite
        });
      })
      .catch(err => {
        console.log("ERROR IN getLimit", err);
      });
  };
  getCommerces = async () => {
    let merchant = [];
    await db
      .collection("commerces")
      .get()
      .then(snapshot => {
        snapshot.docs.map(async docCommerce => {
          merchant.push({
            code: docCommerce.id,
            name: docCommerce.data().name
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN getCommerces", err);
      });
    this.setState({ boxMerchant: merchant });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const children = [];


    if (this.state.boxMerchant) {
      this.state.boxMerchant.map(merchant => {
        children.push(
          <Option key={merchant.code.toString()}>
            {merchant.name.toString()}
          </Option>
        );
      });
    }
    return (
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Widget
            title={
              <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
                <i className="icon icon-mail-open gx-mr-3" />
               Form Validate
              </h4>
            }
          >
            <hr></hr>
            <div
              class="ant-card gx-card-widget "
              style={{ background: this.state.colorStatus }}
            >
              <div class="ant-card-body">
                <span class="gx-widget-badge">
                  transaction info{" "}
                </span>
                <br></br>
                <h1 class="gx-fs-xxxl gx-font-weight-semi-bold gx-mb-3 gx-mb-sm-4">
                  Amount:
                  {this.state.RecordValues ? (
                    <>
                      {this.state.RecordValues.amount ? (
                        <NumberFormat
                          value={this.state.RecordValues.amount}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                        />
                      ) : null}
                    </>
                  ) : (
                      null
                    )}
                </h1>
                <p>
                  {" "}
                  <b>Rut:
                  {this.state.RecordValues
                      ? formatRut(this.state.RecordValues.rut)
                      : ""}</b>
                </p>

                <p>
                  <b> Date Request:</b>
                  <b>{this.state.RecordValues ? this.state.RecordValues.datetime : ""}</b>
                </p>

                {this.state.RecordValues ? (
                  <p>
                    <b> Operation Code:</b>
                    <b>{this.state.RecordValues.bankOperationCode}</b>
                  </p>
                ) : (
                    ""
                  )}
                <hr></hr>
              </div>
            </div>

            {this.state.resp ? (
              this.state.ErrorLimit ? (
                <Row>
                  <Col lg={24} md={24} sm={24} xs={24} className="contenedorAlert">
                    <Alert
                      message="Result"
                      description={`${this.state.resp.message} `}
                      type="success"
                    />
                  </Col>
                </Row>
              ) : (
                  <div>
                    <Alert
                      message="Result"
                      description={`  ${this.state.resp.message}  `}
                      type="success"
                    />
                    <hr></hr>
                  </div>
                )
            ) : (
                <div>
                  {this.state.spin ? (
                    <div>
                      Loading Operation......
                      <Spin size="large" />
                    </div>
                  ) : (
                      ""
                    )}
                  <hr></hr>
                </div>
              )}

            <Form
              className="gx-signup-form gx-form-row0 gx-mb-0"
              onSubmit={this.handleSubmit}
            >
              <FormItem label="Request Id:">
                <div className="gx-mb-3" style={{ width: "300px" }}>
                  {getFieldDecorator("commerceReqId", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter the Commerce Request Id!!"
                      }
                    ]
                  })(
                    <Input placeholder="Commerce Request Id:" />
                  )}
                </div>
              </FormItem>

              <FormItem label="User Email:">
                <div className="gx-mb-3" style={{ width: "300px" }}>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter the Email!!"
                      }
                    ]
                  })(
                    <Input placeholder="Commerce Request Id:" />
                  )}
                </div>
              </FormItem>

              <FormItem label="User Name:">
                <div className="gx-mb-3" style={{ width: "300px" }}>
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter the Name!!"
                      }
                    ]
                  })(
                    <Input placeholder="Name:" />
                  )}
                </div>
              </FormItem>


              <Form.Item name="merchant" label="Merchants">
                <div className="gx-mb-3" style={{ width: "300px" }}>
                  {getFieldDecorator("merchant", {
                    // initialValue:childrenEdit,// ['a10', 'c12'],//childrenEdit,//
                    validateTrigger: ["onChange", "onBlur"],
                    rules: [
                      {
                        required: true,
                        message: "Please enter the Merchant Default!"
                      }
                    ]
                  })(
                    <Select
                      //mode="tags"
                      // mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Please select Merchant"
                    // defaultValue={this.state.type !== "edit"?children:['a10', 'c12']}
                    >
                      {children}
                    </Select>
                  )}
                </div>
              </Form.Item>
              <>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  Validate
                  </Button>
                <Button
                  type="primary"
                  className="gx-mb-0"
                  onClick={this.resetAll}
                >
                  clean
                  </Button>
              </>

            </Form>
          </Widget>
        </Col>

      </Row>
    );
  }
}

const CallbackForm = Form.create()(Callback);

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;

  const { dataUser } = settings;

  return { authUser, dataUser };
};
export default connect(mapStateToProps)(CallbackForm);

