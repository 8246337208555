import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

// Add this import:

import {AppContainer} from 'react-hot-loader';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <GoogleReCaptchaProvider
        useEnterprise={true}
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SECRET_KEY}
        scriptProps={{ async: true, defer: true, appendTo: "body" }}
      >
        <NextApp/>
      </GoogleReCaptchaProvider>
    </AppContainer>,
    document.getElementById('root')
  );
};

// Do this once
/* registerServiceWorker(); */
unregister();
// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
