import React, { Component } from "react";
import Widget from "../../../components/Widget";
import { connect } from "react-redux";
import CryptoJS from 'crypto-js';
class CryptoMaintainer extends Component {

  componentDidMount = async () => {
 
  }

  render() {
   //  if (this.props.dataUser.typeUser != "admin")
    //   return "No permissions to enter this route";
    /*
    let body = {
     "commerceId":  this.props.merchant.code
    }*/
    let key = "";
    switch (this.props.merchant.code) {
      case process.env.REACT_APP_MERCHANT_ID_JPRO:
        key = process.env.REACT_APP_MERCHANT_ID_JPRO_KEY;
        break;
      case process.env.REACT_APP_MERCHANT_ID_JCOM:
        key = process.env.REACT_APP_MERCHANT_ID_JCOM_KEY;
        break;
      default:
        break;
    }

    let url = "";
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case "prod":
        url = `https://stratechcorp.retool.com/embedded/public/d1eca5d7-1558-4e99-a3a9-504445b54547?typeUser=${this.props.dataUser.typeUser}&key=${key}&country=${this.props.country.code}`;
        break;
      default:
        url = `https://stratechcorp.retool.com/embedded/public/fc5358f3-67bf-441a-91fb-c1bf3db4a978?typeUser=${this.props.dataUser.typeUser}&key=${key}&country=${this.props.country.code}`;
        break;
    }
    // var objJSON = JSON.stringify(body);
    // console.log("DATA --------------------------", objJSON, process.env.REACT_APP_ENCRYPT_RETOOL)
    // var objEncrypted = CryptoJS.AES.encrypt(objJSON, process.env.REACT_APP_RECAPTCHA_SECRET_KEY).toString();
    // console.log("objEncrypted ------------------------------", objEncrypted)
    return (
      <>
      <Widget title="Crypto">
        <iframe frameBorder="0" src={url} width="100%" height="660px" />
      </Widget>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { dataUser, country, merchant } = settings;
  console.log("AUTH", authUser, dataUser, country, merchant)
  return { authUser, dataUser, country, merchant };
};
export default connect(mapStateToProps)(CryptoMaintainer);