import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  Alert,
  Row,
  Col,
  Checkbox,
  Select,
  Badge,
  Spin,
  Collapse
} from "antd";
import Widget from "components/Widget/index";
import API from "../../../util/api";
import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebase";
import moment from "moment-timezone";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import { AllHtmlEntities } from "html-entities";
import currencyNumberFormat from "../../../util/currencyNumberFormat";
import NumberFormat from "react-number-format";
import "./stylesCallback.css";

const FormItem = Form.Item;
const db = firebase.firestore();
const entities = new AllHtmlEntities();
const { Panel } = Collapse;
class callbackPayOut extends Component {
  state = {
    resp: "",
    Resend: false,
    deco: false,
    dataTransfer: [],
    timeZone: "America/Santiago",
    dateRequest: "",
    colorStatus: "rgb(8,5,5,0.13489145658263302)",
    status: "",
    merchant: "",
    spin: false,
    spinListAdmin: false,
    Limit: 0,
    TransactionPendingAdmin: [],
    ErrorLimit: false,
    viewPinCode: false,
    codigoDropdown: "",
    newOperation: " "
  };

  componentDidMount = async e => {
    this.getAdminApproval();
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.payMethod !== prevProps.payMethod) {
        this.getAdminApproval();
      }
    }
  }

  getLimit = async country => {
    await db
      .collection("parametrosGenerales")
      .doc("callback")
      // .collection("PE")//comentar para produccion
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log(
            `No matching documents in. commissions getLimit,  ${country} `
          );
        }
        console.log(" getLimit data", snapshot.data(), this.props.payMethod);
        let limite = 0;
        limite = snapshot.data().limiteAirtmPayOut;
        this.setState({
          Limit: limite
        });
      })
      .catch(err => {
        console.log("ERROR IN getLimit", err);
        throw err;
      });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      resp: {
        message: "Procesando ..."
      },
      spin: true
    });

    let validator = true;
    await this.getLimit(this.state.dataTransfer.country);
    console.log(
      "quantity Limit",
      Number(this.state.dataTransfer.quantity),
      Number(this.state.Limit)
    );
    if (Number(this.state.dataTransfer.quantity) >= Number(this.state.Limit)) {
      validator = false;
    }

    if (
      this.props.dataUser.typeUser === "admin" ||
      this.props.dataUser.typeUser === "soporteAdmin"
    ) {
      validator = true;
    }
    try {
      this.props.form.validateFields(async (err, values) => {
        let idZippy = values.idZippy;
        if (!err) {
          if (validator) {
            console.log(
              "Received values of form: ",
              values,
              "resend",
              this.state.Resend
            );

            if (values.codigo === "0") {
              //ESTO SE EJECUTA EN CODIGO 0
              console.log("ESTO SE EJECUTA EN CODIGO 0", values.codigo);
              //SE AGREGA EL CAMPO  operationData
              let operationCode;
              if (this.state.Resend === false) {
                /*   if (values.operationCode.match(/^\d+$/)) { */
                operationCode = values.operationCode;
                /*     console.log("operationCode", operationCode);
                } else {
                  this.setState({
                    resp: {
                      message:
                        "Error de Formato"
                    },
                    spin: false
                  });
                  setTimeout(() => {
                    this.setState({
                      resp: "",
                    });
                  },
                    5000);
                  return false
                } */
              }
              let payload;
              console.log("payload1", payload);

              payload = {
                id: values.idZippy,
                code: Number(values.codigo),
                operationCode: operationCode,
                user: this.props.authUser,
                resend: this.state.Resend,
                aggregator: values.agregator
              };
              console.log("payload", payload);
              // LLAMAR A LA API
              await API.utils
                .CallbackPayOut(payload)
                .then(async resp => {
                  console.log("resp CallbackPayOut", resp);
                  if (resp.status === "ok" && this.state.Resend === true) {
                    if (this.state.Resend === true) {
                      this.setState({
                        resp: {
                          message: " The status of the resend  is OK!"
                        }
                      });
                    } else {
                      this.setState({
                        resp: {
                          message: resp.message
                        }
                      });
                    }

                    this.updateQuery(idZippy);
                    await this.deleteAdminApprovalPayIn(
                      idZippy,
                      this.state.dataTransfer.collection
                    );
                    /*    this.resetAll(); */
                    setTimeout(() => {
                      this.getAdminApproval();
                    }, 1000);
                  } else {
                    if (resp.status !== 500) {
                      switch (resp.code.toString()) {
                        case "0":
                          this.setState({
                            resp: {
                              message: resp.message
                            }
                          });

                          this.updateQuery(idZippy);
                          await this.deleteAdminApprovalPayIn(
                            idZippy,
                            this.state.dataTransfer.collection
                          );
                          setTimeout(() => {
                            this.getAdminApproval();
                          }, 1000);
                          break;
                        case "9":
                          this.setState({
                            resp: {
                              message:
                                " The status of the transaction is changed to Pending!"
                            }
                          });
                          this.updateQuery(idZippy);
                          await this.deleteAdminApprovalPayIn(
                            idZippy,
                            this.state.dataTransfer.collection
                          );
                          setTimeout(() => {
                            this.getAdminApproval();
                          }, 1000);
                          break;
                        case "12":
                          this.setState({
                            resp: {
                              message:
                                " The status of the transaction is changed to Failed!"
                            }
                          });
                          this.updateQuery(idZippy);
                          await this.deleteAdminApprovalPayIn(
                            idZippy,
                            this.state.dataTransfer.collection
                          );
                          setTimeout(() => {
                            this.getAdminApproval();
                          }, 1000);
                          break;

                        default:
                          this.setState({
                            resp: {
                              message: resp.status
                            }
                          });
                          break;
                      }
                    } else {
                      this.setState({
                        resp: {
                          message: resp.description
                        }
                      });
                    }
                  }
                })
                .catch(error => {
                  console.log(
                    `error al  llamar a la api ,detalle de error:${error}`
                  );
                });
            } else {
              //ESTO SE EJECUTA EN CODIGO 12 y 9

              await API.utils
                .CallbackPayOut({
                  id: values.idZippy,
                  code: Number(values.codigo),
                  operationCode: "",
                  user: this.props.authUser,
                  resend: this.state.Resend,
                  aggregator: values.agregator
                })
                .then(async resp => {
                  console.log(resp);

                  switch (resp.code.toString()) {
                    case "0":
                      this.setState({
                        resp: {
                          message:
                            " The status of the transaction is changed to Validated!"
                        }
                      });
                      this.updateQuery(idZippy);
                      await this.deleteAdminApprovalPayIn(
                        idZippy,
                        this.state.dataTransfer.collection
                      );
                      setTimeout(() => {
                        this.getAdminApproval();
                      }, 1000);
                      break;
                    case "9":
                      this.setState({
                        resp: {
                          message:
                            " The status of the transaction is changed to Pending!"
                        }
                      });
                      this.updateQuery(idZippy);
                      await this.deleteAdminApprovalPayIn(
                        idZippy,
                        this.state.dataTransfer.collection
                      );
                      setTimeout(() => {
                        this.getAdminApproval();
                      }, 1000);
                      break;
                    case "12":
                      this.setState({
                        resp: {
                          message:
                            " The status of the transaction is changed to Failed!"
                        }
                      });
                      this.updateQuery(idZippy);
                      await this.deleteAdminApprovalPayIn(
                        idZippy,
                        this.state.dataTransfer.collection
                      );
                      setTimeout(() => {
                        this.getAdminApproval();
                      }, 1000);
                      break;

                    default:
                      this.setState({
                        resp
                      });
                      break;
                  }
                })
                .catch(error => {
                  console.log(
                    `error al  llamar a la api ,detalle de error:${error}`
                  );
                });
            }
          } else {
            this.setState({
              spin: false
            });
            this.setState({
              resp: {
                message:
                  "You cannot Validate this Transaction. Only the Admin can do it. "
              },
              ErrorLimit: true
            });
          }
        } else {
          this.setState({
            resp: "",
            spin: false
          });
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  resetTransactionInfo = () => {
    this.setState({
      ErrorLimit: false,
      dataTransfer: [],
      colorStatus: "rgb(8,5,5,0.13489145658263302)",
      status: "",
      resp: "",
      merchant: "",
      spin: false,
      dateRequest: ""
    });
  };

  resetAll = () => {
    this.props.form.setFieldsValue({
      ["idZippy"]: "",
      ["codigo"]: "",
      ["operationCode"]: "",
      ["prepaidCode"]: "",
      ["cardNumber"]: ""
    });

    this.setState({
      resp: "",
      deco: false,
      dataTransfer: [],
      dateRequest: "",
      colorStatus: "rgb(8,5,5,0.13489145658263302)",
      status: "",
      merchant: "",
      spin: false,
      Resend: false,
      spinListAdmin: false,
      ErrorLimit: false,
      viewPinCode: false,
      newOperation: " "
    });

    console.log("state", this.state);
  };

  addTransactionListAdmin = () => {
    console.log(
      "addTransactionListAdmin dataTransfer",
      this.state.dataTransfer
    );

    if (this.state.TransactionPendingAdmin.length > 0) {
      let index = this.state.TransactionPendingAdmin.findIndex(
        transaction => transaction.id === this.state.dataTransfer.id
      );
      if (index !== -1) {
        this.setState({
          resp: {
            message: "This Transaction is already being reviewed by the Admin.."
          }
        });
        return false;
      } else {
        let id;
        let codigo;
        let operationCode;
        this.props.form.validateFields(async (err, values) => {
          id = values.idZippy;
          codigo = values.codigo;
          operationCode = values.operationCode;
        });
        this.saveRecommended(
          id,
          codigo,
          this.state.Resend,
          operationCode,
          this.state.dataTransfer.collection
        );
        setTimeout(() => {
          this.setState({
            resp: "",
            ErrorLimit: false
          });
        }, 2000);
        this.resetAll();
      }
    } else {
      let id;
      let codigo;
      let operationCode;
      this.props.form.validateFields(async (err, values) => {
        id = values.idZippy;
        codigo = values.codigo;
        operationCode = values.operationCode;
      });
      this.saveRecommended(
        id,
        codigo,
        this.state.Resend,
        operationCode,
        this.state.dataTransfer.collection
      );
      setTimeout(() => {
        this.setState({
          resp: "",
          ErrorLimit: false
        });
      }, 2000);
      this.resetAll();
    }
  };

  validarTransaction = () => {
    if (
      this.props.dataUser.typeUser === "Admin" ||
      this.props.dataUser.typeUser === "soporteAdmin"
    ) {
      let id = this.state.TransactionPendingAdmin[0].id;
      let index = this.state.TransactionPendingAdmin.findIndex(
        transaction => transaction.id === id
      );
      if (index !== -1) this.state.TransactionPendingAdmin.splice(index, 1);
      console.log(this.state.TransactionPendingAdmin);
    } else {
      console.log("Usuario sin Autorizacion");
    }
  };

  saveRecommended = async (id, codigo, resend, operationCode, collection) => {
    let _el = this;
    let data = [];
    let updateData = {};
    if (codigo === "0") {
      updateData = {
        id: id,
        adminApproval: 1,
        recommended: codigo,
        recommendedOperation: operationCode,
        resend: resend,
        collection,
        userRecommended: this.props.authUser
      };
    } else {
      updateData = {
        id: id,
        adminApproval: 1,
        recommended: codigo,
        collection,
        userRecommended: this.props.authUser
      };
    }
    /*     console.log(updateData) */

    API.utils
      .updateAdminApprovalAirtmPayOut(updateData)
      .then(resp => {
        console.log("Document successfully updated!");
        _el.getAdminApproval();
      })
      .catch(err => {
        console.log("ERROR IN saveRecommended", err);
      });
  };

  cancelTransacion = async (id, collection) => {
    console.log("cancelTransacion", id, collection);
    await this.deleteAdminApprovalPayIn(id, collection);
    setTimeout(() => {
      this.getAdminApproval();
    }, 1000);
  };

  deleteAdminApprovalPayIn = (id, collection) => {
    let updateData = {
      id: id,
      adminApproval: 0,
      recommended: "",
      recommendedOperation: "",
      collection: collection
    };

    API.utils
      .updateAdminApprovalAirtmPayOut(updateData)
      .then(resp => {
        console.log("Document successfully updated!");
      })
      .catch(err => {
        console.log("ERROR IN deleteAdminApprovalPayIn", err);
      });
  };

  getAdminApproval = async () => {
    console.log("getAdminApproval", this.props.payMethod.toString());
    let resp = "";
    this.setState({
      spinListAdmin: true
    });
    let updateData = {
      payMethod: this.props.payMethod.toString()
    };
    resp = await API.utils
      .getAdminApprovalAirtmPayOut(updateData)
      .then(resp => {
        if (!resp.empty) {
          this.setState({
            TransactionPendingAdmin: resp,
            spinListAdmin: false
          });
        }
      })
      .catch(err => {
        console.log("ERROR IN getAdminApprovalAirtmPayOut", err);
      });
    return resp;
  };

  onChange = e => {
    console.log("onChange", e.target.checked);
    this.setState({ Resend: e.target.checked });
    if (e.target.checked) {
      /*   this.props.form.resetFields(
          ["operationCode"]
        ); */
      this.props.form.setFieldsValue({
        ["codigo"]: "0"
      });
    }
  };

  onChangeID = e => {
    console.log("onChangeID", e);
    if (e === "0") {
      this.setState({ deco: true });
      console.log("onChangeID", e);
    } else {
      this.setState({ deco: false });
      console.log("onChangeID", "false");
    }
  };

  getDataTrx = async e => {
    this.setState({
      spin: true
    });
    let id = e.target.value;
    console.log("getDataTrx", e.target.value);
    this.updateQuery(id);
  };

  getDataTrxAdmin = async (
    id,
    recommendCode,
    operationCode,
    resend,
    recommendedOperation
  ) => {
    this.resetTransactionInfo();
    this.resetAll();
    this.setState({
      spin: true
    });
    await this.updateQuery(id);
    if (Number(recommendCode) === 0) {
      this.setState({
        deco: true,
        resp: ""
      });
      if (resend) {
        this.props.form.setFieldsValue({
          ["idZippy"]: id,
          ["codigo"]: recommendCode,
          ["operationCode"]: ""
        });
        this.setState({
          Resend: resend,
          newOperation: ""
        });
      } else {
        this.props.form.setFieldsValue({
          ["idZippy"]: id,
          ["codigo"]: recommendCode,
          ["operationCode"]: recommendedOperation
        });
        this.setState({
          Resend: false,
          newOperation: recommendedOperation
        });
      }
    } else {
      this.setState({
        resp: "",
        Resend: false
      });
      this.props.form.setFieldsValue({
        ["idZippy"]: id,
        ["codigo"]: recommendCode
      });
    }

    this.setState({
      spin: false
    });

    return true;
  };

  transformCommerceID = commerceId => {
    let name = "";
    this.props.dataUser.merchants.filter(val => {
      if (val.code.toString() === commerceId.toString()) {
        name = val.name;
      }
    });
    return name;
  };

  updateQuery = async id => {
    try {
      this.setState({
        viewPinCode: false
      });
      let data = [];
      if (id !== "") {
        let resp = await API.utils.getDataForIdZippy(id);

        if (/* !resp.empty || */ resp.status != 400) {
          console.log("resp getDataForIdZippy ", resp);

          let dateRequest = moment(
            new Date(
              resp[0].dataRequest
                ? resp[0].dataRequest._seconds * 1000
                : resp[0].dateRequest._seconds * 1000
            ).toISOString()
          )
            .toDate()
            .toLocaleString("es-CL", {
              timeZone: this.state.timeZone,
              timeZoneName: "short"
            });
          switch (resp[0].code.toString()) {
            case "0":
              this.setState({
                colorStatus: "rgb(53 138 42 / 13%)",
                status: "OK"
              });
              break;
            case "9":
              this.setState({
                colorStatus: "rgb(243 216 26 / 13%)",
                status: "Pending"
              });

              break;
            case "12":
              this.setState({
                colorStatus: "rgb(243 26 26 / 13%)",
                status: "Failed"
              });
              break;
            case "1":
              this.setState({
                colorStatus: "rgb(243 26 26 / 13%)",
                status: "Failed"
              });
              break;
            case "-1":
              this.setState({
                colorStatus: "rgb(243 26 26 / 13%)",
                status: "Failed"
              });
              break;
            case "-2":
              this.setState({
                colorStatus: "rgb(243 26 26 / 13%)",
                status: "Failed"
              });
              break;
            case "-8":
              this.setState({
                colorStatus: "rgb(243 26 26 / 13%)",
                status: "Failed"
              });
              break;
            default:
              this.setState({
                colorStatus: "rgb(8,5,5,0.13489145658263302)"
              });
              break;
          }
          this.setState({
            dataTransfer: {
              ...resp[0],
              id,
              dateRequestFormat: dateRequest,
              merchant: resp[2].merchant,
              collection: resp[1].type,
              payMethod: resp[1].payMethod
            },
            spin: false
          });
          console.log("resp getDataForIdZippy ", resp, this.state.dataTransfer);
        } else {
          console.log("resp status ", resp.status);
          this.resetTransactionInfo();
          this.setState({
            resp: {
              message: `No Zippy id found associated with this paymethod:(${this.props.payMethod
                .toString()
                .toUpperCase()}) , try another paymethod`
            }
          });
        }
      }
    } catch (err) {
      console.log("Error en getDataTrx :", err);
      this.resetTransactionInfo();
    }
  };

  generatePin = () => {
    let randomTest = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min);
    };
    let operationCode = randomTest(100000000, 999900000).toString();

    this.setState({
      newOperation: operationCode
    });
    /*      return operationCode; */
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const codigoDropdown = this.state.codigoDropdown;
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 12
        },
        sm: {
          span: 8
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16
        }
      }
    };

    return (
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Widget
            title={
              <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
                <i className="icon icon-mail-open gx-mr-3" />
                Callback Manual {this.props.payMethod}
              </h4>
            }
          >
            <hr></hr>

            {/* {this.state.dataTransfer ? ( */}
            <div
              class="ant-card gx-card-widget "
              style={{ background: this.state.colorStatus }}
            >
              <div class="ant-card-body">
                <span class="gx-widget-badge">
                  transaction info{" "}
                  {this.state.status === "OK" ? (
                    <Badge color="green" text={"Validated"} />
                  ) : this.state.status === "Pending" ? (
                    <Badge color="yellow" text={this.state.status} />
                  ) : (
                    <Badge color="red" text={this.state.status} />
                  )}
                </span>
                <br></br>
                <Row justify="space-between">
                  <Col lg={8} md={10} sm={24} xs={24}>
                    <h1 class="gx-fs-xxxl gx-font-weight-semi-bold gx-mb-3 gx-mb-sm-4">
                      Amount:
                    </h1>
                  </Col>
                  <Col lg={16} md={14} sm={24} xs={24}>
                    <h1 class="gx-fs-xxxl gx-font-weight-semi-bold gx-mb-3 gx-mb-sm-4">
                      {this.state.dataTransfer ? (
                        <>
                          {this.state.dataTransfer.quantity
                            ? currencyNumberFormat(
                                this.state.dataTransfer.quantity,
                                this.props.country.code
                              )
                            : null}
                        </> //this.state.dataTransfer.quantity
                      ) : null}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={24} xs={24}>
                    <b>Zippy Id: </b>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    {this.state.dataTransfer ? this.state.dataTransfer.id : ""}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>Name: </b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer
                      ? entities.decode(this.state.dataTransfer.name)
                      : ""}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>rut: </b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer.documentId
                      ? formatRut(this.state.dataTransfer.documentId)
                      : ""}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6} md={6} sm={24} xs={24}>
                    <b>Email:</b>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    {this.state.dataTransfer
                      ? this.state.dataTransfer.email
                      : ""}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b> Date Request:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer.dateRequestFormat
                      ? this.state.dataTransfer.dateRequestFormat
                      : ""}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b> Merchant:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer.merchant}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>Pay Method:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer.payMethod}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>Country:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer.country}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>Agregator:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer.collection
                      ? this.state.dataTransfer.collection.toString() /* .substring(0, this.state.collection.length - 1) */
                      : ""}
                  </Col>
                </Row>
                <br />
                {this.state.dataTransfer.operationCode ? (
                  <Row>
                    <Col lg={6} md={8} sm={24} xs={24}>
                      <b> Operation Code:</b>
                    </Col>
                    <Col lg={18} md={12} sm={24} xs={24}>
                      <b>{this.state.dataTransfer.operationCode}</b>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <hr></hr>

                <h6 className="gx-mb-3 gx-mb-m-2 gx-font-weight-light">
                  {/*   Operation Example = 08/04/2020 00:13:27 07019313 */}
                </h6>

                <h6>
                  Resend: send Validated request to the merchant ,only
                  transactions ok!
                </h6>
              </div>
            </div>

            {this.state.resp ? (
              this.state.ErrorLimit ? (
                <Row>
                  <Col lg={24} className="contenedorAlert">
                    <Alert
                      message="Result"
                      description={`${this.state.resp.message} `}
                      type="success"
                    />
                  </Col>
                </Row>
              ) : (
                <div>
                  <Alert
                    message="Result"
                    description={`${this.state.resp.message}`}
                    type="success"
                  />
                  <hr></hr>
                </div>
              )
            ) : (
              <div>
                {this.state.spin ? (
                  <div>
                    Loading Operation......
                    <Spin size="large" />
                  </div>
                ) : (
                  ""
                )}
                <hr></hr>
              </div>
            )}

            <Checkbox checked={this.state.Resend} onChange={this.onChange}>
              Resend
            </Checkbox>

            <Form
              {...formItemLayout}
              className="gx-signup-form gx-form-row0 gx-mb-0"
              onSubmit={this.handleSubmit}
            >
              <FormItem label="ID Zippy:">
                <div className="gx-mb-3" style={{ padding: "0px auto" }}>
                  {getFieldDecorator("idZippy", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter the Trx ID!!"
                      }
                    ]
                  })(
                    <Input placeholder="ID Zippy" onChange={this.getDataTrx} />
                  )}
                </div>
              </FormItem>
              <FormItem label={"Type Agregator"}>
                <div className="gx-mb-3">
                  {getFieldDecorator("agregator", {
                    validateTrigger: ["onChange", "onBlur"],
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Please enter the response Type!"
                      }
                    ]
                  })(
                    // <Input placeholder="Código" onChange={this.onChangeID}/>
                    <Select
                      style={{ padding: "0px auto" }}
                      onChange={this.onChangeID}
                    >
                      <Option value="">Select Type Agregator</Option>
                      <Option value="monnet">MONNET</Option>
                      <Option value="alps">ALPS</Option>
                      <Option value="airtm">AIRTM </Option>
                      <Option value="cashout">CASHOUT</Option>
                      <Option value="pagsmile">PAGSMILE</Option>
                    </Select>
                  )}
                </div>
              </FormItem>
              <FormItem
                label={this.state.ErrorLimit ? "Update To" : "Status Code: "}
              >
                <div className="gx-mb-3">
                  {getFieldDecorator("codigo", {
                    validateTrigger: ["onChange", "onBlur"],
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Please enter the response code!"
                      }
                    ]
                  })(
                    // <Input placeholder="Código" onChange={this.onChangeID}/>
                    <Select
                      style={{ padding: "0px auto" }}
                      onChange={this.onChangeID}
                    >
                      <Option value="">Select state for trasnsaction</Option>
                      <Option value="0">Validate (0) </Option>
                      <Option value="9">Pending (9)</Option>
                      <Option value="12">Failed (12)</Option>
                    </Select>
                  )}
                </div>
              </FormItem>

              {this.state.Resend !== true && this.state.deco ? (
                //this.state.deco ?
                <FormItem label="Operation:">
                  <div className="gx-mb-3" style={{ padding: "0px auto" }}>
                    {getFieldDecorator("operationCode", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter the Operation code!!"
                        }
                      ]
                    })(<Input placeholder="Operation code" />)}
                  </div>
                </FormItem>
              ) : (
                //: ""
                ""
              )}

              {this.state.viewPinCode ? (
                <FormItem label=" Pin Code:">
                  <div className="gx-mb-3" style={{ padding: "0px auto" }}>
                    {getFieldDecorator(
                      "prepaidCode",
                      {}
                    )(<Input placeholder="Prepaid Code" />)}
                  </div>
                </FormItem>
              ) : null}
              {this.state.ErrorLimit ? (
                <Row>
                  <Col lg={12} className="centrarBtn">
                    <Button
                      type="primary"
                      onClick={this.addTransactionListAdmin}
                    >
                      {" "}
                      + admin approval
                    </Button>
                    {/*  </Col>
                  <Col lg={12} className="centrarBtn"> */}

                    <Button
                      //blacklist.jsstyle={{ width: "50%" }}
                      block
                      type="danger"
                      onClick={this.resetAll}
                    >
                      Cancel
                    </Button>
                    <hr></hr>
                  </Col>
                </Row>
              ) : (
                <>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    Validate
                  </Button>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    onClick={this.resetAll}
                  >
                    clean
                  </Button>
                </>
              )}
            </Form>
          </Widget>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Widget
            title={
              <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
                <i className="icon icon-mail-open gx-mr-3" />
                waiting for administrator approval {this.props.payMethod}
              </h4>
            }
          >
            <Row>
              <Col lg={24}>
                {this.state.spinListAdmin ? (
                  <div>
                    Loading Information......
                    <Spin size="large" />
                  </div>
                ) : (
                  <>
                    {this.state.TransactionPendingAdmin.map(
                      (transaction, index) => {
                        return (
                          <Row
                            className="contenedorPendientes"
                            style={{ fontSize: 12 }}
                          >
                            <Collapse bordered={false}>
                              <Panel
                                header={
                                  <span>
                                    {/*   <div> */}
                                    <b> ID Zippy:</b>
                                    <a
                                      onClick={() =>
                                        this.getDataTrxAdmin(
                                          transaction.id,
                                          transaction.recommended,
                                          transaction.operationCode,
                                          transaction.resend,
                                          transaction.recommendedOperation
                                        )
                                      }
                                    >
                                      {" "}
                                      {transaction.id}
                                    </a>{" "}
                                  </span>
                                }
                                style={{ fontSize: 12, borderBottom: 0 }}
                              >
                                <Col lg={24}>
                                  <span>
                                    <b>Amount : </b>
                                    {currencyNumberFormat(
                                      transaction.quantity,
                                      this.props.country.code
                                    )}
                                  </span>
                                </Col>

                                <Col lg={24}>
                                  <span>
                                    <b>Name: </b>
                                    {transaction.name}
                                  </span>
                                </Col>
                                <Col lg={24}>
                                  <span>
                                    <b>Rut:</b> {transaction.documentId}
                                  </span>
                                </Col>
                                <Col lg={24}>
                                  <span>
                                    <b>Merchant:</b>
                                    {this.transformCommerceID(
                                      transaction.commerceId
                                    )}
                                  </span>
                                </Col>

                                <Col lg={24}>
                                  <span>
                                    <b> Recommended code / status:</b>{" "}
                                    {transaction.recommended === "0"
                                      ? "validate"
                                      : transaction.recommended === "9"
                                      ? "pending"
                                      : transaction.recommended === "12"
                                      ? "fallido"
                                      : ""}
                                  </span>
                                </Col>
                                {transaction.resend ? (
                                  <Col lg={24}>
                                    <span>
                                      <b> Resend:</b> ✈️
                                    </span>
                                  </Col>
                                ) : null}

                                {transaction.recommendedOperation ? (
                                  <Col lg={24}>
                                    <span>
                                      <b> Operation Recommended:</b>{" "}
                                      {transaction.recommendedOperation}
                                    </span>
                                  </Col>
                                ) : null}
                                {transaction.userRecommended ? (
                                  <Col lg={24}>
                                    <span>
                                      <b> Support:</b>{" "}
                                      {transaction.userRecommended}
                                    </span>
                                  </Col>
                                ) : null}

                                <Col lg={24}>
                                  <span>
                                    <div style={{ paddingTop: "8px" }}>
                                      <Button
                                        type="danger"
                                        onClick={() => {
                                          this.cancelTransacion(
                                            transaction.id,
                                            transaction.collection
                                          );
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </span>
                                </Col>
                              </Panel>
                            </Collapse>
                          </Row>
                        );
                      }
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Widget>
        </Col>
      </Row>
    );
  }
}

const CallbackForm = Form.create()(callbackPayOut);

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;

  const { dataUser, country } = settings;

  return { authUser, dataUser, country };
};
export default connect(mapStateToProps)(CallbackForm);
