import React from "react";
import { Button, Col, Row, DatePicker,Spin } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";
import ReactExport from "react-export-excel";
import Widget from "components/Widget/index";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import { AllHtmlEntities } from "html-entities";
import firebase from "firebase/app";

const entities = new AllHtmlEntities();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;
const db = firebase.firestore();
let query;

let dataList = [];

class TotalCashin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      timeZone: "America/Santiago",
      title: "Total Cashin ",
      filename: `Total Cashin`,
      mensaje: "",
      spin:""
    };
  }

  componentDidMount = () => {
    moment.tz.setDefault(this.state.timeZone);

   /*  let firstDay = moment
      .tz(this.state.timeZone)
      .startOf("month")
      .toDate();
    let lastDay = moment
      .tz(this.state.timeZone)
      .endOf("month")
      .toDate(); */
   /*  this.getData(firstDay, lastDay); */
    // CALCULO EL MES ACTUAL
    let mes = moment.tz(this.state.timeZone).format("MMMM");
    this.setState({
      mes: mes
    });
  };

  getData =async (firstDay, lastDay) => {

    dataList = [];

    query = db
      .collection("transfers")
      .where("code", "==", 0)
      .where(
        "dateRequest",
        ">=",
        moment(firstDay)
          .tz(this.state.timeZone)
          .toDate()
      )
      .where(
        "dateRequest",
        "<=",
        moment(lastDay)
          .tz(this.state.timeZone)
          .toDate()
      );

    query
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("  No matching documents.");
          this.setState({
            dataList,
            mensaje: " No matching documents."
          });

          return;
        }

        let resultadosOk = [];

        snapshot.forEach(doc => {
/************************************COMISIONES******************************************* */
let commissionesBd=this.props.comissionData?this.props.comissionData: JSON.parse(localStorage.getItem("comisiones"));
commissionesBd = commissionesBd.filter(item=> item.merchant===doc.data().commerceId)
commissionesBd = commissionesBd[0].commission.filter(item=> item.countrie===this.props.country.code)
let factor =commissionesBd[0].cashin;
/************************************************************************************************ */


          if (this.state.firstTime) {
            resultadosOk.push({
              date: moment(doc.data().dateRequest.toDate()).toLocaleString(
                "es-CL",
                {
                  timeZone: this.state.timeZone,
                  timeZoneName: "short"
                }
              ),
              quantity: doc.data().quantity
            });

            // OBJETO DE RESULTADO
            var docs = snapshot.docs.map(doc => doc.data());


            // SUMA TOTAL DEL MES
            let sum = _.sumBy(docs, item => Number(item.quantity));
            let count = docs.length;

            // CALCULO COMISIONES
            let commission = Number(
              parseFloat(Number(sum) * factor).toFixed(2)
            );
            let ivaCommission = Number(
              parseFloat(commission * 0.19).toFixed(2)
            );

            if (isNaN(commission)) {
              commission = 0;
              ivaCommission = 0;
            }
            let TotalSinCommission = 0;
            TotalSinCommission = sum - commission - ivaCommission;

            let response;

            console.log(
              "TotalSinCommission " +
                TotalSinCommission +
                "count: " +
                count +
                "suma: " +
                sum +
                "commission " +
                commission +
                "ivaCommission " +
                ivaCommission
            );

            response = {
              sum,
              count,
              TotalSinCommission
            };

            this.setState({
              dataCashin: response,
              firstTime: false
            });
          }

          let cashinId = doc.id;
          let factorCashin=commissionesBd[0].cashin;

          let commision = Number(
            Number(doc.data().quantity * factorCashin ).toFixed(0)
          );
          let iva = Number((commision * 0.19).toFixed(0));
          console.log("DATALISTpush", "=>", dataList);
          dataList.push({
            ...doc.data(),
            cashinId,
            commision,
            iva,
            totCommision: commision + iva,
            total: Number(doc.data().quantity) - (commision + iva)
          });
        });

        this.setState({
          dataList,
          mensaje: `    Number of records: ${dataList.length}`,
          spin:false        });
        console.log("DATALIST", "=>", dataList);
      })
      .catch(console.log);
  };

  onTimeZoneChange = zone => {
    console.log("zone", "=>", zone);
    this.setState({
      timeZone: zone
    });
  };
  updateSearchFilter(evt) {
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }
  onChangeRange = (dates, dateStrings) => {


    this.setState({
      dataList:[],
      mensaje: "",
      spin:true
    });



    let firstDay = moment(dateStrings[0], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .toDate();
    let lastDay = moment(dateStrings[1], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .endOf("day")
      .toDate();

    this.getData(firstDay, lastDay);
  };
  render() {
    this.columns = [
      {
        title: "Date Request",
        dataIndex: "dateRequest",
        key: "dateRequest",
        width: 200,
        render: date =>
          date.toDate().toLocaleString("es-CL", {
            timeZone: this.state.timeZone,
            timeZoneName: "short"
          }),

        sorter: (a, b) => a.dateRequest.seconds - b.dateRequest.seconds,
        sortOrder:
          this.state.sortedInfo.columnKey === "dateRequest" &&
          this.state.sortedInfo.order
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 150,
        render: name => entities.decode(name)
      },
      {
        title: "RUT",
        dataIndex: "rut",
        key: "rut",
        width: 150,
        render: rut => formatRut(rut)
      },
      {
        title: "Amount",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        render: text => (
          <di style={{ float: "right" }}>
            <NumberFormat
              value={text}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
            />
          </di>
        )
      },
      {
        title: `Merchant`,
        dataIndex: "commerceId",
        key: "commerceId",
        width: 100
      },
      {
        title: `Merchant id`,
        dataIndex: "commerceReqId",
        key: "commerceReqId",
        width: 100
      },
      {
        title: "ID",
        dataIndex: "cashinId",
        key: "cashinId",
        width: 100
      }
    ];

    return (
      /*  <div className="components-table-demo-control-bar"> */
      <>
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Widget>
            <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
              <i className="icon icon-editor gx-mr-3" />
              Multimerchant Cashin Report
            </h4>
            <hr></hr>
            {/*  <h6 className="gx-mb-3 gx-mb-m-2 gx-font-weight-light">
              Month: {this.state.mes}
            </h6> */}
            <Row>
              <Col lg={24} md={24} sm={24} xs={24}>
                <RangePicker
                  className="gx-mb-3 "
                  format="DD/MM/YYYY"
                  ranges={{
                    All: [
                      moment("01-01-2019", "MM-DD-YYYY").tz(
                        this.state.timeZone
                      ),
                      moment().tz(this.state.timeZone)
                    ],
                    Today: [
                      moment().tz(this.state.timeZone),
                      moment().tz(this.state.timeZone)
                    ],
                    Yesterday: [
                      moment()
                        .tz(this.state.timeZone)
                        .subtract(1, "days"),
                      moment()
                        .tz(this.state.timeZone)
                        .subtract(1, "days")
                    ],
                    "This week": [
                      moment()
                        .tz(this.state.timeZone)
                        .startOf("isoweek"),
                      moment()
                        .tz(this.state.timeZone)
                        .endOf("isoweek")
                    ],
                    "Last 7 Days": [
                      moment()
                        .tz(this.state.timeZone)
                        .subtract(6, "days"),
                      moment().tz(this.state.timeZone)
                    ],
                    "Last Month": [
                      moment()
                        .tz(this.state.timeZone)
                        .startOf("month")
                        .subtract(1, "months"),
                      moment()
                        .tz(this.state.timeZone)
                        .endOf("month")
                        .subtract(1, "months")
                    ],
                    "This Month": [
                      moment()
                        .tz(this.state.timeZone)
                        .startOf("month"),
                      moment()
                        .tz(this.state.timeZone)
                        .endOf("month")
                    ]
                  }}
                  onChange={this.onChangeRange}
                  defaultValue={[
                   // moment().tz(this.state.timeZone).startOf("month"),
                  //  moment().tz(this.state.timeZone).endOf("month")
                  ]}
                />
              </Col>

              <Col lg={12} md={12} sm={24} xs={24}>
                {/*  {"cantidad de REGISTROS:"} */}
                <ExcelFile
                  filename={this.state.filename}
                  element={
                    <Button
                      className="ant-btn-primary"
                      disabled={!(dataList.length > 0) ? true : false}
                    >
                      Download
                    </Button>
                  }
                >
                  <ExcelSheet data={this.state.dataList} name="Hoja 1">
                    <ExcelColumn
                      label="Date"
                      value={col =>
                        col.dateRequest.toDate().toLocaleString("es-CL", {
                          timeZone: this.state.timeZone,
                          timeZoneName: "short"
                        })
                      }
                    />
                    <ExcelColumn label="Name" value="name" />
                    <ExcelColumn label="Rut" value="rut" />
                    <ExcelColumn
                      label="Amount"
                      value={col => Number(col.quantity)}
                    />
                    <ExcelColumn label="ID Zippy" value="cashinId" />
                    <ExcelColumn label="Operation Code" value="operationCode" />
                    <ExcelColumn
                      label={`Commerce`}
                      value={col => col.commerceId}
                    />
                    <ExcelColumn
                      label={`ID Commerce`}
                      value={col => col.commerceReqId}
                    />

                    <ExcelColumn label="Commision" value="commision" />
                    <ExcelColumn label="IVA" value="iva" />
                    <ExcelColumn label="Tot Commision" value="totCommision" />
                    <ExcelColumn label="Total" value="total" />
                  </ExcelSheet>
                </ExcelFile>

                {this.state.spin===true?
                <>

{"  Generating File ..."} <Spin/></>
                :
                this.state.mensaje

                }

              </Col>
            </Row>
          </Widget>
        </Col>
      </Row>
      </>
    );
  }
}

/* export default TotalCashin; */

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant,comissionData ,country} = settings;
  return { authUser, merchant ,comissionData,country};
};
export default connect(mapStateToProps)(TotalCashin);
