import React from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Input,
  DatePicker,
  Divider,
  Form,
  Button,
  Tooltip,
  Checkbox
} from "antd";
import _ from "lodash";
import NumberFormat from "react-number-format";
import moment from "moment";
import ReactExport from "react-export-excel";
import Auxiliary from "../../../util/Auxiliary";
import { AllHtmlEntities } from "html-entities";
import copy from "copy-to-clipboard";
import { firebase } from "../../../firebase/firebase";
import {
  //format as formatRut
  validate as validateRut
  // clean as cleanRut
} from "rut.js";

const entities = new AllHtmlEntities();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const db = firebase.firestore();
const { RangePicker } = DatePicker;
const { TextArea } = Input;
/* const FormItem = Form.Item; */
const pagination = { position: "bottom" }; //bottom top both none
/* const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
}; */
class Dynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regValSanta: "",
      bordered: false,
      loading: false,
      pagination: { current: 1 },
      size: "middle", // default middle small
      expandedRowRender: props.expandedRowRender,
      title: undefined,
      showHeader: true,
      footer: () => (
        <div>
          Suma:{" "}
          <NumberFormat
            value={this.state.sumDataList}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        </div>
      ),
      rowSelection: false, // {} or false
      selectedRowKeys: [],
      scroll: undefined,
      searchFilter: "",
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      timeZone: "America/Santiago",
      defaultValueRangeFilter: [

        moment().tz("America/Santiago").subtract(1, "days"),
        moment().tz("America/Santiago")
      ]
    };
  }

  componentDidMount = async () => {
    
  }

  componentWillReceiveProps(nextProps) {
    this.filter(this.state.searchFilter, nextProps.dataList);
  }

  filter = (searchValue, nextProps) => {
    /*   console.log(" filter cashout :");
      console.log("nextProps", nextProps); */
    /*   debugger; */
    if (searchValue === "") {
      /*   console.log("nextProps", nextProps); */
      if (typeof nextProps !== "undefined") {
        /*  console.log("if"); */

        let selectedRowKeys = [];
        if (nextProps) {
          let a = nextProps.map(reg => {
            if (reg.documentation) {
              selectedRowKeys.push(reg.cashoutId.toString());
            }
          });
        }

        let txns = []

        if (this.props.isActiveOldCashout) {
          txns = nextProps.filter(txn => !txn.payrollId || !/^[0-9a-fA-F]{24}$/.test(txn.payrollId))
        } else {
          txns = nextProps
        }

        this.setState({
          dataList: nextProps,
          sumDataList: _.sumBy(txns, item => Number(item.quantity)),
          selectedRowKeys
        });
      } else {
        //  console.log("else :");

        let selectedRowKeys = [];
        if (this.props.dataList) {
          console.log('dataList', this.props.dataList);
          let a = this.props.dataList.map(reg => {
            if (reg.documentation) {
              selectedRowKeys.push(reg.cashoutId.toString());
            }
          });
        

        let txns = []

        if (this.props.isActiveOldCashout) {
          txns = this.props.dataList.filter(txn => !txn.payrollId || !/^[0-9a-fA-F]{24}$/.test(txn.payrollId))
        } else {
          txns = this.props.dataList
        }

        this.setState({
          dataList: this.props.dataList,
          sumDataList: _.sumBy(
            txns,
            item => Number(item.quantity),
            selectedRowKeys
          )
        });
        }
      }
      /*      this.validateFileBch(); */
    } else {
      try {
        let dataList;
        if (typeof nextProps !== "undefined") {
          dataList = nextProps;
        } else {
          dataList = this.props.dataList;
        }
        this.validateFileBch(dataList);

        //aca falta la validacion del santander

        this.validateSantander(dataList);
        /*  console.log("cashout in filter:", dataList); */
        const filter = _.filter(
          dataList,
          cashout =>
            cashout.name.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1 ||
            cashout.email.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1 ||
            cashout.rut.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
            cashout.quantity.toString().indexOf(searchValue) > -1 ||
            cashout.bankName.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1 ||
            cashout.cashoutId.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1 ||
            (cashout.commerceReqId
              ? cashout.commerceReqId
                .toString()
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cashout.typeAccount
              ? cashout.typeAccount
                .toString()
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cashout.valBCH
              ? cashout.valBCH
                .toString()
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cashout.valSAN
              ? cashout.valSAN
                .toString()
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "")
            ||
            (cashout.bankValidate
              ? cashout.bankValidate
                .toString()
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "")
        );
        console.log('filterfilter', filter);

        let txns = []

        if (this.props.isActiveOldCashout) {
          txns = filter.filter(txn => !txn.payrollId || !/^[0-9a-fA-F]{24}$/.test(txn.payrollId))
        } else {
          txns = filter
        }

        this.setState({
          dataList: filter,
          sumDataList: _.sumBy(txns, item => Number(item.quantity))
        });
      } catch (error) {
        console.log("Error in filter:", error);
      }
    }
    /*   this.validateFileBch(); */
  };

  updateSearchFilter(evt) {
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }

  onChangeRange = (dates, dateStrings) => {
    let firstDay = moment(dateStrings[0], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .toDate();
    let lastDay = moment(dateStrings[1], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .endOf("day")
      .toDate();

    this.props.getData(firstDay, lastDay);
  };

  onTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      pagination
    });
  };

  onTimeZoneChange = zone => {
    console.log("zone", "=>", zone);
    this.setState({
      timeZone: zone
    });
  };

  filterDocument = () => {
    var filterDocument = [];

    if (this.props.isActiveOldCashout) {
      filterDocument = this.state.dataList.filter(txn => !txn.payrollId || !/^[0-9a-fA-F]{24}$/.test(txn.payrollId));
    } else {
      filterDocument = this.state.dataList;
    }

    filterDocument = filterDocument.filter(reg => reg.documentation !== true);
    this.setState({
      filterDocument
    });
    /*   console.log("filterDocument", filterDocument); */
    return filterDocument;
  };

  nameMasivas = () => {
    let title = "Transferencias Masivas";
    try {
      let date = moment()
        .toDate()
        .toLocaleString("es-CL", {
          timeZone: this.state.timeZone,
          timeZoneName: "short"
        });
      // console.log("date",date);
      title = title + date.toString();
    } catch (e) {
      console.log(`error en nameMasivas : ${e}`);
    }
    return title;
  };

  downloadJson = () => {
    let dataList = [];
    dataList = this.filterDocument();

    let dataListSantander = [];
    let dataListOtros = [];

    let total = [];

    dataList.map(reg => {
      if (reg.bankId === "0") {
        dataListSantander.push({
          accountDestination: reg.numAccount,
          accountType: reg.typeAccountId,
          aliasDestination: entities.decode(reg.name).replace("ñ", "n"),
          amount: reg.quantity,
          bankDestination: reg.bankId,
          cashoutId: reg.cashoutId,
          emailDestination: reg.email,
          rutDestination: String(reg.rut).replace(/[\. ,:-]+/g, "").trim()
        });
      } else {
        dataListOtros.push({
          accountDestination: reg.numAccount,
          accountType: reg.typeAccountId,
          aliasDestination: entities.decode(reg.name).replace("ñ", "n"),
          amount: reg.quantity,
          bankDestination: reg.bankId,
          cashoutId: reg.cashoutId,
          emailDestination: reg.email,
          rutDestination: String(reg.rut).replace(/[\. ,:-]+/g, "").trim()
        });
      }
    });

    /*  console.log("dataListSantander", JSON.stringify(dataListSantander));
     console.log("dataListOtros", JSON.stringify(dataListOtros)); */

    copy(
      "[{" +
      JSON.stringify(dataListOtros)
        .replace("[", "")
        .replace("]", "") +
      "},{" +
      JSON.stringify(dataListSantander)
        .replace("[", "")
        .replace("]", "") +
      "}]"
    );

    let objCopy = [];
    objCopy.push({
      dataListSantander,
      dataListOtros
    });
  };

  onCopyClipBoard = record => {
    console.log(record);
    let objCopy = {
      accountDestination: record.numAccount, // "7000515617",
      accountType: this.getTypeAccount(record.typeAccount), //"CTV",
      aliasDestination: record.name, // "Córnéjó Nicolás",
      amount: Number(record.quantity), //cashoutPendientes.js 60000,
      bankDestination: record.bankId,
      cashoutId: record.cashoutId, // "test-dev-iEHWh6GzBrGx0e24G476",
      emailDestination: record.email, // "nik00.cd@gmail.com",
      rutDestination: String(record.rut).replace(/[\. ,:-]+/g, "").trim()  //"265465943"
    };
    copy(JSON.stringify(objCopy));
  };

  downloadTxtFile = () => {
    /* debugger; */
    /* debugger; */
    /* console.log("dataListsssssss",this.state.dataList[0]); */
    let dataList = [];
    debugger;
    dataList = this.filterDocument();
    /*    console.log("dataListFILTERCHECK", dataList); */
    let dta = [];
    let cuenta = 0;

    /*  if(this.state.dataList.length===1) */
    if (dataList.length === 1) {
      let register = dataList[0];
      /* let register=this.state.dataList[0]; */

      if (register.typeAccountId !== "AHO") {
        let operationType;
        let accountType;
        //para determinar /TIPO DE OPERACION //TEC:BANCO DE CHILE   //TOC:OTROS BANCOS
        if (register.bankId === "1") {
          //TEC:BANCO DE CHILE

          operationType = "TEC";
        } else {
          //TOC:OTROS BANCOS
          operationType = "TOB";
        }
        let rutBank;
        var databank = this.props.banks.filter(dato => dato.id == register.bankId)
        rutBank = databank[0].rut;
        /*    switch (register.bankId) {
             case "12":
               rutBank = "970300007"; //"BANCO ESTADO";
               break;
             case "1":
               rutBank = "970040005"; //"BANCO DE CHILE";97004000-5
               break;
             case "37":
               rutBank = "97036000K"; //"BANCO SANTANDER"; 97.036.000-k
               break;
             case "9":
               rutBank = "970110003"; //"BANCO INTERNACIONAL";
               break;
             case "14":
               rutBank = "970180001"; //"SCOTIABANK-DESARROLLO";
               break;
             case "16":
               rutBank = "970060006"; //"BCI";
               break;
             case "27":
               rutBank = "970230009"; //"CORP-BANCA";
               break;
             case "28":
               rutBank = "97080000K"; //"BICE";
               break;
             case "31":
               rutBank = "979510004"; //"HSBC BANK CHILE";
               break;
             case "39":
               rutBank = "970230009"; //"966654503"; antiguo rut antes de la fusion //"BANCO ITAU";
               break;
             case "45":
               rutBank = "590022209"; //"MUFG BANK, LTD.";
               break;
             case "49":
               rutBank = "970530002"; //"BANCO SECURITY";
               break;
             case "51":
               rutBank = "965096604"; //"BANCO FALABELLA";set
               break;
             case "53":
               rutBank = "979470002"; //"BANCO RIPLEY";
               break;
             case "55":
               rutBank = "995004100"; //"BANCO CONSORCIO";
               break;
             case "504":
               rutBank = "970320008"; //"BANCO BBVA";
               break;
             case "672":
               rutBank = "828789007"; //"COOPEUCH";
               break;
             default:
               rutBank = "---";
               break;
           } */
        if (register.bankId !== "1") {
          if (register.typeAccountId === "CRUT") {
            //JUV:CUENTA VISTA
            accountType = "JUV";
          }
          if (register.typeAccountId === "CTV") {
            //JUV:CUENTA VISTA
            accountType = "JUV";
          }
          if (register.typeAccountId === "CCT") {
            //CTD:CUENTA CORRIENTE
            accountType = "CTD";
          }
          if (register.typeAccountId === "AHO") {
            //AHB:CUENTA AHORRO
            accountType = "AHB";
          }
        }

        let glosa;
        switch (register.commerceId) {
          case "2019CL1xbet-8k3y":
            glosa = "001 " + register.cashoutId;
          case "2020juegalo-5n2q":
            glosa = "002 " + register.cashoutId;
          case "2020cestelar-3j9s":
            glosa = "003 " + register.cashoutId;
          case "2020juegalopro-7j7g":
            glosa = "004 " + register.cashoutId;
          case "2020dw-6d9w":
            glosa = "005 " + register.cashoutId;
          case "2020e-Management2u5i":
            glosa = "006 " + register.cashoutId;
          case "2020Payretailers7g21":
            glosa = "007 " + register.cashoutId;
          case "2021abudantia-2m5i":
            glosa = "008 " + register.cashoutId;
          case "2020techsolutions22gf":
            glosa = "009 " + register.cashoutId;
          case "2021juegaloCom-9n3u":
            glosa = "010 " + register.cashoutId;
        }
        if (register.bankId === "1") {
          dta.push(
            operationType,
            this.zfill(770115051, 10),
            this.zfill(580853207, 12),
            this.zfill(String(register.rut).replace(/[\. ,:-]+/g, "").trim().toLocaleUpperCase(), 10),
            /*  this.zfill(150775809, 10), */
            this.rfill(
              entities
                .decode(register.name)
                .replace("ñ", "n")
                .replace("-", "")
                .replace(".", "")
                .trim(),
              30
            ),

            this.rfill(
              register.numAccount
                .toLocaleLowerCase()
                .replace(/-/g, "")
                .replace(/\./g, "")
                .replace(/k/g, ""),
              18
            ),
            /*  this.rfill(15077580, 18), */
            /*     this.zfill(register.rut, 10), */
            this.zfill(rutBank, 10),
            this.zfill(register.quantity.toString(), 11),
            /*  this.zfill(100, 11), */
            " ",
            this.rfill(glosa, 30),
            1,
            this.rfill("TRANSACCION", 30),
            this.rfill(register.email, 50),
            "\r\n"
          );
        } else {

          console.log("register.rut", register.rut)
          let rut = String(register.rut).replace(/[\. ,:-]+/g, "").trim().toLocaleUpperCase()
          console.log("rut", rut)
          dta.push(
            operationType,
            this.zfill(770115051, 10),
            this.zfill(580853207, 12),
            this.zfill(String(register.rut).replace(/[\. ,:-]+/g, "").trim().toLocaleUpperCase(), 10),
            /*  this.zfill(150775809, 10), */
            this.rfill(
              entities
                .decode(register.name)
                .replace("ñ", "n")
                .replace("-", "")
                .replace(".", "")
                .trim(),
              30
            ),

            this.rfill(
              register.numAccount
                .toLocaleLowerCase()
                .replace(/-/g, "")
                .replace(/\./g, "")
                .replace(/k/g, ""),
              18
            ),
            /*  this.rfill(15077580, 18), */
            /*     this.zfill(register.rut, 10), */
            this.zfill(rutBank, 10),
            this.zfill(register.quantity.toString(), 11),
            /*  this.zfill(100, 11), */
            " ",
            this.rfill(glosa, 30),
            1,
            this.rfill("TRANSACCION", 30),
            this.rfill(register.email, 50),
            accountType,
            "\r\n"
          );
        }
      }
    } else {
      dataList.map(register => {
        console.log("String(register.rut).replace.trim()", String(register.rut).replace("-", "").trim())
        if (
          cuenta < 100
          && register.typeAccountId !== "AHO"
          // && register.bankId !== "1"
        ) {
          let operationType;
          let accountType;
          //para determinar /TIPO DE OPERACION //TEC:BANCO DE CHILE   //TOC:OTROS BANCOS
          if (register.bankId === "1") {
            //TEC:BANCO DE CHILE

            operationType = "TEC";
          } else {
            //TOC:OTROS BANCOS
            operationType = "TOB";
          }
          let rutBank;
          var databank = this.props.banks.filter(dato => dato.id == register.bankId)
          rutBank = databank[0].rut;
          /*  switch (register.bankId) {
             case "12":
               rutBank = "970300007"; //"BANCO ESTADO";
               break;
             case "1":
               rutBank = "970040005"; //"BANCO DE CHILE";97004000-5
               break;
             case "37":
               rutBank = "97036000K"; //"BANCO SANTANDER"; 97.036.000-k
               break;
             case "9":
               rutBank = "970110003"; //"BANCO INTERNACIONAL";
               break;
             case "14":
               rutBank = "970180001"; //"SCOTIABANK-DESARROLLO";
               break;
             case "16":
               rutBank = "970060006"; //"BCI";
               break;
             case "27":
               rutBank = "970230009"; //"CORP-BANCA";
               break;
             case "28":
               rutBank = "97080000K"; //"BICE";
               break;
             case "31":
               rutBank = "979510004"; //"HSBC BANK CHILE";
               break;
             case "39":
               rutBank = "76645030K"; //"BANCO ITAU";96665450-3
               break;
             case "45":
               rutBank = "590022209"; //"MUFG BANK, LTD.";
               break;
             case "49":
               rutBank = "970530002"; //"BANCO SECURITY";
               break;
             case "51":
               rutBank = "965096604"; //"BANCO FALABELLA";
               break;
             case "53":
               rutBank = "979470002"; //"BANCO RIPLEY";
               break;
             case "55":
               rutBank = "995004100"; //"BANCO CONSORCIO";
               break;
             case "504":
               rutBank = "970320008"; //"BANCO BBVA";
               break;
             case "672":
               rutBank = "828789007"; //"COOPEUCH";
               break;
             default:
               rutBank = "---";
               break;
           } */
          if (register.bankId !== "1") {
            if (register.typeAccountId === "CRUT") {
              //JUV:CUENTA VISTA
              accountType = "JUV";
            }
            if (register.typeAccountId === "CTV") {
              //JUV:CUENTA VISTA
              accountType = "JUV";
            }
            if (register.typeAccountId === "CCT") {
              //CTD:CUENTA CORRIENTE
              accountType = "CTD";
            }
            if (register.typeAccountId === "AHO") {
              //AHB:CUENTA AHORRO
              accountType = "AHB";
            }
          }

          /* console.log("commerceId",register.commerceId); */
          let glosa;
          switch (register.commerceId) {
            case "2019CL1xbet-8k3y":
              glosa = "001 " + register.cashoutId;
            case "2020juegalo-5n2q":
              glosa = "002 " + register.cashoutId;
            case "2020cestelar-3j9s":
              glosa = "003 " + register.cashoutId;
            case "2020juegalopro-7j7g":
              glosa = "004 " + register.cashoutId;
            case "2020dw-6d9w":
              glosa = "005 " + register.cashoutId;
            case "2020e-Management2u5i":
              glosa = "006 " + register.cashoutId;
            case "2020Payretailers7g21":
              glosa = "007 " + register.cashoutId;
            case "2021abudantia-2m5i":
              glosa = "008 " + register.cashoutId;
            case "2020techsolutions22gf":
              glosa = "009 " + register.cashoutId;
            case "2021juegaloCom-9n3u":
              glosa = "010 " + register.cashoutId;

          }
          if (register.bankId === "1") {
            dta.push(
              operationType,
              this.zfill(770115051, 10),
              this.zfill(580853207, 12),
              this.zfill(String(register.rut).replace(/[\. ,:-]+/g, "").trim().toLocaleUpperCase(), 10),
              /*  this.zfill(150775809, 10), */
              this.rfill(
                entities
                  .decode(register.name)
                  .replace("ñ", "n")
                  .replace("-", "")
                  .replace(".", "")
                  .trim(),
                30
              ),

              this.rfill(
                register.numAccount
                  .toLocaleLowerCase()
                  .replace(/-/g, "")
                  .replace(/\./g, "")
                  .replace(/k/g, ""),
                18
              ),
              /*  this.rfill(15077580, 18), */
              /*     this.zfill(register.rut, 10), */
              this.zfill(rutBank, 10),
              this.zfill(register.quantity.toString(), 11),
              /*  this.zfill(100, 11), */
              " ",
              this.rfill(glosa, 30),
              1,
              this.rfill("TRANSACCION", 30),
              this.rfill(register.email, 50),
              "\r\n"
            );
          } else {
            dta.push(
              operationType,
              this.zfill(770115051, 10),
              this.zfill(580853207, 12),
              this.zfill(String(register.rut).replace(/[\. ,:-]+/g, "").trim().toLocaleUpperCase(), 10),
              /*  this.zfill(150775809, 10), */
              this.rfill(
                entities
                  .decode(register.name)
                  .replace("ñ", "n")
                  .replace("-", "")
                  .replace(".", "")
                  .trim(),
                30
              ),

              this.rfill(
                register.numAccount
                  .toString()
                  .toLocaleLowerCase()
                  .replace(/-/g, "")
                  .replace(/\./g, "")
                  .replace(/k/g, ""),
                18
              ),
              /*  this.rfill(15077580, 18), */
              /*     this.zfill(register.rut, 10), */
              this.zfill(rutBank, 10),
              this.zfill(register.quantity.toString(), 11),
              /*  this.zfill(100, 11), */
              " ",
              this.rfill(glosa, 30),
              1,
              this.rfill("TRANSACCION", 30),
              this.rfill(register.email, 50),
              accountType,
              "\r\n"
            );
          }
          /* 
                    let largo =
                      operationType +
                      this.zfill(770115051, 10) +
                      this.zfill(580853207, 12) +
                      this.zfill(register.rut, 10) +
                      this.rfill(register.name, 30) +
                      this.rfill(register.numAccount, 18) +
                      this.zfill(register.rut, 10) +
                      this.zfill(register.quantity.toString(), 11) +
                      " " +
                      this.rfill("MOTIVO TRANSACCION", 30) +
                      1 +
                      this.rfill("MOTIVO TRANSACCION", 30) +
                      this.rfill(register.email, 50) +
                      accountType; */
          /*   +
            "\r\n"; */
          /*   console.log("largo", largo.length, largo, operationType.length); */


          //   console.log("dta", dta.length, dta, operationType.length);
          cuenta++;
        }
      });
    }

    /*  console.log("dta", dta); */
    const element = document.createElement("a");
    const file = new Blob(dta, { type: "text/plain" });
    element.href = URL.createObjectURL(file);

    let title = this.nameMasivas();
    /*     try {
      let date = moment()
        .toDate()
        .toLocaleString("es-CL", {
          timeZone: this.state.timeZone,
          timeZoneName: "short"
        });
      // console.log("date",date);
        title= title +date.toString();
    } catch (e) {
      console.log(`error en nameMasivas : ${e}`);
    } */

    element.download = title;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  rfill = (number, width) => {
    try {
      var numberOutput = Math.abs(number); /* Valor absoluto del número */
      var length = number.toString().length; /* Largo del número */
      var zero = " "; /* String de cero */
      if (length > width) {
        return number.toString().substr(0, width);
      } else {
        if (width <= length) {
          if (number < 0) {
            return number.toString();
          } else {
            return number.toString();
          }
        } else {
          if (number < 0) {
            return number.toString() + zero.repeat(width - length);
          } else {
            return number.toString() + zero.repeat(width - length);
          }
        }
      }
    } catch (e) {
      console.log(`error en rfill : ${e}`);
    }
  };

  zfill = (number, width) => {
    try {
      var numberOutput = Math.abs(number); /* Valor absoluto del número */
      var length = number.toString().length; /* Largo del número */
      var zero = "0"; /* String de cero */

      if (width <= length) {
        if (number < 0) {
          return number.toString();
        } else {
          return number.toString();
        }
      } else {
        if (number < 0) {
          return zero.repeat(width - length) + number.toString();
        } else {
          return zero.repeat(width - length) + number.toString();
        }
      }
    } catch (e) {
      console.log(`error en zfill : ${e} ${number} ${width}`);
    }
  };

  showFile = () => {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      var preview = document.getElementById("show-text");
      var file = document.querySelector("input[type=file]").files[0];
      var reader = new FileReader();

      var textFile = /text.*/;

      if (typeof file !== "undefined") {
        if (file.type.match(textFile)) {
          reader.onload = function (event) {
            /*  preview.innerHTML = event.target.result;
             */
            var lines = event.target.result.toString().split("\n");

            var result = [];

            var headers = lines[0].split(";");

            for (var i = 0; i < lines.length; i++) {
              var obj = {};
              var currentline = lines[i].split(";");

              for (var j = 0; j < headers.length; j++) {
                obj[j] = currentline[j];
              }
              result.push(obj);
            }
            localStorage.setItem("fileBch", JSON.stringify(result));

            /*  console.log(JSON.stringify(result),"JSON.stringify(result)"); */
          };
        } else {
          preview.innerHTML =
            "<span class='error'>It doesn't seem to be a text file!</span>";
        }
        preview.innerHTML = "<span class='error'>archivo cargado</span>";
        reader.readAsText(file);
        /*  localStorage.setItem("fileBch",reader.readAsText(file)); */
      } else {
        /*  alert("Your browser is too old to support HTML5 File API"); */
      }
    }
  };

  clearFile = () => {
    const file = document.querySelector("input[type=file]");
    file.value = "";
    localStorage.setItem("fileBch", "");
    const preview = document.getElementById("show-text");
    preview.innerHTML = "";
    debugger;
    window.location.reload();
  };
  handleForce = e => {
    console.log("handleForce", e);
  };
  handleFiles = files => {
    let result;

    var reader = new FileReader();
    reader.onload = function (e) {
      // Use reader.result
      alert(reader.result);

      result = reader.result;
    };

    console.log("objeto resultado", files[0]);
    let result2 = this.csvJSON(result);

    console.log("objeto resultado", result2);

    reader.readAsText(files[0]);
  };

  csvJSON = csv => {
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  };
  //updated
  upadateDocumentation = (id, documentation) => {
    new Promise(async (resolve, reject) => {
      let query = db.collection("cashouts");
      await query
        .doc(id)
        .update({
          documentation: documentation
        })
        .then(function () {
          resolve(true);
          console.log("Document successfully updated upadateDocumentation!");
        })
        .catch(err => {
          reject(false);
          console.log("ERROR IN upadateDocumentation", err);
        });
    });
  };

  upadatPreVal = (id, preVal) => {
    new Promise(async (resolve, reject) => {
      let query = db.collection("cashouts");
      await query
        .doc(id)
        .update({
          preVal: preVal
        })
        .then(function () {
          resolve(true);
          console.log("Document successfully updated upadatPreVal!");
        })
        .catch(err => {
          reject(false);
          console.log("ERROR IN upadatPreVal", err);
        });
    });
  };

  onSelectChange = selectedRowKeys => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
    /*  this.upadateDocumentation(record.cashoutId, selected); */
    /*  this.upadateDocumentation(record.cashoutId, selected); */
  };
  incrementNumber = () => {
    let i;

    if (this.props.dataList) {
      /*  i = this.props.dataList.length - 1;
       console.log(i); */

      return index => {
        /*   console.log("index", index); */
        if (this.state.pagination.current === 1) {
          //  console.log("index++", index++);
          return index + 1;
        } else {
          let valor = this.state.pagination.current - 1;
          return index + 1 + valor * 10;
        }
      };
    }
  };

  validateFileBch = dataList => {
    /*  console.log("validateFileBch", localStorage.getItem("fileBch")) */
    /*  console.log("validateFileBch",this.state.dataList) */
    try {
      let data = [];
      if (dataList.length > 0) {
        data = dataList;
      } else {
        data = this.props.dataList;
      }

      /*   debugger; */
      let dataListnew = [];
      let fileListValidated = JSON.parse(localStorage.getItem("fileBch"));
      console.log("fileListValidated", fileListValidated,);
      if (fileListValidated && data) {
        let a = data.map(reg => {
          console.log("reg", String(reg.rut).replace(/[\. ,:-]+/g, "").trim().toLocaleUpperCase(), reg.quantity.toString());
          let filtro = fileListValidated.find(
            row =>
              String(reg.rut).replace(/[\. ,:-]+/g, "").trim().toLocaleUpperCase() === String(row[3]).replace(/[\. ,:-]+/g, "").trim().toLocaleUpperCase() &&
              reg.quantity.toString() === row[8].toString(),
            /*   console.log("row[3]",String(row[3]).replace(/[\. ,:-]+/g, "").trim().toLocaleUpperCase()) */
          ); //&& reg.numAccount === row[7]);//&&reg.name.trim()===row[6].toString().trim());
          /*  console.log("filtro", filtro);
  */
          if (typeof filtro !== "undefined") {
            fileListValidated.splice(
              fileListValidated.findIndex(e => e === filtro),
              1
            );

            reg.valBCH = filtro[9];
            dataListnew.push(reg);
          } else {
            reg.valBCH = "no encontrada";
            dataListnew.push(reg);
          }
        });

        /*  console.log("dataListnew", dataListnew); */

        this.setState({ dataList: dataListnew });

        /*  console.log("dataList", this.state.dataList); */
      }
    } catch (error) {
      console.log("Error in validateFileBch:", error);
    }
  };

  ///santander
  texAreaOnchange = e => {
    /*  this.setState({ regValSanta: e.target.value }); */

    let regValSanta = e.target.value;
    /*   if (typeof regValSanta !== "undefined" || regValSanta !==""|| typeof regValSanta !== undefined) { */
    /*   console.log("validateSantander",regValSanta,typeof regValSanta); */
    var nstr = regValSanta.toString().split(/\n/); //se separa por  saltos de linea
    nstr
      .filter(re => re === "")
      .map(re => {
        //se filtra y se eliminan los vacios
        nstr.splice(
          nstr.findIndex(e => e === re),
          1
        );
      });

    /*  let data = []; */

    let arregloOriginal = nstr,
      arregloDeArreglos = []; // Aquí almacenamos los nuevos arreglos
    /* console.log("Arreglo original: ", arregloOriginal); */
    const LONGITUD_PEDAZOS = 7; // Partir en arreglo de 3
    for (let i = 0; i < arregloOriginal.length; i += LONGITUD_PEDAZOS) {
      let pedazo = arregloOriginal.slice(i, i + LONGITUD_PEDAZOS);
      arregloDeArreglos.push(pedazo);
    }

    localStorage.setItem("regValSanta", JSON.stringify(arregloDeArreglos));
  };

  preValidation = () => {
    let data = [];
    data = this.state.dataList;

    let dataListnew = [];
    let a = data.map(reg => {
      reg.preVal = "Ok";
      if (!validateRut(String(reg.rut).replace(/[\. ,:-]+/g, "").trim())) {
        //  if (!validateRut(reg.rut)) {
        reg.preVal = "INVALIDO";
      }

      if (
        reg.numAccount
          .toString()
          .toLocaleLowerCase()
          .replace(/-/g, "")
          .replace(/\./g, "")
          .replace(/k/g, "").length > 14
      ) {
        reg.preVal = "INVALIDO";
      }
      dataListnew.push(reg);
    });
    this.setState({ dataList: dataListnew });
  };

  validateSantander = dataList => {
    try {
      let data = [];
      if (dataList.length > 0) {
        data = dataList;
      } else {
        data = this.state.dataList;
      }

      let dataListnew = [];

      let fileListValidated = JSON.parse(localStorage.getItem("regValSanta"));
      /*   let fileListValidated = arregloDeArreglos; */
      if (fileListValidated && data) {
        let a = data.map(reg => {
          let filtro = fileListValidated.find(
            row =>
              String(reg.rut).replace(/[\. ,:-]+/g, "").trim().toLocaleUpperCase() === row[3].toString().replace(/[\. ,:-]+/g, "").toLocaleUpperCase() &&
              reg.quantity.toString() ===
              row[5].toString().replace(/[\. ,.$]+/g, "")
          ); //&& reg.numAccount === row[7]);//&&reg.name.trim()===row[6].toString().trim());
          console.log("filtro", filtro);

          if (typeof filtro !== "undefined") {
            fileListValidated.splice(
              fileListValidated.findIndex(e => e === filtro),
              1
            );

            if (filtro[6] === "Realizada") {
              reg.valSAN = "Aceptada";
            } else {
              reg.valSAN = "Rechazada";
            }

            dataListnew.push(reg);
          } else {
            reg.valSAN = "no encontrada";
            dataListnew.push(reg);
          }
        });

        this.setState({ dataList: dataListnew });
        console.log("dataListnew", this.state.dataList);
      }
    } catch (error) {
      console.log("Error in validateSantander:", error);
    }
  };

  clearSant = () => {
    /*  this.props.form.resetFields();

  */

    /*  let preview=document.getElementById("textArea") ;

    preview.defaultValue = "<span class='error'>archivo Eliminado</span>";

    console.log("defaultValue",preview.defaultValue );
       console.log("textArea",document.getElementById("textArea").value );
       document.getElementById("textArea").value ="" ;
        this.setState({ regValSanta: ""}); */
    localStorage.setItem("regValSanta", "");
    document.getElementById("textArea1").value = "";
    window.location.reload();
  };
  handleSubmitTextArea = async e => {
    e.preventDefault();

    console.log("handleSubmitTextArea", e);
  };

  onFinish = values => {
    console.log("onFinish", values);
  };

  /* onReset = () => {
   form.resetFields();
 }; */

  render() {
    const fromCurrentIndex = this.incrementNumber();
    /*   const { getFieldDecorator } = this.props.form; */
    const selectedRowKeys = this.state.selectedRowKeys;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      /*   onChange: (selectedRowKeys, selectedRows) => {
         console.log(
           `selectedRowKeys: ${selectedRowKeys}`,
           "selectedRows: ",
           selectedRows
         );
         this.setState({ selectedRowKeys });
       }, */
      getCheckboxProps: record => ({
        disabled: (record.payrollId && record.payrollId != "undone_payroll") ? true : false,
      }),
      onSelect: (record, selected, selectedRows) => {
        console.log("onSelect", selected, selectedRows);
        this.upadateDocumentation(record.cashoutId, selected);
        let dataList = [];

        let a = this.props.dataList.map(reg => {
          if (reg.cashoutId === record.cashoutId) {
            reg.documentation = selected;
            dataList.push(reg);
          }
          dataList.push(reg);
        });
        console.log(
          "dataList",
          dataList
        ); /* dataList = this.state.dataList.filter(reg => reg.cashoutId!==selectedRows); */
        /*
         */
        /*  console.log("this.state.dataList", this.state.dataList); */

        /*    this.setState({ dataList }); */
      },
      onSelectAll: async (selected, selectedRows, changeRows) => {
        console.log("onSelectAll", selected, selectedRows, changeRows);
        let dataList = [];

        changeRows.map(row => {
          this.upadateDocumentation(row.cashoutId, selected);

          let a = this.props.dataList.map(reg => {
            if (reg.cashoutId === row.cashoutId) {
              reg.documentation = selected;
              dataList.push(reg);
            }
            dataList.push(reg);
          });
        });
        /*  this.setState({ dataList }); */
      },
    };
    this.columnsIndex = [
      {
        title: "n°",
        width: 50,
        render: (text, record, index) => {
          return fromCurrentIndex(index);
        }
      }
    ];
    this.columns = [
      {
        title: "Can Do Y ",
        key: "canDoY",
        width: 100,
        render: val => <Checkbox value={val}> </Checkbox>
      },
      {
        title: "In Process",
        dataIndex: "inProcess",
        /*    key: "name", */
        key: "inProcess",
        width: 150,
        render: text => ('ese')
      },
      {
        title: "Date",
        dataIndex: "dateRequest",
        // key: "dateRequest",
        key: "1",
        width: 200,
        render: date =>
          date.toDate().toLocaleString("es-CL", {
            timeZone: this.state.timeZone,
            timeZoneName: "short"
          }),
        sorter: (a, b) => a.dateRequest.seconds - b.dateRequest.seconds,
        sortOrder:
          this.state.sortedInfo.columnKey === "dateRequest" &&
          this.state.sortedInfo.order
      },
      {
        title: "Name",
        dataIndex: "name",
        /*    key: "name", */
        key: "2",
        width: 150,
        render: name => entities.decode(name)
      },
      {
        title: "Amount",
        dataIndex: "quantity",
        /*  key: "quantity", */
        key: "3",
        width: 100,
        render: text => (
          <di style={{ float: "right" }}>
            <NumberFormat
              value={text}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
            />
          </di>
        )
      },
      {
        title: `ID ${this.props.merchant.name}`,
        dataIndex: "commerceReqId",
        /*  key: "commerceReqId", */
        key: "4",
        width: 100,
        sorter: (a, b) => a.commerceReqId - b.commerceReqId
      },
      {
        title: "ID",
        dataIndex: "cashoutId",
        /*  key: "cashoutId", */
        key: "5",
        width: 100
      }
      /*  ];

       this.columnsfailed = [
    */
      /*     {
            title: `Reason`,
            dataIndex: "reason",
               key: "reason",
            key: "6",
            width: 100,
            render: text => <div style={{ float: "right" }}>{text}</div>
          } */
    ];

    return (
      <Card
        title={this.props.title}
        extra={
          <Auxiliary>
            TimeZone: {this.state.timeZone}{" "}
            <i
              className={`flag flag-24 gx-mr-2 flag-ru`}
              onClick={this.onTimeZoneChange.bind(this, "Europe/Moscow")}
            ></i>
            <i
              className={`flag flag-24 gx-mr-2 flag-cl`}
              onClick={this.onTimeZoneChange.bind(this, "America/Santiago")}
            ></i>
          </Auxiliary>
        }
      >
        <div className="components-table-demo-control-bar">
          <Row>
            <Col xl={15} lg={15} md={15} sm={24} xs={24} className="gx-pr-md-2">
              <RangePicker
                className="gx-mb-3 gx-w-50"
                format="DD/MM/YYYY"
                ranges={{
                  All: [
                    moment("01-01-2019", "MM-DD-YYYY").tz(this.state.timeZone),
                    moment().tz(this.state.timeZone)
                  ],
                  Today: [
                    moment().tz(this.state.timeZone),
                    moment().tz(this.state.timeZone)
                  ],
                  Yesterday: [
                    moment()
                      .tz(this.state.timeZone)
                      .subtract(1, "days"),
                    moment()
                      .tz(this.state.timeZone)
                      .subtract(1, "days")
                  ],
                  "This week": [
                    moment()
                      .tz(this.state.timeZone)
                      .startOf("isoweek"),
                    moment()
                      .tz(this.state.timeZone)
                      .endOf("isoweek")
                  ],
                  "Last 7 Days": [
                    moment()
                      .tz(this.state.timeZone)
                      .subtract(6, "days"),
                    moment().tz(this.state.timeZone)
                  ],
                  "Last Month": [
                    moment()
                      .tz(this.state.timeZone)
                      .startOf("month")
                      .subtract(1, "months"),
                    moment()
                      .tz(this.state.timeZone)
                      .endOf("month")
                      .subtract(1, "months")
                  ],
                  "This Month": [
                    moment()
                      .tz(this.state.timeZone)
                      .startOf("month"),
                    moment()
                      .tz(this.state.timeZone)
                      .endOf("month")
                  ]
                }}
                onChange={this.onChangeRange}
                /*  defaultValue={[
                   moment()
                     .tz(this.state.timeZone)
                     .startOf("month"),
                   moment()
                     .tz(this.state.timeZone)
                     .endOf("month")
                     
                 ]} */

                defaultValue={this.state.defaultValueRangeFilter}
              />
            </Col>
            <Col xl={9} lg={9} md={9} sm={24} xs={24} className="gx-pr-md-2">
              <Input
                placeholder="Buscar..."
                onChange={this.updateSearchFilter.bind(this)}
              ></Input>
            </Col>
          </Row>
        </div>

        <Table
          className="gx-table-responsive"
          {...this.state}
          rowSelection={
            this.props.typeUser === "merchant" ? false : rowSelection
          }
          columns={
            (this.columnsIndex = this.columnsIndex.concat(this.props.columns))
          }
          dataSource={this.state.dataList}
          rowKey="cashoutId"
          onChange={this.onTableChange}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50", "100"]
          }}
        />

        {/*  <div style={{ marginBottom: "25px" }}> */}

        {/*  </div> */}
          <hr />
          <Row>
            <Col xl={4} lg={4} md={4} sm={24} xs={24} className="gx-pr-md-2">
              <ExcelFile filename={this.props.filename}
                element={(
                    <div>
                      <Tooltip title="  Download">
                        <Button style={{ backgroundColor: "grey", color: "white" }} >
                          Download </Button>
                      </Tooltip>
                    </div>
                  )}>
                <ExcelSheet data={this.state.dataList} name="Hoja 1">
                  <ExcelColumn
                    label="Date"
                    value={col =>
                      col.dateRequest.toDate().toLocaleString("es-CL", {
                        timeZone: this.state.timeZone,
                        timeZoneName: "short"
                      })
                    }
                  />
                  <ExcelColumn label="Name" value="name" />
                  <ExcelColumn label="Rut" value={col => String(col.rut).replace(/[\. ,:-]+/g, "").trim().toLocaleUpperCase()} />
                  <ExcelColumn label="Amount" value={col => Number(col.quantity)} />
                  <ExcelColumn label="ID" value="cashoutId" />
                  <ExcelColumn
                    label={`ID ${this.props.merchant.name}`}
                    value="commerceReqId"
                  />
                  {/* <ExcelColumn label="Commision" value="commision" />
                  <ExcelColumn label="IVA" value="iva" />
                  <ExcelColumn label="Tot Commision" value="totCommision" />
                  <ExcelColumn label="Total" value="total" /> */}
                  <ExcelColumn label="BankValidate" value="bankValidate" />
                  <ExcelColumn label="Payroll" value={col => {
                    return col.payrollId ? `${col.payrollId}|${col.cashoutId}` : '';
                  }} />

                </ExcelSheet>
              </ExcelFile>
            </Col>
            { this.props.isActiveOldCashout && (
            <>
              <Col xl={4} lg={4} md={4} sm={24} xs={24} className="gx-pr-md-2">
                {this.props.typeUser === "admin" ||
                  this.props.typeUser === "soporteCashout" ? (
                  <div>
                    <Tooltip title="Pre Validate">
                      <Button type="dashed" style={{ backgroundColor: "brown", color: "white" }}
                        onClick={this.preValidation} >

                        Pre Validate</Button>
                    </Tooltip>
                  </div>
                ) : (
                  ""
                )}
              </Col>
              {(this.props.typeUser === "admin" ||
                this.props.typeUser === "soporteCashout") && (
                <>
                  <Col xl={4} lg={4} md={4} sm={24} xs={24} className="gx-pr-md-2">
                    <ExcelFile
                      filename={this.nameMasivas()}
                      element={

                        /*  <div> */
                        <Tooltip title="  Download SANTANDER">
                          <Button style={{ backgroundColor: "red", color: "white" }} >
                            Download SANTANDER</Button>
                        </Tooltip>
                        /*  </div> */

                      }
                    >
                      <ExcelSheet data={this.filterDocument} name="Hoja 1">
                        <ExcelColumn
                          label="Cuenta origen(obligatorio)"
                          value={col => "74667538"}
                        />
                        <ExcelColumn
                          label="Moneda origen(obligatorio)"
                          value={col => "CLP"}
                        />
                        <ExcelColumn
                          label="Cuenta destino(obligatorio)"
                          value={col =>
                            Number(
                              col.numAccount
                                .toString()
                                .replace(/ /g, "")
                                .toLocaleLowerCase()
                                .replace(/-/g, "")
                                .replace(/\./g, "")
                                .replace(/k/g, "")
                            )
                          }
                        />
                        <ExcelColumn
                          label="Moneda destino(obligatorio)"
                          value={col => "CLP"}
                        />
                        <ExcelColumn
                          label="Código banco destino(obligatorio solo si banco destino no es Santander)"
                          value="bankId"
                        />
                        <ExcelColumn
                          label="RUT beneficiario(obligatorio solo si banco destino no es Santander)"
                          value={col => String(col.rut).replace(/[\. ,:-]+/g, "").trim().toLocaleUpperCase()}
                        />
                        <ExcelColumn
                          label="Nombre beneficiario(obligatorio solo si banco destino no es Santander)"
                          value={col => entities.decode(col.name)}
                        />
                        <ExcelColumn
                          label="Monto transferencia(obligatorio)"
                          value={col => Number(col.quantity)}
                        />
                        <ExcelColumn
                          label="Glosa personalizada transferencia(opcional)"
                          value={col => col.cashoutId}
                        />
                        {/* <ExcelColumn
                          label="Glosa personalizada transferencia(opcional)"
                          value={col => {
                            switch (col.commerceId) {
                              case "2019CL1xbet-8k3y":
                                return "001 " + col.cashoutId;
                              case "2020juegalo-5n2q":
                                return "002 " + col.cashoutId;
                              case "2020cestelar-3j9s":
                                return "003 " + col.cashoutId;
                              case "2020juegalopro-7j7g":
                                return "004 " + col.cashoutId;
                              case "2020dw-6d9w":
                                return "005 " + col.cashoutId;
                              case "2020Payretailers7g21":
                                return "006 " + col.cashoutId;
                              case "2020e-Management2u5i":
                                return "007 " + col.cashoutId;
                              case "2021abudantia-2m5i":
                                return "008 " + col.cashoutId;
                              default:
                                return null;
                            }
                          }}
                        /> */}
                        <ExcelColumn
                          label="Correo beneficiario(opcional)"
                          value="email"
                        />
                        <ExcelColumn
                          label="Mensaje correo beneficiario(opcional)"
                          value={col => "Retiro Zippy"}
                        />
                        <ExcelColumn
                          label="Glosa cartola originador(opcional)"
                          value={col => "Retiro Zippy"}
                        />
                        <ExcelColumn
                          label="Glosa cartola beneficiario(opcional)"
                          value="total"
                        />
                      </ExcelSheet>
                    </ExcelFile>
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={24} xs={24} className="gx-pr-md-2">
                    <div>
                      <Tooltip title="Download BCH">
                        <Button style={{ backgroundColor: "blue", color: "white" }}
                          onClick={this.downloadTxtFile} >
                          Download BCH</Button>
                      </Tooltip>
                    </div>
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={24} xs={24} className="gx-pr-md-2">
                    <div>
                      <Tooltip title=" Download JSON">
                        <Button style={{ backgroundColor: "green", color: "white" }}
                          onClick={this.downloadJson} >
                          Download JSON</Button>
                      </Tooltip>
                    </div>
                  </Col>
                </>
              )}
              </>
            )}
          </Row>
          <hr />
          {this.props.isActiveOldCashout && (
            <>
              <div style={{ marginTop: "25px" }}>
                {(this.props.typeUser === "admin" ||
                  this.props.typeUser === "soporteCashout") && (
                  <div
                    style={{
                      borderBlockStart: "solid",
                      borderBlockStartColor: "blue"
                    }}
                  >
                     <hr />
                    <h3>Validation Banco de Chile</h3>
                    <hr />
                    <input type="file" onChange={this.showFile} id="uploadFile" />
                    <hr />
                    <div id="show-text"></div>
                    <Button style={{ backgroundColor: "blue", color: "white" }} onClick={this.validateFileBch} icon="check">Validate</Button>
                    <Button  onClick={this.clearFile}>Clean</Button>
                  </div>
                )}
              </div>
            
            <div style={{ marginTop: "25px" }}>
              {(this.props.typeUser === "admin" ||
                this.props.typeUser === "soporteCashout") && (
                <div
                  style={{
                    borderBlockStart: "solid",
                    borderBlockStartColor: "red"
                  }}
                >
                  <hr />
                  <h3>Validation Banco Santander</h3>
                  <hr />
                  <TextArea
                    rows={4}
                    id={"textArea1"}
                    onChange={this.texAreaOnchange} /* allowClear ={true}*/
                  />
                  <hr />
                  <Button style={{ backgroundColor: "red", color: "white" }} onClick={this.validateSantander} icon="check">Validate</Button>
                  <Button onClick={this.clearSant}>Clean</Button>
                </div>
                )}
              </div>
            </>
          )}
      </Card>
    );
  }
}

export default Dynamic;

/*  const DynamicgForm = Form.create()(Dynamic);
export default DynamicgForm; */

