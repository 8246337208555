import moment from "moment";
import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import queryParams from "../../../../util/queryParams";
const { RangePicker } = DatePicker;

const RangeDatePayroll = props => {
  let { timeZone, onChangeRange } = props;
  const [value, setValue] = useState([]);
  useEffect(() => {
    setValue([
      moment(queryParams().start_date),
      moment(queryParams().end_date)
    ]);
  }, [document.location.href]);
  return (
    <>
      <RangePicker
        className="gx-mb-3 gx-w-50"
        format="DD/MM/YYYY"
        ranges={{
          All: [
            moment("01-01-2019", "MM-DD-YYYY").tz(timeZone),
            moment().tz(timeZone)
          ],
          Today: [moment().tz(timeZone), moment().tz(timeZone)],
          Yesterday: [
            moment()
              .tz(timeZone)
              .subtract(1, "days"),
            moment()
              .tz(timeZone)
              .subtract(1, "days")
          ],
          "This week": [
            moment()
              .tz(timeZone)
              .startOf("isoweek"),
            moment()
              .tz(timeZone)
              .endOf("isoweek")
          ],
          "Last 7 Days": [
            moment()
              .tz(timeZone)
              .subtract(6, "days"),
            moment().tz(timeZone)
          ],
          "Last Month": [
            moment()
              .tz(timeZone)
              .startOf("month")
              .subtract(1, "months"),
            moment()
              .tz(timeZone)
              .endOf("month")
              .subtract(1, "months")
          ],
          "This Month": [
            moment()
              .tz(timeZone)
              .startOf("month"),
            moment()
              .tz(timeZone)
              .endOf("month")
          ]
        }}
        onChange={onChangeRange}
        value={value}
      />
    </>
  );
};

export default RangeDatePayroll;
