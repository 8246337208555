import React from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Input,
  DatePicker,
  Button,
  Tooltip,
  Badge
} from "antd";
import moment from "moment";
import _ from "lodash";
import { firebase } from "../../firebase/firebase";
import API from "../../util/api";
import GrowthCardCartola from "components/dashboard/CRM/GrowthCardCartola";
import NumberFormat from "react-number-format";
import copy from "copy-to-clipboard";
import { connect } from "react-redux";
import UserPayHistory from "./UserPayHistory";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import Auxiliary from "util/Auxiliary";
const db = firebase.firestore();
const pagination = { position: "bottom" }; //bottom top both none
const { RangePicker } = DatePicker;

class cartolaBancoChile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeZone: "America/Santiago",
      bordered: false,
      loading: false,
      pagination: { current: 1 },
      size: "middle", // default middle small
      title: undefined,
      scroll: undefined,
      searchFilter: "",
      cartola: [],
      cartolaInfo: [],
      cartolaList: [],
      month: "Enero",
      sortedInfo: {
        order: "descend",
        columnKey: "datetime"
      },
      timeZone: "America/Santiago",
      dataLostUserPay:[],
      userPay:"",
    };
  }
  componentDidMount = async () => {
    let starDay = moment
      .tz("America/Santiago")
      .tz("America/Santiago")
      .startOf("day")
      .toDate();



      let starDayT = moment(starDay)
        .subtract(1, "days")
        .toDate();

        console.log("starDayT",starDayT,"starDay",starDay)

    let endDay = moment
      .tz("America/Santiago")
      .endOf("days").add(1, 'days').toDate();
     
    /* console.log("starDay",starDay,"endDay",endDay) */
    this.obtenerDetalleCartola(starDayT, endDay);
  };

  obtenerDetalleCartola = async (starDay, endDay) => {
    this.setState({
      loading: true
    });


    this.setState({
      desde: moment(starDay).format("DD/MM/YYYY"),
      hasta: moment(endDay).format("DD/MM/YYYY")
    });

    try {
      let starDayC = moment(starDay).format("YYYY-MM-DD");
      let endDayC = moment(endDay).format("YYYY-MM-DD");

      let starDayT = moment(starDay)
        .subtract(1, "days")
        .toDate();

      let payload = {
        starDayC,
        endDayC
      };

      await API.cartola.getCarto(payload).then(cons => {
        if (cons.data.length > 0) {




          cons.data.map(e => {
            e.created = moment(e.created).tz("America/Santiago"); //.format('DD/MM/YYYY HH:mm:ss')
          });
          /* console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",cons.data); */

          /* let dataList=    cons.data.filter(e=>{ return e.created >= starDayC})// && e.created <=endDayC})

 */
          let dataList = cons.data.filter(reg => reg.created >= starDay);

          console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", dataList.length,dataList);


          //se eliminan los duplicados de la cartola

          dataList.map(e => {

            let dataListTest = dataList.filter(f => f.operationCode === "C00392125780451912" )
            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",dataListTest);






            let dataList2 = dataList.filter(f => f.operationCode === e.operationCode && f.amount === e.amount && f.rut === e.rut)

            if (dataList2.length > 1) {
           /*    console.log("este tiene mas de uno ", dataList2); */
              dataList.splice(
                dataList.findIndex(e => e === dataList2[0]),
                1
              );
            }
          });
        /*   console.info(dataList.length) */
          this.setState({ cartolaList: dataList, cartola: dataList });

          this.alertcuadreBC(starDayT, endDay);
        }
      });
    } catch (error) {
      console.log("error en la getCarto", error);
    }

    this.setState({
      loading: false
    });
  };

  /*  exports.alertcuadreBC = async (req, res) => { */
  alertcuadreBC = async (starDay, endDay) => {
    /*   console.log("\x1b[36m%s\x1b[0m", "***********INICIO********************"); */
    let alertok = [];
    let transfers = [];

    let collection = "transfers";

    // SE PREGUNTA POR LA CARTOLA , ESTA MANDA , YA QUE ES QUIEN  DETERMINA LA ULTIMA FECHA , EL NOW , HASTA ESE VALOR HAY QUE CUADRAR
    //let cartola = await this.getCartolaLocalBC();
    let cartola = this.state.cartolaList;

    let count = 0;

    /*  console.log("cartola", cartola[0].datetime);
    console.log("cartola",cartola[cartola.length-1].datetime); */

    let countAmountCartola = 0;
    cartola.map(car => {
      countAmountCartola = countAmountCartola + Number(car.amount);
    });
 /*    console.log("countAmountCartola", countAmountCartola); */
    //SE TRAE LA DATA DE CASHIN
    transfers = await this.getTransfers(starDay, endDay);
    /*  console.log("transfers", transfers); */
    //Indicar numero de transacciones ok, fallidas y total.

    let countOk = 0;
    let countMontoOk = 0;
    let countPending = 0;
    let countMontoPending = 0;
    let countFailed = 0;
    let countMontoFailed = 0;
    let countOtros = 0;
    let countMontoOtros = 0;

    let arrayOK = [];
    let arrayPending = [];
    let arrayFailed = [];
    let infoImportant = [];

    transfers.map(soli => {
      if (soli.code === 0) {
        countOk = countOk + 1;
        countMontoOk = countMontoOk + Number(soli.quantity);
        arrayOK.push(soli);
      }
      if (soli.code === 9) {
        countPending = countPending + 1;
        countMontoPending = countMontoPending + Number(soli.quantity);
        /*  console.log(soli); */
        arrayPending.push(soli);
      }

      if (soli.code === 12) {
        countFailed = countFailed + 1;
        countMontoFailed = countMontoFailed + Number(soli.quantity);
        arrayFailed.push(soli);
      }
    });


    //BUSCANDO COINCIDENCIAS ENTRE CARTOLA Y CASHIN

    let CartolaEncashinOK = [];
    let NOCartolaEncashinOK = [];

    //OK
    cartola.map(res => {
      let count = arrayOK.filter(
        reg =>
          reg.rut === res.rut &&
          Number(reg.quantity) === Number(res.amount) &&
          reg.bankOperationCode === res.bankOperationCode
      );

      let filtro = arrayOK.find(
        reg =>
          reg.rut === res.rut &&
          Number(reg.quantity) === Number(res.amount) &&
          reg.bankOperationCode === res.bankOperationCode
      );

      if (typeof filtro !== "undefined") {
        arrayOK.splice(
          arrayOK.findIndex(e => e === filtro),
          1
        );
      }

      if (count.length > 0) {
        CartolaEncashinOK.push(filtro);
      } //estos son los registros coinciden ente la cartola y los cahin ok
      else {
        //estos son los datos de la cartola qeu no se encontraron en los ok
        NOCartolaEncashinOK.push(res);
      }
    });
    /*    console.log(
         "al descartarle los  registros a los cachin ok , queda esto: , si esta en cero , esta correcto , si tiene uno , es porque algo no coincide ",
         arrayOK
       ); */
    //CASHIN OK
    let countQuantityOKCartola = 0;
    CartolaEncashinOK.map(soli => {
      //sumo el total  quantity de los registros que coinciden entre la cartola y los ok
      countQuantityOKCartola = countQuantityOKCartola + Number(soli.quantity);
    });
    let NOcountQuantityOKCartola = 0;
    NOCartolaEncashinOK.map(soli => {
      //sumo el total  quantity de los registros que NO  coinciden entre la cartola y los ok
      NOcountQuantityOKCartola = NOcountQuantityOKCartola + Number(soli.amount);
    });

    /*   console.log(
        "\x1b[36m%s\x1b[0m",
        "***********COINCIDENCIAS OK******************"
      );
      console.log(`cantidad de total de OK `, arrayOK.length);
      console.log(
        `los registros de la cartola que se encuentran en cashin OK `,
        CartolaEncashinOK.length
      );
      console.log(`MONTO `, countQuantityOKCartola);
      console.log(
        `esto es lo que sobro del cruce  entre los registros de la cartola y los cashin ok , es decir, es lo que sobro de la cartola`,
        NOCartolaEncashinOK.length
      );
      console.log(`MONTO`, NOcountQuantityOKCartola);
      console.log(`NOCartolaEncashinOK`, NOCartolaEncashinOK);
      console.log("\x1b[36m%s\x1b[0m", "*********************************"); */

    //PENDING
  /*   console.log("\x1b[36m%s\x1b[0m", "PENDING****************************");
 */
    let cashinPendingCartola = [];
    let NOcashinPendingCartola = [];

    //ME INTERESAN LAS QUE NO COINCIDEN en el ok
    let searchPendingINI = [];
    let NosearchPendingINI = [];

    NOCartolaEncashinOK.map(res => {
      let count = arrayPending.filter(
        reg =>
          reg.rut === res.rut && Number(reg.quantity) === Number(res.amount)
      );
      if (count.length > 0) {
        searchPendingINI.push(count[0]);
        const index = arrayPending.indexOf(count[0]);
        if (index > -1) {
          arrayPending.splice(index, 1);
        }
      } else {
        NosearchPendingINI.push(res);
      }
    });

    /*   let searchFilter = []; */

    /* console.log("searchFilter", searchFilter.length); */
    // aux = searchPendingINI
 /*    console.log("searchPendingINI", searchPendingINI.length, searchPendingINI); */
    /*  searchPendingINI = searchFilter; */
 /*    console.log("NosearchPendingINI", NosearchPendingINI.length); */

    /*    let searchPending = [];
       let NosearchPending = [];
   
       console.log("searchPending", searchPending.length);
       console.log("NosearchPending", NosearchPending.length); */

    //se arma objeto

    //CASHIN PENDING se cuentan los montos
    let countQuantityPendingCartola = 0;
    searchPendingINI.map(soli => {
      countQuantityPendingCartola =
        countQuantityPendingCartola + Number(soli.quantity);
    });

    let NOcountQuantityPendingCartola = 0;
    NOcashinPendingCartola.map(soli => {
      NOcountQuantityPendingCartola =
        NOcountQuantityPendingCartola + Number(soli.quantity);
    });

    //******failed**************** */

    let cashinFailedCartola = [];
    let NOcashinFailedCartola = [];
    let searchFailedINI = [];
    let NosearchFailedINI = [];

    /* console.log("arrayFailed", arrayFailed.length); */
    NosearchPendingINI.map(res => {
      let count = arrayFailed.filter(
        reg =>
          reg.rut === res.rut && Number(reg.quantity) === Number(res.amount)
      );
      if (count.length > 0) {
        searchFailedINI.push(count[0]);
        const index = arrayFailed.indexOf(count[0]);
        if (index > -1) {
          arrayFailed.splice(index, 1);
        }
      } else {
        NosearchFailedINI.push(res);
      }
    });

    /*    console.log("searchFailedINI", searchFailedINI.length);
       console.log("NosearchFailedINI", NosearchFailedINI.length); */

    let searchFailed = [];
    let NosearchFailed;
    searchFailedINI.map(res => {
      let count = CartolaEncashinOK.filter(
        reg =>
          reg.rut === res.rut && Number(reg.quantity) === Number(res.amount)
      );
      if (count.length > 0) {
        NosearchFailed.push(count[0]);
      } else {
        searchFailed.push(res);
      }
    });

    //CASHIN FAILED
    let countQuantityFailedCartola = 0;
    searchFailed.map(soli => {
      countQuantityFailedCartola =
        countQuantityFailedCartola + Number(soli.quantity);
    });
    let NOcountQuantityFailedCartola = 0;
    NOcashinFailedCartola.map(soli => {
      NOcountQuantityFailedCartola =
        NOcountQuantityFailedCartola + Number(soli.quantity);
    });

   /*  console.log(`la cartola no cuadra en :  `, NOcountQuantityOKCartola); */

    let validadosContraCartola = [];
    validadosContraCartola.push(
      ...searchPendingINI,
      ...searchFailed,
      ...CartolaEncashinOK
    );

    let countLost = 0;
    NosearchFailedINI.map(soli => {
      countLost = countLost + Number(soli.amount);
    });

    infoImportant.push({
      registrosEnCartola: cartola.length,
      montoEnCartola: countAmountCartola,
      cantidadOk: CartolaEncashinOK.length,
      montoOk: countQuantityOKCartola,
      cantidadPending: searchPendingINI.length,
      montoPending: countQuantityPendingCartola,
      searchPending: searchPendingINI,
      cantidadFailed: searchFailed.length,
      montoFailed: countQuantityFailedCartola,
      montoLost: NOcountQuantityOKCartola,
      transactionsPending:
        cashinPendingCartola.length > 0 ? cashinPendingCartola : [],
      cashinFailedCartola:
        cashinFailedCartola.length > 0 ? cashinFailedCartola : [],
      lost: NosearchFailedINI,
      cantidadlost: NosearchFailedINI.length,
      montolost: countLost,
      encontradoOk: CartolaEncashinOK
    });


  /*   console.log("infoImportant", infoImportant); */

    let objDiff = { NOcountQuantityOKCartola };

    this.setState({ cartolaInfo: infoImportant[0] });
    if (searchPendingINI.length > 0) {
      this.setState({ searchPending: searchPendingINI });
    }
    if (searchFailed.length > 0) {
      this.setState({ searchFailed: searchFailed });
    }
    if (NosearchFailedINI.length > 0) {
      this.setState({ lost: NosearchFailedINI });
      /*  console.log("cons.lost", cons.lost); */
    }
  };

  getTransfers = async (starDay, endDay) => {
    //rut,name
    let transfers = [];
    let idtransfers = [];
    /*   let lastDay = moment(moment().format('DD/MM/YYYY'), "DD/MM/YYYY").tz("America/Santiago").endOf("day").toDate()

    let fechaHasta = moment.tz("America/Santiago").endOf("days").toDate() */
    //let fechaDesde = moment.tz("America/Santiago").startOf("days").subtract(, "days").toDate();
    /*   let  fechaDesde = moment.tz("America/Santiago").tz("America/Santiago").startOf("day").toDate();
    console.log('fechaDesde', '=>', fechaDesde);
    console.log('fechaHasta', '=>', fechaHasta); */
    /*  if(date)
    {
      fechaDesde = moment(date, "DD/MM/YYYY").tz("America/Santiago").startOf("day").toDate();
    } */

    let query = await db
      .collection("transfers")
      .where("dateRequest", ">=", starDay)
      /*  .where("dateRequest", "<=", new Date(Number(fechaDesde)))  */
      .where("dateRequest", "<=", endDay)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
        } else {
          snapshot.docs.map(doc => {
            idtransfers.push(doc.id);
            transfers.push(doc.data());
          });
        }
      })
      .catch(err => {
        /*   res.status(400).json({ message: "Error in getalertok" }); */
        console.log("ERROR IN getalertok:", err);
      });

    for (let i = 0; i < transfers.length; i++) {
      transfers[i].id = idtransfers[i];
    }

    return transfers;
  };

  componentWillReceiveProps(nextProps) { }

  onChangeRange = async (dates, dateStrings) => {
    //before antes de
    //after despues de
    let firstDay = moment(dateStrings[0], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .toDate();
    let lastDay = moment(dateStrings[1], "DD/MM/YYYY")
      .tz(this.state.timeZone)
      .endOf("day")
      .toDate();
    this.obtenerDetalleCartola(firstDay, lastDay);
    /*  let cartolaFina = []; */

    /*   let payload = {
      date: moment(firstDay).format('DD/MM/YYYY'),
      date2: moment(lastDay).format('DD/MM/YYYY')
    } */
    let res;
    /* console.log("payload",payload) */
    /*   let res = await API.utils.getCartolaBCWithParams(payload)
     */
    /*     if(res.length > 0){
      let result = []
      //Logica de filtro por firstDay y lastDay
      for(let i = 0; i < res.length; i++){
     //   console.log(res[i].datetime)
    //    console.log(payload.date)
        if(res[i].datetime >= payload.date && res[i].datetime <= payload.date2){
          result.push(res[i])
        }
      }
      this.setState({
        cartola: result
      });
    } else {
      this.setState({
        cartola: []
      });
    } */
  };

  filter = searchValue => {
  /*   console.log("searchValue", searchValue); */
    if (searchValue === "") {
      /*    console.log("searchthis.state.cartolaInfoValue",this.state.cartolaList); */
      this.setState({
        cartola: this.state.cartolaList
      });
    } else {
      try {
        const filter = _.filter(
          this.state.cartola,
          cartola =>
            (cartola.datetime
              ? cartola.datetime
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cartola.account
              ? cartola.account
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cartola.rut
              ? cartola.rut.toLowerCase().indexOf(searchValue.toLowerCase()) >
              -1
              : "") ||
            (cartola.bankOperationCode
              ? cartola.bankOperationCode
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cartola.bank
              ? cartola.bank.toLowerCase().indexOf(searchValue.toLowerCase()) >
              -1
              : "") ||
            (cartola.operationCode
              ? cartola.operationCode
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cartola.amount
              ? cartola.amount.toString().indexOf(searchValue) > -1
              : "") ||
            (cartola.originBank
              ? cartola.originBank.toString().indexOf(searchValue) > -1
              : "")
        );
        this.setState({
          cartola: filter
        });
      } catch {
        console.log("Error en  el filtro ");
      }
    }
  };

  updateSearchFilter(evt) {
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }

  onTableChange = (pagination, filters, sorter) => {
    /*   console.log(pagination)
    console.log(filters)
    console.log(sorter)
    console.log("Various parameters", pagination, filters, sorter); */
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  sorteDate = (a, b) => {
    let datea = new Date(a.datetime);
    let dateb = new Date(b.datetime);
    if (datea !== NaN && dateb !== NaN) {
      return dateb - datea;
    }
    if (datea !== NaN) {
      return datea;
    }
    if (dateb !== NaN) {
      return dateb;
    }
  };
  incrementNumber = () => {
    let i;
    //   console.log("this.state.cartola",this.state.cartola)
    /*  return index */
    /* debugger; */
    if (this.state.cartolaList) {
      /*  i = this.props.dataList.length - 1;
       console.log(i); */

      return index => {
        /*   console.log("index", index); */
        if (this.state.pagination.current === 1) {
          //  console.log("index++", index++);
          return index + 1;
        } else {
          let valor = this.state.pagination.current - 1;
          return index + 1 + valor * 10;
        }
      };
    }
  };

  copyClip = reg => {
    copy(reg);
  };

  onCopyClipBoardOperationCode = record => {
    let objCopy ;
    if(record.originBank==="bancoestado")
    {
      objCopy= record.datetime + "\t"+record.bankOperationCode
    }
    else {
      objCopy= record.operationCode
    }
    copy(objCopy);
  };
  viewHistoryUserPay = async record => {
    console.log("viewHistoryUserPay", record.rut);
    let transfers = [];let statusName;
    let query = await db
      .collection("transfers")
      .where("rut", "==", record.rut)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
        } else {
          snapshot.docs.map(doc => {
            transfers.push({
              nameStatus:this.transformStatus(doc.data().code.toString()),
              id: doc.id,
              ...doc.data()
            });
          });
        }
      })
      .catch(err => {
        console.log("ERROR IN getalertok:", err);
      });


      let queryvisitor = await db
      .collection("visitors")
      .where("rut", "==", record.rut)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
        } else {
          snapshot.docs.map(doc => {
            transfers.push({
              nameStatus:this.transformStatus(doc.data().code),
              dateRequest:doc.data().dateVisitor,
              id: doc.id,
              ...doc.data()
            });
          });
        }
      })
      .catch(err => {
        console.log("ERROR IN getalertok:", err);
      });

    this.setState({dataLostUserPay:transfers,userPay:record.rut })
    console.log("transfers", transfers,record.rut,this.props.dataUser.merchants);
    return transfers;
  };

  transformStatus(status) {
    switch (status) {
      case "0":
        return "Validated";
      case "9":
        return "Pending";
      case "12":
        return "Failed";
      case "1":
        return "Visitor";
      default:
        return "Visitor";
    }
    
  };
  render() {
    const fromCurrentIndex = this.incrementNumber();
    const { transactionsPending } = this.state.cartola;
    this.columnsIndex = [
      {
        title: "n°",
        width: 50,
        render: (text, record, index) => {
          /* console.log("antes de fromCurrentIndex",index) */
          return fromCurrentIndex(index);
          /*  return index; */
        }
      }
    ];
    this.columnsActions = [
      {
        title: "History",
        key: "history",
        width: 10,
        render: (text, record) => (
          <span>
            <Button
              onClick={() => this.viewHistoryUserPay(record)}//searchUserPay
              onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
            >
              <span className="gx-link">View History</span>
            </Button>
          </span>
        )
      }

    ];
    this.columns = [
      {
        title: "",
        dataIndex: "originBank",
        key: "originBank",
        width: 50,
        render: text => (
          <span>
            {text === "bancochile" ? (
              <div>
                <Tooltip title="Banco de Chile!">
                  {" "}
                  <img
                    style={{ width: "20px", height: "20px" }}
                    alt=""
                    src={require("assets/images/chile.jpg")}
                  />
                </Tooltip>
              </div>
            ) : text === "bancoestado" ? (
              <div>
                <Tooltip title="Banco Estado!">
                  {" "}
                  <img
                    style={{ width: "20px", height: "20px" }}
                    alt=""
                    src={require("assets/images/estado.png")}
                  />
                </Tooltip>
              </div>
            ) : (
                  <div>
                    <Tooltip title="sin dato">
                      {" "}
                      <Badge color="grey" />
                    </Tooltip>
                  </div>
                )}
          </span>
        )
      },

      //created

      {
        title: "Date Created",
        dataIndex: "created",
        // key: "dateRequest",
        key: "created",
        width: 200,
        /*  render: date =>
          date.toDate().toLocaleString("es-CL", {
            timeZone: this.state.timeZone,
            timeZoneName: "short"
          }), */
        //  render: created =>  moment(created, "DD/MM/YYYY HH:mm:ss").tz("America/Santiago")
        render: created =>
          moment(created)
            .tz("America/Santiago")
            .format("DD/MM/YYYY HH:mm:ss"),
        // render: created => created
        sorter: (a, b) => this.sorteDate(a, b),
        sortDirections: ["descend", "ascend"]
      },
      {
        title: "Date Cartola",
        dataIndex: "datetime",
        key: "datetime",
        width: 100,
        render: datetime => <span>{datetime}</span>,
        sorter: (a, b) => this.sorteDate(a, b),
        sortDirections: ["descend", "ascend"]
        // render: datetime => moment(datetime).format("DD/MM/YYYY HH:mm:ss"),
        //render: datetime =>  moment(datetime, "DD/MM/YYYY HH:mm:ss").tz("America/Santiago"),
        /*   sorter: (a, b) => a.datetime > b.datetime,

    /*     render: datetime => (
          <span>{moment(datetime).toDate().toLocaleString("es-CL", {
            timeZone: "America/Santiago",
            timeZoneName: "short"
          })}</span>
        ), */
        /* sorter: (a, b) => a.datetime - b.datetime,
        sortOrder:
          this.state.sortedInfo.columnKey === "datetime" &&
          this.state.sortedInfo.order  */
      },
      {
        title: "BankOperationCode",
        dataIndex: "bankOperationCode",
        key: "bankOperationCode",
        width: 100
      },
      {
        title: "OperationCode",
        dataIndex: "operationCode",
        key: "operationCode",
        width: 100
      },
      {
        title: "Rut",
        dataIndex: "rut",
        key: "rut",
        width: 100,
        // render: rut => formatRut(rut)
        render: text => (
          <div style={{ float: "right" }}>
            {/*   <button onClick={this.copyClip(text)}>copy</button> */}
            <i
              onClick={() => {
                this.copyClip(text);
              }}
            >
              {formatRut(text)}
            </i>
          </div>
        )
      },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
        width: 100
      },

      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        width: 100,
        render: text => (
          <div style={{ float: "right" }}>
            <NumberFormat
              value={text}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
            />
          </div>
        )
      },
      {
        title: "Bank",
        dataIndex: "bank",
        key: "bank",
        width: 100 /* ,
        sorter: (a, b) => a.type - b.type,
        sortOrder:
          this.state.sortedInfo.columnKey === "tipo" &&
          this.state.sortedInfo.order */
      },
      {
        title: "",
        /* key: "action", */
        key: "8",
    /*     width: 150, */
        render: (text, record) => (
          <span>
            <Tooltip title="Copy Operation Code">
              <a
                onClick={() => this.onCopyClipBoardOperationCode(record)}
              >
                <span className="gx-link">📑</span>
              </a>
            </Tooltip>

          </span>
        )
      }
    ];

    /*   console.log(this.state.cartolaInfo); */
    /*    console.log(transactionsPending); */
    return (
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Button
            onClick={() => {
              this.obtenerDetalleCartola(
                moment
                  .tz("America/Santiago")
                  .tz("America/Santiago")
                  .startOf("day")
                  .subtract(1, "days")
                  .toDate(),
                moment
                  .tz("America/Santiago")
                  .endOf("days")
                  .add(1, 'days').toDate()
              );
            }}
          >
            Update
          </Button>
          {this.state.desde
            ? `  Cartola del ${this.state.desde} al ${this.state.hasta}`
            : null}
        </Col>
        <Col xl={5} lg={12} md={12} sm={12} xs={24}>
          <GrowthCardCartola
            loading={this.state.loading}
            trafficData={[]}
            title="Cartola"
            help="daily cartola"
            month={this.state.month}
            quantityTransaction={this.state.cartolaInfo.registrosEnCartola}
            amountTransaction={this.state.cartolaInfo.montoEnCartola}
            balanced={this.state.cartolaInfo.montoLost > 0 ? false : true}
            typeWidget={"Cua"}
            prefix={"$"}
            merchants={this.props.dataUser}
          />
        </Col>
        <Col xl={19} lg={24} md={24} sm={24} xs={24} className="gx-pr-md-2">
          <Row>
            <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-pr-md-2">
              <GrowthCardCartola
                help="these are the cashin Ok records found within the Cartola "
                loading={this.state.loading}
                trafficData={[]}
                title="Cashin Ok"
                month={this.state.month}
                quantityTransaction={this.state.cartolaInfo.cantidadOk}
                amountTransaction={this.state.cartolaInfo.montoOk}
                balanced={false}
                typeWidget={"Ok"}
                lostAmount={0}
                prefix={"$"}
                lostAmount={this.state.cartolaInfo.montoLost}
                merchants={this.props.dataUser}
              />
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-pr-md-2">
              <GrowthCardCartola
                help="these are the cashin Pending records found within the Cartola "
                loading={this.state.loading}
                trafficData={[]}
                title="Cashin Pending"
                month={this.state.month}
                quantityTransaction={this.state.cartolaInfo.cantidadPending}
                amountTransaction={this.state.cartolaInfo.montoPending}
                balanced={true}
                TransactionPending={[]}
                typeWidget={"Pending"}
                lostAmount={0}
                dataPending={this.state.searchPending}
                prefix={"$"}
                merchants={this.props.dataUser}
              />
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-pr-md-2">
              <GrowthCardCartola
                help="these are the cashin Failed records found within the Cartola"
                loading={this.state.loading}
                trafficData={[]}
                title="Cashin Failed"
                month={this.state.month}
                quantityTransaction={this.state.cartolaInfo.cantidadFailed}
                amountTransaction={this.state.cartolaInfo.montoFailed}
                dataFailed={this.state.searchFailed}
                balanced={true}
                typeWidget={"Failed"}
                lostAmount={0}
                prefix={"$"}
                merchants={this.props.dataUser}
              />
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-pr-md-2">
              <GrowthCardCartola
                help="These are the transactions of which we do not have a record in the database, but they are in the map. Example: the user deposited but did not fill in the zippy form. "
                loading={this.state.loading}
                trafficData={[]}
                title="Lost"
                month={this.state.month}
                quantityTransaction={this.state.cartolaInfo.cantidadlost}
                amountTransaction={this.state.cartolaInfo.montolost}
                dataLost={this.state.lost}
                dataLostUserPay={this.state.dataLostUserPay}
                merchants={this.props.dataUser}
                balanced={true}
                columnsActions={this.columnsActions}
                typeWidget={"Lost"}
                lostAmount={0}
                prefix={"$"}
                userPay={this.state.userPay}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Card
            title={"CARTOLA"}
            extra={
              <Auxiliary>
                TimeZone: {this.state.timeZone}{" "}
                {/*  <i
              className={`flag flag-24 gx-mr-2 flag-ru`}
              onClick={this.onTimeZoneChange.bind(this, "Europe/Moscow")}
            ></i> */}
                {/*   <i
              className={`flag flag-24 gx-mr-2 flag-cl`}
              onClick={this.onTimeZoneChange.bind(this, "America/Santiago")}
            ></i> */}
              </Auxiliary>
            }
          >
            <div className="components-table-demo-control-bar">
              <Row>
                <Col
                  xl={15}
                  lg={15}
                  md={15}
                  sm={24}
                  xs={24}
                  className="gx-pr-md-2"
                >
                  {
                    <RangePicker
                      className="gx-mb-3 gx-w-50"
                      size="small"
                      format="DD/MM/YYYY"
                      ranges={{
                        All: [
                          moment("01-01-2019", "MM-DD-YYYY").tz(
                            this.state.timeZone
                          ),
                          moment().tz(this.state.timeZone)
                        ],
                        Today: [
                          moment().tz(this.state.timeZone),
                          moment().tz(this.state.timeZone)
                        ],
                        Yesterday: [
                          moment()
                            .tz(this.state.timeZone)
                            .subtract(1, "days"),
                          moment()
                            .tz(this.state.timeZone)
                            .subtract(1, "days")
                        ],
                        "This week": [
                          moment()
                            .tz(this.state.timeZone)
                            .startOf("isoweek"),
                          moment()
                            .tz(this.state.timeZone)
                            .endOf("isoweek")
                        ],
                        "Last 7 Days": [
                          moment()
                            .tz(this.state.timeZone)
                            .subtract(6, "days"),
                          moment().tz(this.state.timeZone)
                        ],
                        "Last Month": [
                          moment()
                            .tz(this.state.timeZone)
                            .startOf("month")
                            .subtract(1, "months"),
                          moment()
                            .tz(this.state.timeZone)
                            .endOf("month")
                            .subtract(1, "months")
                        ],
                        "This Month": [
                          moment()
                            .tz(this.state.timeZone)
                            .startOf("month"),
                          moment()
                            .tz(this.state.timeZone)
                            .endOf("month")
                        ]
                      }}
                      onChange={this.onChangeRange}
                      defaultValue={
                        [
                          //  moment().tz(this.state.timeZone).startOf("month"),
                          //  moment().tz(this.state.timeZone).endOf("month")
                          // moment().tz(this.state.timeZone).subtract(1, "days"),
                          // moment().tz(this.state.timeZone)
                        ]
                      }
                    />
                  }
                </Col>
                <Col
                  xl={9}
                  lg={9}
                  md={9}
                  sm={24}
                  xs={24}
                  className="gx-pr-md-2"
                >
                  <Tooltip
                    title="
                  search without dots or dashes"
                  >
                    {" "}
                    <Input
                      size="small"
                      placeholder="Search..."
                      onChange={this.updateSearchFilter.bind(this)}
                    ></Input>
                  </Tooltip>
                </Col>
              </Row>
            </div>
          {  <Table
              className="gx-table-responsive"
              // columns={this.props.columns || this.columns}

              columns={
                (this.columnsIndex = this.columnsIndex.concat(this.columns))
              }
              dataSource={this.state.cartola}
              rowKey="valor"
              onChange={this.onTableChange}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30", "50", "100"]
              }}
            />}
          </Card>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={24}
          className="gx-pr-md-2"
        ></Col>
      </Row>
    );
  }
}

/* export default cartolaBancoChile; */


/* export default CallbackForm; */

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;

  const { dataUser } = settings;

  return { authUser, dataUser };
};
export default connect(mapStateToProps)(cartolaBancoChile);
