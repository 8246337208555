import React, { Component } from "react";
import { Col, Row, Button, Input } from "antd";
import { connect } from "react-redux";
import { firebase } from "../../../../firebase/firebase";
import Dynamic from "./Dynamic";
import moment from "moment-timezone";
import GrowthCard from "components/dashboard/CRM/GrowthCardMerchant";
import _ from "lodash";
import Auxiliary from "util/Auxiliary";
import API from "../../../../util/api";

const db = firebase.firestore();
let query;
let queryWeek;
let dataList = [];

export class kushkiOk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      payMethod: "bankTransfer",
      title: ` Kushki BankTransfer  Validated`,
      filename: `Kushki BankTransfer Validated ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      indicadores: {
        dolar: {
          valor: ""
        }
      },

      date: "",
      dataKushki: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        TotalSinCommission: 0
      },
      dataKushkiWeek: {
        dataWeek: "",
        sumWeek: 0,
        countdocsWeek: 0
      },
      firstTime: true,
      inputPreaidCode: "",
      defaultValueRangeFilter: [

        moment().tz("America/Santiago").subtract(1, "days"),
        moment().tz("America/Santiago")
      ]
    };
  }


  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps, "country_code", localStorage.getItem("country_code"));
    /* this.componentDidMount(); */
  }

  componentDidMount = () => {

    moment.tz.setDefault(this.state.timeZone);

    let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
    let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();

    let firstDayWeek = moment.tz(this.state.timeZone).startOf("isoWeek").toDate();
    let lastDayWeek = moment.tz(this.state.timeZone).endOf("isoWeek").toDate();

    let now = moment().tz(this.state.timeZone).toDate();
    let yesterday = moment().tz(this.state.timeZone).subtract(1, "day").startOf('day').toDate();


   


    this.updateQuery(yesterday, now);
    // if(this.props.dataUser.typeUser==="merchant"){this.getKushkiOKInfo(firstDayWeek, lastDayWeek,firstDay,lastDay) }

    // CALCULO EL MES ACTUAL
    let mes = moment.tz(this.state.timeZone).format("MMMM");
    this.setState({
      mes: mes
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      console.log("componentDidUpdate");
      const merchant = localStorage.getItem("merchant_code");
      moment.tz.setDefault(this.state.timeZone);
      let firstDayWeek = moment.tz(this.state.timeZone).startOf("isoWeek").toDate();
      let lastDayWeek = moment.tz(this.state.timeZone).endOf("isoWeek").toDate();
      let now = moment().tz(this.state.timeZone).toDate();
      let yesterday = moment().tz(this.state.timeZone).subtract(1, "day").startOf('day').toDate();
      let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
      let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();
      // CALCULO EL MES ACTUAL
      let mes = moment.tz(this.state.timeZone).format("MMMM");
      this.setState({
        mes: mes
      });

  
      this.updateQuery(yesterday, now);
      this.setState({
        filename: `Kushki validated ${localStorage.getItem("merchant_name")}`
      });
    }
  }



  updateQuery = async (firstDay, lastDay, merchant) => {

    dataList = [];
    let commissionesBd = this.props.comissionData
      ? this.props.comissionData
      : JSON.parse(localStorage.getItem("comisiones"));
    commissionesBd = commissionesBd.filter(
      item => item.merchant === this.props.merchant.code
    );
    commissionesBd = commissionesBd[0].commission.filter(
      item => item.countrie === this.props.country.code
    );

    /************************************************************************************************ */
    let url = window.location.href;
    var arrayDeCadenas = url.split("?");
    let parametros = {
      "merchant": this.props.merchant.code,
      "firstDay": firstDay,
      "lastDay": lastDay,
      "comission": commissionesBd[0],
      "country": localStorage.getItem("country_code"),
      "code": 0,
      "payMethod": this.state.payMethod,

    }
    console.log("parametros", parametros)


    await API.utils.getKushkiForParamsOK(parametros).then(cons => {
      console.log("getBalance", cons)
      this.setState({
        dataList: cons
      })

      cons.sort(function (x, y) {
        return x.dataKushki - y.dataKushki;
      })
      console.log("data ordenada ", cons);

    });
    //console.log(this.state.dataList.sort((a, b) => new Date(a.fechas).getTime() > new Date(b.fechas).getTime()));
  };
  update = async (e) => {
    await this.componentDidUpdate(this.state);
  }

  render() {
    this.expandedRowRender = record => (
      <div>
        <p>
          Operation Code: <b>{record.operationCode}</b>
        </p>
        <p>
          Email: <b>{record.email}</b>
        </p>


        {this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporteAdmin" ? (
          <div>
            {record.adminCallBackDate ?
              (<p>
                Admin Callback: Date:<b>{
                  moment(new Date(record.adminCallBackDate._seconds * 1000).toISOString()).toDate().toLocaleString("es-CL", {
                    timeZone: this.state.timeZone,
                    timeZoneName: "short"
                  }
                  )
                }
                </b>
              </p>) : ""
            }
          </div>

        ) : (
          ""
        )}

        {this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporteAdmin" ? (

          <div>
            {record.adminCallBack ?
              (<p>
                Admin Callback:<b>{record.adminCallBack}</b>
              </p>) : ""
            }
          </div>
        ) : (
          <> </>
        )}
        {this.props.dataUser.typeUser !== "merchant" ? (
          <div>
            {record.collection ?
              (<p>
                Agregator:<b>{record.collection}</b>
              </p>) : ""
            }
          </div>
        ) : (
          <> </>
        )}

        {this.props.dataUser.typeUser !== "merchant" ? (
          <div>
            {record.payMethod ?
              (<p>
                Pay Method:<b>{record.payMethod}</b>
              </p>) : ""
            }
          </div>
        ) : (
          <> </>
        )}

      </div>
    );
    let view;
    if (this.props.dataUser.typeUser === "merchant") {
      view = (
        <Row>
         
          <Col span={24}>
            <Dynamic
              defaultValue={this.state.defaultValueRangeFilter}
              updateQuery={this.updateQuery}
              filename={this.state.filename}
              title={this.state.title}
              dataList={this.state.dataList}
              // columns={this.columns}
              expandedRowRender={this.expandedRowRender}
              getData={this.getData}
              onTableChange={this.onTableChange}
              timeZone={this.state.timeZone}
              merchant={this.props.merchant}
              typeUser={this.props.dataUser.typeUser}
            // onTimeZoneChange={e => this.onTimeZoneChange(e)}
            />
          </Col>
        </Row>
      );

    }
    else {
      view = (<Row>
        <Col span={24}>
          <Dynamic
            defaultValue={this.state.defaultValueRangeFilter}
            updateQuery={this.updateQuery}
            user={this.props.authUser}
            update={this.update}
            filename={this.state.filename}
            title={this.state.title}
            dataList={this.state.dataList}
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            onTableChange={this.onTableChange}
            merchant={this.props.merchant}
            typeUser={this.props.dataUser.typeUser}
          />
        </Col>

      </Row>);
    }
    return (
      <Auxiliary>
        {view}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, comissionData, country, dataUser } = settings;
  return { authUser, merchant, comissionData, country, dataUser };
};
export default connect(mapStateToProps)(kushkiOk);
