import React, { useState, useEffect, useCallback } from "react";
import {
  Input,
  Button,
  Breadcrumb,
  notification,
  Alert,
  Tooltip,
  Modal,
  Tag,
  Steps,
  Icon,
  Timeline,
  Row,
  Col,
  Divider
} from "antd";
import TablePayrollDetail from "./payroll-detail/resources/table-payroll-detail";
import { Link } from "react-router-dom";
import moment from "moment";
import TimeZoneSelect from "./payroll/TimeZoneSelect";
import SearchPayroll from "./payroll/Search";
import queryParams from "../../../util/queryParams";
import api from "../../../util/api";
import CollapseLogsPayroll from "./payroll-detail/collapse-logs-payroll";
import MenuPayrollHeader from "./MenuPayrollHeader";
import {
  isActiveNewCashout,
  isActiveOldCashout
} from "../../../util/check-availability-new-cashout";
import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebase";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReactExport from "react-export-excel";
import ModalChildrenSelfManagement from "./payroll-detail/modal-children-self-management";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { TextArea } = Input;
const { Step } = Steps;
const db = firebase.firestore();

const statusConfig = {
  0: "validated",
  1: "created",
  7: "authorized",
  9: "pending",
  12: "failed"
};

const messageDisabledNewCashout =
  "The new cashout (payrolls) is disabled and the old cashout is enabled";

const PayrollDetail = props => {
  const payroll_id = props.match.params.id;
  const type = queryParams().type;
  const [data, setData] = useState({});
  const [txns, setTxns] = useState([]);
  const [loading, setLoading] = useState(true);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const fetchPayroll = async () => {
    setLoading(true);
    setData({});
    return await api.utils
      .findById({
        payroll_id: payroll_id
      })
      .then(response => {
        let dataR = response.data;
        setData(dataR);
        return dataR;
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [timeZone, setTimeZone] = useState("America/Santiago");

  const onTimeZoneChange = zone => {
    moment.tz.setDefault(zone);
    setTimeZone(zone);
  };

  const [loadingParametrosGenerales, setloadingParametrosGenerales] = useState(
    true
  );
  const [parametrosGenerales, setparametrosGenerales] = useState({
    isActiveNewCashout: false,
    commerces: {}
  });

  const getParametrosGenerales = () => {
    setloadingParametrosGenerales(true);
    db.collection("parametrosGenerales")
      .doc("cashout")
      .get()
      .then(parametrosGenerales => {
        const {
          commerces = {},
          isActiveNewCashout = false
        } = parametrosGenerales.data();
        setparametrosGenerales({
          isActiveNewCashout,
          commerces
        });
      })
      .catch(err => {
        console.log("ERROR IN getParametrosGenerales", err);
      })
      .finally(() => {
        setloadingParametrosGenerales(false);
      });
  };

  useEffect(() => {
    moment.tz.setDefault(timeZone);
    fetchPayroll();
    getParametrosGenerales();
  }, []);

  const changeHistory = query => {
    props.history.push({
      pathname: props.path,
      search: `?${query}`,
      state: { detail: "some_value" }
    });
  };

  const searchTxns = params => {
    if (data && data.txns && data.txns.length) {
      let copyDataTxns = JSON.parse(JSON.stringify(data.txns));
      setTxns(
        copyDataTxns.filter(item => {
          if (params.filter && params.search) {
            return JSON.stringify(item[params.filter])
              .toLowerCase()
              .includes(params.search.toLowerCase());
          } else {
            return item;
          }
        })
      );
    }
  };

  const onSearch = params => {
    let query = new URLSearchParams({
      ...queryParams(),
      search: params.search,
      filter: params.filter
    }).toString();

    changeHistory(query);
  };

  useEffect(() => {
    searchTxns(queryParams());
  }, [document.location.search, data]);

  const optionsFilter = [
    { value: "zippyId", label: "Zippy ID" },
    { value: "commerceReqId", label: "Commerce ID" },
    { value: "name", label: "Name" },
    { value: "rut", label: "RUT" },
    { value: "email", label: "Email" }
  ];

  const [isLoadButtonLoad, setIsLoadButtonLoad] = useState(false);
  const [isDisabledButtonLoad, setIsDisabledButtonLoad] = useState(false);

  const onLoad = useCallback(async () => {
    setIsLoadButtonLoad(true);
    getStateCashoutV2().then(async res => {
      if (res.isActiveNewCashout) {
        const result = await executeRecaptcha("payrollLoad");
        await api.utils
          .payrollLoad({
            payroll_id: payroll_id,
            token: result
          })
          .then(async res => {
            if (!res.success) {
              throw res;
            }

            setIsDisabledButtonLoad(true);

            let resp = await fetchPayroll();
            props.history.push(`?type=${statusConfig[resp.status]}`);

            notification.success({
              message: "Payroll loaded successfully",
              description: ""
            });
          })
          .catch(err => {
            notification.error({
              message: err.message,
              description: ""
            });
          })
          .finally(() => {
            setIsLoadButtonLoad(false);
          });
      } else {
        setIsLoadButtonLoad(false);
        alert(messageDisabledNewCashout);
      }
    });
  }, [executeRecaptcha]);

  const getStateCashoutV2 = async () => {
    return await api.utils.getStateCashoutV2();
  };

  const updateStateDisabledButtonLoad = async () => {
    setIsLoadButtonLoad(false);
    getStateCashoutV2().then(data => {
      setIsDisabledButtonLoad(!data.isActiveNewCashout);
    });
  };

  const [isLoadButtonValidate, setIsLoadButtonValidate] = useState(false);

  const onValidate = useCallback(async () => {
    setIsLoadButtonValidate(true);
    getStateCashoutV2().then(async res => {
      if (res.isActiveNewCashout) {
        const result = await executeRecaptcha("updateRetriesValidation");
        await api.utils
          .updateRetries({
            payrollId: payroll_id,
            type: "validation",
            token: result
          })
          .then(async data => {
            if (data.status == "success") {
              let resp = await fetchPayroll();
              props.history.push(`?type=${statusConfig[resp.status]}`);
            } else {
              throw "Error";
            }
          })
          .catch(err => {
            notification.error({
              message: "Error",
              description: "There is an error in validation retrie"
            });
            console.log(err);
          })
          .finally(() => {
            setIsLoadButtonValidate(false);
          });
      } else {
        setIsLoadButtonValidate(false);
        alert(messageDisabledNewCashout);
      }
    });
  }, [executeRecaptcha]);

  const [isLoadButtonAuthorizate, setIsLoadButtonAuthorizate] = useState(false);

  const onAuthorizate = useCallback(async () => {
    setIsLoadButtonAuthorizate(true);
    getStateCashoutV2().then(async res => {
      if (res.isActiveNewCashout) {
        const result = await executeRecaptcha("updateRetriesAuthorization");
        await api.utils
          .updateRetries({
            payrollId: payroll_id,
            type: "authorization",
            token: result
          })
          .then(async data => {
            if (data.status == "success") {
              let resp = await fetchPayroll();
              props.history.push(`?type=${statusConfig[resp.status]}`);
            } else {
              throw "Error";
            }
          })
          .catch(err => {
            notification.error({
              message: "Error",
              description: "There is an error in authorization retrie"
            });
            console.log(err);
          })
          .finally(() => {
            setIsLoadButtonAuthorizate(false);
          });
      } else {
        setIsLoadButtonAuthorizate(false);
        alert(messageDisabledNewCashout);
      }
    });
  }, [executeRecaptcha]);

  const [isLoadButtonPending, setIsLoadButtonPending] = useState(false);

  const onPending = useCallback(async () => {
    setIsLoadButtonPending(true);
    getStateCashoutV2().then(async res => {
      if (res.isActiveNewCashout) {
        const result = await executeRecaptcha("updateRetriesLoad");
        await api.utils
          .updateRetries({
            payrollId: payroll_id,
            type: "load",
            token: result
          })
          .then(async data => {
            if (data.status == "success") {
              let resp = await fetchPayroll();
              props.history.push(`?type=${statusConfig[resp.status]}`);
            } else {
              throw "Error";
            }
          })
          .catch(err => {
            notification.error({
              message: "Error",
              description: "There is an error in pending/load retrie"
            });
            console.log(err);
          })
          .finally(() => {
            setIsLoadButtonPending(false);
          });
      } else {
        setIsLoadButtonPending(false);
        alert(messageDisabledNewCashout);
      }
    });
  }, [executeRecaptcha]);

  const textAreaOnchange = e => {
    let regValSanta = e.target.value;

    var nstr = regValSanta.toString().split(/\n/); //se separa por  saltos de linea
    nstr
      .filter(re => re === "")
      .map(re => {
        //se filtra y se eliminan los vacios
        nstr.splice(
          nstr.findIndex(e => e === re),
          1
        );
      });

    let arregloOriginal = nstr,
      arregloDeArreglos = []; // Aquí almacenamos los nuevos arreglos
    const LONGITUD_PEDAZOS = 7; // Partir en arreglo de 3
    for (let i = 0; i < arregloOriginal.length; i += LONGITUD_PEDAZOS) {
      let pedazo = arregloOriginal.slice(i, i + LONGITUD_PEDAZOS);
      arregloDeArreglos.push(pedazo);
    }

    localStorage.setItem("regValSantander", JSON.stringify(arregloDeArreglos));
  };

  const [loadingValidateSantander, setloadingValidateSantander] = useState(
    false
  );

  const validateSantander = useCallback(
    async dataList => {
      try {
        setloadingValidateSantander(true);
        console.log("entra! dataList", dataList);
        let arreglo = [];
        if (dataList.length > 0) {
          arreglo = dataList;
        } else {
          console.log("entra data.txns", data.txns);
          arreglo = data.txns;
        }

        // arreglo = data.txns;
        let dataListnew = [];

        let fileListValidated = JSON.parse(
          localStorage.getItem("regValSantander")
        );
        if (fileListValidated && arreglo) {
          let a = arreglo.map(reg => {
            let filtro = fileListValidated.find(
              row =>
                String(reg.rut)
                  .replace(/[\. ,:-]+/g, "")
                  .trim()
                  .toLocaleUpperCase() ===
                  row[3]
                    .toString()
                    .replace(/[\. ,:-]+/g, "")
                    .toLocaleUpperCase() &&
                reg.quantity.toString() ===
                  row[5].toString().replace(/[\. ,.$]+/g, "")
            );

            if (typeof filtro !== "undefined") {
              fileListValidated.splice(
                fileListValidated.findIndex(e => e === filtro),
                1
              );

              if (filtro[6] === "Realizada") {
                reg.valSAN = "Accepted";
              } else {
                reg.valSAN = "Rejected";
              }

              dataListnew.push(reg);
            } else {
              reg.valSAN = "Not found";
              dataListnew.push(reg);
            }
          });
          const result = await executeRecaptcha("setValidateManual");
          console.log(
            "entra3",
            dataListnew
              .map(item => {
                return {
                  id: item.id,
                  valSAN: item.valSAN
                };
              })
              .filter(item => item.valSAN != "Not found")
          );
          api.utils
            .setValidateManual({
              payrollId: payroll_id,
              txns: dataListnew
                .map(item => {
                  return {
                    id: item.id,
                    valSAN: item.valSAN
                  };
                })
                .filter(item => item.valSAN != "Not found"),
              token: result
            })
            .then(async res => {
              if (res.status == "success") {
                notification.success({
                  message: "Payroll manual validated successfully"
                });
                const fetchData = await fetchPayroll();

                if (fetchData.status == 0) {
                  localStorage.setItem("regValSantander", "");
                  document.getElementById("textArea1").value = "";
                  props.history.push(
                    `/main/payroll/payroll-detail/${payroll_id}?type=validated`
                  );
                }
              }
            })
            .catch(error => {
              console.log(error);
              throw "Error";
            })
            .finally(() => {
              setloadingValidateSantander(false);
            });
        }
      } catch (error) {
        console.log("Error in validateSantander:", error);
        notification.error({
          message: "Error",
          description:
            "Payroll manual validationThere is an error in the manual validation of payroll"
        });
      }
    },
    [executeRecaptcha]
  );

  const clearSant = () => {
    localStorage.setItem("regValSantander", "");
    document.getElementById("textArea1").value = "";
    window.location.reload();
  };

  useEffect(() => {
    updateStateDisabledButtonLoad();
  }, []);

  const [merchantCode, setMerchantCode] = useState(props.merchant.code);

  useEffect(() => {
    if (merchantCode != props.merchant.code) {
      props.history.push(`/main/payroll/${statusConfig[data.status]}`);
    }
  }, [props.merchant.code]);

  const [
    isLoadingButtonSelfManagement,
    setisLoadingButtonSelfManagement
  ] = useState(false);

  const [modalConfirm, setModalConfirm] = useState({
    show: false,
    res: {}
  });

  const selfManagement = useCallback(async () => {
    setisLoadingButtonSelfManagement(true);
    try {
      const res = await api.utils.getSelfManagement(payroll_id);
      if (res.status != 200) throw { message: res.message, status: res.status };

      setModalConfirm({
        show: true,
        res
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: `Error ${error.status}`,
        description: error.message
      });
    } finally {
      setisLoadingButtonSelfManagement(false);
    }
  }, [executeRecaptcha]);

  const hideModalConfirm = () => {
    setModalConfirm({
      show: false,
      res: {}
    });
  };

  const [
    isLoadingButtonSelfManagementConfirm,
    setisLoadingButtonSelfManagementConfirm
  ] = useState(false);

  const selfManagementConfirmated = useCallback(
    async data => {
      setisLoadingButtonSelfManagementConfirm(true);
      const result = await executeRecaptcha("selfManagementConfirmated");
      try {
        const res = await api.utils.selfManagementConfirmated(payroll_id, {
          nextState: data.next.status,
          token: result,
          modifierUser: props.authUser
        });
        if (res.status != 200)
          throw { message: res.message, status: res.status };
        hideModalConfirm();
        notification.success({
          message: "Status change",
          description: "The payroll status change was successfully made"
        });
        let resp = await fetchPayroll();
        props.history.push(`?type=${statusConfig[resp.status]}`);
      } catch (error) {
        console.error(error);
        notification.error({
          message: `Error ${error.status}`,
          description: error.message
        });
      } finally {
        setisLoadingButtonSelfManagementConfirm(false);
      }
    },
    [executeRecaptcha]
  );

  const timeCondition = update_at => {
    const now = new Date();
    const updateAtFormated = new Date(
      moment(update_at)
        .tz(timeZone)
        .toDate()
    );
    const diffMinutes = Math.floor((now - updateAtFormated) / 60000);
    if (diffMinutes >= 10) return true;

    return false;
  };

  return (
    <>
      <div>
        <div>
          {isActiveOldCashout(
            parametrosGenerales,
            props.merchant.code,
            loadingParametrosGenerales
          ) && (
            <>
              <Alert
                message="Warning"
                description={messageDisabledNewCashout}
                type="warning"
                showIcon
              />
              <hr />
            </>
          )}
        </div>
        <div className="table-operations-header">
          <div>
            <div>
              <h2>Payroll detail</h2>
              <Breadcrumb separator="">
                <Breadcrumb.Item>
                  <Link to="/main/dashboard">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Separator />
                <Breadcrumb.Item>
                  <Link to={`/main/payroll/${type}`}>Payroll {type}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Separator />
                <Breadcrumb.Item>Payroll detail</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div>
            <div>
              <TimeZoneSelect
                onTimeZoneChange={onTimeZoneChange}
                value={timeZone}
              />
            </div>
          </div>
        </div>
        <MenuPayrollHeader type={type} />
        <br />
        <div className="table-operations-header">
          <div style={{ width: "100%" }}>
            <Button icon="arrow-left" onClick={() => props.history.go(-1)}>
              Go back
            </Button>
            {isActiveNewCashout(
              parametrosGenerales,
              props.merchant.code,
              loadingParametrosGenerales
            ) && (
              <>
                {(props.dataUser.typeUser == "admin" ||
                  props.dataUser.typeUser == "soporteCashout") &&
                  (data.status != 0 || !data.isEnabled) &&
                  timeCondition(data.updatedAt) && (
                    <>
                      <Button
                        icon="check"
                        type="primary"
                        disabled={loading}
                        onClick={selfManagement}
                        loading={isLoadingButtonSelfManagement}
                      >
                        Self Management
                      </Button>
                      <Modal
                        title="Confirm Self Management Payroll"
                        visible={modalConfirm.show}
                        onOk={() => selfManagementConfirmated(modalConfirm.res)}
                        onCancel={hideModalConfirm}
                        okText="Confirm"
                        cancelText="Cancel"
                        confirmLoading={isLoadingButtonSelfManagementConfirm}
                      >
                        {modalConfirm.res.current && modalConfirm.res.next && (
                          <ModalChildrenSelfManagement
                            payroll_id={payroll_id}
                            data={modalConfirm.res}
                          />
                        )}
                      </Modal>
                    </>
                  )}
                {(props.dataUser.typeUser == "admin" ||
                  props.dataUser.typeUser == "soporteCashout") &&
                  data.status == 1 &&
                  data.isEnabled && (
                    <Button
                      icon="check"
                      type="primary"
                      disabled={
                        isDisabledButtonLoad || loading || data.inProcess
                      }
                      onClick={onLoad}
                      loading={isLoadButtonLoad}
                    >
                      Load payroll
                    </Button>
                  )}
                {(props.dataUser.typeUser == "admin" ||
                  props.authUser == "rhernandez@stratechcorp.com" ||
                  props.authUser == "btapia@stratechcorp.com" ||
                  props.authUser == "ca.marchant.tapia@gmail.com") &&
                  data.isEnabled == false && (
                    <>
                      <Button
                        icon="check"
                        type="primary"
                        disabled={data.validationRetries != 0}
                        onClick={onValidate}
                        loading={isLoadButtonValidate}
                      >
                        Enable validate
                      </Button>
                      <Button
                        icon="check"
                        type="primary"
                        disabled={data.authorizationRetries != 0}
                        onClick={onAuthorizate}
                        loading={isLoadButtonAuthorizate}
                      >
                        Enable authorizate
                      </Button>
                      <Button
                        icon="check"
                        type="primary"
                        disabled={data.loadRetries != 0}
                        onClick={onPending}
                        loading={isLoadButtonPending}
                      >
                        Enable load
                      </Button>
                    </>
                  )}
              </>
            )}
          </div>
          <div style={{ width: "100%", textAlign: "right" }}>
            <SearchPayroll
              onChange={onSearch}
              options={optionsFilter}
              placeholder="Search by transactions..."
            />
          </div>
        </div>
        <div>
          <TablePayrollDetail
            txns={txns}
            fetching={loading}
            style={{ background: "white" }}
            payroll_id={payroll_id}
            onReload={fetchPayroll}
            data={data}
          />
        </div>
        <hr />
        <div>
          <ExcelFile
            filename={`Payroll transactions: ${payroll_id} - ${new Date().toDateString()}`}
            element={
              <div>
                <Tooltip title="Download transactions">
                  <Button
                    loading={loading}
                    icon="download"
                    style={{ marginBottom: 0 }}
                  >
                    Transactions
                  </Button>
                </Tooltip>
              </div>
            }
          >
            <ExcelSheet data={txns} name="Transactions">
              <ExcelColumn label="Payroll ID" value={col => payroll_id} />
              <ExcelColumn label="Zippy ID" value="zippyId" />
              <ExcelColumn
                label="Quantity"
                value={col => Number(col.quantity)}
              />
              <ExcelColumn
                label="RUT"
                value={col =>
                  String(col.rut)
                    .replace(/[\. ,:-]+/g, "")
                    .trim()
                    .toLocaleUpperCase()
                }
              />
              <ExcelColumn label="Name" value="name" />
              <ExcelColumn label="Email" value="email" />
              <ExcelColumn label="Number account" value="numAccount" />
              <ExcelColumn label="Bank ID" value="bankId" />
              <ExcelColumn label="Type account ID" value="typeAccountId" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Code" value="code" />
              <ExcelColumn label="Commerce req. ID" value="commerceReqId" />
              <ExcelColumn
                label="Date request"
                value={col =>
                  new Date(col.dateRequest).toLocaleString("es-CL", {
                    timeZone: timeZone,
                    timeZoneName: "short"
                  })
                }
              />
              <ExcelColumn label="ID" value="id" />
            </ExcelSheet>
          </ExcelFile>
        </div>
        <hr />
        {(props.dataUser.typeUser == "admin" ||
          props.dataUser.typeUser == "soporteCashout") &&
          isActiveOldCashout(
            parametrosGenerales,
            props.merchant.code,
            loadingParametrosGenerales
          ) &&
          data.status == 7 && (
            <div>
              <hr />
              <div>
                <h3>Validation Banco Santander</h3>
                <div>
                  <TextArea
                    rows={8}
                    placeholder="Paste the selected text from the bank here..."
                    id={"textArea1"}
                    onChange={textAreaOnchange}
                    disabled={
                      data.txns.filter(item => item.valSAN == "Accepted")
                        .length == data.txns.length
                    }
                  />
                </div>
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => validateSantander(data.txns)}
                loading={loadingValidateSantander}
                disabled={
                  data.status != 7 ||
                  data.txns.filter(item => item.valSAN == "Accepted").length ==
                    data.txns.length
                }
              >
                Validate
              </Button>
              <Button
                onClick={clearSant}
                disabled={
                  data.status != 7 ||
                  data.txns.filter(item => item.valSAN == "Accepted").length ==
                    data.txns.length
                }
              >
                Clean
              </Button>
            </div>
          )}
        <hr />
        <CollapseLogsPayroll
          data={data}
          payroll_id={payroll_id}
          loading={loading}
        />
        <br />
      </div>
      <style jsx="true">{`
        .loading {
          width: 100%;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .section-button {
          display: flex;
          justify-content: flex-end;
        }
        .table-operations-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1em;
        }
      `}</style>
    </>
  );
};

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, dataUser } = settings;
  return { merchant, dataUser, authUser };
};
export default connect(mapStateToProps)(PayrollDetail);
