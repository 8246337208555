const utils = {
  utils: {
    colorProvider(provider) {
      let color = {};
      switch (provider) {
        case "MONNET":
          color = {
            color: "#000000",
            background: "#b0f2c2"
          };
          return color;
        case "SAFETYPAY":
          color = {
            color: "#000000",
            background: "#ffda9e"
          };
          return color;
        case "ETHEREUM":
          color = {
            color: "#000000",
            background: "#b2e2f2"
          };
          return color;
        case "PAGOEFECTIVO":
          color = {
            color: "#000000",
            background: "#fcb7af"
          };
          return color;
        case "AIRTM":
          color = {
            color: "#000000",
            background: "#fdf9c4"
          };
          return color;
        case "SKINSBACK":
          color = {
            color: "#000000",
            background: "#d2bead"
          };
          return color;
        case "ALPS":
          color = {
            color: "#000000",
            background: "#afeeee"
          };
          return color;
        case "KUSHKI":
          color = {
            color: "#000000",
            background: "#a2c8cc"
          };
          return color;
        case "PAYKU":
          color = {
            color: "#000000",
            background: "#b0c2f2"
          };
          return color;
        case "WEBPAY":
          color = {
            color: "#000000",
            background: "#b0f2c2"
          };
          return color;
        case "APIRONE":
          color = {
            color: "#000000",
            background: "#f6a5c0"
          };
          return color;
        case "MACH":
          color = {
            color: "#000000",
            background: "#87cefa"
          };
          return color;
        case "REDPAY":
          color = {
            color: "#000000",
            background: "#ff8097"
          };
          return color;
        default:
          color = {
            color: "#000000",
            background: "#f8edeb"
          };
          return color;
      }
    },
    colorActive(active) {
      let color = {};
      switch (active) {
        case true:
        case "true":
          color = {
            color: "#000000",
            background: "#77dd77"
          };
          return color;
        case false:
        case "false":
          color = {
            color: "#000000",
            background: "#ff6961"
          };
          return color;
        default:
          color = {
            color: "#000000",
            background: "#ffcba4"
          };
          return color;
      }
    },
    getNameCountry(prefix){
      switch (prefix) {
        case "CL":
          return "CHILE";
        case "CO":
          return "COLOMBIA";
        case "CR":
          return "COSTA RICA";
        case "EC":
          return "ECUADOR";
        case "MX":
          return "MEXICO";
        case "PE":
          return "PERU";
        case "SV":
          return "EL SALVADOR";
        case "AR":
          return "ARGENTINA";
        case "BR":
          return "BRASIL";
        default:
          return prefix;
      }
    }
  }
};

export default utils;
