import React from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { Col, Row, Divider } from "antd";
import NumberFormat from "react-number-format";
import currencyNumberFormat from "../../../util/currencyNumberFormat"
import Metrics from "components/Metrics";

const GrowthCard = ({ trafficData, title, month, suma, avg, dailyAvg, typeUser,countryCode }) => {
  return (
    <Metrics styleName={`gx-card-full`} title={title + " (" + month + ")"}>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="gx-pb-0 gx-pl-4  gx-pr-4 gx-pt-3 gx-text-right" style={{ right: 0 }}>
            {/* <p className="gx-mb-0 gx-text-grey">{month}</p> */}
            <h2 className="gx-fs-xxxl gx-font-weight-medium gx-chart-up">
            {currencyNumberFormat(suma,countryCode) }
             {/*  <NumberFormat
                value={parseFloat(suma).toFixed(2) }
                displayType={"text"}
                thousandSeparator={","}
                decimalSeparator={"."}
                prefix={"$"}
              /> */}
              {/* <i className="icon icon-menu-up gx-fs-xxl" /> */}
            </h2>
          </div>
        </Col>
        <Col lg={13} md={13} sm={13} xs={13}>
          <div className="gx-pb-0 gx-pl-4 gx-pt-3">
            <ResponsiveContainer width="100%" height={103}>
              <AreaChart
                data={trafficData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                {trafficData ?
                  <Tooltip
                    labelFormatter={index => {
                      if (index) {
                        /* console.log("trafficData[index]",trafficData[index]); */
                        if (trafficData[index]) {
                          return trafficData[index].name;
                        }
                        return "";

                      }
                    }}
                  />
                  :
                  null
                }


                {/* <Tooltip content={<CustomTooltip />} /> */}
                <defs>
                  <linearGradient id="color1" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="5%" stopColor="#FF55AA" stopOpacity={0.9} />
                    <stop offset="95%" stopColor="#6757DE" stopOpacity={0.9} />
                  </linearGradient>
                </defs>
                <Area
                  data={trafficData}
                  nameKey="name"
                  dataKey="value"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#867AE5"
                  fill="url(#color1)"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <br></br>
        </Col>
         <Col lg={11} md={11} sm={11} xs={11}>
         <div className="gx-pb-0 gx-pl-4  gx-pr-4 gx-pt-3 gx-text-right">
             <>
               <div className="gx-fs-xs">
                 Trx Avg.:{" "}
                 {avg >0?currencyNumberFormat(avg,countryCode):currencyNumberFormat(0,countryCode)}
               </div>
               <div className="gx-fs-xs">
                 Daily Avg.:{" "}
                 {dailyAvg >0?currencyNumberFormat(dailyAvg,countryCode):currencyNumberFormat(0,countryCode)}
               </div>
               <br></br>
             </>
         
           <br></br>
         </div>
       </Col>
      </Row>
    </Metrics>
  );
};

export default GrowthCard;
