import {
  // DELETE_CONTACT_SUCCESS,
  GET_All_INVOICE_SUCCESS,
  ON_ADD_INVOICE_SUCCESS
  // UPDATE_CONTACT_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  invoiceList: [],
  selectedInvoice: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_All_INVOICE_SUCCESS: {
      return {
        ...state,
        invoiceList: action.payload
      };
    }

    // case UPDATE_CONTACT_SUCCESS:
    //   return {
    //     ...state,
    //     contactList: state.contactList.map((contact) => contact.id === action.payload.id ? action.payload : contact),
    //   };

    // case DELETE_CONTACT_SUCCESS:
    //   return {
    //     ...state,
    //     contactList: state.contactList.filter((contact) => contact.id !== action.payload.id),
    //   };

    case ON_ADD_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceList: action.payload.invoice(state.invoiceList)
      };

    default:
      return state;
  }
};
