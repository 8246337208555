import React, { useState, useEffect } from "react";
import OcupationalTrafficPayin from "./payin";
import OcupationalTrafficPayout from "./payout";
import OcupationalTrafficWebpay from "./webpay";
import API from "../../../util/api";
import { Button } from "antd";
import * as ExcelJS from "exceljs";
import utils from "./utils";

const OcupationalTraffic = ({}) => {
  const [data, setData] = useState();
  const [disabledExcel, setDisabledExcel] = useState(true)
  const getOccupationalTraffic = async () => {
    try {
      await API.utils
        .getOccupationalTraffic()
        .then(data => {
          // setOccupationalTrafficPayin(data.response.data.PAYIN);
          // setFilterCountries(data.response.filter.countries);
          // setFilterMethodsPayin(data.response.filter.methodsPayin);
          // setFilterIsActiveCommerce(data.response.filter.activeCommerce);
          // setFilterProvidersPayin(data.response.filter.providersPayin);
          console.log("getOccupationalTraffic  ", data.response);
          setData(data.response);
          localStorage.setItem(
            "OccupationalTraffic",
            JSON.stringify(data.response)
          );
          setDisabledExcel(false)
          // createTable();
        })
        .catch(error => {
          console.log("error getOccupationalTraffic", error);
        });
    } catch (error) {
      console.log("getOccupationalTraffic error " + error);
    }
  };
  useEffect(() => {
    getOccupationalTraffic();
    // createTable()
  }, []);

  const getColumnLetter = columnIndex => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let columnLetter = "";
    while (columnIndex >= 0) {
      columnLetter = alphabet[columnIndex % 26] + columnLetter;
      columnIndex = Math.floor(columnIndex / 26) - 1;
    }

    return columnLetter;
  };

  const getColumnLetterByHeader = async (sheet, searchedHeader) => {
    let letterColumn = null;
    sheet.columns.forEach((columna, indice) => {
      if (columna.header === searchedHeader) {
        letterColumn = getColumnLetter(indice);
      }
    });

    return letterColumn;
  };

  const getColumnsTitleCountry = async (sheet, countries) => {
    let columnsCountry = {};
    //Se busca el comienzo de la columna
    sheet.columns.forEach((column, indice) => {
      countries[0].PAYIN.map(async country => {
        if (
          !columnsCountry.hasOwnProperty(country) &&
          column.header === country
        ) {
          let letterColumn = getColumnLetter(indice);
          columnsCountry[country] = [letterColumn];
        }
      });
    });
    // se busca el final de la columna
    sheet.columns.forEach(column => {
      countries[0].PAYIN.map(async country => {
        if (
          columnsCountry.hasOwnProperty(country) &&
          column.header === country
        ) {
          let letterColumn = await getColumnLetterByHeader(sheet, country);
          let verifyColumn = columnsCountry[country].filter(
            c => c === letterColumn
          );
          if (verifyColumn.length === 0)
            columnsCountry[country] = [
              ...columnsCountry[country],
              letterColumn
            ];
        }
      });
    });
    return columnsCountry;
  };

  const combineCellsWithSameValueInRow = async (worksheet, row) => {
    let previusValue = null;
    let startCombination = 3; // Comenzar desde la segunda columna (columna de datos)

    worksheet.getRow(row).eachCell((cell, colNumber) => {
      const currentValue = cell.value;
      if (currentValue === previusValue) {
        // Continuar combinando si el valor es el mismo que el anterior
      } else {
        // Combinar celdas si el valor es diferente al anterior
        if (colNumber - startCombination > 1) {
          worksheet.mergeCells(
            `${getColumnName(startCombination)}${row}:${getColumnName(
              colNumber - 1
            )}${row}`
          );
        }
        startCombination = colNumber;
      }

      previusValue = currentValue;
    });
  };

  // Función para obtener el nombre de la columna a partir del número de columna
  const getColumnName = columnNumber => {
    let dividend = columnNumber;
    let columnName = "";
    let module;

    while (dividend > 0) {
      module = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + module) + columnName;
      dividend = parseInt((dividend - module) / 26);
    }
    return columnName;
  };

  const customizeCell = sheet => {
    sheet.eachRow({ includeEmpty: true }, row => {
      row.eachCell(cell => {
        let colors = utils.utils.colorProvider(cell.value);
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: colors.background.replace("#", "") }
        };
        cell.font = { color: { argb: colors.color.replace("#", "") } };
        if (
          cell.value === true ||
          cell.value === false ||
          cell.value === "parameter does not exist"
        ) {
          colors = utils.utils.colorActive(cell.value);
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: colors.background.replace("#", "") }
          };
          cell.font = { color: { argb: colors.color.replace("#", "") } };
        }
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.value = utils.utils.getNameCountry(cell.value);
      });
    });
  };
  const handleExportExcel = async () => {
    const data = JSON.parse(localStorage.getItem("OccupationalTraffic"));
    const payin = data.data.PAYIN ?  data.data.PAYIN : false;
    const payout = data.data.PAYOUT ? data.data.PAYOUT : false;
    const webpay = data.data.WEBPAY ? data.data.WEBPAY: false;
    const countries = data.filter.countries ? data.filter.countries : false;
    const methodsPayin = data.filter.methodsPayin ? data.filter.methodsPayin : false;
    const activeCommerce = data.filter.activeCommerce ? data.filter.activeCommerce : false;
    console.log("[LOG][DATA PAYIN]", payin);
    console.log("[LOG][COUNTRIES]", countries);
    console.log("[LOG][METHODS]", methodsPayin);

    const book = new ExcelJS.Workbook();
    let dataExcel = [];
    let columnsCountries = [" ", " "];
    let contColumns = 2;
    let sheetPayin = false
    /* ---------------------------------------- PAYIN ---------------------------------------------------------*/
    if(payin && countries && methodsPayin){
      sheetPayin = book.addWorksheet("detalle_operacional_payin");
      // let columnsCountries = [{ header: "ACTIVE", key: "active", width: 15 }, { header: "COMMERCE", key: "commerce", width: 15 }]
  
      let columnsMethods = [
        { header: "ACTIVE", key: "active", width: 20 },
        { header: "COMMERCE", key: "commerce", width: 25 }
      ];
      for (let method in methodsPayin) {
        if (methodsPayin[method].length > 0) {
          methodsPayin[method].map(m => {
            columnsMethods.push({ header: m, key: method + m, width: 20 });
          });
        }
      }
      sheetPayin.columns = columnsMethods;
      const currentRows = sheetPayin.getSheetValues();
      sheetPayin.spliceRows(1, currentRows.length);
      sheetPayin.spliceRows(1, 0, ...currentRows);
  
      countries[0].PAYIN.map(async country => {
        if (methodsPayin.hasOwnProperty(country)) {
          let cont = 0;
          let methods = methodsPayin[country];
          while (cont < methods.length) {
            // columnsCountries.push( { header: country, key: country, width: 15 })
            columnsCountries.push(country);
            const position = getColumnLetter(sheetPayin.columnCount);
            contColumns++;
            cont++;
          }
          cont = 0;
        }
      });
      columnsCountries.push("");
      sheetPayin.spliceRows(2, 0, columnsCountries);
      await combineCellsWithSameValueInRow(sheetPayin, 2);
     
      payin.map(commerce => {
        let dataCommerce = {};
        for (let nameCountry in commerce) {
          for (let country in commerce[nameCountry]) {
            for (let method in commerce[nameCountry][country]) {
              dataCommerce[country + method] =
                commerce[nameCountry][country][method].provider;
            }
          }
          dataExcel.push({
            active: activeCommerce[nameCountry],
            commerce: nameCountry,
            ...dataCommerce
          });
        }
      });
      dataExcel.forEach(fila => {
        sheetPayin.addRow(fila);
      });
    }
    

    /* ---------------------------------------- PAYOUT ---------------------------------------------------------*/
    dataExcel = [];
    let sheetPayout = false
    if(payout && countries){ 
    sheetPayout = book.addWorksheet("detalle_operacional_payout");
    columnsCountries = [
      { header: "ACTIVE", key: "active", width: 20 },
      { header: "COMMERCE", key: "commerce", width: 25 }
    ];
    countries[1].PAYOUT.map(async country => {
      columnsCountries.push({ header: country, key: country, width: 20 });
    });
    sheetPayout.columns = columnsCountries;
    payout.map(commerce => {
      let dataCommerce = {};
      for (let nameCountry in commerce) {
        for (let country in commerce[nameCountry]) {
          dataCommerce[country] = commerce[nameCountry][country].provider;
        }
        dataExcel.push({
          active: activeCommerce[nameCountry],
          commerce: nameCountry,
          ...dataCommerce
        });
      }
    });
    dataExcel.forEach(fila => {
      sheetPayout.addRow(fila);
    });
    }
    

    /* ---------------------------------------- WEBPAY ---------------------------------------------------------*/
    dataExcel = [];
    let sheetWebpay = false
    if(webpay){
      sheetWebpay = book.addWorksheet("detalle_operacional_webpay");
      columnsCountries = [
        { header: "ACTIVE", key: "active", width: 20 },
        { header: "COMMERCE", key: "commerce", width: 25 },
        { header: "PROVIDER", key: "provider", width: 20 }
      ];
      sheetWebpay.columns = columnsCountries;
      webpay.map(commerce => {
        let dataCommerce = {};
        for (let nameCountry in commerce) {
          for (let country in commerce[nameCountry]) {
            dataCommerce["provider"] = commerce[nameCountry].provider;
          }
          dataExcel.push({
            active: activeCommerce[nameCountry],
            commerce: nameCountry,
            ...dataCommerce
          });
        }
      });
  
      dataExcel.forEach(fila => {
        sheetWebpay.addRow(fila);
      });
  
      
    }
    let sheets = [sheetPayin, sheetPayout, sheetWebpay];
    sheets.map(sheet => {
      if(sheet) customizeCell(sheet);
    });
    
    /*
    let columnsCountry = await getColumnsTitleCountry(sheet, countries)
    console.log("[LOG] NUM COLUMS COUNTRY", columnsCountry)
    for (let columns in columnsCountry) {
        if(columnsCountry[columns][1]){
          sheet.mergeCells(`${columnsCountry[columns][0]}1:${columnsCountry[columns][1]}1`);
        } else {
          sheet.mergeCells(`${columnsCountry[columns][0]}1:${columnsCountry[columns][0]}1`);
        }
       const titleCell = sheet.getCell(`${columnsCountry[columns][0]}1`);
       titleCell.alignment = { vertical: "middle", horizontal: "center" };
       titleCell.font = { size: 14, bold: true };
       // const indexHeader = sheet.getColumn(`${columnsCountry[columns][0]}`).number
       // cambiarPosicionColumnas(sheet, [`${columnsCountry[columns][0]}`], indexHeader + 2 , indexHeader + 2);
    } 
    let titleCell = sheet.getCell(`A1`);
    titleCell.alignment = { vertical: "middle", horizontal: "center" };
    titleCell.font = { size: 14, bold: true };
    titleCell = sheet.getCell(`B1`);
    titleCell.alignment = { vertical: "middle", horizontal: "center" };
    titleCell.font = { size: 14, bold: true }; 
  
 */
    // Crear un archivo Blob desde el libro de Excel
    const blob = await book.xlsx.writeBuffer();

    // Crear un objeto Blob URL para el archivo Blob
    const blobUrl = URL.createObjectURL(new Blob([blob]));

    // Crear un enlace de descarga y hacer clic en él para iniciar la descarga
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = "ejemplo_excel.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <h1>Operational Traffic</h1>
      <div style={{ display: "flex", marginBottom: "10px" }}>
        {" "}
        <Button type="primary" className="gx-mb-0" onClick={handleExportExcel} disabled = {disabledExcel}>
          Download Excel
        </Button>
      </div>
         <OcupationalTrafficPayin data={data}></OcupationalTrafficPayin>
       <OcupationalTrafficPayout data={data}></OcupationalTrafficPayout>
      <OcupationalTrafficWebpay data={data}></OcupationalTrafficWebpay> 
    </>
  );
};

export default OcupationalTraffic;
