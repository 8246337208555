import {
  Breadcrumb,
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Spin
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Widget from "../../../components/Widget";
import api from "../../../util/api";
import MenuPayrollHeader from "../cashout/MenuPayrollHeader";
import ReactJson from "react-json-view";
import MerchantAdministration from "../../../components/Admin/Payroll/merchant-administration";
import { connect } from "react-redux";
import ModalUndoPayrolls from "../../../components/Admin/Payroll/undo-payrolls/modal";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ChartPayrollOfMonth } from "../../../components/Admin/Payroll/report/chart-month-payrolls";
import { ChartTodayPayrolls } from "../../../components/Admin/Payroll/report/chart-today-payrolls";
import queryParams from "../../../util/queryParams";

const AdminPayroll = props => {
  if (props.dataUser.typeUser != "admin")
    return "No permissions to enter this route";

  const changeHistory = query => {
    props.history.push({
      pathname: props.path,
      search: `?${query}`,
      state: { detail: "some_value" }
    });
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [stateCashoutV2, setStateCashoutV2] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [loadingSetUndo, setLoadingSetUndo] = useState(false);
  const [undoData, setUndoData] = useState({});
  const [listUndoPayrolls, setListUndoPayrolls] = useState([]);
  const [loadingListUndoPayrolls, setLoadingListUndoPayrolls] = useState(false);

  const getStateCashoutV2 = async () => {
    return await api.utils.getStateCashoutV2();
  };

  const updateStateDisabledButtonLoad = async () => {
    getStateCashoutV2().then(data => {
      setStateCashoutV2(data);
    });
  };

  const setUndoPayroll = useCallback(async (dataPayrollIds) => {
    setLoadingSetUndo(true);
    /*
    if (!executeRecaptcha) {
      return;
    } */
    console.log("dataPayrollIds", dataPayrollIds)

    
    const result = await executeRecaptcha("setUndoPayroll");
    api.utils
      .setUndoV2({ token: result, data: dataPayrollIds})
      .then(res => {
        if (res.status == "success") {
          setUndoData(res.summary);
        } else {
          throw "Error";
        }
      })
      .catch(error => {
        notification.error({
          message: "There is an error",
          description: "There was an error in the generation of the payroll"
        });
        console.log(error);
      })
      .finally(() => {
        setLoadingSetUndo(false);
      });
  }, [executeRecaptcha]);

  const switchNewCashout = useCallback(
    async isActiveNewCashout => {
      if (!executeRecaptcha) {
        return;
      }

      const result = await executeRecaptcha("switchNewCashout");
      setLoadingSwitch(true);
      await api.utils.setStatusChangesCashoutV2({
        isActiveNewCashout: !isActiveNewCashout,
        token: result
      });
      setLoadingSwitch(false);
      updateStateDisabledButtonLoad();
    },
    [executeRecaptcha]
  );

  const [merchants, setMerchants] = useState([]);
  const [loadingMerchants, setLoadingMerchants] = useState(true);

  const formatDataMerchantsAdministration = obj => {
    let data = [];
    for (let item in obj) {
      data.push({
        ...obj[item],
        commerce_id: item
      });
    }

    return data;
  };

  const getMerchantsAdministration = async () => {
    api.utils
      .getMerchantsAdministration()
      .then(res => {
        setMerchants(
          formatDataMerchantsAdministration(res).sort((r1, r2) =>
            r1.commerce_id > r2.commerce_id
              ? 1
              : r1.commerce_id < r2.commerce_id
              ? -1
              : 0
          )
        );
      })
      .finally(() => {
        setLoadingMerchants(false);
      });
  };

  const [commercesAll, setCommercesAll] = useState([]);

  const [loadingReportPayrolls, setLoadingReportPayrolls] = useState(true);
  const [dataReportPayrolls, setDataReportPayrolls] = useState({});

  const getReportPayrolls = (withLoading = true) => {
    withLoading && setLoadingReportPayrolls(true);
    api.utils
      .getReportPayrolls(queryParams())
      .then(res => {
        if (res.success == true) {
          setDataReportPayrolls(res.data.report);
          setCommercesAll(res.data.commerces);
        } else {
          throw "Error";
        }
      })
      .catch(err => {
        notification.error({
          message: "Error",
          description: "There is an error with the report payrolls"
        });
        console.log(err);
      })
      .finally(() => {
        withLoading && setLoadingReportPayrolls(false);
      });
  };

  useEffect(() => {
    updateStateDisabledButtonLoad();
    getMerchantsAdministration();

    const interval = setInterval(() => {
      getReportPayrolls(false);
      updateStateDisabledButtonLoad();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getReportPayrolls();
  }, [document.location.href]);

  const dataTransformObject = array => {
    return array.reduce((accumulator, value) => {
      return {
        ...accumulator,
        [value.commerce_id]: {
          isActiveNewCashout: value.isActiveNewCashout,
          isAllowedNewCashout: value.isAllowedNewCashout
        }
      };
    }, {});
  };

  const updateMerchants = useCallback(
    async merchants => {
      if (!executeRecaptcha) {
        return;
      }
      const result = await executeRecaptcha("updateMerchants");
      setMerchants(merchants);

      const obj = dataTransformObject(merchants);
      api.utils
        .updateParametrosGenerales({
          data: obj,
          token: result
        })
        .then(res => {
          if (res.success) {
            setMerchants(formatDataMerchantsAdministration(res.data));
            notification.success({
              message: "Merchant Administration",
              description:
                "Changes were successfully made in the administration of Merchants."
            });
          } else {
            notification.error({
              message: "Merchant Administration error",
              description: ""
            });
          }
        })
        .finally(() => {
          setLoadingMerchants(false);
        });
    },
    [executeRecaptcha]
  );

  const onHandleSubmit = async e => {
    setLoadingMerchants(true);
    let copyMerchants = JSON.parse(JSON.stringify(merchants));

    if (e.type == "edit") {
      copyMerchants = copyMerchants.map(item =>
        item.commerce_id == e.values.commerce_id ? e.values : item
      );
    } else {
      copyMerchants.push(e.values);
    }
    updateMerchants(copyMerchants);
  };

  const onDelete = commerce_id => {
    let copyMerchants = JSON.parse(JSON.stringify(merchants));
    copyMerchants = copyMerchants.filter(
      item => item.commerce_id != commerce_id
    );

    updateMerchants(copyMerchants);
  };

  const [modalUndoPayrolls, setModalUndoPayrolls] = useState({
    isVisible: false
  });

  

  const openModalUndoPayrolls = () => {
    setModalUndoPayrolls({
      isVisible: true
    });
    setLoadingListUndoPayrolls(true);
    api.utils
      .getDetailUndo()
      .then(res => {
        if (res.status == "success") {
          console.log("SE EJECUTA", res.payrolls)
          setListUndoPayrolls(res.payrolls);
        } else {
          throw "Error";
        }
      })
      .catch(err => {
        notification.error({
          message: "Error",
          description: "There is an error with the list of undo payrolls"
        });
        console.log(err);
      })
      .finally(() => {
        setLoadingListUndoPayrolls(false);
      });
  };

  const closeModalUndoPayrolls = () => {
    setModalUndoPayrolls({
      isVisible: false
    });
  };

  const onHandleUndoPayrolls = (dataPayrollIds) => {
    closeModalUndoPayrolls();
    setUndoPayroll(dataPayrollIds);
  };

  const onchangeDate = (date, field) => {
    let query = new URLSearchParams({
      ...queryParams(),
      [field]: date
    }).toString();

    changeHistory(query);
  };

  return (
    <>
      <Row gutter={[30, 10]}>
        <Col span={24}>
          <Row align="middle" type="flex" gutter={[10, 20]}>
            <Col span={12}>
              <h2>Admin Payroll</h2>
              <Breadcrumb separator="">
                <Breadcrumb.Item>
                  <Link to="/main/dashboard">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Separator />
                <Breadcrumb.Item>
                  <Link to={`/main/admin/payroll`}>Admin Payroll</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={12}>
              <div className="section-button">
                {stateCashoutV2 &&
                  (!stateCashoutV2.isActiveNewCashout ? (
                    <Popconfirm
                      title="Are you sure enable new cash out?"
                      onConfirm={() =>
                        switchNewCashout(stateCashoutV2.isActiveNewCashout)
                      }
                      okText="Yes, enable"
                      cancelText="No"
                    >
                      <Button
                        icon="check"
                        type="primary"
                        loading={loadingSwitch}
                      >
                        Enable new cash out
                      </Button>
                    </Popconfirm>
                  ) : (
                    <Popconfirm
                      title="Are you sure disable new cash out?"
                      onConfirm={() =>
                        switchNewCashout(stateCashoutV2.isActiveNewCashout)
                      }
                      okText="Yes, disable"
                      okType="danger"
                      cancelText="No"
                    >
                      <Button icon="api" type="danger" loading={loadingSwitch}>
                        Disable new cash out
                      </Button>
                    </Popconfirm>
                  ))}
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <MenuPayrollHeader />
        </Col>
        <hr />
        <Col span={24}>
          <Row>
            <Col lg={16} md={24} sm={24} xs={24} className="gx-pr-2">
              <Widget>
                <ChartPayrollOfMonth
                  barData={dataReportPayrolls.bar || []}
                  loading={loadingReportPayrolls}
                  commerces={commercesAll}
                  onchangeMonth={date => onchangeDate(date, "date_bar")}
                />
              </Widget>
            </Col>
            <Col lg={8} md={24} sm={24} xs={24}>
              <Widget>
                <ChartTodayPayrolls
                  pieData={dataReportPayrolls.pie || {}}
                  loading={loadingReportPayrolls}
                  commerces={commercesAll}
                  onchangeDate={date => onchangeDate(date, "date_pie")}
                />
              </Widget>
            </Col>
          </Row>
        </Col>
        <hr />

        <Col span={24}>
          <Row align="middle" type="flex" gutter={[10, 20]}>
            <Col span={12}>
              <h3>Undo payroll</h3>
              <p>This button will make the lists with status 1 and 9 undo</p>
            </Col>
            <Col span={12}>
              <div className="section-button">
                <Button
                  icon="pause"
                  type="default"
                  onClick={openModalUndoPayrolls}
                >
                  Undo payrolls
                </Button>
              </div>
              <ModalUndoPayrolls
                onCancel={closeModalUndoPayrolls}
                modal={modalUndoPayrolls}
                onHandleUndoPayrolls={onHandleUndoPayrolls}
                data={listUndoPayrolls}
                loading={loadingListUndoPayrolls}
                setListUndoPayrolls={setListUndoPayrolls}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Widget>
            <Spin tip="Loading..." spinning={loadingSetUndo}>
              <ReactJson
                src={undoData}
                name="Undo payrolls"
                iconStyle="circle"
                displayDataTypes={false}
                enableEdit={false}
                enableDelete={false}
                enableAdd={false}
                indentWidth={2}
              />
            </Spin>
          </Widget>
        </Col>
        <hr />
        <Col span={24}>
          <Spin tip="Loading..." spinning={loadingMerchants}>
            <MerchantAdministration
              merchants={merchants}
              commercesAll={commercesAll}
              onHandleSubmit={onHandleSubmit}
              onDelete={onDelete}
            />
          </Spin>
        </Col>
        <hr />
      </Row>
      <style jsx="true">{`
        .loading {
          width: 100%;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .section-button {
          display: flex;
          justify-content: flex-end;
        }
      `}</style>
    </>
  );
};

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { dataUser } = settings;
  return { authUser, dataUser };
};
export default connect(mapStateToProps)(AdminPayroll);
