import React, { Component } from "react";
import RetentionsComponent from "../../../components/Retentions/index";
import Widget from "../../../components/Widget";
import { Button, Row, Col } from "antd";
import { connect } from "react-redux";

class Retentions extends Component {
  
  state = {
    resp: "",
    updateTable: false,
    type: "cashin"
  };
  componentDidMount = async e => {
   /*  this.state({
      type: "cashout"
    }); */
  };


  onchangeToCashout = () => {
    this.setState({
      type: "cashout"
    });
  };
  onchangeToCashin = () => {
    this.setState({
      type: "cashin"
    });
  };

  render() {

    if (this.props.dataUser.typeUser != "admin")
      return "No permissions to enter this route";

    return (
      <>
      {/* <RetentionsComponent /> */}
      <Widget title="Retention/Early Payment">
        <iframe frameBorder="0" src={`https://stratechcorp.retool.com/embedded/public/514a19e9-68fd-4b2c-b158-3771a9f83551?country=${this.props.country.code}&merchantId=${this.props.merchant.code}&user=${this.props.authUser}`} width="100%" height="660px" />
      </Widget>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { dataUser, country, merchant } = settings;
  return { authUser, dataUser, country, merchant };
};
export default connect(mapStateToProps)(Retentions);