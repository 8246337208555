import React from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { Col, Row, Divider, Spin, Skeleton } from "antd";
import NumberFormat from "react-number-format";
import currencyNumberFormat from "../../../util/currencyNumberFormat"
import Metrics from "components/Metrics";

const GrowthCard = ({ BalanceMntCl,BalanceMntPE,BalanceMntEC, BalanceAtm, title, LoadingBalanceMnt, LoadingBalanceAtm }) => {
  return (
    <Metrics styleName={`gx-card-full`} title={`Balance External Suppliers`}>
      <Row>
      <Divider></Divider>
      
        <Col lg={13} md={13} sm={13} xs={13}>
          <div className="gx-pb-0 gx-pl-4 gx-pt-3">
            AIRTM
          </div>
        </Col>

        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="gx-pb-0 gx-pl-4  gx-pr-4 gx-pt-3 gx-text-right" >
            <Skeleton active loading={LoadingBalanceAtm} />
            {BalanceAtm && BalanceAtm.clp && (
              <div className="gx-fs-xxxl gx-font-weight-lighter  gx-chart-up">
                <div className="gx-fs-lg">
                  <i class="flag flag-24 flag-cl"></i>
                </div>
                <div className="gx-fs-lg">
                  CLP:{" "}
                  {currencyNumberFormat(BalanceAtm.clp || 0, "CL")}
                </div>
                <div className="gx-fs-lg">
                  USD:{" "}
                  {BalanceAtm.usd | '0'}
                </div>
              </div>
            )}
          </div>
        </Col>
      
        <Divider></Divider>
          <Col lg={13} md={13} sm={13} xs={13}>
            <div className="gx-pb-0 gx-pl-4 gx-pt-3">
              MONNET
            </div>
          </Col>

          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="gx-pb-0 gx-pl-4  gx-pr-4 gx-pt-3 gx-text-right" >
            <Skeleton active loading={LoadingBalanceMnt} />
              <div className="gx-fs-xxxl gx-font-weight-lighter  gx-chart-up ">
              {BalanceMntCl.length > 0 && (
                <>
                  <div>
                    <div className="gx-fs-lg">
                      <i class="flag flag-24 flag-cl"></i>
                    </div>
                    {BalanceMntCl.map(itemBank => (
                      <div style={{marginBottom: '.5em'}}>
                        <div className="gx-fs-lg" style={{color: "#545454"}}>
                          {itemBank.bank || '--'}
                        </div>
                        <div className="gx-fs-lg gx-chart-up">
                          CLP:{" "}
                          {currencyNumberFormat(itemBank.clp || 0, "CL")}
                        </div>
                      </div>
                    ))}
                  </div>
                  <hr />
                </>
              )}
                {BalanceMntPE.length > 0 && (
                  <>
                    <div>
                      <div className="gx-fs-lg">
                        <i class="flag flag-24 flag-pe"></i>
                      </div>
                      {BalanceMntPE.map(itemBank => (
                        <div style={{marginBottom: '.5em'}}>
                          <div className="gx-fs-lg" style={{color: "#545454"}}>
                            {itemBank.bank || '--'}
                          </div>
                          <div className="gx-fs-lg gx-chart-up">
                            CLP:{" "}
                            {currencyNumberFormat(itemBank.clp || 0, "CL")}
                          </div>
                          <div className="gx-fs-lg gx-chart-up">
                            PEN:{" "}
                            {currencyNumberFormat(itemBank.pen || 0, "PE")}
                          </div>
                        </div>
                      ))}
                    </div>
                    <hr />
                  </>
                )}
                {BalanceMntEC.length > 0 && (
                  <>
                    <div>
                      <div className="gx-fs-lg">
                        <i class="flag flag-24 flag-ec"></i>
                      </div>
                      {BalanceMntEC.map(itemBank => (
                        <div style={{marginBottom: '.5em'}}>
                          <div className="gx-fs-lg" style={{color: "#545454"}}>
                            {itemBank.bank || '--'}
                          </div>
                          <div className="gx-fs-lg gx-chart-up">
                            CLP:{" "}
                            {currencyNumberFormat(itemBank.clp || 0, "CL")}
                          </div>
                          <div className="gx-fs-lg gx-chart-up">
                            USD:{" "}
                            {currencyNumberFormat(itemBank.usd || 0, "EC")}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
        
      </Row>
    </Metrics>
  );
};

export default GrowthCard;
