import React, { Component } from "react";
import MultiSerachComponent from "../../../components/MultySearch/MultySearchs";
import { Button, Row, Col } from "antd";

class MultiSerach extends Component {
  state = {
    resp: "",
    updateTable: false,
    type: "cashin"
  };
  componentDidMount = async e => {
   /*  this.state({
      type: "cashout"
    }); */
  };


  onchangeToCashout = () => {
    this.setState({
      type: "cashout"
    });
  };
  onchangeToCashin = () => {
    this.setState({
      type: "cashin"
    });
  };

  render() {
    return (
      <>
      <MultiSerachComponent />
      </>
    );
  }
}

export default MultiSerach;
