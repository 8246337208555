import React from "react";
import { Row, Col, Card, Table, Input, DatePicker, Button } from "antd";
import _ from "lodash";
import NumberFormat from "react-number-format";
import moment from "moment";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { RangePicker } = DatePicker;
const pagination = { position: "bottom" }; //bottom top both none

class Dynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bordered: false,
      loading: false,
      pagination,
      size: "middle", // default middle small
      expandedRowRender: props.expandedRowRender,
      title: undefined,
      showHeader: true,
      footer: () => (
        <div>
          Suma Neto:{" "}
          <NumberFormat
            value={this.state.sumNetoDataList}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        </div>
      ),
      rowSelection: false, // {} or false
      scroll: undefined,
      searchFilter: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      dataList: nextProps.dataList,
      sumNetoDataList: _.sumBy(nextProps.dataList, item => Number(item.neto))
    });
  }

  filter = searchValue => {
    //const { filterOption } = this.state;
    if (searchValue === "") {
      this.setState({
        dataList: this.props.dataList
      });
    } else {
      const filter = _.filter(
        this.props.dataList,
        invoice =>
          invoice.number.toString().indexOf(searchValue) > -1 ||
          invoice.rut.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
          invoice.razon_social
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) > -1 ||
          invoice.invoiceId.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1
      );
      this.setState({
        dataList: filter
      });
    }
    this.setState({
      sumNetoDataList: _.sumBy(this.props.dataList, item => Number(item.neto))
    });
  };

  updateSearchFilter(evt) {
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }

  onChangeRange = (dates, dateStrings) => {
    console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    let fechaInicio = dateStrings[0].split("/");
    let fechaFin = dateStrings[1].split("/");
    let firstDay = new Date(
      fechaInicio[2],
      Number(fechaInicio[1]) - 1,
      fechaInicio[0]
    ).getTime();
    let lastDay = new Date(
      fechaFin[2],
      Number(fechaFin[1]) - 1,
      fechaFin[0],
      23,
      59,
      59
    ).getTime();
    this.props.getData(firstDay, lastDay);
  };

  render() {
    const newButton = (
      <Button
        icon="plus"
        className="gx-mr-2"
        type="primary"
        onClick={this.props.onClickNewButton}
      >
        New invoice
      </Button>
    );
    return (
      <Card title={this.props.title} extra={newButton}>
        <div className="components-table-demo-control-bar">
          <Row>
            <Col xl={15} lg={15} md={15} sm={24} xs={24} className="gx-pr-md-2">
              <RangePicker
                className="gx-mb-3 gx-w-50"
                format="DD/MM/YYYY"
                ranges={{
                  All: [moment("01-01-2019", "MM-DD-YYYY"), moment()],
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                  ],
                  "This week": [
                    moment().startOf("week"),
                    moment().endOf("week")
                  ],
                  "Last 7 Days": [moment().subtract(6, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ],
                  "Last 30 Days": [moment().subtract(29, "days"), moment()]
                }}
                onChange={this.onChangeRange}
                defaultValue={[moment("01-01-2019", "MM-DD-YYYY"), moment()]}
              />
            </Col>
            <Col xl={9} lg={9} md={9} sm={24} xs={24} className="gx-pr-md-2">
              <Input
                placeholder="Buscar..."
                onChange={this.updateSearchFilter.bind(this)}
              ></Input>
            </Col>
          </Row>
        </div>

        <Table
          className="gx-table-responsive"
          {...this.state}
          columns={this.props.columns}
          dataSource={this.state.dataList}
          rowKey="invoiceId"
        />

        <ExcelFile filename={this.props.filename}>
          <ExcelSheet data={this.state.dataList} name="Hoja 1">
            <ExcelColumn label="Name" value="name" />
            <ExcelColumn label="Rut" value="rut" />
            <ExcelColumn label="Amount" value="quantity" />
            <ExcelColumn label="Date" value="formatDateRequest" />
            <ExcelColumn label="ID 1xBet" value="commerceReqId" />
            <ExcelColumn label="ID" value="cashoutId" />
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="Bank Name" value="bankName" />
            <ExcelColumn label="Type Account" value="typeAccount" />
            <ExcelColumn label="Account Number" value="numAccount" />
          </ExcelSheet>
        </ExcelFile>
      </Card>
    );
  }
}

export default Dynamic;
