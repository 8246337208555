import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  Alert,
  Row,
  Col,
  Checkbox,
  Select,
  Badge,
  Spin,
  Collapse
} from "antd";
import Widget from "components/Widget/index";
import API from "../../../util/api";
import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebase";
import moment from "moment-timezone";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import { AllHtmlEntities } from "html-entities";
import NumberFormat from "react-number-format";
import "./stylesCallback.css";

const FormItem = Form.Item;
const db = firebase.firestore();
const entities = new AllHtmlEntities();
const { Panel } = Collapse;
class CallbackPayku extends Component {
  state = {
    resp: "",
    Resend: false,
    deco: false,
    dataTransfer: [],
    timeZone: "America/Santiago",
    dateRequest: "",
    colorStatus: "rgb(8,5,5,0.13489145658263302)",
    status: "",
    inputId: true,
    merchant: "",
    spin: false,
    spinListAdmin: false,
    Limit: 0,
    TransactionPendingAdmin: [],
    ErrorLimit: false,
    viewPinCode: false,
    codigoDropdown: "",
    typeAlert: "success"
  };

  componentDidMount = async e => {
    this.getLimit();
    this.getAdminApproval();
  };

  getLimit = async e => {
    await db
      .collection("parametrosGenerales")
      .doc("callback")
      .get()
      .then(doc => {
        this.setState({
          Limit: doc.data().limitePayku
        });
      })
      .catch(err => {
        console.log("ERROR IN blacklist", err);
      });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      resp: {
        message: "Procesando ..."
      },
      spin: true,
      typeAlert: "success"
    });

    let validator = true;

    /*  console.log("this.state.dataTransfer.quantity",this.state.dataTransfer.quantity,Number(this.state.Limit)); */
    if (Number(this.state.dataTransfer.quantity) >= Number(this.state.Limit)) {
      validator = false;
    }

    if (
      this.props.dataUser.typeUser === "admin" ||
      this.props.dataUser.typeUser === "soporteAdmin"
    ) {
      validator = true;
    }

    this.props.form.validateFields(async (err, values) => {
      let idZippy = values.idZippy;
      if (!err) {
        if (validator) {
          console.log(
            "Received values of form: ",
            values,
            "resend",
            this.state.Resend
          );
          let transaction_id;
          if (values.codigo === "0") {
            //ESTO SE EJECUTA EN CODIGO 0
            //SE AGREGA EL CAMPO  operationData
            let operationCode;

            if (this.state.Resend === false) {
              if (values.operationCode.match(/^\d+$/)) {
                operationCode = values.operationCode;
              } else {
                this.setState({
                  resp: {
                    message: "Error de Formato"
                  },
                  spin: false,
                  typeAlert: "warning"
                });
                setTimeout(() => {
                  this.setState({
                    resp: ""
                  });
                }, 5000);
                return false;
              }
            }
            let payload;
            if (values.prepaidCode) {
              if (values.cardNumber) {
                payload = {
                  id: values.idZippy.trim(),
                  code: Number(values.codigo),
                  operationCode: operationCode,
                  transaction_id: values.transaction_id,
                  user: this.props.authUser,
                  prepaidCode: values.prepaidCode,
                  cardNumber: values.cardNumber,
                  resend: this.state.Resend
                };
              } else {
                payload = {
                  id: values.idZippy.trim(),
                  code: Number(values.codigo),
                  operationCode: operationCode,
                  transaction_id: values.transaction_id,
                  user: this.props.authUser,
                  prepaidCode: values.prepaidCode,
                  resend: this.state.Resend
                };
              }
            } else {
              if (values.cardNumber) {
                payload = {
                  id: values.idZippy,
                  code: Number(values.codigo),
                  operationCode: operationCode,
                  transaction_id: values.transaction_id,
                  user: this.props.authUser,
                  cardNumber: values.cardNumber,
                  resend: this.state.Resend
                };
              } else {
                payload = {
                  id: values.idZippy.trim(),
                  code: Number(values.codigo),
                  operationCode: operationCode,
                  transaction_id: values.transaction_id,
                  user: this.props.authUser,
                  resend: this.state.Resend
                };
              }
            }
            // LLAMAR A LA API
            console.log("LLAMANDO A API CODE 0");
            await API.utils
              .callbackPayku(payload)
              .then(async resp => {
                console.log("resp callbackPayku", resp);
                if (resp.status >= 400 && resp.status <= 500) {
                  this.setState({
                    resp: {
                      message: resp.description
                    },
                    typeAlert: "error"
                  });
                }

                if (resp.status === "ok" && this.state.Resend === true) {
                  if (this.state.Resend === true) {
                    this.setState({
                      resp: {
                        message: " The status of the resend  is OK!"
                      }
                    });
                  } else {
                    this.setState({
                      resp: {
                        message: resp.message
                      }
                    });
                  }

                  this.updateQuery(idZippy);
                  await this.deleteAdminApprovalPayku(idZippy);
                  setTimeout(() => {
                    this.getAdminApproval();
                  }, 1000);
                } else {
                  console.log("CAMBIO DE ESTADO", resp.code.toString());
                  switch (resp.code.toString()) {
                    case "0":
                      console.log("CAMBIO DE ESTADO 0");
                      this.setState({
                        resp: {
                          message: resp.message
                        }
                      });
                      /*    } */
                      this.updateQuery(idZippy);
                      await this.deleteAdminApprovalPayku(idZippy);
                      setTimeout(() => {
                        this.getAdminApproval();
                      }, 1000);
                      break;
                    case "9":
                      console.log("CAMBIO DE ESTADO 9");
                      this.setState({
                        resp: {
                          message:
                            " The status of the transaction is changed to Pending!"
                        }
                      });
                      this.updateQuery(idZippy);
                      await this.deleteAdminApprovalPayku(idZippy);
                      setTimeout(() => {
                        this.getAdminApproval();
                      }, 1000);
                      break;
                    case "12":
                      this.setState({
                        resp: {
                          message:
                            " The status of the transaction is changed to Failed!"
                        }
                      });
                      this.updateQuery(idZippy);
                      await this.deleteAdminApprovalPayku(idZippy);
                      setTimeout(() => {
                        this.getAdminApproval();
                      }, 1000);
                      break;

                    default:
                      this.setState({
                        resp
                      });
                      break;
                  }
                }
              })
              .catch(error => {
                console.log(
                  `error al  llamar a la api ,detalle de error:${error}`
                );
              });
          } else {
            //ESTO SE EJECUTA EN CODIGO 12 y 9
            console.log("LLAMANDO A API CODE 12 Y 9");
            await API.utils
              .callbackPayku({
                id: values.idZippy,
                code: Number(values.codigo),
                operationCode: "",
                transaction_id: "",
                user: this.props.authUser,
                resend: this.state.Resend
              })
              .then(async resp => {
                console.log(resp);
                if (resp.status >= 400 && resp.status <= 500) {
                  this.setState({
                    resp: {
                      message: resp.description
                    },
                    typeAlert: "error"
                  });
                }
                switch (resp.code.toString()) {
                  case "0":
                    this.setState({
                      resp: {
                        message:
                          " The status of the transaction is changed to Validated!"
                      }
                    });
                    this.updateQuery(idZippy);
                    await this.deleteAdminApprovalPayku(idZippy);
                    setTimeout(() => {
                      this.getAdminApproval();
                    }, 1000);
                    break;
                  case "9":
                    this.setState({
                      resp: {
                        message:
                          " The status of the transaction is changed to Pending!"
                      }
                    });
                    this.updateQuery(idZippy);
                    await this.deleteAdminApprovalPayku(idZippy);
                    setTimeout(() => {
                      this.getAdminApproval();
                    }, 1000);
                    break;
                  case "12":
                    this.setState({
                      resp: {
                        message:
                          " The status of the transaction is changed to Failed!"
                      }
                    });
                    this.updateQuery(idZippy);
                    await this.deleteAdminApprovalPayku(idZippy);
                    setTimeout(() => {
                      this.getAdminApproval();
                    }, 1000);
                    break;

                  default:
                    this.setState({
                      resp
                    });
                    break;
                }
              })
              .catch(error => {
                console.log(
                  `error al  llamar a la api ,detalle de error:${error}`
                );
              });
          }
        } else {
          this.setState({
            spin: false
          });
          this.setState({
            resp: {
              message:
                "You cannot Validate this Transaction. Only the Admin can do it. "
            },
            ErrorLimit: true,
            typeAlert: "warning"
          });
        }
      } else {
        this.setState({
          resp: "",
          spin: false
        });
      }
      /*    }; */
    });
  };
  resetTransactionInfo = () => {
    this.setState({
      ErrorLimit: false,
      dataTransfer: [],
      colorStatus: "rgb(8,5,5,0.13489145658263302)",
      status: "",
      resp: "",
      merchant: "",
      spin: false,
      dateRequest: "",
      inputId: true
    });
  };

  resetAll = () => {
    this.props.form.setFieldsValue({
      ["idZippy"]: "",
      ["codigo"]: "",
      ["operationCode"]: "",
      ["transaction_id"]: "",
      ["prepaidCode"]: "",
      ["cardNumber"]: ""
    });

    this.setState({
      resp: "",
      deco: false,
      dataTransfer: [],
      dateRequest: "",
      colorStatus: "rgb(8,5,5,0.13489145658263302)",
      status: "",
      merchant: "",
      spin: false,
      Resend: false,
      spinListAdmin: false,
      ErrorLimit: false,
      viewPinCode: false
    });
  };

  addTransactionListAdmin = () => {
    if (this.state.TransactionPendingAdmin.length > 0) {
      let index = this.state.TransactionPendingAdmin.findIndex(
        transaction => transaction.id === this.state.dataTransfer.id
      );
      if (index !== -1) {
        this.setState({
          resp: {
            message: "This Transaction is already being reviewed by the Admin.."
          }
        });
        return false;
      } else {
        let id;
        let codigo;
        let operationCode;
        let transaction_id;
        this.props.form.validateFields(async (err, values) => {
          id = values.idZippy;
          codigo = values.codigo;
          operationCode = values.operationCode;
          transaction_id = values.transaction_id;
        });
        this.saveRecommended(
          id,
          codigo,
          this.state.Resend,
          operationCode,
          transaction_id
        );
        setTimeout(() => {
          this.setState({
            resp: "",
            ErrorLimit: false
          });
        }, 2000);
        this.resetAll();
      }
    } else {
      let id;
      let codigo;
      let operationCode;
      let transaction_id;
      this.props.form.validateFields(async (err, values) => {
        id = values.idZippy;
        codigo = values.codigo;
        operationCode = values.operationCode;
        transaction_id = values.transaction_id;
      });
      this.saveRecommended(
        id,
        codigo,
        this.state.Resend,
        operationCode,
        transaction_id
      );
      setTimeout(() => {
        this.setState({
          resp: "",
          ErrorLimit: false
        });
      }, 2000);
      this.resetAll();
    }
  };

  validarTransaction = () => {
    if (
      this.props.dataUser.typeUser === "Admin" ||
      this.props.dataUser.typeUser === "soporteAdmin"
    ) {
      let id = this.state.TransactionPendingAdmin[0].id;
      let index = this.state.TransactionPendingAdmin.findIndex(
        transaction => transaction.id === id
      );
      if (index !== -1) this.state.TransactionPendingAdmin.splice(index, 1);
      console.log(this.state.TransactionPendingAdmin);
    } else {
      console.log("Usuario sin Autorizacion");
    }
  };

  saveRecommended = async (
    id,
    codigo,
    resend,
    operationCode,
    transaction_id
  ) => {
    let _el = this;
    let data = [];
    let updateData = {};
    if (codigo === "0") {
      updateData = {
        id: id,
        adminApproval: 1,
        recommended: codigo,
        recommendedOperation: operationCode,
        resend: resend,
        recommendedTransactionId: transaction_id,
        userRecommended: this.props.authUser,
        collection: "paykus"
      };
    } else {
      updateData = {
        id: id,
        adminApproval: 1,
        recommended: codigo,
        userRecommended: this.props.authUser,
        collection: "paykus"
      };
    }

    console.log(updateData);
    API.utils
      .updateAdminApprovalPayku(updateData)
      .then(resp => {
        console.log(resp);
        console.log("Document successfully updated!");
        _el.getAdminApproval();
      })
      .catch(err => {
        console.log("ERROR IN saveRecommended", err);
      });
  };

  cancelTransacion = async id => {
    await this.deleteAdminApprovalPayku(id);
    setTimeout(() => {
      this.getAdminApproval();
    }, 1000);
  };

  deleteAdminApprovalPayku = id => {
    let updateData = {
      id: id,
      adminApproval: 0,
      recommended: "",
      recommendedOperation: "",
      collection: "paykus"
    };
    console.log(updateData);
    API.utils
      .updateAdminApprovalPayku(updateData)
      .then(resp => {
        console.log(resp);
        console.log("Document successfully updated!");
      })
      .catch(err => {
        console.log("ERROR IN deleteAdminApprovalPayku", err);
      });
  };

  getAdminApproval = async () => {
    console.log("getAdminApproval");
    let resp = "";
    this.setState({
      spinListAdmin: true
    });
    let updateData = {
      payMethod: ""
    };
    resp = await API.utils
      .getAdminApprovalPayku(updateData)
      .then(resp => {
        if (!resp.empty) {
          this.setState({
            TransactionPendingAdmin: resp,
            spinListAdmin: false
          });
        }
      })
      .catch(err => {
        console.log("ERROR IN getAdminApprovalPayku", err);
      });
    return resp;
  };
  onChange = e => {
    console.log("onChange", e.target.checked);
    this.setState({ Resend: e.target.checked });
    if (e.target.checked) {
      /*   this.props.form.resetFields(
          ["operationCode"]
        ); */
      this.props.form.setFieldsValue({
        ["codigo"]: "0"
      });
    }
  };

  onChangeID = e => {
    console.log("onChangeID", e);
    if (e === "0") {
      this.setState({ deco: true });
      console.log("onChangeID", e);
    } else {
      this.setState({ deco: false });
      console.log("onChangeID", "false");
    }
  };

  getDataTrx = async e => {
    this.setState({
      spin: true
    });
    let id = e.target.value;
    console.log("getDataTrx", e.target.value);
    this.updateQuery(id);
  };

  getDataTrxAdmin = async (
    id,
    recommendCode,
    operationCode,
    recommendedTransactionId,
    resend,
    recommendedOperation
  ) => {
    console.log(
      "id",
      id,
      "recommendCode",
      recommendCode,
      "operationCode",
      operationCode,
      "resend",
      resend,
      "recommendedOperation",
      recommendedOperation,
      "recommendedTransactionId",
      recommendedTransactionId
    );

    if (Number(recommendCode) === 0) {
      this.setState({
        deco: true,
        resp: ""
      });
      if (resend) {
        this.props.form.setFieldsValue({
          ["idZippy"]: id,
          ["codigo"]: recommendCode,
          ["operationCode"]: "",
          ["transaction_id"]: ""
        });
        this.setState({
          Resend: resend
        });
      } else {
        this.props.form.setFieldsValue({
          ["idZippy"]: id,
          ["codigo"]: recommendCode,
          ["operationCode"]: recommendedOperation,
          ["transaction_id"]: recommendedTransactionId
        });
        this.setState({
          Resend: false
        });
      }
    } else {
      this.setState({
        resp: "",
        Resend: false
      });
      this.props.form.setFieldsValue({
        ["idZippy"]: id,
        ["codigo"]: recommendCode
      });
    }

    this.setState({
      spin: true
    });
    this.updateQuery(id);
    return true;
  };

  transformCommerceID = commerceId => {
    let name = "";
    this.props.dataUser.merchants.filter(val => {
      if (val.code.toString() === commerceId.toString()) {
        name = val.name;
      }
    });
    return name;
  };

  updateQuery = async id => {
    try {
      this.setState({
        viewPinCode: false
      });
      /*  var transfer = db.collection("transfers"); */
      let data = [];
      if (id !== "") {
        let resp = await API.utils.getUpdateQueryPayku(id);
        if (!resp.empty) {
          console.log(resp);

          let dateRequest = moment(
            new Date(
              resp.dataRequest
                ? resp.dataRequest._seconds * 1000
                : resp.dateRequest._seconds * 1000
            ).toISOString()
          )
            .toDate()
            .toLocaleString("es-CL", {
              timeZone: this.state.timeZone,
              timeZoneName: "short"
            });

          this.setState({ dateRequest: dateRequest });
          console.log("CODIGO ID", resp.code.toString());
          switch (resp.code.toString()) {
            case "0":
              this.setState({
                colorStatus: "rgb(53 138 42 / 13%)",
                status: "OK",
                inputId: false
              });
              break;
            case "9":
              this.setState({
                colorStatus: "rgb(243 216 26 / 13%)",
                status: "Pending",
                inputId: false
              });

              break;
            case "12":
              this.setState({
                colorStatus: "rgb(243 26 26 / 13%)",
                status: "Failed",
                inputId: false
              });
              break;
            case "1":
              this.setState({
                colorStatus: "rgb(243 26 26 / 13%)",
                status: "Failed",
                inputId: true
              });
              break;
            case "-1":
              this.setState({
                colorStatus: "rgb(243 26 26 / 13%)",
                status: "Failed",
                inputId: true
              });
              break;
            case "-2":
              this.setState({
                colorStatus: "rgb(243 26 26 / 13%)",
                status: "Failed",
                inputId: true
              });
              break;
            case "-8":
              this.setState({
                colorStatus: "rgb(243 26 26 / 13%)",
                status: "Failed",
                inputId: true
              });
              break;
            default:
              this.setState({
                colorStatus: "rgb(8,5,5,0.13489145658263302)"
              });
              break;
          }
          let commerceId = resp.comerceId ? resp.comerceId : resp.commerceId;
          switch (commerceId.toString()) {
            case "2019CL1xbet-8k3y":
              this.setState({
                viewPinCode: false,
                merchant: "1xbet"
              });
              break;
            case "2020juegalo-5n2q":
              this.setState({
                viewPinCode: true,
                merchant: "JuegaloBet"
              });
              break;
            case "2020cestelar-3j9s":
              this.setState({
                viewPinCode: true,
                merchant: "CeStelar"
              });
              break;
            case "2020dw-6d9w":
              this.setState({
                viewPinCode: false,
                merchant: "Dotworkers"
              });
              break;
            case "2020juegalopro-7j7g":
              this.setState({
                viewPinCode: false,
                merchant: "JuegaloPro"
              });
              break;
            case "2020e-Management2u5i":
              this.setState({
                merchant: "e-Management"
              });
              break;

            case "2020Payretailers7g21":
              this.setState({
                merchant: "Payretailers"
              });
              break;

            case "2020techsolutions22gf":
              this.setState({
                merchant: "TechSolutions"
              });
              break;
            case "2021abudantia-2m5i":
              this.setState({
                merchant: "Abudantia"
              });
              break;
            case "2021juegaloCom-9n3u":
              this.setState({
                merchant: "JuegaloCom"
              });
              break;
            case "2022Lootcase9h8k":
              this.setState({
                merchant: "LootCase"
              });
              break;

            default:
              this.setState({
                viewPinCode: false,
                merchant: "JuegaloBet"
              });
              break;
          }
          this.setState({ dataTransfer: { ...resp, id }, spin: false });
          console.log(this.state);
        } else {
          this.resetTransactionInfo();
        }
      }
    } catch (err) {
      console.log("Error en getDataTrx :", err);
      this.resetTransactionInfo();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const codigoDropdown = this.state.codigoDropdown;
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 12
        },
        sm: {
          span: 8
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16
        }
      }
    };

    return (
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Widget
            title={
              <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
                <i className="icon icon-mail-open gx-mr-3" />
                Callback Manual Payku
              </h4>
            }
          >
            <hr></hr>

            {/* {this.state.dataTransfer ? ( */}
            <div
              class="ant-card gx-card-widget "
              style={{ background: this.state.colorStatus }}
            >
              <div class="ant-card-body">
                <span class="gx-widget-badge">
                  transaction info{" "}
                  {this.state.status === "OK" ? (
                    <Badge color="green" text={"Validated"} />
                  ) : this.state.status === "Pending" ? (
                    <Badge color="yellow" text={this.state.status} />
                  ) : (
                    <Badge color="red" text={this.state.status} />
                  )}
                </span>
                <br/>
                <Row justify="space-between">
                  <Col lg={8} md={10} sm={24} xs={24}>
                    <h1 class="gx-fs-xxxl gx-font-weight-semi-bold gx-mb-3 gx-mb-sm-4">
                      Amount:
                    </h1>
                  </Col>
                  <Col lg={16} md={14} sm={24} xs={24}>
                    <h1 class="gx-fs-xxxl gx-font-weight-semi-bold gx-mb-3 gx-mb-sm-4">
                      {this.state.dataTransfer ? (
                        <>
                          {this.state.dataTransfer.quantity ? (
                            <NumberFormat
                              value={this.state.dataTransfer.quantity}
                              displayType={"text"}
                              thousandSeparator={","}
                              decimalSeparator={"."}
                              prefix={"$"}
                            />
                          ) : null}
                        </> //this.state.dataTransfer.quantity
                      ) : null}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={24} xs={24}>
                    <b>Zippy Id: </b>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    {this.state.dataTransfer ? this.state.dataTransfer.id : ""}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>Name: </b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer
                      ? entities.decode(this.state.dataTransfer.name)
                      : ""}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b>RUT: </b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dataTransfer.rut
                      ? formatRut(this.state.dataTransfer.rut)
                      : ""}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col lg={6} md={6} sm={24} xs={24}>
                    <b>Email:</b>
                  </Col>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    {this.state.dataTransfer
                      ? this.state.dataTransfer.email
                      : ""}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b> Date Request:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.dateRequest ? this.state.dateRequest : ""}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col lg={6} md={8} sm={24} xs={24}>
                    <b> Merchant:</b>
                  </Col>
                  <Col lg={18} md={12} sm={24} xs={24}>
                    {this.state.merchant}
                  </Col>
                </Row>
                <br/>
                {this.state.dataTransfer.operationCode ? (
                  <Row>
                    <Col lg={6} md={8} sm={24} xs={24}>
                      <b> Operation Code:</b>
                    </Col>
                    <Col lg={18} md={12} sm={24} xs={24}>
                      <b>{this.state.dataTransfer.operationCode}</b>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <br/>
                {this.state.dataTransfer.transaction_id ? (
                  <Row>
                    <Col lg={6} md={8} sm={24} xs={24}>
                      <b> Transaction id:</b>
                    </Col>
                    <Col lg={18} md={12} sm={24} xs={24}>
                      <b>{this.state.dataTransfer.transaction_id}</b>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <hr></hr>

                <h6 className="gx-mb-3 gx-mb-m-2 gx-font-weight-light">
                  {/*   Operation Example = 08/04/2020 00:13:27 07019313 */}
                </h6>

                <h6>
                  Resend: send Validated request to the merchant ,only
                  transactions ok!
                </h6>
              </div>
            </div>

            {this.state.resp ? (
              this.state.ErrorLimit ? (
                <Row>
                  <Col lg={24} className="contenedorAlert">
                    <Alert
                      message="Result"
                      description={`${this.state.resp.message} `}
                      type="success"
                    />
                  </Col>
                </Row>
              ) : (
                <div>
                  <Alert
                    message="Result"
                    description={`${this.state.resp.message}`}
                    type={this.state.typeAlert}
                    showIcon
                  />
                  <hr></hr>
                </div>
              )
            ) : (
              <div>
                {this.state.spin ? (
                  <div>
                    Loading Operation......
                    <Spin size="large" />
                  </div>
                ) : (
                  ""
                )}
                <hr></hr>
              </div>
            )}

            <Checkbox checked={this.state.Resend} onChange={this.onChange}>
              Resend
            </Checkbox>

            <Form
              {...formItemLayout}
              className="gx-signup-form gx-form-row0 gx-mb-0"
              onSubmit={this.handleSubmit}
            >
              <FormItem label="ID Zippy:">
                <div className="gx-mb-3" style={{ padding: "0px auto" }}>
                  {getFieldDecorator("idZippy", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter the Trx ID!!"
                      }
                    ]
                  })(
                    <Input placeholder="ID Zippy" onChange={this.getDataTrx} />
                  )}
                </div>
              </FormItem>
              <FormItem
                label={this.state.ErrorLimit ? "Update To" : "Status Code: "}
              >
                <div className="gx-mb-3">
                  {getFieldDecorator("codigo", {
                    validateTrigger: ["onChange", "onBlur"],
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Please enter the response code!"
                      }
                    ]
                  })(
                    // <Input placeholder="Código" onChange={this.onChangeID}/>
                    <Select
                      style={{ padding: "0px auto" }}
                      onChange={this.onChangeID}
                      disabled={this.state.inputId}
                    >
                      <Option value="">Select state por trasnsaction</Option>
                      <Option value="0">Validate (0) </Option>
                      {this.state.status !== "OK" && (
                        <Option value="9">Pending (9)</Option>
                      )}
                      {this.state.status !== "OK" && (
                        <Option value="12">Failed (12)</Option>
                      )}
                    </Select>
                  )}
                </div>
              </FormItem>
              <FormItem label="Operation:">
                {this.state.deco && !this.state.Resend ? (
                  <div className="gx-mb-3" style={{ padding: "0px auto" }}>
                    {getFieldDecorator("operationCode", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter the Operation code!!"
                        }
                      ]
                    })(<Input placeholder="Operation code" />)}
                  </div>
                ) : (
                  <div className="gx-mb-3" style={{ padding: "0px auto" }}>
                    {getFieldDecorator(
                      "operationCode",
                      {}
                    )(
                      <Input
                        placeholder="Operation code"
                        disabled={this.state.Resend}
                      />
                    )}
                  </div>
                )}
              </FormItem>
              <FormItem label="Transaction">
                {this.state.deco && !this.state.Resend ? (
                  <div className="gx-mb-3" style={{ padding: "0px auto" }}>
                    {getFieldDecorator("transaction_id", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Transaction Id!!"
                        }
                      ]
                    })(<Input placeholder="Transaction Id" />)}
                  </div>
                ) : (
                  <div className="gx-mb-3" style={{ padding: "0px auto" }}>
                    {getFieldDecorator(
                      "transaction_id",
                      {}
                    )(
                      <Input
                        placeholder="Transaction Id"
                        disabled={this.state.Resend}
                      />
                    )}
                  </div>
                )}
              </FormItem>
              {/*<FormItem label="Transaction">
                {this.state.deco && !this.state.Resend ? (
                  <div className="gx-mb-3" style={{ width: "300px" }}>
                    {getFieldDecorator("transaction_id", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Transaction Id!!"
                        }
                      ]
                    })(<Input placeholder="Transaction Id" />)}
                  </div>
                ) : (
                    <div className="gx-mb-3" style={{ width: "300px" }}>
                      {getFieldDecorator(
                        "transaction_id",
                        {}
                      )(
                        <Input
                          placeholder="Transaction Id"
                          disabled={this.state.Resend}
                        />
                      )}
                    </div>
                  )}
                      </FormItem>*/}

              {this.state.viewPinCode ? (
                <FormItem label=" Pin Code:">
                  <div className="gx-mb-3" style={{ padding: "0px auto" }}>
                    {getFieldDecorator(
                      "prepaidCode",
                      {}
                    )(<Input placeholder="Prepaid Code" />)}
                  </div>
                </FormItem>
              ) : null}

              {this.state.ErrorLimit ? (
                <Row>
                  <Col lg={12} className="centrarBtn">
                    <Button
                      type="primary"
                      onClick={this.addTransactionListAdmin}
                    >
                      {" "}
                      + admin approval
                    </Button>
                    {/*  </Col>
                  <Col lg={12} className="centrarBtn"> */}

                    <Button
                      //blacklist.jsstyle={{ width: "50%" }}
                      block
                      type="danger"
                      onClick={this.resetAll}
                    >
                      Cancel
                    </Button>
                    <hr></hr>
                  </Col>
                </Row>
              ) : (
                <>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    Validate
                  </Button>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    onClick={this.resetAll}
                  >
                    clean
                  </Button>
                </>
              )}
            </Form>
          </Widget>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Widget
            title={
              <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
                <i className="icon icon-mail-open gx-mr-3" />
                waiting for administrator approval
              </h4>
            }
          >
            <Row>
              <Col lg={24}>
                {this.state.spinListAdmin ? (
                  <div>
                    Loading Information......
                    <Spin size="large" />
                  </div>
                ) : (
                  <>
                    {this.state.TransactionPendingAdmin.map(
                      (transaction, index) => {
                        return (
                          <Row
                            className="contenedorPendientes"
                            style={{ fontSize: 12 }}
                          >
                            <Collapse bordered={false}>
                              <Panel
                                header={
                                  <span>
                                    {/*   <div> */}
                                    <b> ID Zippy:</b>
                                    <a
                                      onClick={() =>
                                        this.getDataTrxAdmin(
                                          transaction.id,
                                          transaction.recommended,
                                          transaction.operationCode,
                                          transaction.recommendedTransactionId,
                                          transaction.resend,
                                          transaction.recommendedOperation
                                        )
                                      }
                                    >
                                      {" "}
                                      {transaction.id}
                                    </a>{" "}
                                  </span>
                                }
                                style={{ fontSize: 12, borderBottom: 0 }}
                              >
                                <Col lg={24}>
                                  <span>
                                    <b>Monto : </b>
                                    <NumberFormat
                                      value={transaction.quantity}
                                      displayType={"text"}
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      prefix={"$"}
                                    />
                                  </span>
                                </Col>

                                <Col lg={24}>
                                  <span>
                                    <b>Nombre: </b>
                                    {transaction.name}
                                  </span>
                                </Col>
                                <Col lg={24}>
                                  <span>
                                    <b>Rut:</b> {transaction.rut}
                                  </span>
                                </Col>
                                <Col lg={24}>
                                  <span>
                                    <b>Comercio:</b>
                                    {this.transformCommerceID(
                                      transaction.commerceId
                                    )}
                                  </span>
                                </Col>

                                <Col lg={24}>
                                  <span>
                                    <b> Codigo Recomendado:</b>{" "}
                                    {transaction.recommended}
                                  </span>
                                </Col>
                                {transaction.resend ? (
                                  <Col lg={24}>
                                    <span>
                                      <b> Resend:</b> ✈️
                                    </span>
                                  </Col>
                                ) : null}

                                {transaction.recommendedOperation ? (
                                  <Col lg={24}>
                                    <span>
                                      <b> Operation Recomendado:</b>{" "}
                                      {transaction.recommendedOperation}
                                    </span>
                                  </Col>
                                ) : null}

                                {transaction.recommendedTransactionId ? (
                                  <Col lg={24}>
                                    <span>
                                      <b> Transaction Id Recomendado:</b>{" "}
                                      {transaction.recommendedTransactionId}
                                    </span>
                                  </Col>
                                ) : null}
                                {transaction.userRecommended ? (
                                  <Col lg={24}>
                                    <span>
                                      <b> Support:</b>{" "}
                                      {transaction.userRecommended}
                                    </span>
                                  </Col>
                                ) : null}
                                <Col lg={24}>
                                  <span>
                                    <div style={{ paddingTop: "8px" }}>
                                      <Button
                                        type="danger"
                                        onClick={() => {
                                          this.cancelTransacion(transaction.id);
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </span>
                                </Col>
                              </Panel>
                            </Collapse>
                          </Row>
                        );
                      }
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Widget>
        </Col>
      </Row>
    );
  }
}

const CallbackForm = Form.create()(CallbackPayku);

/* export default CallbackForm; */

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;

  const { dataUser } = settings;

  return { authUser, dataUser };
};
export default connect(mapStateToProps)(CallbackForm);
