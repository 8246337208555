import React, { Component } from "react";
import {
  Form,
  Input,
  Alert,
  Row,
  Col,
  Badge,
  Spin,
  Tooltip
} from "antd";
import Widget from "components/Widget/index";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { format as formatRut } from "rut.js";
import { AllHtmlEntities } from "html-entities";
import NumberFormat from "react-number-format";
import "./stylesCallback.css";
import Api from "../../../util/api";
import { firebase } from "../../../firebase/firebase";
const FormItem = Form.Item;
const entities = new AllHtmlEntities();
const db = firebase.firestore();
class searchCashoutForId extends Component {
  state = {
    resp: "",
    dataTransfer: [],
    timeZone: "America/Santiago",
    dateRequest: "",
    colorStatus: "rgb(8,5,5,0.13489145658263302)",
    status: "",
    merchant: "",
    spin: false,
  };


  resetTransactionInfo = () => {
    this.setState({
      dataTransfer: [],
      colorStatus: "rgb(8,5,5,0.13489145658263302)",
      status: "",
      resp: "",
      merchant: "",
      spin: false,
      dateRequest: "",
    });
  };

  getDataTrx = async e => {
    this.setState({
      spin: true
    });
    let id = e.target.value;
    console.log("getDataTrx", e.target.value);
    try {
      var cashouts = db.collection("cashouts");
      let data = [];
      await cashouts
        .doc(id)
        .get()
        .then(snapshot => {
          if (!snapshot.empty) {
            console.log(snapshot.data());

            let dateRequest = moment(
              snapshot.data().dateRequest.toDate()
            ).toLocaleString("es-CL", {
              timeZone: this.state.timeZone,
              timeZoneName: "short"
            });

            let format = moment(dateRequest).format("YYYY-MM-DD HH:mm:ss");

            this.setState({ dateRequest: format });

            switch (snapshot.data().code.toString()) {
              case "0":
                this.setState({
                  colorStatus: "rgb(53 138 42 / 13%)",
                  status: "OK"
                });
                break;
              case "9":
                this.setState({
                  colorStatus: "rgb(243 216 26 / 13%)",
                  status: "Pending"
                });

                break;
              case "12":
                this.setState({
                  colorStatus: "rgb(243 26 26 / 13%)",
                  status: "Failed"
                });
                break;
              default:
                this.setState({
                  colorStatus: "rgb(8,5,5,0.13489145658263302)"
                });
                break;
            }

            switch (snapshot.data().commerceId.toString()) {
              case "2019CL1xbet-8k3y":
                this.setState({
                  merchant: "1xbet"
                });
                break;
              case "2020juegalo-5n2q":
                this.setState({
                  merchant: "JuegaloBet"
                });
                break;
              case "2020cestelar-3j9s":
                this.setState({
                  merchant: "CeStelar"
                });
                break;
              case "2020dw-6d9w":
                this.setState({
                  merchant: "Dotworkers"
                });
                break;
              case "2020juegalopro-7j7g":
                this.setState({
                  merchant: "JuegaloPro"
                });
                break;



              case "2020e-Management2u5i":
                this.setState({
                  merchant: "e-Management"
                });
                break;


              case "2020Payretailers7g21":
                this.setState({
                  merchant: "Payretailers"
                });
                break;



              default:
                this.setState({
                  merchant: "JuegaloBet"
                });
                break;

            }

            data.push({
              id,
              ...snapshot.data()
            });
            this.setState({ dataTransfer: data[0], spin: false });
          } else {
            this.resetTransactionInfo();
          }
        })
        .catch(err => {
          console.log("ERROR IN updateQuery", err);
          this.resetTransactionInfo();
        });
    } catch (err) {
      console.log("Error en updateQuery :", err);
      this.resetTransactionInfo();
    }
    setTimeout(
      function () {
        this.setState({ resp: "" });
      }.bind(this),
      5000
    );
  };

  render() {

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 4
        },
        sm: {
          span: 4
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 20
        }
      }
    };
    return (
      <Widget
        title={
          <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
            <i className="icon icon-search gx-mr-3" />
              Search Cashout For  Zippy Id:
              </h4>
        }
      >
        <div
          className="ant-card gx-card-widget "
          style={{ background: this.state.colorStatus }}
        >
          <div className="ant-card-body">
            <span className="gx-widget-badge">
              transaction info{" "}
              {this.state.status === "OK" ? (
                <Badge color="green" text={"Validated"} />
              ) : this.state.status === "Pending" ? (
                <Badge color="yellow" text={this.state.status} />
              ) : (
                    <Badge color="red" text={this.state.status} />
                  )}
            </span>
            <br></br>
            <Row>
              <Col lg={12} md={12} sm={24} xs={24}>
                <b>Amount: </b>
                {this.state.dataTransfer ? (
                  <>
                    {this.state.dataTransfer.quantity ? (
                      <NumberFormat
                        value={this.state.dataTransfer.quantity}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"$"}
                      />
                    ) : null}
                  </>
                ) : (
                    null
                  )}
                {/*  </h1> */}
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <p>
                  {" "}
                  <b>Zippy Id: </b>
                  {this.state.dataTransfer ? this.state.dataTransfer.id : ""}
                </p>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <p>
                  {" "}
                  <b>Name: </b>
                  {this.state.dataTransfer
                    ? entities.decode(this.state.dataTransfer.name)
                    : ""}
                </p>
              </Col>

              <Col lg={12} md={12} sm={24} xs={24}>
                <p>
                  {" "}
                  <b>Rut: </b>
                  {this.state.dataTransfer
                    ? formatRut(this.state.dataTransfer.rut)
                    : ""}
                </p>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <p>
                  {" "}
                  <b>Email:</b>
                  {this.state.dataTransfer ? this.state.dataTransfer.email : ""}
                </p>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <p>
                  <b> Date Request:</b>
                  {this.state.dateRequest ? this.state.dateRequest : ""}
                </p>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <p>
                  <b> Merchant:</b> {this.state.merchant}
                </p>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <p>
                  <b> Payment type:</b>
                  {this.state.dataTransfer ? this.state.dataTransfer.type : ""}
                </p>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                {this.state.dataTransfer.operationCode ? (
                  <p>
                    <b> Operation Code:</b>
                    <b>{this.state.dataTransfer.operationCode}</b>
                  </p>
                ) : (
                    ""
                  )}
              </Col>
            </Row>
          </div>

        </div>
        <Form
          {...formItemLayout}
          onSubmit={this.getDataTrx}
        >
          <Row >
            <Col lg={12} md={12} sm={24} xs={24} >
              <FormItem label="ID :"  >
                <Input placeholder="Insert Zippy ID" onChange={this.getDataTrx} />
              </FormItem>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              {this.state.spin ? (
                <div>
                  <Spin size="large" />
                </div>
              ) : (
                  ""
                )}
              {
                this.state.resp.message ? (
                  <Tooltip
                    title={this.state.resp.message}
                  >
                    {" "}
                    <Alert
                      showIcon
                      // message="Result"
                      description={`${this.state.resp.message}`}
                      type={this.state.resp.type}
                    />
                  </Tooltip>

                ) : (
                    null
                  )
              }
            </Col>
          </Row>
        </Form>
      </Widget>

    );
  }
}

const searchCashoutForm = Form.create()(searchCashoutForId);
const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;

  const { dataUser } = settings;

  return { authUser, dataUser };
};
export default connect(mapStateToProps)(searchCashoutForm);
