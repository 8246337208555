


  const currencyNumberFormat = (valor, countryCode) => {
    if (countryCode) {
    /*   console.log("countryCode", countryCode); */
    // alert(countryCode)
      let currencyValue;
      switch (countryCode) {
        case "PE":
          currencyValue = "PEN"
          break;
        case "CL":
          currencyValue = "CLP"
          break
        case "BR":
          currencyValue = "BRL"
          break
        case "CR":
          currencyValue = "CRC"
          break
        case "EC":
          currencyValue = "USD"
          break
        case "MX":
          currencyValue = "MXN"
          break
        case "CO":
          currencyValue = "COP"
          break
        case "AR":
          currencyValue = "ARS"
          break
        case "GT":
          currencyValue = "GTQ"
          break
      }

      const formatter = new Intl.NumberFormat
        (`es-${countryCode}`, {
          style: 'currency',
          currency: currencyValue,
          // minimumFractionDigits: 0
        })
    /*   console.log("formatter", formatter.format(valor)); */

      return formatter.format(valor);
    }
  }

  export default  currencyNumberFormat;