import React, { Component } from "react";
import User from "../../../../components/User/Users";
import {Col,Row } from "antd";
import { connect } from "react-redux";

class users extends Component {
  state = {
    resp: "",
    updateTable: false,
    type: "create"
  };
  componentDidMount = async e => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  render() {
    return (
      <>
      <Row className="container p-0">
        <Col lg={24} md={24} sm={24} xs={24}>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div style={{ marginTop: "10px" }}>
            <User type={this.state.type}   userCreate={this.props.dataUser.idUser } />
          </div>
        </Col>
      </Row>
   </>
   );
  }
}
/*
export default users; */



const mapStateToProps = ({ settings }) => {
  const { merchant, dataUser } = settings;
  return { merchant, dataUser };
};
export default connect(mapStateToProps)(users);
