import React, { Component } from "react";
import BlacklistCashout from "../../../components/Changelog/index";
import { Button, Row, Col } from "antd";

class Changelog extends Component {
  state = {
    resp: "",
    updateTable: false,
    type: "cashin"
  };
  componentDidMount = async e => {
   /*  this.state({
      type: "cashout"
    }); */
  };

/* 
  onchangeToCashout = () => {
    this.setState({
      type: "cashout"
    });
  };
  onchangeToCashin = () => {
    this.setState({
      type: "cashin"
    });
  }; */

  render() {
    return (
      <BlacklistCashout />
    );
  }
}

export default Changelog;
