


import React from 'react';
import ReactDOM from 'react-dom';
/* import 'antd/dist/antd.css';
import './index.css'; */
import { Select } from 'antd';


class SelectM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      data:[]
    };
  }
  componentDidMount = async e => {
    const children = [];
   /*  for (let i = 10; i < 36; i++) {
      children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    } */
  };

  componentWillReceiveProps(nextProps) {
    this.setState({

      data:nextProps.data
    });
  }
  render() {
    const { Option } = Select

      const children = [];
   /*   for (let i = 10; i < 36; i++) {
       children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
     } */
     this.state.data.map(merchant=>{
      children.push(<Option key={merchant.code.toString() }>{merchant.name.toString()}</Option>);
     /*  childrenEdit.push(merchant.name.toString()); */
    })

      function handleChange(value) {
       console.log(`selected ${value}`);
     }

    return (
      <>
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder="Please select"
        defaultValue={['a10', 'c12']}
        onChange={handleChange}
      >
        {children}
      </Select>
      <br />
      <Select
        mode="multiple"
        disabled
        style={{ width: '100%' }}
        placeholder="Please select"
        defaultValue={['a10', 'c12']}
        onChange={handleChange}
      >
        {children}
      </Select>
    </>
   );
  }
}

export default SelectM;
