import { Button, Form, Input, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function ModalGenratePayroll({
  isVisible,
  onCancel,
  data,
  onHandleSubmit
}) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const shemaValidationDefault = Yup.object().shape({
    payrollMaxAmount: Yup.number(),
    payrollMinAmount: Yup.number(),
    payrollTxnsRange: Yup.number()
  });

  const [shemaValidation, setshemaValidation] = useState(
    shemaValidationDefault
  );

  const sendData = useCallback(
    async values => {
      const result = await executeRecaptcha("generatePayroll");
      onHandleSubmit({
        maxAmount: parseInt(values.payrollMaxAmount),
        minAmount: parseInt(values.payrollMinAmount),
        txnsRange: parseInt(values.payrollTxnsRange),
        token: result
      });
    },
    [executeRecaptcha]
  );

  const formik = useFormik({
    initialValues: {
      payrollMaxAmount: 0,
      payrollMinAmount: 0,
      payrollTxnsRange: 0
    },
    validationSchema: shemaValidation,
    onSubmit: values => {
      sendData(values);
      onCancel();
    }
  });

  useEffect(() => {
    formik.setValues(data);
    setshemaValidation(
      Yup.object().shape({
        payrollMaxAmount: Yup.number().max(
          data.payrollMaxAmount,
          `Must be less than ${data.payrollMaxAmount}`
        ),
        payrollMinAmount: Yup.number().max(
          data.payrollMinAmount,
          `Must be less than ${data.payrollMinAmount}`
        ),
        payrollTxnsRange: Yup.number().max(
          data.payrollTxnsRange,
          `Must be less than ${data.payrollTxnsRange}`
        )
      })
    );
  }, [data]);

  return (
    <>
      <Modal
        title="Generate payroll"
        visible={isVisible}
        onCancel={onCancel}
        footer={[
          <Button htmlType="submit" onClick={onCancel} key="cancel-button">
            Cancel
          </Button>,
          <Button
            type="primary"
            htmlType="submit"
            onClick={formik.handleSubmit}
            disabled={!formik.isValid}
            key="submit-button"
          >
            Generate payroll
          </Button>
        ]}
      >
        <Form
          // labelCol={{ span: 24 }}
          // wrapperCol={{ span: 24 }}
          layout="vertical"
          onSubmit={formik.handleSubmit}
        >
          <Form.Item
            label="Maximum payroll amount"
            hasFeedback
            validateStatus={
              formik.errors.payrollMaxAmount && formik.errors.payrollMaxAmount
                ? "error"
                : ""
            }
            help={
              formik.errors.payrollMaxAmount && formik.errors.payrollMaxAmount
            }
          >
            <Input
              onChange={e =>
                formik.setFieldValue("payrollMaxAmount", e.target.value, true)
              }
              value={formik.values.payrollMaxAmount}
              type="number"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            label="Minimum payroll amount"
            hasFeedback
            validateStatus={
              formik.errors.payrollMinAmount && formik.errors.payrollMinAmount
                ? "error"
                : ""
            }
            help={
              formik.errors.payrollMinAmount && formik.errors.payrollMinAmount
            }
          >
            <Input
              onChange={e =>
                formik.setFieldValue("payrollMinAmount", e.target.value, true)
              }
              value={formik.values.payrollMinAmount}
              type="number"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            label="Payroll transaction range"
            hasFeedback
            validateStatus={
              formik.errors.payrollTxnsRange && formik.errors.payrollTxnsRange
                ? "error"
                : ""
            }
            help={
              formik.errors.payrollTxnsRange && formik.errors.payrollTxnsRange
            }
          >
            <Input
              onChange={e =>
                formik.setFieldValue("payrollTxnsRange", e.target.value, true)
              }
              value={formik.values.payrollTxnsRange}
              type="number"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
