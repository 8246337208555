// fixed para corrección de errores.
// added para funcionalidades nuevas.
// security en caso de vulnerabilidades.
// changed para los cambios en las funcionalidades existentes.
// removed para las características en desuso que se eliminaron en esta versión.
// deprecated para indicar que una característica o funcionalidad está obsoleta y que se eliminará en las próximas versiones.

export const changelogData = [
  {
    title: "V-0.2.0",
    date: "06 de diciembre de 2022",
    details: [
      {
        type: "added",
        description: "Se añade bóton para la creación de una nómina dentro del cashout.",
        issueUrl: "",
      },
      {
        type: "added",
        description: "Se añade la pantalla de Admin/Payroll. Esta pantalla permite un reporte global de las nóminas, activar o desactivar el módulo payroll, deshacer nóminas, y actvar o desactivar el módulo por comercio.",
        issueUrl: "",
      },
      {
        type: "added",
        description: "Se añade la pantalla de Payroll. Esta pantalla permite mostrar en que estado se encuentra cada payroll (nómina): validated, pending, authorizated, created, disabled",
        issueUrl: "",
      }
    ],
  },
  {
    title: "V-0.1.3",
    date: "30 de marzo de 2022",
    details: [
      {
        type: "added",
        description: "Se añade la pantalla de Effectiveness. Esta pantalla permite la comparación de efectividad entre dos comercios, con filtros como: fecha, agregador, tipo de agregador y país. También dispone de un reporte por comercio.",
        issueUrl: "",
      }
    ],
  },
  {
    title: "V-0.1.2",
    date: "08 de octubre de 2021",
    details: [
      {
        type: "added",
        description: "Se añade la pantalla de Retention. Esta pantalla permite la edición de los valores retention y early payment , por mernchant mas país. ",
        issueUrl: "",
      },
      {
        type: "fixed",
        description:
          "Cashout:Se resuelve bug que generaba de duplicados .",
        issueUrl: "",
      },
      {
        type: "fixed",
        description:
          "Cashout:Se ajustan valores de TechSolutions al formato  de banco de chile para efectuar retiros.",
        issueUrl: "",
      },
    ],
  },
  {
    title: "V-0.1.1",
    date: "14 de septiembre de 2021",
    details: [
      {
        type: "added",
        description: "Se añade la pantalla de Multisearch. Esta pantalla permite hacer búsquedas de trx de todos nuestros productos  aplicando varios filtros de manera independiente o convinadas",
        issueUrl: "",
      },
      {
        type: "added",
        description:
          "Se añade a la funcionalidad  Multisearch  la búsqueda  y visualización de los intentos de pago(visitor).",
        issueUrl: "",
      },
      {
        type: "added",
        description: " Se añade timeZone de PERU a las pantallas de  payIn y payOut.",
        issueUrl: "",
      },
      {
        type: "changed",
        description:
          "Mejora de cashout , se integra api que trae parametros de los bancos , ruts y códigos .",
        issueUrl: "",
      },
      {
        type: "changed",
        description:
          "Mejora de Performance de SumaryReport , se integra bucket para almacenar y descargar los archivos (actualmente funcionando  con productos internos) .",
        issueUrl: "",
      },
      {
        type: "added",
        description:
          "Se añade el merchant TechSolutions. con servicios de cashin , cashout ,webpay",
        issueUrl: "",
      },
      {
        type: "changed",
        description:
          "Se cambia mapeo de datos en PayIn para que muestre  datos bancarios de la Trx",
        issueUrl: "",
      },
    ],
  },
  {
    title: "V-0.1.0",
    date: "12 de agosto de 2021",
    details: [
      {
        type: "added",
        description:
          "main/admin/changelog: Se añade la funcionalidad de Changalog en el menú admin/suport  para informar actualizaciones del sistema .",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-174",
      },
      {
        type: "added",
        description:
          "main/admin/callback: Se añade campo (Transaction id') al callback manual de payku  .",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-174",
      },
      {
        type: "fixed",
        description:
          "main/admin/callback: Se soluciona el bug de duplicados de code bank en callback Manual Cashin  .",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-174",
      },
      {
        type: "added",
        description:
          "main/dashboard: Se añade solución currency en el home , con el fin de mostrar los valores  dependiendo del currency del pais seleccionado  .",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-174",
      },
      {
        type: "added",
        description:
          "main/cashout/pending: Se añade agrupación  por montos mayores a 1.000.000  y montos menores de 1.000.000   en Cashout.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-174",
      },
      

     
    ],
   },

 /* {
    title: "V-0.4.0",
    date: "03 de agosto de 2021",
    details: [
      {
        type: "added",
        description:
          "main/customer/edit/id: Se añade un tab llamado Balances, que contiene la información obtenida desde el servicio Wallet",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-129",
      },
      {
        type: "added",
        description:
          "main/customer/edit/id: En el tab bets se añade funcionalidad para actualizar la zona horaria de las fechas.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-111",
      },
      {
        type: "added",
        description:
          "main/customer/edit/id: En el tab bets se añade opción en la tabla para colapsar datos (bet id y win id).",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-111",
      },
      {
        type: "added",
        description:
          "main/customer/edit/id: En el tab bets se muestran los nuevos campos añadidos al servicio.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-111",
      },
      {
        type: "fixed",
        description:
          "main/customer/edit/id: El saldo total es obtenido desde el servicio wallet",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-129",
      },
      {
        type: "fixed",
        description:
          "main/customer/edit/id: En el tab bets se formatea la paginación.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-129",
      },
      {
        type: "added",
        description:
          "main/games: Se añade la paginación utilizando el servicio.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-81",
      },
      {
        type: "added",
        description:
          "main/games: Se añade funcionalidad para actualizar estado active de un game, directo en la tabla.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-81",
      },
      {
        type: "changed",
        description:
          "main/games: Se cambia la forma de mostrar la black list a un menu colapsable en la tabla.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-81",
      },
      {
        type: "changed",
        description:
          "main/games/edit/id: Se cambia la forma de mostrar la black list con todas las banderas y descripción.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-81",
      },
    ],
  },
  {
    title: "V-0.3.0",
    date: "29 de julio de 2021",
    details: [
      {
        type: "added",
        description:
          "main/customer: Se añade funcionalidad para actualizar la zona horaria de las fechas.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-79",
      },
      {
        type: "added",
        description:
          "main/customer: Se añade opción para eliminar filtro actual y volver a mostrar toda la data.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-79",
      },
    ],
  },
  {
    title: "V-0.2.0",
    date: "28 de julio de 2021",
    details: [
      {
        type: "added",
        description:
          "main/customer: Se añade funcionalidad para actualizar estado active y verified de un customer, directo en la tabla.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-79",
      },
      {
        type: "changed",
        description:
          "main/customer: Se unifica el phone code con phone number en una misma columna",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-79",
      },
      {
        type: "changed",
        description:
          "main/customer: Se añade la bandera correspondiente al country, en lugar de la abreviación a secas.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-79",
      },
      {
        type: "changed",
        description: "main/customer: Se movió la paginación a la derecha.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-79",
      },
      {
        type: "changed",
        description: "main/customer: Se añadió campo verified.",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-79",
      },
    ],
  },
  {
    title: "V-0.1.0",
    date: "28 de julio de 2021",
    details: [
      {
        type: "added",
        description:
          "Se añade una nueva ruta que contiene el Changelog de la aplicación, en main/changelog",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-80",
      },
    ],
  },
  {
    title: "V-0.0.1",
    date: "27 de julio de 2021",
    details: [
      {
        type: "fixed",
        description:
          "Se actualizaron los headers corporativos enviados a los servicios",
        issueUrl: "https://stratechcorp.atlassian.net/browse/KDP-41",
      },
    ],
  }, */
];
