import React from "react";
import { Dropdown, Menu } from "antd";

import AddInvoice from "../../AddInvoice/index";

const options = ["View Invoice"];

class InvoiceCell extends React.Component {
  onInvoiceClose = () => {
    this.setState({ addInvoiceState: false });
  };
  onDeleteInvoice = () => {
    this.setState({ addInvoiceState: false });
    this.props.onDeleteInvoice(this.props.id);
  };
  onEditInvoice = () => {
    this.setState({ addInvoiceState: true });
  };
  menus = () => (
    <Menu
      onClick={e => {
        // if (e.key === "Edit") {
        //   this.onEditInvoice();
        // } else {
        //   this.onDeleteInvoice(this.props.id);
        // }
        if (e.key === "View Invoice") {
          this.onEditInvoice();
        }
      }}
    >
      {options.map(option => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  constructor() {
    super();
    this.state = {
      addInvoiceState: false
    };
  }

  render() {
    const { invoice, id, onSaveInvoice } = this.props;
    const { addInvoiceState } = this.state;
    const { name, email, phone, designation } = invoice;

    return (
      <div className="gx-contact-item">
        <div className="gx-module-list-icon">
          {/* <div
            className="gx-d-none gx-d-sm-flex"
            onClick={() => {
              addFavourite(id, invoice);
            }}
          >
            {starred ? (
              <i className="gx-icon-btn icon icon-star" />
            ) : (
              <i className="gx-icon-btn icon icon-star-o" />
            )}
          </div> */}
          <div className="gx-ml-2 gx-d-none gx-d-sm-flex">
            {/* {thumb === null || thumb === "" ? (
              <Avatar size="large">{name.charAt(0).toUpperCase()}</Avatar>
            ) : (
              <Avatar size="large" alt={name} src={thumb} />
            )} */}
          </div>
        </div>

        <div className="gx-module-list-info gx-contact-list-info">
          <div className="gx-module-contact-content">
            <p className="gx-mb-1">
              <span className="gx-text-truncate gx-contact-name">
                {invoice.id}
              </span>
              <span className="gx-toolbar-separator">&nbsp;</span>
              <span className="gx-text-truncate gx-contact-name"> {name} </span>
              <span className="gx-toolbar-separator">&nbsp;</span>
              <span className="gx-text-truncate gx-job-title">
                {designation}
              </span>
            </p>

            <div className="gx-text-muted">
              <span className="gx-email gx-d-inline-block gx-mr-2">
                {email},
              </span>
              <span className="gx-phone gx-d-inline-block">{phone}</span>
            </div>
          </div>

          <div className="gx-module-contact-right">
            <Dropdown
              overlay={this.menus()}
              placement="bottomRight"
              trigger={["click"]}
            >
              <i className="gx-icon-btn icon icon-ellipse-v" />
            </Dropdown>

            {addInvoiceState && (
              <AddInvoice
                open={addInvoiceState}
                invoice={invoice}
                onSaveInvoice={onSaveInvoice}
                invoiceId={id}
                onInvoiceClose={this.onInvoiceClose}
                onDeleteInvoice={this.onDeleteInvoice}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceCell;
