import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import ModalMerchantAdministration from "./modal";
import TableMerchantAdministration from "./table";

export default function MerchantAdministration({
  merchants,
  commercesAll,
  onHandleSubmit,
  onDelete
}) {
  const modalDefault = {
    data: {
      commerce_id: "",
      isActiveNewCashout: false,
      isAllowedNewCashout: false
    },
    isVisible: false,
    type: null
  };
  const [modal, setModal] = useState(modalDefault);

  const onCancelModal = () => {
    setModal(modalDefault);
  };

  const onOpenModalEdit = merchant => {
    setModal({
      data: merchant,
      isVisible: true,
      type: "edit"
    });
  };

  const openModalAdd = () => {
    setModal({ ...modalDefault, isVisible: true });
  };

  return (
    <>
      <Row align="middle" type="flex" gutter={[10, 20]}>
        <Col span={12}>
          <h3>Commerce Administration</h3>
          {/* <p>This button will make the lists with status 1 and 9 undo</p> */}
        </Col>
        <Col span={12}>
          <div className="section-button">
            <Button icon="plus" type="default" onClick={openModalAdd}>
              Add commerce
            </Button>
          </div>
        </Col>
        <Col span={24}>
          <TableMerchantAdministration
            merchants={merchants}
            onOpenModalEdit={onOpenModalEdit}
            onDelete={onDelete}
          />
        </Col>
      </Row>

      <ModalMerchantAdministration
        modal={modal}
        onCancel={onCancelModal}
        commercesAll={commercesAll}
        merchants={merchants}
        onHandleSubmit={onHandleSubmit}
      />
    </>
  );
}
