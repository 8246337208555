import React, { Component } from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import Dynamic from "./Dynamic";
import moment from "moment-timezone";
import API from "../../../util/api";

export class transferFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: "Bank Transfer Failed",
      filename: `Bank Transfer Failed ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      defaultValueRangeFilter: [
        moment().tz("America/Santiago").startOf("month"),
        moment().tz("America/Santiago").endOf("month")
      ]
    };
  }
  componentWillReceiveProps(nextProps) {
   /*  this.componentDidMount(); */
  }
  componentDidMount = () => {
    moment.tz.setDefault(this.state.timeZone);

    let firstDay = moment
      .tz(this.state.timeZone)
      .startOf("month")
      .toDate();
    let lastDay = moment
      .tz(this.state.timeZone)
      .endOf("month")
      .toDate();
    this.updateQuery(firstDay, lastDay);
  };
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      moment.tz.setDefault(this.state.timeZone);

      let firstDay = moment
        .tz(this.state.timeZone)
        .startOf("month")
        .toDate();
      let lastDay = moment
        .tz(this.state.timeZone)
        .endOf("month")
        .toDate();

      this.updateQuery(firstDay, lastDay);
      this.setState({
        filename: `Bank Transfer validated ${localStorage.getItem("merchant_name")}`
      });
    }
  }
  updateQuery = async (firstDay, lastDay) => {
    let parametros = {
      "merchant": this.props.merchant.code,
      "firstDay": firstDay,
      "lastDay": lastDay,
      "country": this.props.country.code,
      "code": 12,
      "payMethod":"bankskins",
    }
    await API.utils.getSkinsBackForParamsFailed(parametros).then(cons => {
      console.log(cons)
      this.setState({
        dataList: cons
      })
    });
  };

  onTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  update = (e) => {
    this.componentDidUpdate(this.state);
  }

  render() {
    this.expandedRowRender = record => (
      <div>
        <p>
          Email: <b>{record.email}</b>
        </p>
        {this.props.dataUser.typeUser === "admin" ? (

          <p>
            Admin Callback:<b>{record.adminCallBack}</b>
          </p>
        ) : (
            ""
          )}
      </div>
    );

    return (
      <Row>
        <Col span={24}>
          <Dynamic
            defaultValue={this.state.defaultValueRangeFilter}
            updateQuery={this.updateQuery}
            update={this.update}
            filename={this.state.filename}
            title={this.state.title}
            dataList={this.state.dataList}
            columns={this.columns}
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            onTableChange={this.onTableChange}
            merchant={this.props.merchant}
            typeUser={this.props.dataUser.typeUser}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { merchant, dataUser, country } = settings;
  return { merchant, dataUser, country };
};
export default connect(mapStateToProps)(transferFailed);
