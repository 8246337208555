import { Card, Col, Row, Tag, Timeline, Typography } from "antd";
import { changelogData } from "./changelogData";
/* import Link from "next/link"; */

import React from "react";
import { unreleasedData } from "./unreleasedData";

// Fixed para corrección de errores.
// Added para funcionalidades nuevas.
// Security en caso de vulnerabilidades.
// Changed para los cambios en las funcionalidades existentes.
// Removed para las características en desuso que se eliminaron en esta versión.
// Deprecated para indicar que una característica o funcionalidad está obsoleta y que se eliminará en las próximas versiones.

export default function ChangelogComponent() {
  const iconsTypes = [
    { name: "added", color: "green" },
    { name: "changed", color: "orange" },
    { name: "deprecated", color: "black" },
    { name: "removed", color: "purple" },
    { name: "fixed", color: "red" },
    { name: "security", color: "cyan" },
  ];

  const { Title } = Typography;

  return (
    <Row>
      <Col lg={16} md={16} sm={24} xs={24}>
        <Card
          className="gx-card"
          title="Changelog"
          headStyle={{ textAlign: "center" }}
        >
          <Timeline>
            {changelogData.map((data) => (
              <Timeline.Item>
                <Title level={5}>{data.title}</Title>
                <p className="gx-fs-sm">{data.date}</p>
                {data.details.map((detail) => (
                  <>
                  <ul>
                    <li className="gx-text-grey">
                      <Tag
                        color={
                          iconsTypes.find((type) => type.name === detail.type)
                            .color
                        }
                      >
                        {detail.type}
                      </Tag>
                      {detail.description}
                  {/*     <Link href={detail.issueUrl}>
                        <a> (go to issue)</a>
                      </Link> */}
                    </li>
                    <br/>
                  </ul>
                
                  </>
                ))}
              </Timeline.Item>
            ))}
          </Timeline>
        </Card>
      </Col>
      <Col lg={8} md={8} sm={24} xs={24}>
        <Card
          className="gx-card"
          title="Unreleased"
          headStyle={{ textAlign: "center" }}
        >
        {/*   <ul>
            {unreleasedData.map((data) => (
              <li className="gx-text-grey">{data.description}</li>
            ))}
          </ul>
 */}

          <Timeline>
            {unreleasedData.map((data) => (
              <Timeline.Item>
            {/*  {   <Title level={5}>{data.title}</Title>} */}
                <p className="gx-fs-sm">{data.title}</p>
                {data.details.map((detail) => (
                  <>
                  <ul>
                    <li className="gx-text-grey">
                  
                      {detail.description}
                  {/*     <Link href={detail.issueUrl}>
                        <a> (go to issue)</a>
                      </Link> */}
                    </li>
                    <br/>
                  </ul>
                
                  </>
                ))}
              </Timeline.Item>
            ))}
          </Timeline>


        </Card>
      </Col>
    </Row>
  );
}
