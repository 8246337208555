import React from "react";
import { Button } from "antd";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadReportEffectiveness = ({ data }) => {
  return (
    <ExcelFile
      element={
        <Button type="primary" size="large" icon="download">
          Download report
        </Button>
      }
    >
      <ExcelSheet data={data} name="Report Effectiveness">
        <ExcelColumn label="aggregator" value="aggregator" />
        <ExcelColumn label="successes" value="successes" />
        <ExcelColumn label="pendings" value="pendings" />
        <ExcelColumn label="errors" value="errors" />
        <ExcelColumn label="efficiency" value="efficiency" numFmt="0.0%" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default DownloadReportEffectiveness;
