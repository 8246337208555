import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Button, Dropdown, Menu, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { Link } from "react-router-dom";

class UserInfo extends Component {
  render() {
    // alert(JSON.stringify(this.props.dataUser.typeUser != admin))
    console.log('this.props', this.props);
    return (
      <>
        <Dropdown 
          overlay={
            <Menu>
              {this.props.dataUser.typeUser != 'merchant' && (
                <Menu.Item>
                  <Link to="/main/admin/changelog">
                    <Button style={{margin: 0}} type="link" icon="unordered-list">Changelog</Button>
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item>
                <Button style={{margin: 0}} type="link" icon="logout" onClick={() => this.props.userSignOut()}>Logout</Button>
              </Menu.Item>
            </Menu>
          } 
          placement="bottomRight"
        >
          <Avatar
            src={require("assets/images/engranaje.png")}
            className="gx-avatar "
            style={{    border:" solid 1px rgb(0 0 0 / 0%)",
              height:" 80%",
              cursor: "pointer"}}
            alt=""
            width="150px"
          />
        </Dropdown>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { dataUser } = settings;
  return { authUser, dataUser };
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);
