import React, { useState, useEffect } from "react";
import { Alert, Badge, Breadcrumb, Table, Col } from "antd";
import { Link, NavLink } from "react-router-dom";
import Column from "antd/lib/table/Column";
import moment from "moment";
import NumberFormat from "react-number-format";
import TimeZoneSelect from "./TimeZoneSelect";
import SearchPayroll from "./Search";
import RangeDatePayroll from "./RangeDatePayroll";
import queryParams from "../../../../util/queryParams";
import MenuPayrollHeader from "../MenuPayrollHeader";
import api from "../../../../util/api";
import { connect } from "react-redux";
import { isActiveOldCashout } from "../../../../util/check-availability-new-cashout";
import { firebase } from "../../../../firebase/firebase";
import "./stylesPayrollList.css";


const db = firebase.firestore();

const limitRows = 10;

const PayrollList = props => {
  const setDates = (dateStrings = []) => {
    dateStrings = dateStrings.length
      ? dateStrings
      : [moment(new Date()), moment(new Date())];

    let firstDay = dateStrings[0]
      ? moment(dateStrings[0], "DD/MM/YYYY")
          .startOf("day")
          .tz(timeZone)
          .toISOString()
      : "";

    let lastDay = dateStrings[1]
      ? moment(dateStrings[1], "DD/MM/YYYY")
          .endOf("day")
          .tz(timeZone)
          .toISOString()
      : "";

    return { firstDay, lastDay };
  };

  const changeHistory = query => {
    props.history.push({
      pathname: props.path,
      search: `?${query}`,
      state: { detail: "some_value" }
    });
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: limitRows,
    total: 0
  });

  const [data, setData] = useState([]);
 
  const [calcTotalTxnsAllPayrolls, setCalcTotalTxnsAllPayrolls] = useState({
    all: { total: 0, amount: 0 },
    not_rejected: { total: 0, amount: 0 },
    rejected: { total: 0, amount: 0 }
  });
  const [loading, setLoading] = useState(true);

  const fetchPayrolls = async params => {
    const { firstDay, lastDay } = setDates([]);
    setLoading(true);
    await api.utils
      .findAllPaginated({
        limit: limitRows,
        status: props.status,
        isEnabled: props.isEnabled,
        commerceId: props.merchant.code,
        start_date: firstDay,
        end_date: lastDay,
        sort: props.sort,
        ...params
      })
      .then(response => {
        let arrayData = response.data.docs.map(doc => {
          let accepted = doc.txns.filter(txn => txn.code == 0);
          let rejected = doc.txns.filter(txn => txn.code == 12);
          return {
            ...doc,
            accepted_total: accepted.length,
            accepted_amount: accepted.length
              ? accepted.reduce((sum, { quantity }) => sum + quantity, 0)
              : 0,
            rejected_total: rejected.length,
            rejected_amount: rejected.length
              ? rejected.reduce((sum, { quantity }) => sum + quantity, 0)
              : 0
          };
        });
        setData(arrayData);
        setCalcTotalTxnsAllPayrolls(response.data.calcTotalTxnsAllPayrolls);
        setPagination({
          current: parseInt(params.page) || 1,
          pageSize: limitRows,
          total: response.data.totalDocs
        });
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeTable = pagination => {
    let query = new URLSearchParams({
      ...queryParams(),
      page: pagination.current
    }).toString();

    changeHistory(query);
  };

  const onSearch = params => {
    let query = new URLSearchParams({
      ...queryParams(),
      page: 1,
      search: params.search,
      filter: params.filter
    }).toString();

    changeHistory(query);
  };

  const [timeZone, setTimeZone] = useState("America/Santiago");

  const onTimeZoneChange = zone => {
    moment.tz.setDefault(zone);
    setTimeZone(zone);

    let query = new URLSearchParams({
      ...queryParams(),
      timeZone: zone
    }).toString();

    changeHistory(query);
  };

  const onChangeRange = (dates, dateStrings) => {
    const { firstDay, lastDay } = setDates(dateStrings);

    let query = new URLSearchParams({
      ...queryParams(),
      page: 1,
      start_date: firstDay,
      end_date: lastDay
    }).toString();

    changeHistory(query);
  };

  const [loadingParametrosGenerales, setloadingParametrosGenerales] = useState(
    true
  );
  const [parametrosGenerales, setparametrosGenerales] = useState({
    isActiveNewCashout: false,
    commerces: {}
  });

  const getParametrosGenerales = () => {
    setloadingParametrosGenerales(true);
    db.collection("parametrosGenerales")
      .doc("cashout")
      .get()
      .then(parametrosGenerales => {
        const {
          commerces = {},
          isActiveNewCashout = false
        } = parametrosGenerales.data();
        setparametrosGenerales({
          isActiveNewCashout,
          commerces
        });
      })
      .catch(err => {
        console.log("ERROR IN getParametrosGenerales", err);
      })
      .finally(() => {
        setloadingParametrosGenerales(false);
      });
  };

  useEffect(() => {
    moment.tz.setDefault(timeZone);
    fetchPayrolls(queryParams());
    getParametrosGenerales();
  }, [document.location.href, props.merchant.code]);

  const [statusBot, setStatusBot] = useState(true);
  const [statusBotColor, setStatusBotColor] = useState("success");
  const [textButtonActiveBot, setTextButtonActiveBot] = useState("Activate Bot")
  const [colorButtonActivateBotShadow, setcolorButtonActivateBotShadow] = useState("buttonActivateBot-disabled-color")
  const [colorButtonActivateBot, setcolorButtonActivateBot] = useState("shadowButtonActivateBot-disabled-color")
  const [disabledButtonActivateBot, setDisabledButtonActivateBot] = useState(false)

  const changeParamsBot = (isActiveBotValidationV2) =>{
    if (isActiveBotValidationV2) {
      setStatusBot(true);
      setStatusBotColor("success");
      setTextButtonActiveBot("Deactivate Bot")
      setDisabledButtonActivateBot(true)
      setcolorButtonActivateBotShadow("shadowButtonActivateBot-disabled-color")
      setcolorButtonActivateBot("buttonActivateBot-disabled-color")
    } else {
      setStatusBot(false);
      setStatusBotColor("error");
      setTextButtonActiveBot("Activate Bot")
      setcolorButtonActivateBotShadow("shadowButtonActivateBot-active-color")
      setcolorButtonActivateBot("buttonActivateBot-active-color")
    }
  }

  const getStatusBot = async () => {
    await api.cashout
      .getStatusActiveBot()
      .then(resp => {
        const { isActiveBotValidationV2 } = resp;
        changeParamsBot(isActiveBotValidationV2)
        console.log("resp getStatusActiveBot", "=>", resp);
      })
      .catch(error => {
        console.log("error getStatusActiveBot", "=>", error);
      });
  };

  const changeStatusBot = async () =>{
    let payload = {
      isActiveBotValidationV2: !statusBot
    }
    await api.cashout
    .changeActiveBot(payload)
    .then(resp => {
      const { isActiveBotValidationV2 } = resp;
      changeParamsBot(isActiveBotValidationV2)
      console.log("resp getStatusActiveBot", "=>", resp);
    })
    .catch(error => {
      console.log("error getStatusActiveBot", "=>", error);
    });
  }

  useEffect(() => {
    getStatusBot();
  }, []);
  

  const expandedRowRender = row => {
    const columns = [
      {
        title: "Nº",
        key: "num",
        width: 70,
        render: (text, record, index) => <span>{index + 1}</span>
      },
      {
        title: "Date",
        key: "dateRequest",
        render: (text, record) =>
          moment(record.dateRequest).format("DD MMM YYYY HH:mm")
      },
      { title: "Name", dataIndex: "name", key: "name" },
      { title: "RUT", dataIndex: "rut", key: "rut" },
      { title: "Email", dataIndex: "email", key: "email" },
      {
        title: "Amount",
        key: "quantity",
        render: (text, record) => (
          <NumberFormat
            value={record.quantity}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        )
      },
      { title: "Zippy ID", dataIndex: "zippyId", key: "zippyId" }
    ];

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={row.txns}
        pagination={false}
      />
    );
  };

  const optionsFilter = [
    { value: "_id", label: "Payroll ID" },
    { value: "commerceId", label: "Commerce ID" },
    { value: "txns.zippyId", label: "TXN Zippy ID" },
    { value: "txns.name", label: "TXN Name" },
    { value: "txns.rut", label: "TXN RUT" },
    { value: "txns.email", label: "TXN Email" }
  ];

  return (
    <>
      <div>
        <div>
          {isActiveOldCashout(
            parametrosGenerales,
            props.merchant.code,
            loadingParametrosGenerales
          ) && (
            <>
              <Alert
                message="Warning"
                description="The new cashout (payrolls) is disabled and the old cashout is enabled"
                type="warning"
                showIcon
              />
              <hr />
            </>
          )}
        </div>
        <div className="table-operations-header">
          <div>
            <h2>{props.title}</h2>
            <Breadcrumb separator="">
              <Breadcrumb.Item>
                <Link to="/main/dashboard">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Separator />
              <Breadcrumb.Item>{props.title}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <div>
              <TimeZoneSelect
                onTimeZoneChange={onTimeZoneChange}
                value={timeZone}
              />
            </div>
          </div>
        </div>
        <MenuPayrollHeader type={props.type} />
        <br />
        <div className="table-operations-header">
          <div style={{ width: "100%" }}>
            <RangeDatePayroll
              timeZone={timeZone}
              onChangeRange={onChangeRange}
            />
          </div>
          <div style={{ width: "100%", textAlign: "right" }}>
            <SearchPayroll
              onChange={onSearch}
              options={optionsFilter}
              placeholder="Search by transactions..."
            />
          </div>
        </div>
      </div>
      <div>
        <Table
          dataSource={data}
          onChange={onChangeTable}
          pagination={pagination}
          loading={loading}
          style={{ background: "white" }}
          expandedRowRender={expandedRowRender}
          rowKey="_id"
          scroll={{ x: 700 }}
          footer={() =>
            calcTotalTxnsAllPayrolls && (
              <div style={{ display: "flex", gap: "1em 2em" }}>
                <div>
                  <Badge status="blue" text={`Payrolls:`} />
                  <h4 style={{ margin: 0, paddingLeft: ".9em" }}>
                    Total:{" "}
                    <NumberFormat
                      value={pagination && pagination.total}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                    />
                  </h4>
                </div>
                <div>
                  <Badge
                    status="blue"
                    text={`All transactions: ${calcTotalTxnsAllPayrolls.all &&
                      calcTotalTxnsAllPayrolls.all.total}`}
                  />
                  <h4 style={{ margin: 0, paddingLeft: ".9em" }}>
                    Total:{" "}
                    <NumberFormat
                      value={
                        calcTotalTxnsAllPayrolls.all &&
                        calcTotalTxnsAllPayrolls.all.amount
                      }
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                    />
                  </h4>
                </div>

                <div>
                  <Badge
                    status="success"
                    text={`Not Rejected txns: ${calcTotalTxnsAllPayrolls.not_rejected &&
                      calcTotalTxnsAllPayrolls.not_rejected.total}`}
                  />
                  <h4 style={{ margin: 0, paddingLeft: ".9em" }}>
                    Total:{" "}
                    <NumberFormat
                      value={
                        calcTotalTxnsAllPayrolls.not_rejected &&
                        calcTotalTxnsAllPayrolls.not_rejected.amount
                      }
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                    />
                  </h4>
                </div>

                <div>
                  <Badge
                    status="error"
                    text={`Rejected txns: ${calcTotalTxnsAllPayrolls.rejected &&
                      calcTotalTxnsAllPayrolls.rejected.total}`}
                  />
                  <h4 style={{ margin: 0, paddingLeft: ".9em" }}>
                    Total:{" "}
                    <NumberFormat
                      value={
                        calcTotalTxnsAllPayrolls.rejected &&
                        calcTotalTxnsAllPayrolls.rejected.amount
                      }
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                    />
                  </h4>
                </div>
                <div >
                <Col span={!statusBot ? 20 : 24} >
                <Badge
                    status={statusBotColor}
                    text={`Status Validation Bot: ${statusBot ? 'Enabled' : 'Disabled'}`}
                  />
                  {!statusBot ? <button className={`shadowButtonActivateBot ${colorButtonActivateBotShadow}`} onClick={() => changeStatusBot()} style={{marginLeft:"20px"}} disabled = {disabledButtonActivateBot}>
                    <span className={`buttonActivateBot ${colorButtonActivateBot}`}>{textButtonActiveBot}</span>
                  </button> : <></> }
                  
                </Col>
                  
                </div>
                
              </div>
            )
          }
        >
          <Column
            title="ID"
            dataIndex="_id"
            key="_id"
            width={250}
            render={(text, record) => (
              <Link
                to={`/main/payroll/payroll-detail/${record._id}?type=${props.type}`}
              >
                {record._id}
              </Link>
            )}
          />

          <Column title="Commerce ID" dataIndex="commerceId" key="commerceId" />
          <Column
            title="Date"
            dataIndex="createdAt"
            key="createdAt"
            render={(text, record) => moment(text).format("DD MMM YYYY HH:mm")}
          />
          <Column
            title="All"
            dataIndex="all"
            key="all"
            render={(text, record) => (
              <Badge
                status="blue"
                style={{ marginBottom: 0 }}
                text={
                  <>
                    {record.txns.length}
                    {" ("}
                    <NumberFormat
                      value={record.txns.reduce(function(acc, obj) {
                        return acc + parseInt(obj.quantity);
                      }, 0)}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                    />
                    {")"}
                  </>
                }
              />
            )}
          />
          <Column
            title="Not Rejected"
            dataIndex="not_rejected"
            key="not_rejected"
            render={(text, record) => (
              <Badge
                status="success"
                style={{ marginBottom: 0 }}
                text={
                  <>
                    {record.txns.filter(txn => txn.code != 12).length}
                    {" ("}
                    <NumberFormat
                      value={record.txns
                        .filter(txn => txn.code != 12)
                        .reduce(function(acc, obj) {
                          return acc + parseInt(obj.quantity);
                        }, 0)}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                    />
                    {")"}
                  </>
                }
              />
            )}
          />
          <Column
            title="Rejected"
            dataIndex="rejected"
            key="rejected"
            render={(text, record) => (
              <Badge
                status="error"
                style={{ marginBottom: 0 }}
                text={
                  <>
                    {record.txns.filter(txn => txn.code == 12).length}
                    {" ("}
                    <NumberFormat
                      value={record.txns
                        .filter(txn => txn.code == 12)
                        .reduce(function(acc, obj) {
                          return acc + parseInt(obj.quantity);
                        }, 0)}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                    />
                    {")"}
                  </>
                }
              />
            )}
          />
        </Table>
        <hr />
      </div>

      <style jsx="true">{`
        .loading {
          width: 100%;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .section-button {
          display: flex;
          justify-content: flex-end;
        }
        .table-operations-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1em;
        }
      `}</style>
    </>
  );
};

// export default PayrollList;

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, country, comissionData } = settings;
  return { authUser, merchant, country, comissionData };
};
export default connect(mapStateToProps)(PayrollList);
