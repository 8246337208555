import React, { Component } from "react";
import BurnCodeComponent from "../../../components/BurnCode/index";
import { Button, Row, Col } from "antd";
class burnCode extends Component {
  state = {
    resp: "",
    updateTable: false,
    type: "cashin"
  };
  componentDidMount = async e => {
   /*  this.state({
      type: "cashout"
    }); */
  };


  onchangeToCashout = () => {
    this.setState({
      type: "cashout"
    });
  };
  onchangeToCashin = () => {
    this.setState({
      type: "cashin"
    });
  };

  render() {
    return (
      <>
      
      <BurnCodeComponent />
      </>
    );
  }
}

export default burnCode;
