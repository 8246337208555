import React from "react";
import { Row, Col, Card, Table, Input, Popconfirm, Alert, Badge } from "antd";
import _ from "lodash";
import { firebase } from "../../firebase/firebase";
import NumberFormat from "react-number-format";
const db = firebase.firestore();
const pagination = { position: "bottom" }; //bottom top both none

class Dynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue: "",
      bordered: false,
      loading: false,
      pagination,
      size: "middle", // default middle small
      expandedRowRender: props.expandedRowRender,
      title: undefined,
      showHeader: true,
      total: 0,
      footer: () => (
        <div>

          Total Records:{" "}
          <NumberFormat
            value={this.state.total}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
          />
        </div>
      ),
      rowSelection: false, // {} or false
      scroll: undefined,
      searchFilter: "",
      updateTable: false,
      sortedInfo: {
        order: "descend",
        columnKey: "idUser"
      },
    };
  }
  componentDidMount = () => {
    this.getUsers();
  }

  componentWillReceiveProps(nextProps) {
      console.log(nextProps);
    if (nextProps.updateTable) {
      this.getUsers();
    }
    this.filter(this.state.searchFilter);

  }
  getUsers = async () => {
    let user = [];
    await db
      .collection("users")
      .get()
      .then(snapshot => {
        snapshot.docs.map(async docUser => {
          user.push({
            idUser: docUser.id,
            ...docUser.data(),
          });
        })
      })
      .catch(err => {
        console.log("ERROR IN user", err);
      });
    let total = user.length;
    this.setState({ userOrigin: user, user, total: total })

  }

  getMerchantUser = async (id) => {
    let merchant = [];
    await db
      .collection("users")
      .doc(id)
      .collection("merchants")
/*       .where("countrie", "==", "CL") */
      .get()
      .then(snapshot => {
        snapshot.docs.map(resp => {
          merchant.push({
          /*   idcommission: resp.id, */
            ...resp.data()
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN getMerchantUser", err);

      });

    return merchant
  }

  getCountriesUser = async (id) => {
    let countries = [];
    await db
      .collection("users")
      .doc(id)
      .collection("countries")
/*       .where("countrie", "==", "CL") */
      .get()
      .then(snapshot => {
        snapshot.docs.map(resp => {
          countries.push({
          /*   idcommission: resp.id, */
            ...resp.data()
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN getCountriesUser", err);

      });

    return countries
  }



  filter = searchValue => {

console.log("filter searchValue",searchValue,"this.state.userOrigin:" ,this.state.userOrigin,"this.state.user:" ,this.state.user)

    if (searchValue === "") {
      //console.log("if");
      this.setState({
        user: this.state.userOrigin,
      });
    } else {

     // console.log("else");
      try {
        const filter = _.filter(
          this.state.user,
          user =>
          (user.idUser
            ? user.idUser.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
            : "")
            ||
            (user.email
              ? user.email.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
              : "")
              ||
              (user.countryDefault
                ? user.countryDefault.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                : "")
                ||
                (user.merchantDefault
                  ? user.merchantDefault.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                  : "")

                  ||
                  (user.typeUser
                    ? user.typeUser.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                    : "")
                    ||
                    (user.name
                      ? user.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                      : "")
        );
       // console.log("filter",filter);

        this.setState({
          user: filter,
        });
      }
      catch{
        console.log("Error en  el filtro ");
      }
    }
  };

  updateSearchFilter(evt) {

   // console.log("updateSearchFilter" ,evt.target.value);
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }

  onTableChange = (pagination, filters, sorter) => {
  //  console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  edit = async (record) => {
    console.log("edit ");
    let merchants = await this.getMerchantUser(record.idUser);
    let countries = await this.getCountriesUser(record.idUser);
    record.merchants = merchants;
    record.countries = countries;


    console.log("commision",merchants,"record.merchants",record.merchants,"record.iduser",record.idUser);

    this.props.OnEdit(record);
    setTimeout(
      function () {
        this.setState({ resp: "" });
      }
        .bind(this),
      3000
    );
  };


  delete = async (record) => {
console.log("delete",record);

    this.props.OnDelete(record);
    setTimeout(
      function () {
        this.setState({ resp: "" });
      }
        .bind(this),
      3000
    );
  };



  getColumns = () => [
    {
      title: "id User",
      dataIndex: "idUser",
      key: "idUser",
      width: 100,
   /*    render: (text, record) => (
        <span>
          {String(text) === "true" ? <Badge status="success" /> : <Badge status="error" />}
        </span>

      ) */
      /* ,
      sorter: (a, b) => a.valor - b.valor,
      sortOrder:
        this.state.sortedInfo.columnKey === "valor" &&
        this.state.sortedInfo.order */
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 100,
      visible: false,
      display: "none",
      hideOnSmall: true,
      className: "hide"
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150/* ,
    sorter: (a, b) => a.valor - b.valor,
    sortOrder:
      this.state.sortedInfo.columnKey === "valor" &&
      this.state.sortedInfo.order */
    },

    {
      title: "Country Default",
      dataIndex: "countryDefault",
      key: "countryDefault",
      width: 100,
       sorter: (a, b) => a.countryDefault - b.countryDefault,
       sortOrder:
         this.state.sortedInfo.columnKey === "countryDefault" &&
         this.state.sortedInfo.order

    /*   render: (text, record) => (
        <span>
          {String(text) === "true" ? <Badge status="success" /> : <Badge status="error" />}

        </span>

      ) */
    },

    {
      title: "Merchant Default",
      dataIndex: "merchantDefault",
      key: "merchantDefault",
      width: 100,
    sorter: (a, b) => a.merchantDefault - b.merchantDefault,
    sortOrder:
      this.state.sortedInfo.columnKey === "merchantDefault" &&
      this.state.sortedInfo.order
     /*  render: (text, record) => (
        <span>
          {String(text) === "true" ? <Badge status="success" /> : <Badge status="error" />}
        </span>

      ) */
    },

    {
      title: "Type User",
      dataIndex: "typeUser",
      key: "typeUser",
      width: 100,
    /*   render: (text, record) => (
        <span>
          {String(text) === "true" ? <Badge status="success" /> : <Badge status="error" />}
        </span>

      )
      , */
      sorter: (a, b) => a.typeUser - b.typeUser,
      sortOrder:
        this.state.sortedInfo.columnKey === "typeUser" &&
        this.state.sortedInfo.order
    },


 /*    {
      title: "Payku",
      dataIndex: "usePayku",
      key: "usePayku",
      width: 100,
      render: (text, record) => (
        <span>
          {String(text) === "true" ? <Badge status="success" /> : <Badge status="error" />}
        </span>

      )

    }, */

    {
      title: <i className="icon icon-edit" />,
      key: "edit",
      /*  fixed: "right", */
      width: 50,
      render: (text, record) => (
        <span>
          <Popconfirm
            title={`Do you want EDIT this  user? ✏️`}
            onConfirm={() => this.edit(record)}
            onCancel={this.cancel}
            okText="Yes"
            cancelText="No"
          >
            <span className="gx-link">
              {record.prepared ? "✅ " : ""}Edit
          </span>
          </Popconfirm>
        </span>

      )
    },
    {
      title: <i className="icon icon-trash" />,
      key: "delete",
      /*  fixed: "center", */
      width: 50,
      render: (text, record) => (
        <span>
          <Popconfirm
            title={`Do you want DELETE this  user? 🗑️`}
            onConfirm={() => this.delete(record)}
            onCancel={this.cancel}
            okText="Yes"
            cancelText="No"
          >
            <span className="gx-link">
              {record.prepared ? "✅ " : ""}Delete
          </span>
          </Popconfirm>
        </span>

      )
    }
  ];
  render() {

    return (
      <Card>
        <div className="components-table-demo-control-bar">
          <Row>
            <Col xl={15} lg={15} md={15} sm={24} xs={24} className="gx-pr-md-2">
            </Col>
            <Col xl={9} lg={9} md={9} sm={24} xs={24} className="gx-pr-md-2">
              <Input
                id="idSearch"
                defaultValue={this.state.defaultValue}

                placeholder="Search..."
                onChange={this.updateSearchFilter.bind(this)}
              ></Input>
            </Col>
          </Row>
        </div>

        <Table
          className="gx-table-responsive"
          {...this.state}
          // columns={this.props.columns || this.columns}

          columns={this.getColumns()}
          dataSource={this.state.user}
          rowKey="valor"
          onChange={this.onTableChange}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50', '100'] }}
        />

        {this.state.resp ? (<Alert
          message="Resultado"
          description={this.state.resp}
          type="success"
        />) : null}

      </Card>
    );
  }
}

export default Dynamic;
