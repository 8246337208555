import React, { Component } from "react";
import { Button, Form, Input, Alert, Row, Col, Select } from "antd";
import Widget from "components/Widget/index";
import { firebase ,auth} from "../../firebase/firebase";
import Dynamic from "../User/Dynamic";
import GridMerchants from "../User/GridMerchants";
import SelectM from "../User/Select";
import API from "../../util/api";
const db = firebase.firestore();
/* const FormItem = Form.Item;
const { TextArea } = Input; */
/* let query; */
class Users extends Component {
  state = {
    resp: "",
    updateTable: false,
    typeAlert: "success",
    boxMerchant: [],

    type:"create"

  };
  componentDidMount = async e => {

    this.getCommerces();
    this.getCountries();
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      try {
        if (!err) {
          console.log("Received values of form: ", values);

          /*   let flag = this.validaExistencia(values.id);*/

          let flag = false;
          let merchants=[];
          values.merchants.map(e=>{
         /*  console.log("map_merchant",e); */
          let merchantfilter;
          merchantfilter = this.state.boxMerchant.filter(item=> item.code===e)

         /*  console.log("map_merchant",e,merchant); */
         merchants.push(
            ...merchantfilter
          )

          })
          let countries = [];
          values.countries.map(e => {
            /*  console.log("map_merchant",e); */
            let countriesfilter;
            countriesfilter = this.state.boxCountries.filter(
              item => item.code === e
            );

            /*  console.log("map_merchant",e,merchant); */
            countries.push(...countriesfilter);
          });
              let payload = {
            countryDefault: String(values.countryDefault).trim().replace(/\s/g, ""),
            email: values.email,
            name:values.name,
            dateCreation:Date.now().toString(),
            merchantDefault: String(values.merchantDefault).trim().replace(/\s/g, ""),
            typeUser: String(values.typeUser).trim().replace(/\s/g, ""),
            merchants:merchants,
            id:values.idUser,
            password:String(values.password).trim().replace(/\s/g, ""),
            userCreate:this.props.userCreate,
            countries: countries
          };

          console.log("payload",payload);


            let respAction;

            if (this.state.type === "create") {
              respAction = this.saveUser(payload, values);
            }
            else {
              respAction = this.UpdateUser(payload, values.idcommission);
            }
            if (respAction) {
              this.setState({
                resp: `User ${this.state.type} Successfully.\n`,
                updateTable: !this.state.updateTable,
                typeAlert: "success"
              });
              this.clearFrom();
              window.scrollTo({ top: 10, behavior: "smooth" });
            } else {
              this.setState({
                resp: `User ${this.state.type}  Error.\nName: ${values.Name}\n`,
                updateTable: !this.state.updateTable,
                typeAlert: "error"
              });
            }
          setTimeout(
            function () {
              this.setState({ resp: "" });
            }.bind(this),
            20000
          );
        }
      } catch (error) {
        console.error(error);
        this.setState({
          resp: `Commerce: Error when trying to add a record .\nerror:: ${error}\n`,
          updateTable: !this.state.updateTable,
          typeAlert: "error"
        });
      }
    });
  };

  validaExistencia = async value => {
    /*    new Promise(async (resolve, reject) => { */
    let isValid = false;
    console.log("value", value);
    /*   query = db.collection("parametrosGenerales");
      await query
        .doc("seguridad")
        .collection("blacklistCashout")
        .where("valor", "==", value)
        .get()
        .then(async snapshot => {
          let resFirestore = snapshot.docs.map(doc => {
            return doc.data();
          });

          if (resFirestore.length > 0) {
            isValid = true;
          }
        })
        .catch(error => console.log("error en validaExistencia", error)); */

    let user = [];

    await db
      .collection("commerces")
      .doc(value)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          isValid = true;
        }
        console.log("user", snapshot.data());
        //user = snapshot.data()
        // res.status(200).send(response)
      })
      .catch(err => {
        //  res.status(400).json({ message: "Error in commerces" })
        console.log("ERROR IN commerces:", err);
      });

    /*    if(user)
       {
         isValid=true;
       } */
    /*     console.log("user", user) */

    return isValid;

    /*  }); */
  };

  saveUser= async (payload, payLoadCommissions) => {

    auth.onAuthStateChanged( async user => {
      let token = await auth.currentUser.getIdToken();
      await API.user.createUser(payload,token).then(cons => {
        console.log("createUser ok", cons);
        if (cons.status === 400) {
            this.setState({
              resp: cons.message,
              typeAlert: "error"
            })
        }
        else
        {
          this.setState({
            resp: cons.message,
            updateTable: !this.state.updateTable,
            typeAlert: "success"
          })
        }

      }).catch(err => {
        console.log("ERROR IN createUser:", err);
        this.setState({
          resp: err,
          typeAlert: "error"
        })
      });
    });
  };

  UpdateUser= async (payload, payLoadCommissions) => {

    auth.onAuthStateChanged( async user => {
      let token = await auth.currentUser.getIdToken();
      await API.user.updateUser(payload,token).then(cons => {
        console.log("updateUser ok", cons);
        if (cons.status === 400) {
            this.setState({
              resp: cons.message,
              typeAlert: "error"
            })
        }
        else
        {
          this.setState({
            resp: cons.message,
            updateTable: !this.state.updateTable,
            typeAlert: "success"
          })
        }

      }).catch(err => {
        console.log("ERROR IN createUser:", err);
        this.setState({
          resp: err,
          typeAlert: "error"
        })
      });
    });
  };

  UpdateCommerces = async (payload, payLoadCommissions, idcommission) => {
    //Grabar en db

    let resp = false;
    await db
      .collection("commerces")
      .doc(payload.id)
      .set({
        ...payload
      })
      .then(
        await db
          .collection("commerces")
          .doc(payload.id)
          .collection("commissions")
          .doc(idcommission)
          .set({
            ...payLoadCommissions
          })
          .then((resp = true))
          .catch(error => {
            /*  console.log("commissions catch" + error), */
            resp = false;
          })
      )
      .catch(error => {
        /*   console.log("commerces catch" + error), */
        resp = false;
      });
    return resp;
  };
  getCommerces = async () => {
    let merchant = [];
    await db
      .collection("commerces")
      .get()
      .then(snapshot => {
        snapshot.docs.map(async docCommerce => {
          merchant.push({
            code: docCommerce.id,
            name: docCommerce.data().name
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN merchant", err);
      });
    this.setState({ boxMerchant: merchant });
  };

  OnEdit = value => {
    this.setState({ type: "edit", resp: "" });
    this.clearFrom();
    console.log(" OnEdit", value);

    /*  let srtUseWebpay=  ;*/
    console.log("value.merchants", value.merchants);
    this.setState({ userMerchants: value.merchants });
    this.setState({ countries: value.countries });
    this.props.form.setFieldsValue({
      ["idUser"]: value.idUser,
      ["email"]: value.email,
      ["name"]: value.name,
      ["merchantDefault"]: value.merchantDefault,
      ["countryDefault"]: value.countryDefault,
      ["typeUser"]: value.typeUser

    });
    window.scrollTo({ top: 1000, behavior: "smooth" });
  };

  OnDelete = async value => {
console.log("OnDelete",value);


/* new Promise(async (resolve, reject) => { */
  await API.user.deleteUser(value.idUser).then(cons => {
    console.log("deleteUser ok", cons);
    if (cons.status === 400) {
        this.setState({
          resp: cons.message,
          typeAlert: "error"
        })
    }
    else
    {
      this.setState({
        resp: cons.message,
        updateTable: !this.state.updateTable,
        typeAlert: "success"
      })
    }

  }).catch(err => {
    console.log("ERROR IN deleteUser:", err);
    this.setState({
      resp: err,
      typeAlert: "error"
    })
  });

/* }); */

  }
  clearFrom = () => {
    this.props.form.resetFields();
    this.setState({
      userMerchants:"",
      countries: ""
    })
  };

  setType = e => {
    this.setState({
      type: e,
      resp: ""
    });
    this.clearFrom();
    window.scrollTo({ top: 1000, behavior: "smooth" });
  };
  getCountries = async () => {
    await API.utils.getCountries().then(cons => {
      this.setState({
        boxCountries: cons
      });
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;

    /*    const AutoCompleteOption = AutoComplete.Option; */

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 12
        },
        sm: {
          span: 8
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16
        }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    const children = [];
    const childrenEdit = [];
    const childrenDefault = [];
    function handleChange(values) {
      console.log(`selected ${values}`);


    var arreglo = values.toString().split(",");
      arreglo.map(value => {

       /*  if (this.state.boxMerchant) {
        this.state.boxMerchant.filter(merchant => {
          if(value===merchant.code)
          {
 */
            childrenDefault.push(
              <Option key={value.toString()}>
                {value.toString()}
              </Option>
            );
       /*    }
        });
      } */
      });
    }
    if (this.state.userMerchants) {
      this.state.userMerchants.map(merchant => {
        childrenEdit.push(merchant.code.toString());
      });
    }
    if (this.state.boxMerchant) {
      this.state.boxMerchant.map(merchant => {
        children.push(
          <Option key={merchant.code.toString()}>
            {merchant.name.toString()}
          </Option>
        );
      });
    }
    const countriesChildren = [];
    const countriesChildrenEdit = [];
    const countriesChildrenDefault = [];
    if (this.state.countries) {
      this.state.countries.map(country => {
        countriesChildrenEdit.push(country.code.toString());
      });
    }
    if (this.state.boxCountries) {
      this.state.boxCountries.map(country => {
        countriesChildren.push(
          <Option key={country.code.toString()}>
            {country.name.toString()}
          </Option>
        );
      });
    }

    function handleChangeCountries(values) {
      console.log(`selected ${values}`);

      var arreglo = values.toString().split(",");
      arreglo.map(value => {
        /*  if (this.state.boxMerchant) {
        this.state.boxMerchant.filter(merchant => {
          if(value===merchant.code)
          {
 */
        countriesChildrenDefault.push(
          <Option key={value.toString()}>{value.toString()}</Option>
        );
        /*    }
        });
      } */
      });
    }
    return (
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Widget
            title={
              <h4
                className=" gx-text-capitalize gx-mb-0"
                style={{ color: "#070707" }}
              >
         👤
                users
              </h4>
            }
            // style={{ backgroundColor: "#60ec9866" }}
          >
            <Button type="dashed" onClick={e => this.setType("create")}>
              New user
            </Button>
            <Dynamic
              updateTable={this.state.updateTable}
              type={"blacklist"}
              //delete={() => this.delete()}
              OnEdit={e => this.OnEdit(e)}
              OnDelete={(e) => this.OnDelete(e)}
            />
          </Widget>
        </Col>

        <Col lg={24} md={24} sm={24} xs={24}>
          {/* {this.props.type!=="table"? */}
          <Widget
            title={
              <h4
                className=" gx-text-capitalize gx-mb-0"
                style={{ color: "#070707" }}
              >
                {/*  <i className="icon icon-mail-open gx-mr-3" /> */}

                {this.state.type === "edit" ? (
                  <span>{"✏️ Edit user"} </span>
                ) : (
                  <span>{"➕ Add New user"}</span>
                )}
              </h4>
            }
          >
            {this.state.type === "edit" ? (
              <Button type="dashed" onClick={e => this.setType("create")}>
                New user
              </Button>
            ) : null}
            <hr></hr>
            <Form
              {...formItemLayout}
              onSubmit={this.handleSubmit}
              //form={form}
              name="register"
              scrollToFirstError
            >
              <Row>
                <Col lg={12} md={12} sm={24} xs={24}>
               {/*  {this.state.type==="edit"? */}
                <Form.Item name="idUser" label="idUser">
                    {getFieldDecorator("idUser", {
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Please enter the idUser!"
                        }
                      ]
                    })(<Input placeholder="idUser" disabled={this.state.type==="edit"}/>)}
                  </Form.Item>
            {/*     :
                null
                } */}
                  <Form.Item name="email" label="Email">
                    {getFieldDecorator("email", {
                      initialValue: "",
                      rules: [
                        {
                          type: 'email',
                          required: true,
                          message: "Please enter the Email!"
                        }
                      ]
                    })(<Input placeholder="email" disabled={this.state.type==="edit"} />)}
                  </Form.Item>


                  {this.state.type==="create"?
                  <Form.Item name="password" label="password">
                    {getFieldDecorator("password", {
                      initialValue: "",
                      rules: [
                        {

                          required: true,
                          message: "Please enter the password!"
                        }
                      ]
                    })(<Input placeholder="password"  />)}
                  </Form.Item>
                  :
                  null
                  }
                  <Form.Item name="typeUser" label="Type User">
                    {getFieldDecorator("typeUser", {
                      initialValue: "soporte",
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: true,
                          message: "Please enter the value!"
                        }
                      ]
                    })(
                      <Select onChange={this.onChangeID}>
                        <Option value={"admin"}>Admin </Option>
                        <Option value={"soporteAdmin"}>Soport Admin </Option>
                        <Option value={"soporte"}>Soport </Option>
                        <Option value={"merchant"}>Merchant </Option>
                        <Option value={"soporteCashout"}>Soport Cashout </Option>
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item name="merchants" label="Merchants">
                    {getFieldDecorator("merchants", {
                      initialValue: childrenEdit, // ['a10', 'c12'],//childrenEdit,//
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: true,
                          message: "Please enter the Merchant!"
                        }
                      ]
                    })(
                      <Select
                        mode="tags"
                        // mode="multiple"
                        style={{ width: "100%" }}
                       //  placeholder="Please select Merchants"
                       onChange={handleChange}
                      >
                        {children}
                      </Select>
                    )}
                  </Form.Item>

                  {/* <hr></hr> */}
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Form.Item name="name" label="Name">
                    {getFieldDecorator("name", {
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Please enter the value!"
                        }
                      ]
                    })(<Input placeholder="enter the name user" />)}
                  </Form.Item>
                  <Form.Item name="countries" label="Countries">
                    {getFieldDecorator("countries", {
                      initialValue: countriesChildrenEdit, // ['a10', 'c12'],//childrenEdit,//
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: true,
                          message: "Please enter the Countries!"
                        }
                      ]
                    })(
                      <Select
                        mode="tags"
                        // mode="multiple"
                        style={{ width: "100%" }}
                       //  placeholder="Please select Merchants"
                       onChange={handleChangeCountries}
                      >
                        {countriesChildren}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item name="countryDefault" label="Country Default">
                    {getFieldDecorator("countryDefault", {
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: true,
                          message: "Please enter the value!"
                        }
                      ]
                    })(
                      <Select  style={{ width: "100%" }}
                      placeholder="Please select Country Default">
                        {countriesChildren}
                      </Select>
                    )}
                  </Form.Item>

                  <Form.Item name="merchantDefault" label="Merchant Default">
                    {getFieldDecorator("merchantDefault", {
                      // initialValue:childrenEdit,// ['a10', 'c12'],//childrenEdit,//
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: true,
                          message: "Please enter the Merchant Default!"
                        }
                      ]
                    })(
                      <Select
                        //mode="tags"
                        // mode="multiple"
                        style={{ width: "100%" }}
                         placeholder="Please select Merchant Default"
                        // defaultValue={this.state.type !== "edit"?children:['a10', 'c12']}
                      >
                        {children}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item {...tailFormItemLayout}>
                    {this.state.type === "edit" ? (
                      <Button type="primary" htmlType="submit">
                        Edit user
                      </Button>
                    ) : (
                      <>
                        <Button type="primary" htmlType="submit">
                          Create user
                        </Button>
                        <Button type="primary" onClick={this.clearFrom}>
                          Clean
                        </Button>
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            {this.state.resp ? (
              <Alert
                message="Resultado"
                description={this.state.resp}
                type={this.state.typeAlert}
              />
            ) : null}
          </Widget>
        </Col>
      </Row>
    );
  }
}

const UsersForm = Form.create()(Users);

export default UsersForm;
