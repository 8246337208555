import React from "react";
import { Row, Col, Card, Table, Input, Badge, Tooltip } from "antd";
import _, { first } from "lodash";
import moment from "moment-timezone";
import NumberFormat from "react-number-format";
import Auxiliary from "../../util/Auxiliary";
import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js";
import { AllHtmlEntities } from "html-entities";
import currencyNumberFormat from "../../util/currencyNumberFormat"

const entities = new AllHtmlEntities();
const pagination = { position: "bottom" }; //bottom top both none

class Dynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bordered: false,
      loading: this.props.loading,
      pagination,
      size: "middle", // default middle small
      expandedRowRender: props.expandedRowRender,
      title: undefined,
      showHeader: true,
      download: false,
      footer: () => (
        <>
          <div>

            Total Amount:{" "}
            {this.props.country !== "" ? currencyNumberFormat(this.props.suma, this.props.country) : currencyNumberFormat(this.state.sumDataList, "CL")}
          </div>
          <div>
            Total Records:{" "}
            <NumberFormat
              value={this.props.totalRegister}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={""}
            />

          </div>
        </>
      ),
      rowSelection: false, // {} or false
      scroll: undefined,
      searchFilter: "",
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequestForm"
      },
      timeZone: "America/Santiago"
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      dataList: nextProps.dataList,
      sumDataList: _.sumBy(nextProps.dataList, item => Number(item.quantity))
    });
  }

  filter = searchValue => {

    if (searchValue === "") {
      this.setState({
        dataList: this.props.dataList,
        sumDataList: _.sumBy(this.props.dataList, item => Number(item.quantity))
      });
    } else {
      try {
        const filter = _.filter(
          this.props.dataList,
          cashin =>
            (cashin.name
              ? cashin.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cashin.email
              ? cashin.email.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cashin.rut
              ? cashin.rut.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            // cashin.operationCode.toString().indexOf(searchValue) > -1 ||
            (cashin.quantity ? cashin.quantity.toString().indexOf(searchValue) > -1
              : "") ||
            (cashin.commerceReqId ? cashin.commerceReqId
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1 : "") ||
            (cashin.cashinId ? cashin.cashinId.toLowerCase().indexOf(searchValue.toLowerCase()) >
              -1 : "") ||
            (cashin.operationCode
              ? cashin.operationCode
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cashin.prepaidCode
              ? cashin.prepaidCode
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
            (cashin.adminCallBack
              ? cashin.adminCallBack
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
              : "") ||
              (cashin.method
                ? cashin.method
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) > -1
                : "")
                ||
              (cashin.nameCommerce
                ? cashin.nameCommerce
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) > -1
                : "")
              /*   (cashin.nameCommerce
                  ? cashin.nameCommerce
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) > -1
                  : "") */
        );
        this.setState({
          dataList: filter,
          sumDataList: _.sumBy(filter, item => Number(item.quantity))
        });
      } catch {
        console.log("Error en el  filtro ");
      }
    }
  };

  updateSearchFilter(evt) {
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }



  onTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  onTimeZoneChange = zone => {
    console.log("zone", "=>", zone);
    this.setState({
      timeZone: zone
    });
  };

  dateFormat = (data) => {
    if (data.dateRequest != "") {
      return moment(new Date(data.dateRequest._seconds * 1000).toISOString()).toDate().toLocaleString("es-CL", {
        timeZone: this.state.timeZone,
        timeZoneName: "short"
      })
    }
    else {
      return ""
    }
  }



  render() {

    const incrementNumber = (e) => {
      return e;

      /*    let i = from - 1; */
      //return (index) /* => {
      //if (pagination.current === 1) {
      //    return index++;
      // }
      /*  return i = i + 1; */
      /*  }; */
    };

    const fromCurrentIndex = incrementNumber();

    this.columns = [
      /*  {
         title: "",
         dataIndex: "indexTemp",
         key: "indexTemp",
         width: 50,
 
       }, */
      {
        title: "Date Request",
        dataIndex: "dateRequestForm",
        key: "dateRequestForm",
        width: 200,
        render:dateRequestForm  => (
          <span>{moment(new Date(dateRequestForm._seconds * 1000).toISOString()).toDate().toLocaleString("es-CL", {
            timeZone: this.state.timeZone,
            timeZoneName: "short"
          })}</span>
        ),
        sorter: (a, b) => a.dateRequestForm._seconds - b.dateRequestForm._seconds,
        sortOrder:
          this.state.sortedInfo.columnKey === "dateRequestForm" &&
          this.state.sortedInfo.order
      },
      {
        title: "Zippy ID ",
        dataIndex: "id",
        key: "id",
        width: 100
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 150,
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder:
          this.state.sortedInfo.columnKey === "name" && this.state.sortedInfo.order,
        /*   width: 100 */
        render: name => entities.decode(name)
      },
      {
        title: "RUT",
        dataIndex: "rutUser",
        key: "rutUser",
        width: 150,
        sorter: (a, b) => a.rutUser.length - b.rutUser.length,
        sortOrder:
          this.state.sortedInfo.columnKey === "rutUser" && this.state.sortedInfo.order,
        /*   width: 100 */
        render: rutUser => formatRut(rutUser)
      },
      {
        title: "Amount",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        sorter: (a, b) => a.quantity - b.quantity,
        sortOrder:
          this.state.sortedInfo.columnKey === "quantity" && this.state.sortedInfo.order,
        render: text => (
          <div style={{ float: "right" }}>
            {/*     {currencyNumberFormat(text,this.props.country)} */}
            {this.props.country !== "" ? currencyNumberFormat(text, this.props.country) : currencyNumberFormat(text, "CL")}
          </div>
        )
      },
      {
        title: `Status `,
        dataIndex: "code",
        key: "code",
        sorter: (a, b) => a.code - b.code,
        sortOrder:
          this.state.sortedInfo.columnKey === "code" && this.state.sortedInfo.order,
        render: (text, record) => (
          <div style={{ float: "none" }}>
            <span>
            
             
              {String(text) === "0" ? <Tooltip title="Validated"><Badge status="success" /></Tooltip> : String(text) === "9" ? <Tooltip title="Pending"><Badge status="warning" /></Tooltip>: String(text) === "1" ? <Tooltip title="Visitor"><Badge status="processing" /></Tooltip> :<Tooltip title="Failed"><Badge status="error" /></Tooltip>}
            </span>
          </div>

        )
      },
      {
        title: `Method Pay `,
        dataIndex: "method",
        key: "method",
        sorter: (a, b) => a.method.length - b.method.length,
        sortOrder:
          this.state.sortedInfo.columnKey === "method" && this.state.sortedInfo.order,
      },
      {
        title: `payMethod `,
        dataIndex: "payMethod",
        key: "payMethod",
        sorter: (a, b) => a.method.length - b.method.length,
        sortOrder:
          this.state.sortedInfo.columnKey === "payMethod" && this.state.sortedInfo.order,
      },
      {
        title: `Merchant `,
        dataIndex: "nameCommerce",
        key: "nameCommerce",
        sorter: (a, b) => a.nameCommerce.length - b.nameCommerce.length,
        sortOrder:
          this.state.sortedInfo.columnKey === "nameCommerce" && this.state.sortedInfo.order,
        /*   width: 100 */
      },
      {
        title: `ID Merchant `,
        dataIndex: "commerceReqId",
        key: "commerceReqId",
        sorter: (a, b) => a.commerceReqId.length - b.commerceReqId.length,
        sortOrder:
          this.state.sortedInfo.columnKey === "commerceReqId" && this.state.sortedInfo.order,
        /*   width: 100 */
      },
    ];


    return (
      <Card
        title={this.props.title}
        extra={
          <Auxiliary>

            TimeZone: {this.state.timeZone}{" "}
            <i
              className={`flag flag-24 gx-mr-2 flag-ru`}
              onClick={this.onTimeZoneChange.bind(this, "Europe/Moscow")}
            ></i>
            <i
              className={`flag flag-24 gx-mr-2 flag-cl`}
              onClick={this.onTimeZoneChange.bind(this, "America/Santiago")}
            ></i>
            <i
              className={`flag flag-24 gx-mr-2 flag-pe`}
              onClick={this.onTimeZoneChange.bind(this, "America/Lima")}
            ></i>


          </Auxiliary>
        }
      >
        <div className="components-table-demo-control-bar">
          <Row>

            <Col xl={15} lg={15} md={15} sm={24} xs={24} className="gx-pr-md-2">
            </Col>
            <Col xl={9} lg={9} md={9} sm={24} xs={24} className="gx-pr-md-2">
              <Input
                placeholder="Search...."
                onChange={this.updateSearchFilter.bind(this)}
              ></Input>
            </Col>
          </Row>
        </div>

        <Table
          className="gx-table-responsive"
          {...this.state}
          columns={this.columns}
          dataSource={this.state.dataList}
          onChange={this.onTableChange}
          rowKey="cashinId"
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50", "100"]
          }}
        />
      </Card>
    );
  }
}

export default Dynamic;
