import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const COLORS = ["#5797fc", "#FA8C16", "#f5222d", "#d9d9d9","#33FF8A","#FCFF33","#F333FF"];

const TaskByStatus = props => {
  const data = [
    { name: "Webpay", value: props.numWebpay },
    { name: "CashIn", value: props.numCashin },
    { name: "CashOut", value: props.numCashout },

    { name: "BankTransfer", value: props.numBankTransfer },
    { name: "BankCard", value: props.numBankCard },
    { name: "Cash", value: props.numBankCash },
    { name: "PayOut", value: props.numBankPayout },

    { name: "SkinBacks", value: props.numSkinBacks },
  ];
  return (
    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
      <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">
        Deposits vs Withdrawals 
      </h6>
      <div className="gx-py-3">
        <ResponsiveContainer width="100%" height={150}>
          <PieChart>
            <Tooltip />
            <text
              x="50%"
              className="h1"
              y="50%"
              textAnchor="middle"
              dominantBaseline="middle"
              children=""
            >
              {props.total}
            </text>
            <Pie
              data={data}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={47}
              outerRadius={57}
              fill="#8884d8"
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
export default TaskByStatus;
