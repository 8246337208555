import { auth } from "../firebase/firebase";
import { stringify } from "query-string";

async function callApiDolar(endpoint, options = {}) {
  const url = endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callApiProxy(endpoint, options = {}) {
  // await simulateNetworkLatency();

  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json"
    //Authorization: "Bearer " + token
  };

  let host = process.env.REACT_APP_PAYMENTS_API_URL;
  const url = host + endpoint;
  console.log("URL callApiProxy", url);
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callApiDashboard(token, endpoint, options = {}) {
  let token1;
  try {
    token1 = await auth.currentUser.getIdToken();
  } catch (error) {
    console.log("getIdToken ", error);
  }

  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    /*   mode: 'no-cors', */
    zippyuser: token1
  };

  let host = process.env.REACT_APP_DASHBOARD_API_URL;

  // let host ="http://localhost:8800/";

  const url = host + endpoint;
  console.log("URL callapidashboard", url);
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

async function callApiCartola(endpoint, options = {}) {
  /*   options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",

  }; */
  let host = "https://api-consolidated-bank-i53arnhoba-uc.a.run.app/bchile/";
  const url = host + endpoint;
  console.log("url", url);
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.status !== 200) {
    data.status = response.status;
  }
  return data;
}

const api = {
  utils: {
    /*     getDataForIdZippy(payload){
      return callApiDashboard('', "report/getDataForIdZippy", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },
 */
    async getSelfManagement(payroll_id) {
      return await callApiDashboard(
        "",
        `payroll/self-management/${payroll_id}`,
        {
          method: "GET"
        }
      );
    },
    async selfManagementConfirmated(payroll_id, params) {
      console.log("erga!");
      return await callApiDashboard(
        "",
        `payroll/self-management/${payroll_id}`,
        {
          method: "POST",
          body: JSON.stringify(params)
        }
      );
    },
    async getStateCashoutV2() {
      return await callApiDashboard("", `payroll/status-cashout-v2`, {
        method: "GET"
      });
    },
    async getMerchantsAdministration() {
      return await callApiDashboard("", `payroll/merchants-administration`, {
        method: "GET"
      });
    },
    async payrollCreation(params) {
      return await callApiDashboard("", `payroll/payrollCreation`, {
        method: "POST",
        body: JSON.stringify(params)
      });
    },
    async setValidateManual(params) {
      return await callApiDashboard("", `payroll/validate-manual`, {
        method: "POST",
        body: JSON.stringify(params)
      });
    },
    async setStatusChangesCashoutV2(params) {
      return await callApiDashboard("", `payroll/set-status-cashout-v2`, {
        method: "POST",
        body: JSON.stringify(params)
      });
    },
    async updateParametrosGenerales(params) {
      return await callApiDashboard("", `payroll/update-parametros-generales`, {
        method: "PUT",
        body: JSON.stringify(params)
      });
    },
    async setUndo(params) {
      return await callApiDashboard("", `payroll/undo`, {
        method: "POST",
        body: JSON.stringify(params)
      });
    },
    async setUndoV2(params) {
      return await callApiDashboard("", `payroll/undo-v2`, {
        method: "POST",
        body: JSON.stringify(params)
      });
    },
    async updateRetries(params) {
      return await callApiDashboard("", `payroll/update-retries`, {
        method: "POST",
        body: JSON.stringify(params)
      });
    },
    async getSteps(params) {
      return await callApiDashboard("", `payroll/steps?${stringify(params)}`, {
        method: "GET"
      });
    },
    async getReportPayrolls(params) {
      return await callApiDashboard("", `payroll/report?${stringify(params)}`, {
        method: "GET"
      });
    },
    async getDetailUndo() {
      return await callApiDashboard("", `payroll/detail-undo`, {
        method: "GET"
      });
    },
    async findById(params) {
      return await callApiDashboard("", `payroll/detail?${stringify(params)}`, {
        method: "GET"
      });
    },
    async findAllPaginated(params) {
      return await callApiDashboard(
        "",
        `payroll/find-all-paginated?${stringify(params)}`,
        {
          method: "GET"
        }
      );
    },
    async payrollLoad(params) {
      return await callApiDashboard("", "payroll/load", {
        method: "POST",
        body: JSON.stringify(params)
      });
    },
    merchantCountries(parametros) {
      return callApiDashboard(parametros.token, "merchants/merchantCountry", {
        method: "GET"
      });
    },
    burnCode(parametros) {
      return callApiProxy("burnOperationCode", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
    },

    async getEffectivenessByCommerce(payload) {
      return await callApiDashboard(
        "",
        "comparison/getEffectivenessByCommerce",
        {
          method: "POST",
          body: JSON.stringify(payload)
        }
      );
    },

    async getAllEffectivenessByCommerce(payload) {
      return await callApiDashboard(
        "",
        "comparison/getAllEffectivenessByCommerce",
        {
          method: "POST",
          body: JSON.stringify(payload)
        }
      );
    },

    async superSearchZippy(payload) {
      return await callApiDashboard("", "report/superSearchZippy/", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },

    async getDataForIdZippy(id) {
      let respuesta = await callApiDashboard(
        "",
        "report/getDataForIdZippy/" + id,
        {
          method: "GET"
        }
      );
      return respuesta;
    },
    async getCountries() {
      let respuesta = await callApiDashboard("", "merchants/getCountries", {
        method: "GET"
      });
      return respuesta;
    },
    async getDataForIdZippyV2(id) {
      let respuesta = await callApiDashboard(
        "",
        "report/getDataForIdZippyV2/" + id,
        {
          method: "GET"
        }
      );
      return respuesta;
    },
    async getDataTransaction(id, collection) {
      let respuesta = await callApiDashboard(
        "",
        `report/getDataTransaction/${id}/${collection}`,
        {
          method: "GET"
        }
      );
      return respuesta;
    },
    /*
    async getDataForCommerceReqId(id){
      let respuesta = await callApiDashboard('', "report/getDataForCommerceReqId/" + id, {
        method: "GET"
      });
      return respuesta
    }, */

    async getPowerProduct(id) {
      let respuesta = await callApiDashboard(
        "",
        "dashboard/getPowerProduct/" + id,
        {
          method: "GET"
        }
      );
      return respuesta;
    },

    updatePowerProduct(payload) {
      return callApiDashboard(payload.token, "dashboard/updatePowerProduct", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },

    //test excel desde node

    getExcelNode(payload) {
      return callApiDashboard("", "report/excel", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },

    getMultiQueryDate(payload) {
      return callApiDashboard("", "report/resumen", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },

    getAssessment(payload){
      return callApiDashboard("", "report/getAsessmments", {
        method: "POST",
        body: JSON.stringify(payload)
      })
    },

    getDocumentDate(payload) {
      return callApiDashboard("", "report/productReport", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },

    getInfoDashboard(payload) {
      return callApiDashboard("", "dashboard/getBalance", {
        method: "POST",
        // mode: 'cors', // no-cors, *cors, same-origin
        body: JSON.stringify(payload)
      });
    },

    getOccupationalTraffic() {
      return callApiDashboard("", "dashboard/getOccupationalTraffic", {
        method: "GET"
      });
    },

    async gettingPendFail2(payload) {
      let respuesta = await callApiDashboard(
        "",
        "dashboard/gettingPendFail/" + payload.merchant,
        {
          method: "GET"
        }
      );
      return respuesta;
    },

    async gettingPendFail(payload) {
      let respuesta = await callApiDashboard("", "dashboard/gettingPendFail", {
        method: "POST",
        body: JSON.stringify(payload)
      });
      return respuesta;
    },

    indicadores(parametros) {
      return callApiDolar(
        `${process.env.REACT_APP_PAYMENTS_API_URL}indicadores`,
        {
          method: "GET",
          body: JSON.stringify(parametros)
        }
      );
    },
    callback(parametros) {
      return callApiProxy("callbackManual", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
    },
    validateManual(parametros) {
      return callApiProxy("validateManual", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
    },
    getCommission(parametros) {
      return callApiDashboard(
        parametros.token,
        "commissions/commissionsMerCo",
        {
          method: "GET"
        }
      );
    },
    getCartola() {
      return callApiDashboard("", "cartola/cartola", {
        method: "GET"
      });
    },
    getCartolaWithParams(parametros) {
      return callApiDashboard("", "cartola/cartolaBE", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
    },
    getCartolaBCWithParams(parametros) {
      return callApiDashboard("", "cartola/cartolaBCParams", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
    },
    getDetalleCartola() {
      return callApiDashboard("", "cartola/DetalleCartola", {
        method: "GET"
      });
    },
    getDetalleCartolaBC() {
      return callApiDashboard("", "cartola/DetalleCartolaBC", {
        method: "GET"
      });
    },
    getCartolaBancoChile() {
      return callApiDashboard("", "cartola/cartolaBC", {
        method: "GET"
      });
    },
    async getTransfersForParamsOK(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "transfers/transfersforParamsOk",
        {
          method: "POST",
          body: JSON.stringify(parametros)
        }
      );
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getTransfersOKInfo(parametros) {
      let respuesta = await callApiDashboard("", "transfers/transfersOkInfo", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      return respuesta;
    },
    async getTransfersForParamsPending(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "transfers/transfersforParamsPending",
        {
          method: "POST",
          body: JSON.stringify(parametros)
        }
      );
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getTransfersForParamsFailed(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "transfers/transfersforParamsFailed",
        {
          method: "POST",
          body: JSON.stringify(parametros)
        }
      );
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getUpdateQueryWebPay(id) {
      let respuesta = await callApiDashboard(
        "",
        "webpay/transactionswebpay/" + id,
        {
          method: "GET"
        }
      );
      return respuesta;
    },
    async updateTransactionWebPay(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "webpay/updateWebPay/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    },
    async deleteAdminApprovalWebPay(id) {
      let respuesta = await callApiDashboard(
        "",
        "webpay/adminapprovalwebpay/" + id,
        {
          method: "DELETE"
        }
      );
      return respuesta;
    },
    async callbackWebpay(parametros) {
      console.log(parametros);
      let respuesta = await callApiProxy("callbackmanualewebpay", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      return respuesta;
    },
    async getWebPayforParamsOk(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "webpay/getWebPayforParamsOk",
        {
          method: "POST",
          body: JSON.stringify(parametros)
        }
      );
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getAllWebpayMethodOK(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "webpay/getAllWebpayMethodOK",
        {
          method: "POST",
          body: JSON.stringify(parametros)
        }
      );
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },

    //**************************************agregadores ******************************************* */
    //**************************************skinsBack ******************************************* */

    async getSkinsBackForParamsOK(parametros) {
      let respuesta = await callApiDashboard("", "skins/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getSkinsBackOKInfo(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "banktransfer/payBankTransfersOkInfo",
        {
          method: "POST",
          body: JSON.stringify(parametros)
        }
      );
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getSkinsBackForParamsPending(parametros) {
      let respuesta = await callApiDashboard("", "skins/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getSkinsBackForParamsFailed(parametros) {
      let respuesta = await callApiDashboard("", "skins/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getAdminApprovalSkinsBack(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "banktransfer/getAdminApproval",
        {
          method: "POST",
          body: JSON.stringify(parametros)
        }
      );
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async updateAdminApprovalSkinsBack(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "banktransfer/updateTransaction/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    },

    //**************************************skinsBack ******************************************* */
    //**************************************Kushki ******************************************* */

    async getKushkiForParamsOK(parametros) {
      let respuesta = await callApiDashboard("", "kushki/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getKushkiForParamsFailed(parametros) {
      let respuesta = await callApiDashboard("", "kushki/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getKushkiBackForParamsPending(parametros) {
      let respuesta = await callApiDashboard("", "kushki/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },

    /* callback*/
    async CallbackKushki(parametros) {
      console.log("CallbackKushki", parametros);
      let respuesta = "";

      respuesta = await callApiProxy("callbackManualKsh", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      console.log("callbackManualKsh respuesta", respuesta);
      return respuesta;
    },
    async getAdminApprovalKushki(parametros) {
      let respuesta = await callApiDashboard("", "kushki/getAdminApproval", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async updateAdminApprovalKushki(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "kushki/updateTransaction/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    },

    //**************************************AIRTM ******************************************* */

    async getAirtmForParamsOK(parametros) {
      let respuesta = await callApiDashboard("", "airtm/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getAirtmForParamsFailed(parametros) {
      let respuesta = await callApiDashboard("", "airtm/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getAirtmBackForParamsPending(parametros) {
      let respuesta = await callApiDashboard("", "airtm/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },

    /* callback*/
    async CallbackAirtm(parametros) {
      let respuesta = "";

      respuesta = await callApiProxy("callbackManualAtm", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      console.log("CallbackAirtm respuesta", respuesta);
      return respuesta;
    },

    async getAdminApprovalAirtm(parametros) {
      let respuesta = await callApiDashboard("", "airtm/getAdminApproval", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async updateAdminApprovalAirtm(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "airtm/updateTransaction/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    },
    async getOperationairtm(parametros) {
      let respuesta = "";

      respuesta = await callApiProxy(
        `atm/getOperation?type=payIn&zippyId=${parametros.zippyId}`,
        {
          method: "GET"
          /*   body: JSON.stringify(parametros) */
        }
      );
      console.log("getOperationairtm respuesta", respuesta);
      return respuesta;
    },
    //**************************************AIRTM PAYOUT ******************************************* */
    async getAirtmPayOutForParamsOK(parametros) {
      let respuesta = await callApiDashboard("", "airtmPayOut/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getAirtmPayOutForParamsFailed(parametros) {
      let respuesta = await callApiDashboard("", "airtmPayOut/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getAirtmPayOutForParamsPending(parametros) {
      let respuesta = await callApiDashboard("", "airtmPayOut/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },

    async getAdminApprovalAirtmPayOut(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "airtmPayOut/getAdminApproval",
        {
          method: "POST",
          body: JSON.stringify(parametros)
        }
      );
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async updateAdminApprovalAirtmPayOut(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "airtmPayOut/updateTransaction/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    },

    async getOperationairtmPayOut(parametros) {
      let respuesta = "";

      respuesta = await callApiProxy(
        `atm/getOperation?zippyId=${parametros.zippyId}`,
        {
          method: "GET"
          /*   body: JSON.stringify(parametros) */
        }
      );
      console.log("getOperationairtmPayOut respuesta", respuesta);
      return respuesta;
    },
    async getOperationMonnetPayOut(parametros) {
      let respuesta = "";

      respuesta = await callApiProxy(
        `mnt/getPayroll?zippyId=${parametros.zippyId}`,
        {
          method: "GET"
          /*   body: JSON.stringify(parametros) */
        }
      );
      console.log("getOperationairtmPayOut respuesta", respuesta);
      return respuesta;
    },
    /* callback payout generico*/
    async CallbackPayOut(parametros) {
      let respuesta = "";

      respuesta = await callApiProxy("callbackManualPayOutGeneric", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      console.log("callbackManualPayOutGeneric respuesta", respuesta);
      return respuesta;
    },

    //**************************************PAYIN ******************************************* */
    /* CallbackPayIn */
    async CallbackPayIn(parametros, payMethod) {
      console.log("CallbackPayIn", parametros, payMethod);
      let respuesta = "";
      switch (payMethod) {
        case "alpss":
          respuesta = await callApiProxy("callbackManualAlps", {
            method: "POST",
            body: JSON.stringify(parametros)
          });
          console.log("alps", respuesta);
          return respuesta;

        case "monnets":
          respuesta = await callApiProxy("callbackManualMnt", {
            method: "POST",
            body: JSON.stringify(parametros)
          });
          console.log("monnet", respuesta);
          return respuesta;
        case "safetypays":
          respuesta = await callApiProxy("callbackManualSpay", {
            method: "POST",
            body: JSON.stringify(parametros)
          });
          console.log("safetypay", respuesta);
          return respuesta;
        case "kushkis":
          respuesta = await callApiProxy("callbackManualKsh", {
            method: "POST",
            body: JSON.stringify(parametros)
          });
          console.log("kushkis", respuesta);
          return respuesta;
        case "pagoEfectivos":
          respuesta = await callApiProxy("callbackManualPagoEfectivo", {
            method: "POST",
            body: JSON.stringify(parametros)
          });
          console.log("pagoEfectivos", respuesta);
          return respuesta;
        case "pagsmiles":
          respuesta = await callApiProxy("callbackManualPsm", {
            method: "POST",
            body: JSON.stringify(parametros)
          });
          console.log("pagsmiles", respuesta);
          return respuesta;
      }
      return respuesta;
    },
    /*fin callback*/

    //BankTransfer

    async getBankTransfersForParamsOK(parametros) {
      let respuesta = await callApiDashboard("", "banktransfer/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getTransfersOKInfo(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "banktransfer/payBankTransfersOkInfo",
        {
          method: "POST",
          body: JSON.stringify(parametros)
        }
      );
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getBankTransfersForParamsPending(parametros) {
      let respuesta = await callApiDashboard("", "banktransfer/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getBankTransfersForParamsFailed(parametros) {
      let respuesta = await callApiDashboard("", "banktransfer/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getAdminApprovalBankTransfer(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "banktransfer/getAdminApproval",
        {
          method: "POST",
          body: JSON.stringify(parametros)
        }
      );
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async updateAdminApprovalBankTransfers(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "banktransfer/updateTransaction/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    },

    //Bancard
    async getBankCardForParamsOK(parametros) {
      let respuesta = await callApiDashboard("", "bankcard/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getBankCardForParamsPending(parametros) {
      let respuesta = await callApiDashboard("", "bankcard/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getBankCardForParamsFailed(parametros) {
      let respuesta = await callApiDashboard("", "bankcard/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },

    async getAdminApprovalBankCard(parametros) {
      let respuesta = await callApiDashboard("", "bankcard/getAdminApproval", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async updateAdminApprovalBankCard(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "bankcard/updateTransaction/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    },

    //cash
    async getCashForParamsOK(parametros) {
      let respuesta = await callApiDashboard("", "cash/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getCashForParamsPending(parametros) {
      let respuesta = await callApiDashboard("", "cash/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getCashForParamsFailed(parametros) {
      let respuesta = await callApiDashboard("", "cash/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },

    async getAdminApprovalCash(parametros) {
      let respuesta = await callApiDashboard("", "cash/getAdminApproval", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async updateAdminApprovalCash(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "cash/updateTransaction/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    },

      //MACH
      async getMachForParamsOK(parametros) {
        let respuesta = await callApiDashboard("", "mach/getBalance", {
          method: "POST",
          body: JSON.stringify(parametros)
        });
        if (respuesta.status === 404) {
          return [];
        } else {
          return respuesta;
        }
      },
      async getMachForParamsPending(parametros) {
        let respuesta = await callApiDashboard("", "mach/getBalance", {
          method: "POST",
          body: JSON.stringify(parametros)
        });
        if (respuesta.status === 404) {
          return [];
        } else {
          return respuesta;
        }
      },
      async getMachForParamsFailed(parametros) {
        let respuesta = await callApiDashboard("", "mach/getBalance", {
          method: "POST",
          body: JSON.stringify(parametros)
        });
        if (respuesta.status === 404) {
          return [];
        } else {
          return respuesta;
        }
      },
  
      async getAdminApprovalMach(parametros) {
        let respuesta = await callApiDashboard("", "mach/getAdminApproval", {
          method: "POST",
          body: JSON.stringify(parametros)
        });
        if (respuesta.status === 404) {
          return [];
        } else {
          return respuesta;
        }
      },
      async updateAdminApprovalMach(parametros) {
        let respuesta = await callApiDashboard(
          "",
          "mach/updateTransaction/" + parametros.id,
          {
            method: "PUT",
            body: JSON.stringify(parametros)
          }
        );
        return respuesta;
      },


       //COINSPAID
       async getCoinspaidForParamsOK(parametros) {
        let respuesta = await callApiDashboard("", "coinspaid/getBalance", {
          method: "POST",
          body: JSON.stringify(parametros)
        });
        if (respuesta.status === 404) {
          return [];
        } else {
          return respuesta;
        }
      },
      async getCoinspaidForParamsPending(parametros) {
        let respuesta = await callApiDashboard("", "coinspaid/getBalance", {
          method: "POST",
          body: JSON.stringify(parametros)
        });
        if (respuesta.status === 404) {
          return [];
        } else {
          return respuesta;
        }
      },
      async getCoinspaidForParamsFailed(parametros) {
        let respuesta = await callApiDashboard("", "coinspaid/getBalance", {
          method: "POST",
          body: JSON.stringify(parametros)
        });
        if (respuesta.status === 404) {
          return [];
        } else {
          return respuesta;
        }
      },
  
      async getAdminApprovalCoinspaid(parametros) {
        let respuesta = await callApiDashboard("", "coinspaid/getAdminApproval", {
          method: "POST",
          body: JSON.stringify(parametros)
        });
        if (respuesta.status === 404) {
          return [];
        } else {
          return respuesta;
        }
      },
      async updateAdminApprovalCoinspaid(parametros) {
        let respuesta = await callApiDashboard(
          "",
          "coinspaid/updateTransaction/" + parametros.id,
          {
            method: "PUT",
            body: JSON.stringify(parametros)
          }
        );
        return respuesta;
      },

       //FRI
       async getFriForParamsOK(parametros) {
        let respuesta = await callApiDashboard("", "fri/getBalance", {
          method: "POST",
          body: JSON.stringify(parametros)
        });
        if (respuesta.status === 404) {
          return [];
        } else {
          return respuesta;
        }
      },
      async getFriForParamsPending(parametros) {
        let respuesta = await callApiDashboard("", "fri/getBalance", {
          method: "POST",
          body: JSON.stringify(parametros)
        });
        if (respuesta.status === 404) {
          return [];
        } else {
          return respuesta;
        }
      },
      async getFriForParamsFailed(parametros) {
        let respuesta = await callApiDashboard("", "fri/getBalance", {
          method: "POST",
          body: JSON.stringify(parametros)
        });
        if (respuesta.status === 404) {
          return [];
        } else {
          return respuesta;
        }
      },
  
      async getAdminApprovalFri(parametros) {
        let respuesta = await callApiDashboard("", "fri/getAdminApproval", {
          method: "POST",
          body: JSON.stringify(parametros)
        });
        if (respuesta.status === 404) {
          return [];
        } else {
          return respuesta;
        }
      },
      async updateAdminApprovalFri(parametros) {
        let respuesta = await callApiDashboard(
          "",
          "fri/updateTransaction/" + parametros.id,
          {
            method: "PUT",
            body: JSON.stringify(parametros)
          }
        );
        return respuesta;
      },



    //PayOUT
    async getPayOutForParamsOK(parametros) {
      let respuesta = await callApiDashboard("", "payOut/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getPayOutOKInfo(parametros) {
      let respuesta = await callApiDashboard("", "payOut/payOutOkInfo", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getPayOutForParamsPending(parametros) {
      let respuesta = await callApiDashboard("", "payOut/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async getPayOutForParamsFailed(parametros) {
      let respuesta = await callApiDashboard("", "payOut/getBalance", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },

    //**************************************flow ******************************************* */
    async getUpdateQueryFlow(id) {
      let respuesta = await callApiDashboard(
        "",
        "flow/transactionsflow/" + id,
        {
          method: "GET"
        }
      );
      return respuesta;
    },
    async updateTransactionFlow(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "flow/updateflow/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    },
    async deleteAdminApprovalFlow(id) {
      let respuesta = await callApiDashboard(
        "",
        "flow/adminapprovalflow/" + id,
        {
          method: "DELETE"
        }
      );
      return respuesta;
    },
    async callbackFlow(parametros) {
      console.log(parametros);
      let respuesta = await callApiProxy("callbackManualFlow", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      return respuesta;
    },
    async getFlowforParamsOk(parametros) {
      let respuesta = await callApiDashboard("", "flow/getflowforParamsOk", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },

    //**************************************payku ******************************************* */
    async getUpdateQueryPayku(id) {
      let respuesta = await callApiDashboard(
        "",
        "payku/transactionsPayku/" + id,
        {
          method: "GET"
        }
      );
      return respuesta;
    },
    async updateTransactionPayku(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "payku/updatePayku/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    },
    async deleteAdminApprovalPayku(id) {
      let respuesta = await callApiDashboard(
        "",
        "payku/adminapprovalPayku/" + id,
        {
          method: "DELETE"
        }
      );
      return respuesta;
    },

    async getAdminApprovalPayku(parametros) {
      let respuesta = await callApiDashboard("", "payku/getAdminApproval", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async updateAdminApprovalPayku(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "payku/updateTransaction/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    },

    async callbackPayku(parametros) {
      console.log(parametros);
      let respuesta = await callApiProxy("callbackManualPaykuV2", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      return respuesta;
    },
    async getPaykuforParamsOk(parametros) {
      let respuesta = await callApiDashboard("", "payku/getPaykuforParamsOk", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },

    callback(parametros) {
      return callApiProxy("callbackManual", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
    },
    //**************************************pago efectivo *******************************************  */
    async callbackPagoEfectivo(parametros) {
      console.log(parametros);
      let respuesta = await callApiProxy("callbackManualPagoEfectivo", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      return respuesta;
    },
    async getUpdateQueryPagoEfectivo(id) {
      let respuesta = await callApiDashboard(
        "",
        "pagoEfectivo/transactionsPagoEfectivo/" + id,
        {
          method: "GET"
        }
      );
      return respuesta;
    },
    async updateAdminApprovalPagoEfectivo(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "pagoEfectivo/updateTransaction/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    },
    async getAdminApprovalPagoEfectivos(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "pagoEfectivo/getAdminApproval",
        {
          method: "POST",
          body: JSON.stringify(parametros)
        }
      );
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    },
    async callbackManualPagoEfectivo(parametros) {
      console.log(parametros);
      let respuesta = await callApiProxy("callbackManualPagoEfectivo", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
      return respuesta;
    }

    /* async callbackFlow(parametros) {
  console.log(parametros)
  let respuesta = await callApiDashboard('', "flow/callbackManual", {
    method: "POST",
    body: JSON.stringify(parametros)
  });
  return respuesta
}, */
  },
  cashout: {
    getStatusActiveBot() {
      return callApiDashboard("", "cashout/getStatusActiveBot", {
        method: "GET"
      });
    },
    changeActiveBot(parametros) {
      console.log("parametros change", parametros);
      return callApiDashboard("", "cashout/changeActiveBot", {
        method: "PATCH",
        body: JSON.stringify(parametros)
      });
    },
    getParamCashout(parametros) {
      return callApiProxy("cashout/getCashoutParams", {
        method: "GET",
        body: JSON.stringify(parametros)
      });
    },
    validate(parametros) {
      return callApiProxy("cashout/validate", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
    },
    preparar(parametros) {
      return callApiProxy("cashout/preparar", {
        method: "POST",
        body: JSON.stringify(parametros)
      });
    },
    async getCashoutParamsOk(parametros) {
      let respuesta = await callApiDashboard(
        "",
        "cashout/getCashoutforParamsOk",
        {
          method: "POST",
          body: JSON.stringify(parametros)
        }
      );
      if (respuesta.status === 404) {
        return [];
      } else {
        return respuesta;
      }
    }
  },
  user: {
    dataUser(parametros) {
      return callApiDashboard(
        parametros.token,
        `users/dataUser?emailUser=${parametros.emailUser}`,
        {
          method: "GET"
        }
      );
    },
    typeUser(parametros) {
      return callApiDashboard(
        parametros.token,
        `users/typeUser?typeUser=${parametros.typeUser}`,
        {
          method: "GET"
        }
      );
    },
    async createUser(payload, token) {
      return callApiDashboard(token, "users/createUser", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },
    /*
    async  updateUser(payload,token){
      return callApiDashboard(token, "users/updateUser", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    }, */

    async deleteUser(id) {
      let respuesta = await callApiDashboard("", "users/deleteUser/" + id, {
        method: "DELETE"
      });
      return respuesta;
    },
    async updateUser(parametros, token) {
      let respuesta = await callApiDashboard(
        "",
        "users/updateUser/" + parametros.id,
        {
          method: "PUT",
          body: JSON.stringify(parametros)
        }
      );
      return respuesta;
    }
  },
  merchant: {
    async getRetentionErlyPayment(payload) {
      return await callApiDashboard("", "merchants/getRetentionErlyPayment/", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },
    async editRetentionErlyPayment(payload) {
      return await callApiDashboard("", "merchants/editRetentionErlyPayment/", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },
    async addMerchant(payload) {
      return await callApiDashboard("", "merchants/addMerchants/", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },
    async getInformationCommmerces(payload) {
      console.log("PAYLOAD", payload);
      return await callApiDashboard("", "merchants/getInformationCommmerces/", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },
    async updateMerchant(payload) {
      return await callApiDashboard("", "merchants/updateMerchants/", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },
    async getPaymentsByMerchants(payload) {
      return await callApiDashboard("", "merchants/getPaymentsByMerchants/", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },
    async getMethodsPayByMerchants(payload) {
      return await callApiDashboard("", "merchants/getMethodsPayByMerchants/", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },
    async getCommissionsByMerchants(payload) {
      return await callApiDashboard(
        "",
        "merchants/getCommissionsByMerchants/",
        {
          method: "POST",
          body: JSON.stringify(payload)
        }
      );
    },
    async getPayoutsByMerchants(payload) {
      return await callApiDashboard("", "merchants/getPayoutsByMerchants/", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    },
    async getDeleteByMerchants(payload) {
      return await callApiDashboard("", "merchants/deleteByMerchants/", {
        method: "POST",
        body: JSON.stringify(payload)
      });
    }
  },
  cartola: {
    async getCarto(parametros) {
      /* console.log("parametros",parametros); */
      let respuesta = await callApiCartola(
        `getConsolidation?starDay=${parametros.starDayC}&endDay=${parametros.endDayC}`,
        {
          method: "GET"
        }
      );

      return respuesta;
    }
  },
  balances: {
    async getBalanceMnt1(parametros) {
      /* console.log("parametros",parametros); */
      let respuesta = await callApiCartola(`mnt/getBalance`, {
        method: "GET"
      });

      return respuesta;
    },

    getBalanceMnt() {
      return callApiProxy("mnt/getBalance", {
        method: "GET"
      });
    },
    getBalanceAtm() {
      return callApiProxy("atm/getBalance", {
        method: "GET"
      });
    }
  }
};
export default api;
