import React, { Component } from "react";
import { firebase, auth } from "../../../firebase/firebase";
import { connect } from "react-redux";
import moment from "moment-timezone";
import Widget from "components/Widget/index";
import {
  Switch,
  Badge,
  Popconfirm,
  Button,
  Row,
  Col,
  Form,
  Input,
  Alert,
  Collapse
} from "antd";
import { switchMerchant } from "../../../appRedux/actions/Setting";
import { withRouter } from "react-router-dom";
import API from "../../../util/api";
import Lottie from "react-lottie";
import offline from "../../../assets/animation/offline.json";
import online from "../../../assets/animation/online.json";
import unlocking from "../../../assets/animation/unlocking.json";
import * as ExcelJS from 'exceljs';

const db = firebase.firestore();
const FormItem = Form.Item;
const { Panel } = Collapse;
class SemaphoneProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      powerCashin: false,
      powerCashout: false,
      powerWebpay: false,
      powerPayku: false,
      powerFlow: false,
      powerBankCard: false,
      powerBankTransfer: false,
      powerBankCard: false,
      powerCash: false,
      powerSkinsBacks: false,
      powerPayOut: false,
      webpay: [],
      payouts: [],
      payins: [],
      /*   isStopped: true,
        isPaused: true, */
      messageModal: ""
    };
  }
  componentDidMount = () => {
    /*  console.log("this.props.country", "=>", this.props.country); */
    this.getStatePowerProduct();
   
  };
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      console.log("this.props !== prevProps");
      this.getStatePowerProduct();
    }
  }
  getStatePowerProduct = async () => {
    let informationCommerce = await API.merchant
      .getInformationCommmerces({
        id: this.props.merchant.code,
        country: this.props.country.code
      })
      .then(cons => {
        this.setState({ webpay: cons.webPay });
        this.setState({ payouts: cons.payOuts[0].proveedores });
        this.setState({ payins: cons.payIns[0].payments });
      })
      .catch(console.log);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const buttonStyle = {
      display: "block",
      margin: "10px auto"
    };

    const defaultOptionsOnline = {
      loop: true,
      autoplay: true,
      animationData: online,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: offline,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const unlockingOptions = {
      loop: true,
      autoplay: false,
      animationData: unlocking,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    /*  const { powerCashin } = this.state; */

    return (
      <>
        <Widget
          styleName="gx-order-history"
          title={
            <h2 className="h4 gx-text-capitalize gx-mb-0">Product Status</h2>
          }
        >
          <Collapse accordion style={{ backgroundColor: "white" }}>
            <Panel header="WEBPAY" index={1}>
              <Widget styleName="gx-order-history">
                <Row style={{ textAlign: "center", alignItems: "center" }}>
                  {this.state.webpay.length > 0 ? (
                    this.state.webpay.map((w, index) => (
                      <Col
                        lg={4}
                        md={4}
                        sm={24}
                        xs={24}
                        //style={{ textAlign: "center", alignItems: "center" }}
                      >
                        <>
                          <div>
                            {w.name.toUpperCase()}{" "}
                            {w.status ? (
                              <Lottie
                                options={defaultOptionsOnline}
                                height={100}
                                width={100}
                                isStopped={this.state.isStopped}
                                isPaused={this.state.isPaused}
                              />
                            ) : (
                              <Lottie
                                options={defaultOptions}
                                height={100}
                                width={100}
                                isStopped={this.state.isStopped}
                                isPaused={this.state.isPaused}
                              />
                            )}
                          </div>
                        </>
                      </Col>
                    ))
                  ) : (
                    <h3>No existe información de esta configuración</h3>
                  )}
                </Row>
              </Widget>
            </Panel>
            <Panel header="PAYINS" index={2}>
              <Widget styleName="gx-order-history">
                <Collapse
                  style={{ width: "100%", backgroundColor: "white" }}
                  accordion
                >
                  {this.state.payins.length > 0 ? (
                    this.state.payins.map((x, i) => (
                      <Panel header={x.method.toUpperCase()} key={i}>
                        <Row
                          style={{ textAlign: "center", alignItems: "center" }}
                        >
                          {x.proveedores.map((w, index) => (
                            <Col
                              lg={4}
                              md={4}
                              sm={24}
                              xs={24}
                              //style={{ textAlign: "center", alignItems: "center" }}
                            >
                              <>
                                <div>
                                  {w.name.toUpperCase()}{" "}
                                  {w.status ? (
                                    <Lottie
                                      options={defaultOptionsOnline}
                                      height={100}
                                      width={100}
                                      isStopped={this.state.isStopped}
                                      isPaused={this.state.isPaused}
                                    />
                                  ) : (
                                    <Lottie
                                      options={defaultOptions}
                                      height={100}
                                      width={100}
                                      isStopped={this.state.isStopped}
                                      isPaused={this.state.isPaused}
                                    />
                                  )}
                                </div>
                              </>
                            </Col>
                          ))}
                        </Row>
                      </Panel>
                    ))
                  ) : (
                    <h3>No existe información de esta configuración</h3>
                  )}
                </Collapse>
              </Widget>
            </Panel>
            <Panel header="PAYOUTS" index={3}>
              <Widget styleName="gx-order-history">
                <Row style={{ textAlign: "center", alignItems: "center" }}>
                  {this.state.payouts.length > 0 ? (
                    this.state.payouts.map((w, index) => (
                      <Col
                        lg={4}
                        md={4}
                        sm={24}
                        xs={24}
                        //style={{ textAlign: "center", alignItems: "center" }}
                      >
                        <>
                          <div>
                            {w.name.toUpperCase()}{" "}
                            {w.status ? (
                              <Lottie
                                options={defaultOptionsOnline}
                                height={100}
                                width={100}
                                isStopped={this.state.isStopped}
                                isPaused={this.state.isPaused}
                              />
                            ) : (
                              <Lottie
                                options={defaultOptions}
                                height={100}
                                width={100}
                                isStopped={this.state.isStopped}
                                isPaused={this.state.isPaused}
                              />
                            )}
                          </div>
                        </>
                      </Col>
                    ))
                  ) : (
                    <h3>No existe información de esta configuración</h3>
                  )}
                </Row>
              </Widget>
            </Panel>
          </Collapse>
        </Widget>
        <br />
        <br />
      </>
    );
  }
}
const SemaphoneProductsForm = Form.create()(SemaphoneProducts);
const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, country, merchantContr } = settings;
  return { authUser, merchant, country, merchantContr };
};

export default withRouter(
  connect(mapStateToProps, { switchMerchant })(SemaphoneProductsForm)
);
