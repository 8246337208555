import React, { Component } from "react";
import { Col, Row, Button, Input } from "antd";
import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebase";
import Dynamic from "./Dynamic";
import moment from "moment-timezone";
import GrowthCard from "components/dashboard/CRM/GrowthCardMerchant";
import _ from "lodash";
import Auxiliary from "util/Auxiliary";
import API from "../../../util/api";

const db = firebase.firestore();
let query;
let queryWeek;
let dataList = [];

export class coinspaidOk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: "Coinspaid Validated",
      filename: `Coinspaid Validated ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      indicadores: {
        dolar: {
          valor: ""
        }
      },
      date: "",
      datacard: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        TotalSinCommission: 0
      },
      datacardWeek: {
        dataWeek: "",
        sumWeek: 0,
        countdocsWeek: 0
      },
      dataList: [],
      firstTime: true,
      inputPreaidCode: "",
      defaultValueRangeFilter: [

        moment().tz("America/Santiago").subtract(1, "days"),
        moment().tz("America/Santiago")
      ]
    };
  }


  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps",nextProps);
   /*  this.componentDidMount(); */
  }

  componentDidMount = () => {

    moment.tz.setDefault(this.state.timeZone);

    let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
    let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();

     let firstDayWeek = moment.tz(this.state.timeZone).startOf("isoWeek").toDate();
     let lastDayWeek = moment.tz(this.state.timeZone).endOf("isoWeek").toDate();

    let now = moment().tz(this.state.timeZone).toDate();
    let yesterday = moment().tz(this.state.timeZone).subtract(1, "day").startOf('day').toDate();

    this.updateQuery(yesterday, now);
    if(this.props.dataUser.typeUser==="merchant"){this.getcardsOKInfo(firstDayWeek, lastDayWeek) }
    // CALCULO EL MES ACTUAL
    let mes = moment.tz(this.state.timeZone).format("MMMM");
    this.setState({
      mes: mes
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const merchant = localStorage.getItem("merchant_code");
      moment.tz.setDefault(this.state.timeZone);
      let firstDayWeek = moment.tz(this.state.timeZone).startOf("isoWeek").toDate();
      let lastDayWeek = moment.tz(this.state.timeZone).endOf("isoWeek").toDate();
      let now = moment().tz(this.state.timeZone).toDate();
      let yesterday = moment().tz(this.state.timeZone).subtract(1, "day").startOf('day').toDate();
      let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
      let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();
      // CALCULO EL MES ACTUAL
      let mes = moment.tz(this.state.timeZone).format("MMMM");
      this.setState({
        mes: mes
      });


      this.updateQuery(yesterday, now);
      if(this.props.dataUser.typeUser==="merchant"){this.getcardsOKInfo(firstDayWeek, lastDayWeek) }
    
      this.setState({
        filename: `Coinspaid validated ${localStorage.getItem("merchant_name")}`
      });
    }
  }

    getcardsOKInfo = async (firstDayWeek,lastDayWeek,firstDay,lastDay) => {
  
      let commissionesBd = this.props.comissionData
        ? this.props.comissionData
        : JSON.parse(localStorage.getItem("comisiones"));
      commissionesBd = commissionesBd.filter(
        item => item.merchant === this.props.merchant.code
      );
      commissionesBd = commissionesBd[0].commission.filter(
        item => item.countrie === this.props.country.code
      );
  
     
      let parametros = {
        "merchant": this.props.merchant.code,
        "firstDay": firstDayWeek,
        "lastDay": lastDayWeek,
        "comission": commissionesBd[0],
        "country": this.props.country.code,
        "code": 0,
        "payMethod":"coinspaid",
  
      }
      console.log("parametros",parametros)
  
      // await API.utils.getBankCardForParamsOK(parametros).then(
      // resp =>{
      //   console.log('resp', resp)
      //   this.setState({
      //     datacardWeek: resp.datacardWeek,
      //     datacard: resp.datacard
      //   });
      // });
    } 

  updateQuery = async (firstDay, lastDay) => {

    dataList = [];
    let commissionesBd = this.props.comissionData
      ? this.props.comissionData
      : JSON.parse(localStorage.getItem("comisiones"));
    commissionesBd = commissionesBd.filter(
      item => item.merchant === this.props.merchant.code
    );
    commissionesBd = commissionesBd[0].commission.filter(
      item => item.countrie === this.props.country.code
    );

    /************************************************************************************************ */

    let parametros = {
      "merchant": this.props.merchant.code,
      "firstDay": firstDay,
      "lastDay": lastDay,
      "comission": commissionesBd[0],
      "country": this.props.country.code,
      "code": 0

    }
    console.log("parametros", parametros)


    await API.utils.getCoinspaidForParamsOK(parametros).then(cons => {
      console.log("getFriForParamsOK", cons)
      this.setState({
        dataList: cons
      })

      cons.sort(function (x, y) {
        return x.datacard - y.datacard;
      })
      console.log("data ordenada ", cons);

    });
    //console.log(this.state.dataList.sort((a, b) => new Date(a.fechas).getTime() > new Date(b.fechas).getTime()));
  };
  update = async (e) => {
    await this.componentDidUpdate(this.state);
  }

  render() {
    this.expandedRowRender = record => (
      <div>
        <p>
          Operation Code: <b>{record.operationCode}</b>
        </p>
        <p>
          Email: <b>{record.email}</b>
        </p>
        {this.props.dataUser.typeUser === "admin" ||
        this.props.dataUser.typeUser === "soporteAdmin" ? (
          <div>
            {record.adminCallBack ? (
              <p>
                Admin Callback:<b>{record.adminCallBack}</b>
              </p>
            ) : (
              ""
            )}
          </div>
        ) : (
          <> </>
        )}
        {this.props.dataUser.typeUser !== "merchant" ? (
          <div>
            {record.collection ? (
              <p>
                Agregator:<b>{record.collection}</b>
              </p>
            ) : (
              ""
            )}
          </div>
        ) : (
          <> </>
        )}

        {record.payMethod === "bankCard" &&
        (record.lastFourDigits ||
          record.cardNumber ||
          record.last_four_digits) ? (
          <div>
            <p>
              Last Four Digits:{" "}
              <b>
                {record.lastFourDigits ||
                  record.cardNumber ||
                  record.last_four_digits}
              </b>
            </p>
          </div>
        ) : (
          <> </>
        )}

        {record.payMethod === "bankCard" &&
        (record.binCard ) ? (
          <div>
            <p>
            First Digits:{" "}
              <b>
                {record.binCard}
              </b>
            </p>
          </div>
        ) : (
          <> </>
        )}
      </div>
    );
    let view;
    if (this.props.dataUser.typeUser === "merchant") {
      view = (
        <Row>
          <Col xl={8} lg={24} md={8} sm={24} xs={24}>
            <GrowthCard
              trafficData={_.takeRight(this.state.datacard.data, 5)}
              avg={parseFloat(this.state.datacardWeek.sumdocsWeek).toFixed(0)}
              title="Transaction Amount"
              month={this.state.mes}
              suma={this.state.datacard.sum}
              prefix={"$"}
            />
          </Col>
          <Col xl={8} lg={12} md={8} sm={24} xs={24}>
            <GrowthCard
              title="Total Transactions"
              month={this.state.mes}
              suma={this.state.datacard.count}
              avg={this.state.datacardWeek.countdocsWeek}
            />
          </Col>
          <Col xl={8} lg={12} md={8} sm={24} xs={24}>
            <GrowthCard
              param="0"
              title="Total without commision"
              month={this.state.mes}
              suma={this.state.datacard.TotalSinCommission}
              prefix={"$"}
            />
          </Col>
          <Col span={24}>
            <Dynamic
              defaultValue={this.state.defaultValueRangeFilter}
              updateQuery={this.updateQuery}
              filename={this.state.filename}
              title={this.state.title}
              dataList={this.state.dataList}
              // columns={this.columns}
              expandedRowRender={this.expandedRowRender}
              getData={this.getData}
              onTableChange={this.onTableChange}
              timeZone={this.state.timeZone}
              merchant={this.props.merchant}
            // onTimeZoneChange={e => this.onTimeZoneChange(e)}
            typeUser={this.props.dataUser.typeUser}
            />
          </Col>
        </Row>
      );

    }
    else {
      view = (<Row>
        <Col span={24}>
          <Dynamic
            defaultValue={this.state.defaultValueRangeFilter}
            updateQuery={this.updateQuery}
            user={this.props.authUser}
            update={this.update}
            filename={this.state.filename}
            title={this.state.title}
            dataList={this.state.dataList}
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            onTableChange={this.onTableChange}
            merchant={this.props.merchant}
            typeUser={this.props.dataUser.typeUser}
          />
        </Col>
      </Row>);
    }
    return (
      <Auxiliary>
        {view}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, comissionData, country, dataUser } = settings;
  return { authUser, merchant, comissionData, country, dataUser };
};
export default connect(mapStateToProps)(coinspaidOk);
