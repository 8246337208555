import React from "react";
import { Row, Col, Card, Table, Input, Popconfirm, Alert, Badge } from "antd";
import _ from "lodash";
import { firebase } from "../../firebase/firebase";
import NumberFormat from "react-number-format";
const db = firebase.firestore();
const pagination = { position: "bottom" }; //bottom top both none

class GridMerchants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue: " ",
      bordered: false,
      loading: false,
      pagination,
      size: "middle", // default middle small
      expandedRowRender: props.expandedRowRender,
      title: undefined,
      showHeader: true,
      total: 0,
      footer: () => (
        <div>

          Total Records:{" "}
          <NumberFormat
            value={this.state.total}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
          />
        </div>
      ),
      rowSelection: false, // {} or false
      scroll: undefined,
      searchFilter: "",
      updateTable: false

    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      dataList: nextProps.dataList,
      sumDataList: _.sumBy(nextProps.dataList, item => Number(item.quantity))
    });
  }


/*
  getUsers = async () => {
    let user = [];
    await db
      .collection("users")
      .get()
      .then(snapshot => {
        snapshot.docs.map(async docUser => {
          user.push({
            idUser: docUser.id,
            ...docUser.data(),
          });
        })
      })
      .catch(err => {
        console.log("ERROR IN user", err);
      });
    let total = user.length;
    this.setState({ userOrigin: user, user, total: total })

  } */




/*
  onTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  }; */




  getColumns = () => [
    {
      title: "code",
      dataIndex: "code",
      key: "code",
      width: 100,
   /*    render: (text, record) => (
        <span>
          {String(text) === "true" ? <Badge status="success" /> : <Badge status="error" />}
        </span>

      ) */
      /* ,
      sorter: (a, b) => a.valor - b.valor,
      sortOrder:
        this.state.sortedInfo.columnKey === "valor" &&
        this.state.sortedInfo.order */
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      width: 100,

    },

    {
      title: "Action",
      key: "action",
      /*  fixed: "right", */
      width: 150,
      render: (text, record) => (
        <span>
          <Popconfirm
            title="Do you want edit this  user?"
            onConfirm={() => this.edit(record)}
            onCancel={this.cancel}
            okText="Yes"
            cancelText="No"
          >
            <span className="gx-link">
              {record.prepared ? "✅ " : ""}Edit
          </span>
          </Popconfirm>
        </span>

      )
    },
    {
      title: "Action",
      key: "action",
      /*  fixed: "right", */
      width: 150,
      render: (text, record) => (
        <span>
          <Popconfirm
            title="Do you want delete this  user?"
            onConfirm={() => this.edit(record)}
            onCancel={this.cancel}
            okText="Yes"
            cancelText="No"
          >
            <span className="gx-link">
              {record.prepared ? "✅ " : ""}Delete
          </span>
          </Popconfirm>
        </span>

      )
    }
  ];
  render() {

    return (
      <Card>
        <div className="components-table-demo-control-bar">
          <Row>
            <Col xl={15} lg={15} md={15} sm={24} xs={24} className="gx-pr-md-2">
            </Col>
            <Col xl={9} lg={9} md={9} sm={24} xs={24} className="gx-pr-md-2">
         {/*      <Input
                id="idSearch"
                defaultValue={this.state.defaultValue}

                placeholder="Search..."
                onChange={this.updateSearchFilter.bind(this)}
              ></Input> */}
            </Col>
          </Row>
        </div>

        <Table
          className="gx-table-responsive"
          {...this.state}
          // columns={this.props.columns || this.columns}

          columns={this.getColumns()}
          dataSource={this.state.dataList}
          rowKey="valor"
          onChange={this.onTableChange}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50', '100'] }}
        />
        {this.state.resp ? (<Alert
          message="Resultado"
          description={this.state.resp}
          type="success"
        />) : null}

      </Card>
    );
  }
}

export default GridMerchants;
