import React from "react";
import loader1 from "../../assets/images/logo.png";
class loader extends React.Component {

  render() {
    return (<>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            ".contener_general{-webkit-animation:animball_two 1s infinite linear;-moz-animation:animball_two 1s infinite linear;-ms-animation:animball_two 1s infinite linear;animation:animball_two 1s infinite linear;width:84px;height:84px} .ballcolor{width:84px;height:84px;border-radius:50%} .ball_1, .ball_2, .ball_3, .ball_4{position:absolute} .ball_1{border:2px solid #CC1EC3;top:0;left:7px} .ball_2{border:2px solid #3197F7;top:0;left:-7px} .ball_3{border:2px solid #E88A54;top:7px;left:0} .ball_4{border:2px solid #FAFAFA;top:-7px;left:0} @-webkit-keyframes animball_two{0%{-webkit-transform:rotate(0deg)} 100%{-webkit-transform:rotate(360deg)}} @-moz-keyframes animball_two{0%{-moz-transform:rotate(0deg)} 100%{-moz-transform:rotate(360deg)}} @-ms-keyframes animball_two{0%{-ms-transform:rotate(0deg)} 100%{-ms-transform:rotate(360deg)}} @keyframes animball_two{0%{transform:rotate(0deg)} 100%{transform:rotate(360deg)}}"
        }}
      />
      <div align="center">
        {" "}
        <div className="contener_general">

          <div className="ballcolor ball_1">&nbsp;</div>{" "}
           <div className="ballcolor ball_2">&nbsp;</div>{" "}
          <div align="center">
          <img src={loader1} alt="loader"  style={{marginTop:"20px"}}/>
          </div>
            <div className="ballcolor ball_3">&nbsp;</div>{" "}
           <div className="ballcolor ball_4">&nbsp;</div>{" "}

        </div>{" "}
      </div>
    </>)
  }

}

export default loader;
