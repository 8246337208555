import keyGenerator from "../../../util/keyGenerator";
import config_fields from "../../../util/configFields";
export const generateObjectPayments = payments => {
  let newArray = [];
  payments.forEach(pay => {
    let arrayInformacionInicial = [];
    let arraypayMethods = [];
    let newObject = {};
    newObject.key = keyGenerator();
    newObject.level = 1;
    newObject.id = pay.country;
    if (Object.keys(pay.informacionPayments).length !== 0) {
      arrayInformacionInicial.push(generateDataInicial(pay, config_fields));
      newObject.data = arrayInformacionInicial;
    } else {
      newObject.data = [];
    }
    if (pay.payments.length > 0) {
      pay.payments.forEach(p => {
        let arrayPMInterior = [];
        let objectMethodPay = {};
        objectMethodPay.key = keyGenerator();
        objectMethodPay.level = 2;
        objectMethodPay.field = "";
        objectMethodPay.data = [];
        objectMethodPay.id = p.method;
        if (Object.keys(p.information).length > 0) {
          arrayPMInterior.push(generateDataTercerNivel(p, config_fields));
          objectMethodPay.children = arrayPMInterior;
        } else {
          objectMethodPay.children = [];
        }
        arraypayMethods.push(objectMethodPay);
      });
      newObject.children = arraypayMethods;
    } else {
      newObject.children = [];
    }
    newArray.push(newObject);
  });
  return newArray;
};
const generateDataInicial = (pay, config_fields) => {
  let nombre = Object.keys(pay.informacionPayments);
  nombre = nombre[0];
  let nameField = config_fields.payments.fields.filter(
    field => field.field === nombre.trim()
    );
  nameField = nameField[0];
  let object = {};
  object.key = keyGenerator();
  object.field = nameField.field;
  object.value = JSON.stringify(pay.informacionPayments[nombre]);
  object.type = nameField.type;
  return object;
};
const generateDataTercerNivel = (p, config_fields) => {
  let object = {};
  object.key = keyGenerator();
  object.level = 3;
  object.field = "";
  object.id = p.id;
  let nombres = Object.keys(p.information);
  let arrayInterior = [];
  let arrayTraffic = [];
  nombres.forEach(nombre => {
    let nameField = config_fields.payments.fields.filter(
      field => field.field === nombre
    );
    nameField = nameField[0];
    let objectInterior = {};
    objectInterior.key = keyGenerator();
    objectInterior.field = nameField.field;
    objectInterior.value = p.information[nombre];
    // console.log("Llegue aca",nameField.field,nameField,nombres);
    objectInterior.type = nameField.type;
    arrayInterior.push(objectInterior);
  });
  object.data = arrayInterior;
  let arrayQNInterior = [];
  if (p.trafficPaymentLight.id !== "") {
    let trafficPayment = {};
    trafficPayment.key = keyGenerator();
    trafficPayment.level = 4;
    trafficPayment.field = "";
    trafficPayment.data = [];
    if (Object.keys(p.trafficPaymentLight.information).length > 0) {
      arrayQNInterior.push(
        generateDataQuintoNivel(p.trafficPaymentLight, config_fields)
      );
      trafficPayment.children = arrayQNInterior;
    } else {
      trafficPayment.children = [];
    }
    trafficPayment.id = "trafficPaymentLight";
    arrayTraffic.push(trafficPayment);
    object.children = arrayTraffic;
  } else {
    object.children = [];
  }
  return object;
};
const generateDataQuintoNivel = (traffic, config_fields) => {
  let object = {};
  object.key = keyGenerator();
  object.level = 5;
  object.field = "";
  object.id = traffic.id;
  let nombres = Object.keys(traffic.information);
  let arrayInterior = [];
  nombres.forEach(nombre => {
    let nameField = config_fields.payments.fields.filter(
      field => field.field === nombre
    );
    nameField = nameField[0];
    let objectInterior = {};
    objectInterior.key = keyGenerator();
    // console.log("Llegue aqui",nameField.field,nameField,nombres);
    objectInterior.field = nameField.field;
    objectInterior.value = traffic.information[nombre];
    objectInterior.type = nameField.type;
    arrayInterior.push(objectInterior);
  });
  object.data = arrayInterior;
  object.children = [];
  return object;
};
