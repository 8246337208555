import React, { Component } from "react";
import { Col, Row, Button, Input } from "antd";
import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebase";
import Dynamic from "./Dynamic";
import moment from "moment-timezone";
import GrowthCard from "components/dashboard/CRM/GrowthCardMerchant";
import _ from "lodash";
import Auxiliary from "util/Auxiliary";
import API from "../../../util/api";

const db = firebase.firestore();
let query;
let queryWeek;
let dataList = [];

export class cashinOk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: "Cashin Validated",
      filename: `Cashin Validated ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      indicadores: {
        dolar: {
          valor: ""
        }
      },
      date: "",
      dataCashin: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        TotalSinCommission: 0
      },
      dataCashinWeek: {
        dataWeek: "",
        sumWeek: 0,
        countdocsWeek: 0
      },
      firstTime: true,
      inputPreaidCode: "",
      defaultValueRangeFilter:[

        moment().tz("America/Santiago").subtract(1, "days"),
        moment().tz("America/Santiago")
      ]
    };
  }

  componentDidMount = () => {
    //  IF redux.paisActual === "ALL" {Redirect to="/"}

    //  REVISAR SI EL USURIO PUEDE VER ESTA PAGINA PARA ESTE PAIS
    //  IF !obj.componentes.name CONTAINS ("cashin") and obj.componentes.country === redux.country
    //  {Redirect to="/"}

    //  REVISAR QUE COMPONENTES PUEDE VER EL USUARIO
    moment.tz.setDefault(this.state.timeZone);

    let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
    let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();

    let firstDayWeek = moment.tz(this.state.timeZone).startOf("isoWeek").toDate();
    let lastDayWeek = moment.tz(this.state.timeZone).endOf("isoWeek").toDate();

    let now = moment().tz(this.state.timeZone).toDate();
    let yesterday = moment().tz(this.state.timeZone).subtract(1, "day").startOf('day').toDate();

    this.updateQuery(yesterday,now);
    this.getTransfersOKInfo(firstDayWeek, lastDayWeek,firstDay,lastDay)
    // CALCULO EL MES ACTUAL
    let mes = moment.tz(this.state.timeZone).format("MMMM");
    this.setState({
      mes: mes
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.merchant !== prevProps.merchant) {
      const merchant = localStorage.getItem("merchant_code");
      moment.tz.setDefault(this.state.timeZone);
      let firstDayWeek = moment.tz(this.state.timeZone).startOf("isoWeek").toDate();
      let lastDayWeek = moment.tz(this.state.timeZone).endOf("isoWeek").toDate();
      let now = moment().tz(this.state.timeZone).toDate();
      let yesterday = moment().tz(this.state.timeZone).subtract(1, "day").startOf('day').toDate();
      let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
      let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();
      // CALCULO EL MES ACTUAL
      let mes = moment.tz(this.state.timeZone).format("MMMM");
      this.setState({
        mes: mes
      });


      this.updateQuery(yesterday,now);
      this.getTransfersOKInfo(firstDayWeek, lastDayWeek,firstDay,lastDay)
      this.setState({
        filename: `Cashin validated ${localStorage.getItem("merchant_name")}`
      });
    }
  }

  getTransfersOKInfo = async (firstDayWeek,lastDayWeek,firstDay,lastDay) => {

    let commissionesBd = this.props.comissionData
      ? this.props.comissionData
      : JSON.parse(localStorage.getItem("comisiones"));
    commissionesBd = commissionesBd.filter(
      item => item.merchant === this.props.merchant.code
    );
    commissionesBd = commissionesBd[0].commission.filter(
      item => item.countrie === this.props.country.code
    );

    let parametros = {
      comission: commissionesBd[0],
      firstDay: firstDay,
      lastDay: lastDay,
      firstDayWeek: firstDayWeek,
      lastDayWeek: lastDayWeek,
      merchant: this.props.merchant.code
    }
    console.log("parametros",parametros)

    await API.utils.getTransfersOKInfo(parametros).then(
    resp =>{
      console.log(resp)
      this.setState({
        dataCashinWeek: resp.dataCashinWeek,
        dataCashin: resp.dataCashin
      });
    });
  }

  updateQuery = async (firstDay,lastDay) => {
    /****************************************COMISIONES************************************ */

    let commissionesBd = this.props.comissionData
      ? this.props.comissionData
      : JSON.parse(localStorage.getItem("comisiones"));
    commissionesBd = commissionesBd.filter(
      item => item.merchant === this.props.merchant.code
    );
    commissionesBd = commissionesBd[0].commission.filter(
      item => item.countrie === this.props.country.code
    );

    /************************************************************************************************ */
    dataList = [];
    let parametros = {
      "merchant": this.props.merchant.code,
      "firstDay": firstDay,
      "lastDay": lastDay,
      "comission": commissionesBd[0],
      "country":this.props.country.code,
    }
    console.log("parametros",parametros)

    await API.utils.getTransfersForParamsOK(parametros).then( cons=>{
      console.log("getTransfersForParamsOK",cons)
      this.setState({
        dataList: cons
      })

    });


  };

  setPrepaidCode = (event) => {
     this.state.inputPreaidCode = event.target.value
  }
  savePrepaidCode = async (e) => {
    e = e || window.event;
    e = e.target || e.srcElement;

    let data = {};
    data = JSON.parse(e.id);
    /*   console.log(data.cashinId) */
    if (this.state.inputPreaidCode) {
      await db.collection("transfers").doc(data.cashinId).update({
        prepaidCode: this.state.inputPreaidCode,
        userprepaidCode: this.props.authUser
      }).then(function () {
        console.log("Update  prepaidCode OK!");

      })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
      this.componentDidUpdate(this.state);
    }
  }
  update = async (e) => {
    await this.componentDidUpdate(this.state);
  }


  render() {


    this.expandedRowRender = record => (
      <div>
     {/*   <p>
          Operation prepaidCode: <b>{!(JSON.stringify(record.prepaidCode))?"true" :"false"}</b>
        </p> */}
        <p>
          Operation Code: <b>{record.operationCode}</b>
        </p>
        <p>
          Email: <b>{record.email}</b>
        </p>
       {/*  {record.prepaidCode ? ( */}
          <div>
          {record.commerceId==="2020juegalo-5n2q"?

          this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporte"  || this.props.dataUser.typeUser === "soporteAdmin"? (
              <div>
                {record.prepaidCode === "Por favor, Solicite su código de recarga a nuestro soporte en linea" || !(JSON.stringify(record.prepaidCode))==="" || JSON.stringify(record.prepaidCode) === null || !record.prepaidCode?

                  (<p>
                    Prepaid Code:{"⚠️⚠️⚠️"}
                    <input onChange={this.setPrepaidCode} placeholder="insert Prepaid Code" ></input>
                    <Button onClick={this.savePrepaidCode} id={JSON.stringify(record)} className="Dashed" style={{ border: "none", paddingTop: "8px" }}>💾</Button>
                  </p>)
                  :
                  <div>
                    {record.userprepaidCode ?
                      <p>
                        Prepaid Code:
                        <input onChange={this.setPrepaidCode} placeholder={` ${record.prepaidCode} `} ></input>
                        <Button onClick={this.savePrepaidCode} id={JSON.stringify(record)} className="Dashed" style={{ border: "none", paddingTop: "8px" }}>📝</Button>
                      </p>
                      :
                      <p>
                        Prepaid Code:
                  {`✅ ${record.prepaidCode} `}</p>}
                  </div>
                }
              </div>
            ) : (
                <p>
                  Prepaid Code:
                  {`${record.prepaidCode} `}</p>
              )
          :
          <> </>
          }

          </div>
       {/*  ) : (
            ""
          )} */}
        {this.props.dataUser.typeUser === "admin"  || this.props.dataUser.typeUser === "soporteAdmin" ? (
          <div>
            {record.userprepaidCode ?
              (<p>
                User Prepaid Code:<b>{record.userprepaidCode}</b>
              </p>) : ""
            }
          </div>
          
        ) : (
            ""
          )}
        {this.props.dataUser.typeUser === "admin"  || this.props.dataUser.typeUser === "soporteAdmin" ? (
          <div>
            {record.adminCallBackDate ?
              (<p>
               Admin Callback: Date:<b>{
                 moment(new Date(record.adminCallBackDate._seconds * 1000).toISOString()).toDate().toLocaleString("es-CL", {
                    timeZone: this.state.timeZone,
                    timeZoneName: "short"
                  }
                   )
                  }
               </b> 
              </p>) : ""
            }
          </div>
          
        ) : (
            ""
          )}
        {this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporteAdmin" ? (

          <div>
            {record.adminCallBack ?
              (<p>
                Admin Callback:<b>{record.adminCallBack}</b>
              </p>) : ""
            }
          </div>
        ) : (
          // <> </>
           this.props.dataUser.typeUser === "merchant" &&  record.adminCallBack==="conciliación"? (

            <div>
              {record.adminCallBack ?
                (<p>
                  Conciliación:<b>conciliación automatizada </b>
                </p>) : ""
              }
            </div>
          ) : (
             <> </>
            )
           //
          )}
      </div>
    );
    let view;
    if (this.props.dataUser.typeUser === "merchant") {
      view = (
        <Row>
          <Col xl={8} lg={24} md={8} sm={24} xs={24}>
            <GrowthCard
              trafficData={_.takeRight(this.state.dataCashin.data, 5)}
              avg={parseFloat(this.state.dataCashinWeek.sumdocsWeek).toFixed(0)}
              title="Transaction Amount"
              month={this.state.mes}
              suma={this.state.dataCashin.sum}
              prefix={"$"}
            />
          </Col>
          <Col xl={8} lg={12} md={8} sm={24} xs={24}>
            <GrowthCard
              title="Total Transactions"
              month={this.state.mes}
              suma={this.state.dataCashin.count}
              avg={this.state.dataCashinWeek.countdocsWeek}
            />
          </Col>
          <Col xl={8} lg={12} md={8} sm={24} xs={24}>
            <GrowthCard
              param="0"
              title="Total without commision"
              month={this.state.mes}
              suma={this.state.dataCashin.TotalSinCommission}
              prefix={"$"}
            />
          </Col>
          <Col span={24}>
            <Dynamic
               defaultValue={this.state.defaultValueRangeFilter}
              updateQuery={this.updateQuery}
              filename={this.state.filename}
              title={this.state.title}
              dataList={this.state.dataList}
              // columns={this.columns}
              expandedRowRender={this.expandedRowRender}
              getData={this.getData}
              onTableChange={this.onTableChange}
              timeZone={this.state.timeZone}
              merchant={this.props.merchant}
            // onTimeZoneChange={e => this.onTimeZoneChange(e)}
            />
          </Col>
        </Row>
      );

    }
    else {
      view = (<Row>
        <Col span={24}>
          <Dynamic
             defaultValue={this.state.defaultValueRangeFilter}
            updateQuery={this.updateQuery}
            user={this.props.authUser}
            update={this.update}
            filename={this.state.filename}
            title={this.state.title}
            dataList={this.state.dataList}
            // columns={this.columns}
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            onTableChange={this.onTableChange}
            merchant={this.props.merchant}
          // timeZone={this.state.timeZone}
          // onTimeZoneChange={e => this.onTimeZoneChange(e)}
          />
        </Col>
      </Row>);
    }
    return (
      <Auxiliary>
        {view}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, comissionData, country, dataUser } = settings;
  return { authUser, merchant, comissionData, country, dataUser };
};
export default connect(mapStateToProps)(cashinOk);

