import React from "react";
import { Row, Col, Card, Table, Input,Popconfirm ,Alert} from "antd";
import _ from "lodash";
import { firebase } from "../../firebase/firebase";
import NumberFormat from "react-number-format";
const db = firebase.firestore();
const pagination = { position: "bottom" }; //bottom top both none

class Dynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue:"",
      bordered: false,
      loading: false,
      pagination,
      size: "middle", // default middle small
      expandedRowRender: props.expandedRowRender,
      title: undefined,
      showHeader: true,
      total:0,
      footer: () => (
        <div>

          Total Records:{" "}
          <NumberFormat
            value={this.state.total}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
          />
        </div>
      ),
      rowSelection: false, // {} or false
      scroll: undefined,
      searchFilter: "",
      updateTable :false

    };
  }
  componentDidMount = () => {
 this.getBalcklist();
  }


  getBalcklist = async () => {

    let blacklist = [];
    await db
      .collection("parametrosGenerales")
      .doc("seguridad")
      .collection(this.props.type)
      .get()
      .then(snapshot => {
     let data=   snapshot.docs.map(resp => {

          blacklist.push({
            idblacklist: resp.id,
            ...resp.data()
          });
          return data;
        });
      })
      .catch(err => {
        console.log("ERROR IN blacklist", err);
      });

      console.log(blacklist);
      let total =blacklist.length;
    this.setState({ blacklistOrigin: blacklist,blacklist,total:total})

  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.updateTable) {
      this.getBalcklist();
    }

  }

  filter = searchValue => {
    if (searchValue === "") {
      this.setState({
        blacklist: this.state.blacklistOrigin,
      });
    } else {
      try
      {
      const filter = _.filter(
        this.state.blacklist,
        blacklist =>
        blacklist.tipo.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
        blacklist.valor.toLowerCase().indexOf(searchValue.toLowerCase()) > -1||
        (blacklist.reason
          ? blacklist.reason.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
          : "")
      );
      this.setState({
        blacklist: filter,
      });
    }
    catch{
      console.log("Error en  el filtro ");
    }
    }
  };

  updateSearchFilter(evt) {
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }

  onTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  delete=async (record)=>
{
  console.log("delete ");
  console.log("this.props.type ", this.props.type);
  console.log("record ", record);
  let resp ="";
  await db.collection("parametrosGenerales")
  .doc("seguridad")
  .collection(this.props.type).doc(record.idblacklist).delete().then(function() {
    resp ="Document successfully deleted!";

}).catch(function(error) {
    console.error("Error removing document: ", error);
    resp =`Error removing document: , ${error}`;
});


this.setState({resp,defaultValue:""})
this.getBalcklist();

setTimeout(
  function() {
      this.setState({ resp: "" });
  }
  .bind(this),
  3000
);
};


getColumns = () => [
 /*  {
    title: "id",
    dataIndex: "idblacklist",
    key: "idblacklist",
    width: 100 ,
  visible:false,
       display: "none",
    hideOnSmall: true,
    className: "hide"
  }, */
  {
    title: "Type",
    dataIndex: "tipo",
    key: "tipo",
    width: 100/* ,
    sorter: (a, b) => a.type - b.type,
    sortOrder:
      this.state.sortedInfo.columnKey === "tipo" &&
      this.state.sortedInfo.order */
  },
  {
    title: "Value",
    dataIndex: "valor",
    key: "valor",
    width: 150/* ,
    sorter: (a, b) => a.valor - b.valor,
    sortOrder:
      this.state.sortedInfo.columnKey === "valor" &&
      this.state.sortedInfo.order */
  },
  {
    title: "Reason",
    dataIndex: "reason",
    key: "reason",
    width: 100,
  },
  {
    title: "Action",
    key: "action",
   /*  fixed: "right", */
    width: 150,
    render: (text, record) => (
      <span>
        <Popconfirm
          title="Do you want delete this  record?"
          onConfirm={() => this.delete(record)}
          onCancel={this.cancel}
          okText="Yes"
          cancelText="No"
        >
          <span className="gx-link">
            {record.prepared ? "✅ " : ""}Delete
          </span>
        </Popconfirm>
      </span>
    )
  },
  {
    title: "Admin",
    dataIndex: "admin",
    key: "admin",
    width: 100,
  },
];
  render() {
    this.columns = [
      {
        title: "id",
        dataIndex: "idblacklist",
        key: "idblacklist",
        width: 100 ,
     /*    visible:false, */
/*         display: "none",
        hideOnSmall: true, */
        className:  "hide"
      },
      {
        title: "Type",
        dataIndex: "tipo",
        key: "tipo",
        width: 100/* ,
        sorter: (a, b) => a.type - b.type,
        sortOrder:
          this.state.sortedInfo.columnKey === "tipo" &&
          this.state.sortedInfo.order */
      },
      {
        title: "Value",
        dataIndex: "valor",
        key: "valor",
        width: 150/* ,
        sorter: (a, b) => a.valor - b.valor,
        sortOrder:
          this.state.sortedInfo.columnKey === "valor" &&
          this.state.sortedInfo.order */
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
        width: 100,
      },
      {
        title: "Action",
        key: "action",
        width: 150,
        render: (text, record) => (
          <span>
            <Popconfirm
              title="Do you want delete this  record?"
              onConfirm={() => this.delete(record)}
              onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
            >
              <span className="gx-link">
                {record.prepared ? "✅ " : ""}Delete
              </span>
            </Popconfirm>
          </span>
        )
      },
      {
        title: "Admin",
        dataIndex: "admin",
        key: "admin",
        width: 100,
      },

    ];

    return (
      <Card>
        <div className="components-table-demo-control-bar">
          <Row>
            <Col xl={15} lg={15} md={15} sm={24} xs={24} className="gx-pr-md-2">
            </Col>
            <Col xl={9} lg={9} md={9} sm={24} xs={24} className="gx-pr-md-2">
              <Input
               id="idSearch"
               defaultValue={this.state.defaultValue}

                placeholder="Search..."
                onChange={this.updateSearchFilter.bind(this)}
              ></Input>
            </Col>
          </Row>
        </div>

        <Table
          className="gx-table-responsive"
          {...this.state}
         // columns={this.props.columns || this.columns}
          columns={this.getColumns()}
          dataSource={this.state.blacklist}
          rowKey="valor"
          onChange={this.onTableChange}
          pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5','10', '20', '30','50','100']}}
        />
{this.state.resp?(<Alert
                message="Resultado"
                description={this.state.resp}
                type="success"
              />):null}

      </Card>
    );
  }
}

export default Dynamic;
