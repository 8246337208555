import keyGenerator from "../../../util/keyGenerator";
import config_fields from "../../../util/configFields";
export const generateObjectPayouts = payouts => {
  let newArray = [];
  payouts.forEach(payout => {
    let arrayPayout = [];
    let newObject = {};
    newObject.key = keyGenerator();
    newObject.level = 1;
    newObject.id = payout.country;
    if (Object.keys(payout.informacionPayouts).length !== 0) {
      newObject.data = generateDataInicial(payout, config_fields);
    } else {
      newObject.data = [];
    }
    if (payout.trafficPayOutLight.length > 0) {
      payout.trafficPayOutLight.forEach(p => {
        let arrayCMInterior = [];
        let objectPayout = {};
        objectPayout.key = keyGenerator();
        objectPayout.level = 2;
        objectPayout.field = "";
        objectPayout.data = [];
        objectPayout.id = "trafficPayOutLight";
        if (Object.keys(p.information).length > 0) {
          arrayCMInterior.push(generateDataTercerNivel(p, config_fields));
          objectPayout.children = arrayCMInterior;
        } else {
          objectPayout.children = [];
        }
        arrayPayout.push(objectPayout);
      });
      newObject.children = arrayPayout;
    } else {
      newObject.children = [];
    }
    newArray.push(newObject);
  });
  return newArray;
};
const generateDataInicial = (payout, config_fields) => {
  let nombres = Object.keys(payout.informacionPayouts);
  let arrayInterior = [];
  nombres.forEach(nombre => {
    let nameField = config_fields.payouts.fields.filter(
      field => field.field === nombre
    );
    nameField = nameField[0];
    let objectInterior = {};
    objectInterior.key = keyGenerator();
    //console.log("Llegue aqui", nameField, nombre, nombres);
    objectInterior.field = nameField.field;
    objectInterior.value = payout.informacionPayouts[nombre];
    objectInterior.type = nameField.type;
    arrayInterior.push(objectInterior);
  });

  return arrayInterior;
};
const generateDataTercerNivel = (p, config_fields) => {
  let object = {};
  object.key = keyGenerator();
  object.level = 3;
  object.field = "";
  object.id = p.id;
  let nombres = Object.keys(p.information);
  let arrayInterior = [];
  nombres.forEach(nombre => {
    let nameField = config_fields.payouts.fields.filter(
      field => field.field === nombre
    );
    nameField = nameField[0];
    let objectInterior = {};
    objectInterior.key = keyGenerator();
    //console.log("Llegue aca", nameField.field, nameField, nombres);
    objectInterior.field = nameField.field;
    objectInterior.value = p.information[nombre];
    objectInterior.type = nameField.type;
    arrayInterior.push(objectInterior);
  });
  object.data = arrayInterior;
  object.children = [];
  return object;
};
