import React from "react";
import _ from "lodash";

import InvoiceCell from "./InvoiceCell/index";

const InvoiceList = ({
  invoiceList,
  addFavourite,
  onSaveInvoice,
  onDeleteInvoice
}) => {
  return (
    <div className="gx-contact-main-content">
      {_.map(invoiceList, (invoice, key) => (
        <InvoiceCell
          key={key}
          id={key}
          invoice={invoice}
          onDeleteInvoice={onDeleteInvoice}
          onSaveInvoice={onSaveInvoice}
          addFavourite={addFavourite}
        />
      ))}
    </div>
  );
};

export default InvoiceList;
