import React, { Component } from "react";
import { Col, Row, DatePicker } from "antd";
import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebase";
import moment from "moment-timezone";
import Widget from "components/Widget/index";
import GrowthCard from "components/dashboard/CRM/GrowthCardMerchant";
import _ from "lodash";

const db = firebase.firestore();
const { RangePicker } = DatePicker;
let query;

export class CierresInvoiceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      timeZone: "America/Santiago",
      date: "",
      dataCashin: {
        factura:"",
      },
      dataWebpay: {
        factura:"",
      },

      dataFlow: {
        factura:"",
      },

      dataPayku: {
        factura:"",
      },
      dataCashout: {
        factura:"",
      },
      dataCashinOld: {
        factura:"",
      },
      dataWebpayOld: {
        factura:"",
      },

      dataPaykuOld: {
        factura:"",
      },


      dataFlowOld: {
        factura:"",
      },


      dataCashoutOld: {
        factura:"",
      },
    };
  }

  componentDidMount = () => {

    let mes = moment.tz(this.state.timeZone).format("MMMM");
    let firstDay = moment.tz("America/Santiago").startOf("month").toDate();
    let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();

    this.getAllData(firstDay, lastDay, this.props.merchant.code, mes);

  };
  componentDidUpdate(prevProps) {
    if (this.props.merchant !== prevProps.merchant) {
      const merchant = localStorage.getItem("merchant_code");
      let mes = moment.tz(this.state.timeZone).format("MMMM");
      let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
      let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();
      this.setState({
        dataCashin: {factura:""},
        dataWebpay: {factura:""},
        dataFlow: {factura:""},
        dataPayku: {factura:""},
        dataCashout: {factura:""},
    });
      this.getAllData(firstDay, lastDay, merchant, mes);
    }
  }

  getAllData=async  (firstDay, lastDay, merchant, mes)  =>{

    this.setState({
      mes: mes
    });

    // LLAMO LOS DATOS PARA SER MOSTRADO EN LOS WIDGETS
    await this.gettingData("transfers", "cashin", merchant, firstDay, lastDay);
 await   this.gettingData("webpays", "webpay", merchant, firstDay, lastDay);
 await   this.gettingData("flows", "flow", merchant, firstDay, lastDay);
 await   this.gettingData("paykus", "payku", merchant, firstDay, lastDay);
 await  this.gettingData("cashouts", "cashout", merchant, firstDay, lastDay);

    //MES PASADO
 /*    let lastmonthfirstdate = moment(firstDay, "DD/MM/YYYY").tz(this.state.timeZone).subtract(1, 'months').startOf('month').toDate();
    let lastmonthlastdate = moment(lastDay, "DD/MM/YYYY").tz(this.state.timeZone).subtract(1, 'months').endOf('month').toDate(); */

   /*  this.gettingData("transfers", "cashinOld", merchant, lastmonthfirstdate, lastmonthlastdate);
    this.gettingData("webpays", "webpayOld", merchant, lastmonthfirstdate, lastmonthlastdate);
    this.gettingData("cashouts", "cashoutOld", merchant, lastmonthfirstdate, lastmonthlastdate);
 */
  }

  gettingData(collection, nomState, merchant, firstDay, lastDay) {
    const code = merchant;
    console.log("firstDay", "=>", "lastDay ", firstDay, lastDay);
   /*  try { */
      query = db
        .collection(collection)
        .where("code", "==", 0)
        .where("commerceId", "==", code)
        .where("dateRequest", ">=", firstDay)
        .where("dateRequest", "<=", lastDay)
      .get()
      .then(async snapshot => {
        if ( snapshot.empty) {
          console.log("No matching documents.");
        }
        console.log("gettingData.");
     /*    let resultadosOk = [];
        snapshot.forEach(doc => {
          resultadosOk.push({
            date: moment(doc.data().dateRequest.toDate()).format("DD/MM"),
            quantity: doc.data().quantity
          });
        }); */

        // OBJETO DE RESULTADO
        var docs = snapshot.docs.map(doc => doc.data());
        console.log("DATA " + nomState, "=>", docs);

        // SUMA TOTAL DEL MES
        let sum = _.sumBy(docs, item => Number(item.quantity));

        /****************************************COMISIONES************************************ */
        let commissionesBd = this.props.comissionData ? this.props.comissionData : JSON.parse(localStorage.getItem("comisiones"));
        commissionesBd = commissionesBd.filter(item => item.merchant === code)
        commissionesBd = commissionesBd[0].commission.filter(item => item.countrie === this.props.country.code)
        /************************************************************************************************ */

        let factor;
        if (nomState === "cashin"||nomState === "cashinOld") {
          factor = Number(commissionesBd[0].cashin);// factor = 0.045;
        } else if (nomState === "webpay"||nomState === "webpayOld") {
         factor = Number(commissionesBd[0].webPayCobro);
        } else if (nomState === "payku"||nomState === "paykuOld") {
          factor = Number(commissionesBd[0].webPayCobro);
        } else if (nomState === "flow"||nomState === "flowOld") {
          factor = Number(commissionesBd[0].webPayCobro);
        } else if (nomState === "cashout"||nomState === "cashoutOld") {
          factor = commissionesBd[0].cashout; //factor = 0.02;
        }

        let commission = Number(parseFloat(Number(sum) * factor).toFixed(2));
        let ivaCommission = Number(parseFloat(commission * 0.19).toFixed(2));

        if (isNaN(commission)) {
          commission = 0;
          ivaCommission = 0;
        }

        let response;
          response = {
            factura:commission+ivaCommission
          };

        switch (nomState) {
          case "cashin":
            this.setState({
              dataCashin: response
            });
            break;
          case "webpay":
            this.setState({
              dataWebpay: response
            });
            break;
            case "payku":
              this.setState({
                dataPayku: response
              });
              break;
              case "flow":
                this.setState({
                  dataFlow: response
                });
                break;
          case "cashout":
            this.setState({
              dataCashout: response
            });
            break;
            case "cashinOld":
              this.setState({
                dataCashinOld: response
              });
              break;
            case "webpayOld":
              this.setState({
                dataWebpayOld: response
              });
              break;
              case "paykuOld":
                this.setState({
                  dataPaykuOld: response
                });
                break;
                case "flowOld":
                  this.setState({
                    dataFlowOld: response
                  });
                  break;
            case "cashoutOld":
              this.setState({
                dataCashoutOld: response
              });
            break;
          default:
            break;
        }
      })
      .catch(console.log);
  }


  totalInvoice = () => {
    let totalInvoice = this.state.dataCashin.factura +this.state.dataCashout.factura +this.state.dataWebpay.factura  +this.state.dataPayku.factura+this.state.dataFlow.factura;
    return totalInvoice;
  };
  totalInvoiceOld = () => {
    let totalInvoice = this.state.dataCashinOld.factura +this.state.dataCashoutOld.factura +this.state.dataWebpayOld.factura  + +this.state.dataFlowOld.factura+ +this.state.dataPaykuOld.factura;
    return totalInvoice;
  };

  updateSearchFilter(evt) {
    this.setState({
      searchFilter: evt.target.value
    });
    this.filter(evt.target.value);
  }
  onChangeRange = (dates, dateStrings) => {
    let firstDay = moment(dateStrings[0], "DD/MM/YYYY").tz(this.state.timeZone).toDate();
    let lastDay = moment(dateStrings[1], "DD/MM/YYYY").tz(this.state.timeZone).endOf("day").toDate();
    let newMes = moment(dateStrings[0], "DD/MM/YYYY").tz(this.state.timeZone).format("MMMM");
    this.setState({
      dataCashin: {factura:""},
      dataWebpay: {factura:""},
      dataFlow: {factura:""},
      dataPayku: {factura:""},
      dataCashout: {factura:""},
  });
    this.getAllData(firstDay, lastDay, this.props.merchant.code, newMes);
  };

  render() {
    return (

      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
        <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Widget>
            <h2 className=" gx-text-capitalize gx-mb-0">
            Invoice Resume
            </h2>
            <hr></hr>
            <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
              <i className="icon icon-filter gx-mr-3" />
              Invoice  Filter
            </h4>
            <Row>
              <Col lg={24} md={24} sm={24} xs={24}>
                <RangePicker
                  className="gx-mb-3 "
                  format="DD/MM/YYYY"
                  ranges={{

                    "This  Year": [
                      moment()
                        .tz(this.state.timeZone)
                        .startOf("year"),
                      moment()
                        .tz(this.state.timeZone)
                        .endOf("year")
                    ],
                    "Last  Year": [
                      moment()
                        .tz(this.state.timeZone)
                        .startOf("year")
                        .subtract(1, "years"),
                      moment()
                        .tz(this.state.timeZone)
                        .endOf("year")
                        .subtract(1, "years")
                    ],

                    "This Month": [
                      moment()
                        .tz(this.state.timeZone)
                        .startOf("month"),
                      moment()
                        .tz(this.state.timeZone)
                        .endOf("month")
                    ],
                    "Last Month": [
                      moment()
                        .tz(this.state.timeZone)
                        .startOf("month")
                        .subtract(1, "months"),
                      moment()
                        .tz(this.state.timeZone)
                        .endOf("month")
                        .subtract(1, "months")
                    ],
                  }}
                  onChange={this.onChangeRange}
                  defaultValue={[
                    moment()
                      .tz(this.state.timeZone)
                      .startOf("month"),
                    moment()
                      .tz(this.state.timeZone)
                      .endOf("month")
                  ]}
                />
              </Col>
            </Row>
          </Widget>
        </Col>
      </Row>
      </Col>
      <Col lg={12} md={12} sm={24} xs={24}>
      <Row>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <GrowthCard
            avg={this.state.dataCashinOld.factura}
            param="invoice"
            title="Cashin"
            month={this.state.mes}
            suma={this.state.dataCashin.factura}
            prefix={"$"}
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <GrowthCard
           avg={this.state.dataWebpayOld.factura + this.state.dataFlowOld.factura +this.state.dataPaykuOld.factura}
            param="invoice"
            title="Webpay"
            month={this.state.mes}
            suma={this.state.dataWebpay.factura +this.state.dataPayku.factura+this.state.dataFlow.factura}
            prefix={"$"}

          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <GrowthCard
          avg={this.state.dataCashoutOld.factura}
            param="invoice"
            title="Cashout"
            month={this.state.mes}
            suma={this.state.dataCashout.factura}
            prefix={"$"}
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <GrowthCard
            avg={this.totalInvoiceOld()}
            param="invoice"
            title="Total"
            month={this.state.mes}
            suma={this.totalInvoice()}
            prefix={"$"}
            css={"gx-text-primary"}
          />
        </Col>
        </Row>
        </Col>

      </Row>

    );




  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, comissionData, country } = settings;
  return { authUser, merchant, comissionData, country };
};
export default connect(mapStateToProps)(CierresInvoiceFilter);
