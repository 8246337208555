import React from "react";
import { Col, Row, Tag, Timeline } from "antd";

function ModalChildrenSelfManagement({ payroll_id, data }) {
  return (
    <>
      <p>
        <strong>Payroll: </strong> {payroll_id}
      </p>
      <hr style={{ marginBottom: "1.5em" }} />
      <Timeline>
        <Timeline.Item color="green">
          <>
            <p>
              <strong>Current status: </strong>
              <span> {data.current.status}</span>
            </p>
            <Row>
              <Col span={12}>
                <span>
                  is enabled:{" "}
                  {data.current.isEnabled ? (
                    <Tag color="green">Yes</Tag>
                  ) : (
                    <Tag color="red">Not</Tag>
                  )}
                </span>
              </Col>
              <Col span={12}>
                <span>
                  in process:{" "}
                  {data.current.inProcess ? (
                    <Tag color="green">Yes</Tag>
                  ) : (
                    <Tag color="red">Not</Tag>
                  )}
                </span>
              </Col>
            </Row>
          </>
        </Timeline.Item>
        <Timeline.Item color="gray">
          <>
            <p>
              <strong>Next status: </strong>
              <span> {data.next.status}</span>
            </p>
            <Row>
              <Col span={12}>
                <span>
                  is enabled:{" "}
                  {data.next.isEnabled ? (
                    <Tag color="green">Yes</Tag>
                  ) : (
                    <Tag color="red">Not</Tag>
                  )}
                </span>
              </Col>
              <Col span={12}>
                <span>
                  in process:{" "}
                  {data.next.inProcess ? (
                    <Tag color="green">Yes</Tag>
                  ) : (
                    <Tag color="red">Not</Tag>
                  )}
                </span>
              </Col>
            </Row>
          </>
        </Timeline.Item>
      </Timeline>
    </>
  );
}

export default ModalChildrenSelfManagement;
