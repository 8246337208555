import { Empty, Spin, Tag, Timeline } from "antd";

import moment from "moment";
import React, { useState, useEffect, useRef } from "react";

const statusColors = {
  INFO: {
    color: "blue",
    colorText: "#038fde"
  },
  WARNING: {
    color: "orange",
    colorText: "orange"
  },
  ERROR: {
    color: "red",
    colorText: "red"
  },
  SUCCESS: {
    color: "green",
    colorText: "green"
  }
};

const LogsPayrollDetail = ({ steps, fetching }) => {
  if (fetching) {
    return (
      <>
        <div className="fetching">
          <Spin />
        </div>
        <style jsx>{`
          .fetching {
            text-align: center;
            border-radius: 4px;
            margin-bottom: 20px;
            padding: 30px 50px;
            margin: 20px 0;
          }
          .container-timeline {
            width: 100%;
            max-height: 500px;
            overflow-y: auto;
            padding: 0 1em;
            overflow-x: visible;
          }
        `}</style>
      </>
    );
  }

  const [socketSteps, setSocketSteps] = useState([]);
  const logsEndRef = useRef(null);

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (logsEndRef && logsEndRef.current) {
      const element = logsEndRef.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: count > 1 ? "smooth" : "auto"
      });
    }
    setCount(count + 1);
  }, [socketSteps]);

  useEffect(() => {
    setSocketSteps(steps);
  }, [steps]);

  return (
    <>
      <div className="container-timeline" ref={logsEndRef}>
        <Timeline>
          {socketSteps.length ? (
            socketSteps.map((item, index) => (
              <Timeline.Item
                key={item._id}
                color={statusColors[item.status].color}
                dot={statusColors[item.status].icon}
                className="item-timeline"
              >
                <Tag
                  color={statusColors[item.status].color}
                  style={{ marginBottom: 0 }}
                >
                  {item.status}
                </Tag>
                <span style={{ color: statusColors[item.status].colorText }}>
                  [{moment(item.createdAt).format("DD MMM YYYY HH:mm:ss")}]
                </span>{" "}
                - {item.step_description}
              </Timeline.Item>
            ))
          ) : (
            <Empty description="No data logs" />
          )}
        </Timeline>
      </div>

      <style jsx>{`
        .container-timeline {
          width: 100%;
          max-height: 490px;
          overflow-y: auto;
          padding: 1em 1em;
          overflow-x: visible;
        }
      `}</style>
    </>
  );
};

export default LogsPayrollDetail;
