import React, { Component } from "react";
import BlacklistCashout from "../../../components/Blacklist/BlacklistCashout";
import BlacklistCashin from "../../../components/Blacklist/BlacklistCashin";
import { Button, Row, Col } from "antd";

class Blacklist extends Component {
  state = {
    resp: "",
    updateTable: false,
    type: "cashin"
  };
  componentDidMount = async e => {
   /*  this.state({
      type: "cashout"
    }); */
  };

  getPage = type => {
    switch (type) {
      case "cashout":
        return <BlacklistCashout />;
      case "cashin":
        return <BlacklistCashin />;
      default:
        return null;
    }
  };

  onchangeToCashout = () => {
    this.setState({
      type: "cashout"
    });
  };
  onchangeToCashin = () => {
    this.setState({
      type: "cashin"
    });
  };

  render() {
    return (
      <Row className="container p-0">
        <Col lg={24} md={24} sm={24} xs={24}>
          <h1>BLACK LIST</h1>
          <Button
            type="primary"
            className="gx-mb-0"
            onClick={this.onchangeToCashin}
          >
            CASHIN
          </Button>
          <Button
            type="primary"
            className="gx-mb-0"
            onClick={this.onchangeToCashout}
          >
            CASHOUT
          </Button>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div style={{ marginTop: "10px" }}>
            {" "}
            { this.getPage(this.state.type) }
          </div>
        </Col>
      </Row>
    );
  }
}

export default Blacklist;
