import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

const MenuPayrollHeader = ({ type }) => {
  return (
    <div>
      <Menu
        mode="horizontal"
        selectedKeys={[type]}
        style={{ border: "1px solid #ddd" }}
      >
        <Menu.Item key="validated">
          <Link to="/main/payroll/validated">Validated</Link>
        </Menu.Item>
        <Menu.Item key="pending">
          <Link to="/main/payroll/pending">Pending</Link>
        </Menu.Item>
        {/* <Menu.Item key="failed">
          <Link to="/main/payroll/failed">Failed</Link>
        </Menu.Item> */}
        <Menu.Item key="authorized">
          <Link to="/main/payroll/authorized">Authorized</Link>
        </Menu.Item>
        <Menu.Item key="created">
          <Link to="/main/payroll/created">Created</Link>
        </Menu.Item>
        <Menu.Item key="disabled">
          <Link to="/main/payroll/disabled">Disabled</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default MenuPayrollHeader;
