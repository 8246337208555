import React from "react";
import { Button, Col, Row, DatePicker, Select, Form, Input, Spin } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
/* import NumberFormat from "react-number-format"; */
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import Widget from "components/Widget/index";
/* import {
  format as formatRut
  // validate as validateRut,
  // clean as cleanRut
} from "rut.js"; */
import { AllHtmlEntities } from "html-entities";
import firebase from "firebase/app";
import Api from "../../util/api";

const { Option } = Select;
const db = firebase.firestore();
const FormItem = Form.Item;

class SumaryReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeZone: "America/Santiago",
      title: "summary report ",
      commerces: [],
      dataAssessment: [],
      spin: ""
    };
  }

  componentDidMount = async () => {
    moment.tz.setDefault(this.state.timeZone);
    let commerces = [];
    //SE TRAE LOS ID DE MERCHANT
    var ret = await db
      .collection("commerces")
      .get()
      .catch(console.log);
    if (ret && ret.docs) {
      ret.docs.map(e => {
        commerces.push({ id: e.id, name: e.data().name });
      });
    }
    this.setState({
      commerces: commerces
    });
  };

  getData = async (firstDay, lastDay, merchant, country) => {
    let parametros = {
      merchantId: merchant,
      dateInit: firstDay,
      dateFinish: lastDay,
      country: country
    };

    let nameCommece = "";
    this.state.commerces.map(d => {
      if (d.id === merchant) {
        nameCommece = d.name;
      }
    });
    try {
      const datos = await Api.utils.getAssessment(parametros);
      this.setState({
        dataAssessment: datos,
        spin: false
      });
      this.generateAndDownloadExcel();
    } catch (error) {
      console.log("error getMultiQueryDate", error);
    }
  };

  handleChange = value => {
    console.log(`selected ${value}`);
  };
  fechaActual = () => {
    // Obtener la fecha y hora actual
    const fechaHoraActual = new Date();

    // Obtener componentes individuales de la fecha y hora
    const año = fechaHoraActual.getFullYear();
    const mes = String(fechaHoraActual.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
    const día = String(fechaHoraActual.getDate()).padStart(2, "0");
    const hora = String(fechaHoraActual.getHours()).padStart(2, "0");
    const minutos = String(fechaHoraActual.getMinutes()).padStart(2, "0");
    const segundos = String(fechaHoraActual.getSeconds()).padStart(2, "0");

    // Formatear la fecha y hora como "AAAAMMDDHHMMSS"
    return `${año}${mes}${día}${hora}${minutos}${segundos}`;
  };
  handleSubmit = async e => {
    this.setState({
      spin: true
    });
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("values===>", values);
        let firstDay = moment(values.date)
          .tz(this.state.timeZone)
          .toDate();
        let lastDay = moment(values.date)
          .tz(this.state.timeZone)
          .endOf("month")
          .toDate();
        let mes = moment(values.date)
          .tz(this.state.timeZone)
          .format("MMMM");
        let year = moment(values.date)
          .tz(this.state.timeZone)
          .format("YYYY");

        this.setState({
          mes: mes,
          year
        });

        /*  this.gettingData(firstDay, lastDay, values.id); */
        this.getData(firstDay, lastDay, values.id, values.country);
      }
    });
  };
  generateAndDownloadExcel = async () => {
    const excelData = this.state.dataAssessment;
    const assessmentCount = {};
    // Crear el objeto de configuración del archivo Excel
    const config = {
      filename: `Evaluation Report ${this.fechaActual()}.xlsx`
    };

    // Crear el libro de Excel
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Evaluation Report");

    // Encabezados
    const headers = [
      "commerceId",
      "commerceReqId",
      "country",
      "dateRequest",
      "name",
      "email",
      "payMethod",
      "provider",
      "quantity",
      "status",
      "assessment"
    ];

    worksheet.addRow(headers);

    // Establecer el ancho de las columnas basado en la longitud del contenido
    headers.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1); // índice basado en 1
      const columnData = excelData.map(item => String(item[header] || "")); // Obtener los valores de la columna como cadena
      const maxLength = Math.max(
        header.length,
        ...columnData.map(value => value.length)
      );
      column.width = maxLength + 2; // Ajustar el ancho con un pequeño margen
    });

    // Datos y formato de color en función de "assessment"
    excelData.forEach(item => {
      const row = worksheet.addRow([
        item.commerceId,
        item.commerceReqId,
        item.country,
        item.dateRequest,
        item.name,
        item.email,
        item.payMethod,
        item.provider,
        item.quantity,
        item.status,
        item.assessment
      ]);
      if (assessmentCount.hasOwnProperty(item.assessment)) {
        assessmentCount[item.assessment]++;
      } else {
        assessmentCount[item.assessment] = 1;
      }
      // Aplicar formato de color según el valor de "assessment"
      const assessmentCellValue = row.getCell("K").value; // 'K' es la columna de "assessment"
      const assessmentCell = row.getCell("K");

      assessmentCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: assessmentColor(assessmentCellValue) } // Función para obtener el color
      };
    });

    // Obtener valores únicos de "assessment"
    const uniqueAssessmentValues = [
      "No valoro",
      "Terrible",
      "Malo",
      "Regular",
      "Bueno",
      "Excelente"
    ];
    // Obtener la celda de inicio de la tabla
    const assessmentTableStartCell = worksheet.getCell("M2");

    // Agregar tabla desde la celda N
    worksheet.addTable({
      name: "assessmentTable",
      ref: `${assessmentTableStartCell.address}`,
      headerRow: true,
      style: {
        theme: "TableStyleLight15"
      },
      columns: [{ name: "Assessment Values" },{name:"Total"}],
      rows: uniqueAssessmentValues.map(value => [value, assessmentCount[value] || 0])
    });
    // Obtener la primera celda de la columna "Assessment Values"
    const firstCell = worksheet.getCell("M2");
    // Obtener las celdas de la columna "Assessment Values"
    const assessmentColumnCells = worksheet.getColumn("M");

    // Obtener el número de fila de inicio de la tabla
    const startRow = firstCell._row._number;
    // Aplicar formato de color a las celdas de la columna "Assessment Values" dentro de la tabla
    assessmentColumnCells.eachCell((cell, rowNumber) => {
      if (rowNumber >= startRow) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: assessmentColor(cell.value) }
        };
      }
    });
    // Ajustar el ancho de la columna según el contenido
    assessmentColumnCells.eachCell({ includeEmpty: true }, cell => {
      const contentLength = String(cell.value).length;
      const column = worksheet.getColumn(cell.col);
      if (!column.width || contentLength > column.width) {
        column.width = contentLength;
      }
    });
    // Convertir el libro a un archivo
    const excelBuffer = await workbook.xlsx.writeBuffer();

    // Crear un objeto Blob y descargar el archivo usando FileSaver
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    FileSaver.saveAs(blob, config.filename || "data.xlsx");

    // Puedes retornar algo si es necesario
    return "Generación y descarga iniciada...";

    // Función para obtener el color basado en el valor de "assessment"
    function assessmentColor(value) {
      // Puedes definir tus propias reglas de asignación de color aquí
      if (value === "Excelente") {
        return "4CAF50";
      } else if (value === "Bueno") {
        return "8BC34A";
      } else if (value === "Regular") {
        return "FFC107";
      } else if (value === "Malo") {
        return "FF9800";
      } else if (value === "Terrible") {
        return "FF5722";
      } else {
        return "FFFFFF";
      }
    }
  };

  render() {
    /*   const { isopen, time } = this.state */
    const { getFieldDecorator } = this.props.form;
    const { commerces } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 4
        },
        sm: {
          span: 4
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 20
        }
      }
    };
    return (
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Widget>
            <h4 className="gx-text-primary gx-text-capitalize gx-mb-0">
              <i className="icon icon-editor gx-mr-3" />
              evaluation report{" "}
            </h4>
            <hr></hr>
            <h6 className="gx-mb-3 gx-mb-m-2 gx-font-weight-light">
              Generate Report
            </h6>
            <Row>
              <Col lg={10} md={10} sm={24} xs={24}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <FormItem label="Month           :">
                    <div className="">
                      {this.state.time ? this.state.time : null}
                      {getFieldDecorator("date", {
                        rules: [
                          {
                            required: true
                            //  message: "Please enter the Month!!"
                          }
                        ]
                      })(
                        <Input
                          type="month"
                          placeholder="Date"
                          className="input-text"
                        />
                      )}
                    </div>
                  </FormItem>
                  <FormItem label="Merchant:">
                    <div>
                      {getFieldDecorator("id", {
                        validateTrigger: ["onChange", "onBlur"],
                        initialValue: "",
                        rules: [
                          {
                            required: true,
                            message: "Please enter the Merchant!!"
                          }
                        ]
                      })(
                        <Select onChange={this.handleChange}>
                          <Option value="">Select Merchant</Option>
                          {commerces.map(d => (
                            <Option key={d.id}>{d.name}</Option>
                          ))}
                        </Select>
                      )}
                    </div>
                  </FormItem>

                  <Form.Item name="country" label="Country">
                    {getFieldDecorator("country", {
                      initialValue: "",
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: true,
                          message: "Please enter the Country!"
                        }
                      ]
                    })(
                      <Select onChange={this.onChangeID}>
                        <Option value={""}>Select Country </Option>
                        <Option value={"AR"}>
                          <i className={`flag flag-24 gx-mr-2 flag-ar`}></i>
                          Argentina
                        </Option>
                        <Option value={"CL"}>
                          <i className={`flag flag-24 gx-mr-2 flag-cl`}></i>
                          Chile
                        </Option>
                        <Option value={"PE"}>
                          {" "}
                          <i className={`flag flag-24 gx-mr-2 flag-pe`}></i>Peru
                        </Option>
                        <Option value={"BR"}>
                          <i className={`flag flag-24 gx-mr-2 flag-br`}></i>
                          Brasil
                        </Option>
                        <Option value={"EC"}>
                          <i className={`flag flag-24 gx-mr-2 flag-ec`}></i>
                          Ecuador
                        </Option>
                        <Option value={"CO"}>
                          <i className={`flag flag-24 gx-mr-2 flag-co`}></i>
                          Colombia
                        </Option>
                        <Option value={"MX"}>
                          <i className={`flag flag-24 gx-mr-2 flag-mx`}></i>
                          Mexico
                        </Option>
                        <Option value={"SV"}>
                          <i className={`flag flag-24 gx-mr-2 flag-sv`}></i>El
                          Salvador
                        </Option>
                        <Option value={"CR"}>
                          <i className={`flag flag-24 gx-mr-2 flag-cr`}></i>
                          Costa Rica
                        </Option>
                      </Select>
                    )}
                  </Form.Item>
                  <Button
                    className="ant-btn-primary"
                    htmlType="submit"
                    disabled={this.state.spin}
                  >
                    Generate y Download Excel{" "}
                    <i className="icon icon-long-arrow-down"></i>
                  </Button>
                  {this.state.spin === true ? (
                    <>
                      {"  Generating File ..."} <Spin />
                    </>
                  ) : null}
                </Form>
                <hr></hr>
              </Col>
            </Row>
          </Widget>
        </Col>
      </Row>
    );
  }
}
const SumaryReportForm = Form.create()(SumaryReport);
const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, comissionData, country, dataUser } = settings;
  return { authUser, merchant, comissionData, country };
};
export default connect(mapStateToProps)(SumaryReportForm);
