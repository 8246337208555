import React from "react";
import { Col, Row } from "antd";
import NumberFormat from "react-number-format";
import Metrics from "components/Metrics";
import currencyNumberFormat from "../../../util/currencyNumberFormat"

const GrowthCardBill = ({ title, month, suma, cashin, cashout, webpay, flow, payku, typeUser, banktransfer, bankcard, cash, payout, countryCode, skinsbacks, kushkiWebpay, airtm }) => {
  return (
    <Metrics styleName={`gx-card-full`} title={title + " (" + month + ")"}>
      <Row>

        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="gx-pb-0 gx-pl-4  gx-pr-4 gx-pt-3 gx-text-right" style={{ right: 0 }}>
            {/* <p className="gx-mb-0 gx-text-grey">{month}</p> */}
            <h2 className="gx-fs-xxxl gx-font-weight-medium gx-chart-up">


              {currencyNumberFormat(suma, countryCode)}

            </h2>

          </div>

        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="gx-pb-0 gx-pl-4 gx-pr-4 gx-pt-3   gx-text-right">
            {cashin !== 0 ? (
              <div className="gx-fs-xs">
                Cashin.:{" "}
                <NumberFormat
                  value={"$" + parseFloat(cashin).toFixed(0)}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                />
              </div>

            ) : (null)}

            {typeUser === "merchant" ? (
              <div className="gx-fs-xs">
                WebPay.:{" "}
                {currencyNumberFormat(webpay, countryCode)}
              </div>) : (
              <>
                {payku !== 0 ? (
                  <div className="gx-fs-xs">
                    Payku.:{" "}
                    {currencyNumberFormat(payku, countryCode)}
                  </div>
                ) : (null)}
              </>)}
            {cashout !== 0 ? (
              <div className="gx-fs-xs">
                Cashout.:{" "}
                {currencyNumberFormat(cashout, countryCode)}
              </div>
            ) : (null)}

            {banktransfer !== 0.00 ? (
              <div className="gx-fs-xs">
                BankTransfer.:{" "}
                {/*   {banktransfer} */}
                {currencyNumberFormat(banktransfer, countryCode)}

              </div>
            ) : (null)}

            {bankcard !== 0.00 ? (
              <div className="gx-fs-xs">
                BankCard.:{" "}
                {currencyNumberFormat(bankcard, countryCode)}

              </div>
            ) : (null)}
            {cash !== 0.00  ? (
              <div className="gx-fs-xs">
                Cash.:{" "}
                {currencyNumberFormat(cash, countryCode)}

              </div>
            ) : (null)}
            {payout !== 0.00 ? (
              <div className="gx-fs-xs">
                PayOut.:{" "}
                {currencyNumberFormat(payout, countryCode)}
              </div>
            ) : (null)}

            {skinsbacks !== 0 ? (
              <div className="gx-fs-xs">
                Skins Backs.:{" "}
                {currencyNumberFormat(skinsbacks, countryCode)}

              </div>
            ) : (null)}


            {kushkiWebpay !== 0 ? (
              <div className="gx-fs-xs">
                Kushki Wp.:{" "}
                {currencyNumberFormat(kushkiWebpay, countryCode)}

              </div>
            ) : (null)}


            {airtm !== 0 ? (
              <div className="gx-fs-xs">
                Airtm.:{" "}
                {currencyNumberFormat(airtm, countryCode)}

              </div>
            ) : (null)}
            <br></br>
          </div>

        </Col>
        {/*   <Col lg={15} md={24} sm={15} xs={15}>


        </Col> */}

      </Row>
    </Metrics>
  );
};

export default GrowthCardBill;
