import React from "react";
import Widget from "components/Widget/index";
import { Col, Row } from "antd";
import LineIndicator from "./LineIndicator";
import NumberFormat from "react-number-format";
import currencyNumberFormat from "../../../util/currencyNumberFormat"

const Portfolio = props => {
  let porcentajeCashin = (props.cashin * 100) / props.totalNeto;
  let porcentajeCashout = (props.cashout * 100) / props.totalNeto;
  let porcentajeWebpayC = (props.webpayC * 100) / props.totalNeto;
  let porcentajeWebpayD = (props.webpayD * 100) / props.totalNeto;
  let porcentajeWebpayO = (props.webpayO * 100) / props.totalNeto;
  let porcentajeFlow = (props.flow * 100) / props.totalNeto;
  let porcentajePayku = (props.payku * 100) / props.totalNeto;

  let porcentajeBankCard = (props.bankcard * 100) / props.totalNeto;
  let porcentajeBankTransfer = (props.banktransfer * 100) / props.totalNeto;
  let porcentajeCash = (props.cash * 100) / props.totalNeto;
  let porcentajePayOut = (props.payout * 100) / props.totalNeto;
  let porcentajeSkinsBacks = (props.skinsbacks * 100) / props.totalNeto;


  let porcentajeKushkiWebPay = (props.KushkiWebPay * 100) / props.totalNeto;
/*   let porcentajeKushikiBankTransfer = (props.KushikiBankTransfer * 100) / props.totalNeto; */

  let porcentajeAirtm = (props.airtm * 100) / props.totalNeto;

  return (
    <Widget>
      <h2 className="h4 gx-mb-3">Commissions</h2>
      <Row>
        <Col lg={12} md={12} sm={12} xs={24}>

          <div className="ant-row-flex">
            <h2 className="gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium">

              {currencyNumberFormat(props.totalNeto, props.country)}
            </h2>
         
          </div>
          <p className="gx-text-grey">Neto</p>
          <div className="ant-row-flex">
            <h2 className="gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium">
              {currencyNumberFormat(props.totalIva, props.country)}
            </h2>
            {/* <h4 className="gx-pt-2 gx-chart-up">
              64% <i className="icon icon-menu-up gx-fs-sm" />
            </h4> */}
          </div>
          <p className="gx-text-grey">IVA</p>
          <div className="ant-row-flex">
            <h2 className="gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium">

              {currencyNumberFormat(props.totalNeto + props.totalIva, props.country)}
            </h2>
            {/* <h4 className="gx-pt-2 gx-chart-up">
              64% <i className="icon icon-menu-up gx-fs-sm" />
            </h4> */}
          </div>
          <p className="gx-text-grey">Total</p>
          {/* <div className="ant-row-flex gx-mb-3 gx-mb-md-2">
            <Button className="gx-mr-2" type="primary">
              Deposit
            </Button>
            <Button className="gx-btn-cyan">Withdraw</Button>
          </div>

          <p className="gx-text-primary gx-pointer gx-d-none gx-d-sm-block gx-mb-1">
            <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle" />{" "}
            Add New Wallet
          </p> */}
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <div className="gx-site-dash">
            <h5 className="gx-mb-3">Distribution</h5>
            <ul className="gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0">

            {props.useCashin ?
                <li>
                  <LineIndicator
                    width={porcentajeCashin ? porcentajeCashin + "%" : 0 + "%"}
                    title="CashIn"
                    title2={
                      currencyNumberFormat(props.cashin, props.country)}
                    title3={currencyNumberFormat(props.ivaCashin, props.country)}
                    color="primary"
                    value={parseFloat(porcentajeCashin).toFixed(2) + "%"}
                  />
                </li> : ""
              }

              {/*
         {props.useWebpay?
           <li>
                <LineIndicator
                  width={porcentajeWebpayC?porcentajeWebpayC + "%":0+"%"}
                  title="Webpay C"
                  title2={"$" + parseFloat(props.webpayC).toFixed(0)}
                  title3={"$" + parseFloat(props.ivaWebpayC).toFixed(0)}
                  color="pink"
                  value={parseFloat(porcentajeWebpayC).toFixed(2) + "%"}
                />
              </li>
              <li>
                <LineIndicator
                  width={porcentajeWebpayD?porcentajeWebpayD + "%":0+"%"}
                  title="Webpay D"
                  title2={"$" + parseFloat(props.webpayD).toFixed(0)}
                  title3={"$" + parseFloat(props.ivaWebpayD).toFixed(0)}
                  color="orange"
                  value={parseFloat(porcentajeWebpayD).toFixed(2) + "%"}
                />
              </li>
              <li>
                <LineIndicator
                  width={porcentajeWebpayO?porcentajeWebpayO + "%":0+"%"}
                  title="Webpay O"
                  title2={"$" + parseFloat(props.webpayO).toFixed(0)}
                  title3={"$" + parseFloat(props.ivaWebpayO).toFixed(0)}
                  color="orange"
                  value={parseFloat(porcentajeWebpayO).toFixed(2) + "%"}
                />
              </li> */}
              {/*  <li>
                <LineIndicator
                  width={porcentajeFlow?porcentajeFlow + "%":0+"%"}
                  title="Flow"
                  title2={"$" + parseFloat(props.flow).toFixed(0)}
                  title3={"$" + parseFloat(props.ivaFlow).toFixed(0)}
                  color="orange"
                  value={parseFloat(porcentajeFlow).toFixed(2) + "%"}
                />
              </li> :""
            }*/}
              {props.usePayku || props.useWebpay ?
                <li>
                  <LineIndicator
                    width={porcentajePayku ? porcentajePayku + "%" : 0 + "%"}
                    title="Payku"
                    title2={currencyNumberFormat(props.payku, props.country)}
                    title3={currencyNumberFormat(props.ivaPayku, props.country)}
                    color="orange"
                    value={parseFloat(porcentajePayku).toFixed(2) + "%"}
                  />
                </li>
                : ""}
              {props.useCashout ?
                <li>
                  <LineIndicator
                    width={porcentajeCashout ? porcentajeCashout + "%" : 0 + "%"}
                    title="CashOut"

                    title2={currencyNumberFormat(props.cashout, props.country)}
                    title3={currencyNumberFormat(props.ivaCashout, props.country)}
                    color="orange"
                    value={parseFloat(porcentajeCashout).toFixed(2) + "%"}
                  />
                </li>
                : ""}
              {props.useBankTransfer ?
                <li>
                  <LineIndicator
                    width={porcentajeBankTransfer ? porcentajeBankTransfer + "%" : 0 + "%"}
                    title="Bank Transfer"
                    title2={currencyNumberFormat(props.banktransfer, props.country)}
                    title3={currencyNumberFormat(props.ivaBanktransfer, props.country)}
                    color="primary"
                    value={parseFloat(porcentajeBankTransfer).toFixed(2) + "%"}
                  />
                </li>
                : ""}
              {props.useBankCard ?
                <li>
                  <LineIndicator
                    width={porcentajeBankCard ? porcentajeBankCard + "%" : 0 + "%"}
                    title="Bank Card"
                    title2={currencyNumberFormat(props.bankcard, props.country)}
                    title3={currencyNumberFormat(props.ivaBankcard, props.country)}
                    color="primary"
                    value={parseFloat(porcentajeBankCard).toFixed(2) + "%"}
                  />
                </li>
                : ""}
              {props.useCash ?
                <li>
                  <LineIndicator
                    width={porcentajeCash ? porcentajeCash + "%" : 0 + "%"}
                    title="Cash"
                    title2={currencyNumberFormat(props.cash, props.country)}
                    title3={currencyNumberFormat(props.ivaCash, props.country)}
                    color="primary"
                    value={parseFloat(porcentajeCash).toFixed(2) + "%"}
                  />
                </li>
                : ""}
              {props.usePayOut ?
                <li>
                  <LineIndicator
                    width={porcentajePayOut ? porcentajePayOut + "%" : 0 + "%"}
                    title="PayOut"
                    title2={currencyNumberFormat(props.payout, props.country)}
                    title3={currencyNumberFormat(props.ivaPayout, props.country)}
                    color="primary"
                    value={parseFloat(porcentajePayOut).toFixed(2) + "%"}
                  />
                </li>
                : ""}


              {props.useSkinsBacks ?
                <li>
                  <LineIndicator
                    width={porcentajeSkinsBacks ? porcentajeSkinsBacks + "%" : 0 + "%"}
                    title="Skins Backs"
                    title2={currencyNumberFormat(props.skinsbacks, props.country)}
                    title3={currencyNumberFormat(props.ivaSkinsbacks, props.country)}
                    color="primary"
                    value={parseFloat(porcentajeSkinsBacks).toFixed(2) + "%"}
                  />
                </li>
                : ""}

              {/*    {porcentajeKushikiBankTransfer ?
                <li>
                  <LineIndicator
                    width={porcentajeKushikiBankTransfer ? porcentajeKushikiBankTransfer + "%" : 0 + "%"}
                    title="Kushki BankTransfer"
                    title2={currencyNumberFormat(props.KushikiBankTransfer, props.country)}
                    title3={currencyNumberFormat(props.ivaKushikiBankTransfer, props.country)}
                    color="primary"
                    value={parseFloat(porcentajeKushikiBankTransfer).toFixed(2) + "%"}
                  />
                </li>
                : ""} */}


              {porcentajeKushkiWebPay ?
                <li>
                  <LineIndicator
                    width={porcentajeKushkiWebPay ? porcentajeKushkiWebPay + "%" : 0 + "%"}
                    title="Kushki Wp"
                    title2={currencyNumberFormat(props.KushkiWebPay, props.country)}
                    title3={currencyNumberFormat(props.ivaKushkiWebPay, props.country)}
                    color="primary"
                    value={parseFloat(porcentajeKushkiWebPay).toFixed(2) + "%"}
                  />
                </li>
                : ""}

              {props.useAirtm ?
                <li>
                  <LineIndicator
                    width={porcentajeAirtm ? porcentajeAirtm + "%" : 0 + "%"}
                    title="Airtm"
                    title2={currencyNumberFormat(props.airtm, props.country)}
                    title3={currencyNumberFormat(props.ivaairtm, props.country)}
                    color="primary"
                    value={parseFloat(porcentajeAirtm).toFixed(2) + "%"}
                  />
                </li>
                : ""}
            </ul>
            {/* <p className="gx-text-primary gx-pointer gx-d-block gx-d-sm-none gx-mb-0 gx-mt-3">
              <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle" />{" "}
              Add New Wallet
            </p> */}
          </div>
        </Col>
      </Row>
    </Widget>
  );
};

export default Portfolio;
