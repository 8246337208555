import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider
} from "../../firebase/firebase";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  defaultDataSuccess
} from "../../appRedux/actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess
} from "../actions/Auth";
import API from "../../util/api";

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) => {
  let data = {};
  data.user = await auth
    .signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

  const token = await auth.currentUser.getIdToken();
  //const email = await auth.currentUser.email;

  //SE LLAMA API MERCHANTCOUNTRIES
  data.merchantContr = await API.utils
    .merchantCountries({ token, countries: "" })
    .then(merchantContr => merchantContr);

    //console.log("merchantContr",JSON.stringify(data.merchantContr));
  localStorage.setItem("merchant_country", JSON.stringify(data.merchantContr));




  //SE LLAMA API dataUser Y SE CARGA EN REDUX
  data.dataUser = await API.user
    .dataUser({ token, emailUser: email })
    .then(dataUser => dataUser);

    console.log("data.dataUser que llega al comienzo",data.dataUser)
  localStorage.setItem("dataUser", JSON.stringify(data.dataUser));
  //SE RECORRE MERCHANT DEL USER PARA OBTENER EL NOMBRE Y CODIGO POR DEFECTO -SE SETEA EN REDUX-ESTE SETEA DE ENTRADA EL COMBO

  // eslint-disable-next-line
  await data.dataUser.merchants.map(merchant => {
    if (
      data.dataUser.merchantDefault.toLowerCase() ===
      merchant.code.toLowerCase()
    ) {
      data.merchant = merchant;
      localStorage.setItem("merchant_code", merchant.code);
      localStorage.setItem("merchant_name", merchant.name);
    }
  });

   /* SE LLAMA API get comisiones; */
   data.commission= await  API.utils
   .getCommission({token, emailUser: email })
   .then(commission => commission);
  localStorage.setItem("comisiones",  JSON.stringify(data.commission));

  //SE RECORRE CONTRIES DEL USER PARA OBTENER EL NOMBRE Y CODIGO POR DEFECTO -SE SETEA EN REDUX -ESTE SETEA DE ENTRADA EL COMBO
  // eslint-disable-next-line
  await data.dataUser.countries.map(country => {
    if (data.dataUser.countryDefault === country.code) {
      data.country = country;
      localStorage.setItem("country_code", country.code);
      localStorage.setItem("country_name", country.name);
    }
  });
  return data;
};

const signOutRequest = async () =>
  await auth
    .signOut()
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage("Your request has been canceled."));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const data = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );
    const signInUser = data.user;
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem("user_id", signInUser.user.email);
      yield put(userSignInSuccess(signInUser.user.email));

      yield put(defaultDataSuccess(data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");

      localStorage.removeItem("pathname");
      localStorage.removeItem("merchant_code");
      localStorage.removeItem("merchant_name");
      localStorage.removeItem("country_code");
      localStorage.removeItem("country_name");
      localStorage.removeItem("dataUser");
      localStorage.removeItem("merchant_country");
      localStorage.removeItem("comisiones");


      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser)
  ]);
}
