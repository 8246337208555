import React, { Component } from "react";
import Merchant from "../../../../components/Merchant/Merchants";
import {Col,Row } from "antd";

class merchants extends Component {
  state = {
    resp: "",
    updateTable: false,
    type: "create"
  };
  componentDidMount = async e => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  render() {
    return (
      <>
      <Row className="container p-0">
        <Col lg={24} md={24} sm={24} xs={24}>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div style={{ marginTop: "10px" }}>
            <Merchant type={this.state.type}/>
          </div>
        </Col>
      </Row>
   </>
   );
  }
}

export default merchants;
