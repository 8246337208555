import React, { Component } from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
/* import { firebase } from "../../../firebase/firebase"; */
import Dynamic from "./Dynamic";
import moment from "moment-timezone";
import API from "../../../util/api";

/* const db = firebase.firestore(); */
/* let query; */
let dataList = [];

export class AirtmPayOutPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: " Airtm Pay out  Pending",
      payMethod: "airtmPayout",
      filename: `Airtm Pay out  Pending ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      type:"Pending",
      defaultValueRangeFilter: [
        moment().tz("America/Santiago").startOf("month"),
        moment().tz("America/Santiago").endOf("month")
      ]

    };
  }
  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps);
    /* this.componentDidMount(); */
  }
  componentDidMount = () => {
    moment.tz.setDefault(this.state.timeZone);

    let firstDay = moment
      .tz(this.state.timeZone)
      .startOf("month")
      .toDate();
    let lastDay = moment
      .tz(this.state.timeZone)
      .endOf("month")
      .toDate();
    
     
    this.getData(firstDay, lastDay);
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      moment.tz.setDefault(this.state.timeZone);
      let firstDay = moment
        .tz(this.state.timeZone)
        .startOf("month")
        .toDate();
      let lastDay = moment
        .tz(this.state.timeZone)
        .endOf("month")
        .toDate();
      
      this.getData(firstDay, lastDay);
      this.setState({
        filename: `Airtm validated ${localStorage.getItem("merchant_name")}`
      });
    }
  }

  getData = (firstDay, lastDay) => {
    this.updateQuery(firstDay, lastDay);
  };

  updateQuery = async (firstDay, lastDay) => {
    dataList = [];

    let parametros = {
      "merchant": this.props.merchant.code,
      "firstDay": firstDay,
      "lastDay": lastDay,
      "country": this.props.country.code,
      "code": 9,
      "payMethod":this.state.payMethod,
    }


    await API.utils.getAirtmPayOutForParamsPending(parametros).then(cons => {
      console.log(cons)
      this.setState({
        dataList: cons
      })

    });
  };
  update = (e) => {
    this.componentDidUpdate(this.state);
  }
  render() {
    this.expandedRowRender = record => (
      <div>
        <p>
          Email: <b>{record.email}</b>
        </p>
        {this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporteAdmin" ? (
          <div>
            {record.adminCallBackDate ?
              (<p>
                Admin Callback: Date:<b>{
                  moment(new Date(record.adminCallBackDate._seconds * 1000).toISOString()).toDate().toLocaleString("es-CL", {
                    timeZone: this.state.timeZone,
                    timeZoneName: "short"
                  }
                  )
                }
                </b>
              </p>) : ""
            }
          </div>
        ) : (
          ""
        )}
        {this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporteAdmin" ? (
          <div>
            {record.adminCallBack ?
              (<p>
                Admin Callback:<b>{record.adminCallBack}</b>
              </p>) : ""
            }
          </div>
        ) : (
          <> </>
        )}
      </div>
    );
    return (
      <Row>
        <Col span={24}>
          <Dynamic
            defaultValue={this.state.defaultValueRangeFilter}
            updateQuery={this.updateQuery}
            update={this.update}
            filename={this.state.filename}
            title={this.state.title}
            dataList={this.state.dataList}
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            merchant={this.props.merchant}
            typeUser={this.props.dataUser.typeUser}
            type={this.state.type}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { merchant, dataUser, country } = settings;
  return { merchant, dataUser, country };
};
export default connect(mapStateToProps)(AirtmPayOutPending);
