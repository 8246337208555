import React from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { Col, Row, Divider } from "antd";
import NumberFormat from "react-number-format";
import currencyNumberFormat from "../../../util/currencyNumberFormat"

import Metrics from "components/Metrics";

const GrowthCard = ({ trafficData, title, month, suma, avg, dailyAvg, cashinAmount, webpayAmount, lastMonthAmount,earlyPayments, cashOutAmount, invoiceAmount, flowAmount, paykuAmount, banktransferAmount, bankCardAmount, cashAmount, payOutAmount,countryCode ,skinsAmount,kushkiBankTarnsferAmount,kushkiWebpayAmount}) => {

  return (
    <Metrics styleName={`gx-card-full`} title={title + " (" + month + ")"}>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
        
          <div className="gx-pb-0 gx-pl-4  gx-pr-4 gx-pt-3 gx-text-right" style={{ right: 0 }}>
            {/* <p className="gx-mb-0 gx-text-grey">{month}</p> */}
          
            <h2 className="gx-fs-xxxl gx-font-weight-medium gx-chart-up">
           { Number.isNaN(suma)?currencyNumberFormat(0,countryCode):currencyNumberFormat(suma,countryCode)} 
            </h2>
          </div>
        </Col>
        {trafficData ?
        <>
        <Col lg={13} md={13} sm={13} xs={13}>
          <div className="gx-pb-0 gx-pl-4 gx-pt-3">
            <ResponsiveContainer width="100%" height={103}>
              <AreaChart
                data={trafficData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                {trafficData ?
                  <Tooltip
                    labelFormatter={index => {
                      if (index) {
                        /* console.log("trafficData[index]",trafficData[index]); */
                        if (trafficData[index]) {
                          return trafficData[index].name;
                        }
                        return "";

                      }
                    }}
                  />
                  :
                  null
                }


                {/* <Tooltip content={<CustomTooltip />} /> */}
                <defs>
                  <linearGradient id="color1" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="5%" stopColor="#FF55AA" stopOpacity={0.9} />
                    <stop offset="95%" stopColor="#6757DE" stopOpacity={0.9} />
                  </linearGradient>
                </defs>
                <Area
                  data={trafficData}
                  nameKey="name"
                  dataKey="value"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#867AE5"
                  fill="url(#color1)"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <br></br>
        </Col>
         <Col lg={11} md={11} sm={11} xs={11}>
         <div className="gx-pb-0 gx-pl-4  gx-pr-4 gx-pt-3 gx-text-right">
           {cashinAmount && cashinAmount !== 0 ? (
             <div className="gx-fs-xs">
               Cash In:{" "}
               {cashinAmount >0?currencyNumberFormat(cashinAmount,countryCode):currencyNumberFormat(0,countryCode)}
             </div>
           ) : (null)}
           {webpayAmount && webpayAmount !== 0 ? (
             <div className="gx-fs-xs">
               WebPay:{" "}
               {webpayAmount >0?currencyNumberFormat(webpayAmount,countryCode):currencyNumberFormat(0,countryCode)}
             </div>
           ) : (null)}
           {flowAmount || flowAmount === 0 ? (
             <div className="gx-fs-xs">
               Flow:{" "}
               {flowAmount >0?currencyNumberFormat(flowAmount,countryCode):currencyNumberFormat(0,countryCode)}
             </div>
           ) : (null)}

           {paykuAmount && paykuAmount !== 0 ? (
             <div className="gx-fs-xs">
               Payku:{" "}
               {paykuAmount >0?currencyNumberFormat(paykuAmount,countryCode):currencyNumberFormat(0,countryCode)}
             </div>
           ) : (null)}

           {cashOutAmount && cashOutAmount !== 0 ? (
             <div className="gx-fs-xs">
               Cash Out:{" "}
               {cashOutAmount >0?currencyNumberFormat(cashOutAmount,countryCode):currencyNumberFormat(0,countryCode)}
             </div>
           ) : (null)}

           {banktransferAmount && banktransferAmount !== 0 ? (
             <div className="gx-fs-xs">
               BankTransfer.:{" "}
       
               {banktransferAmount >0?currencyNumberFormat(banktransferAmount,countryCode):currencyNumberFormat(0,countryCode)}
               <br></br>
             </div>
           ) : (null)}

           {bankCardAmount && bankCardAmount !== 0 ? (
             <div className="gx-fs-xs">
               BankCard...:{" "}
               {bankCardAmount >0?currencyNumberFormat(bankCardAmount,countryCode):currencyNumberFormat(0,countryCode)}
               <br></br>
             </div>
           ) :
             (null)}
           {cashAmount && cashAmount !== 0 ? (
             <div className="gx-fs-xs">
               Cash.:{" "}
               {cashAmount >0?currencyNumberFormat(cashAmount,countryCode):currencyNumberFormat(0,countryCode)}
               <br></br>
             </div>
           ) : (null)}
           {payOutAmount && payOutAmount !== 0 ? (
             <div className="gx-fs-xs">
               PayOut.:{" "}
               {payOutAmount >0?currencyNumberFormat(payOutAmount,countryCode):currencyNumberFormat(0,countryCode)}
               <br></br>
             </div>

           ) : (null)}
          {lastMonthAmount && lastMonthAmount != 0 ? (
             <div className="gx-fs-xs">
              Last Month:{" "}
              {!isNaN(lastMonthAmount) ? currencyNumberFormat(lastMonthAmount,countryCode) : currencyNumberFormat(0,countryCode)}
             </div>
           ) : (null)}
          {earlyPayments && earlyPayments != 0 ? (
             <div className="gx-fs-xs">
              Early Payments:{" "}
              {!isNaN(earlyPayments) ? currencyNumberFormat(earlyPayments,countryCode) : currencyNumberFormat(0,countryCode)}
             </div>
           ) : (null)}
           {invoiceAmount && invoiceAmount != 0 ? (
             <div className="gx-fs-xs">
              Invoice.:
              {!isNaN(invoiceAmount) ? currencyNumberFormat(invoiceAmount,countryCode) : currencyNumberFormat(0,countryCode)}
              <br></br>
             </div>

           ) : (null)}
           {title !== 'Balance' ? (
             <>
               <div className="gx-fs-xs">
                 Trx Avg.:{" "}
                 {avg >0?currencyNumberFormat(avg,countryCode):currencyNumberFormat(0,countryCode)}
               </div>
               <div className="gx-fs-xs">
                 Daily Avg.:{" "}
                 {dailyAvg >0?currencyNumberFormat(dailyAvg,countryCode):currencyNumberFormat(0,countryCode)}
               </div>
               <br></br>
             </>
           ) : (null)}
           <br></br>
         </div>
       </Col>
       </>
       :<Col lg={24} md={24} sm={24} xs={24}>
       <div className="gx-pb-0 gx-pl-4  gx-pr-4 gx-pt-3 gx-text-right">
         {cashinAmount && cashinAmount !== 0 ? (
           <div className="gx-fs-xs">
             Cash In:{" "}
             {cashinAmount >0?currencyNumberFormat(cashinAmount,countryCode):currencyNumberFormat(0,countryCode)}
           </div>
         ) : (null)}
         
         {webpayAmount && webpayAmount !== 0 ? (
           <div className="gx-fs-xs">
             WebPay:{" "}
             {webpayAmount >0?currencyNumberFormat(webpayAmount,countryCode):currencyNumberFormat(0,countryCode)}
           </div>
         ) : (null)}
         {flowAmount || flowAmount === 0 ? (
           <div className="gx-fs-xs">
             Flow:{" "}
             {flowAmount >0?currencyNumberFormat(flowAmount,countryCode):currencyNumberFormat(0,countryCode)}
           </div>
         ) : (null)}

         {paykuAmount && paykuAmount !== 0 ? (
           <div className="gx-fs-xs">
             Payku:{" "}
             {paykuAmount >0?currencyNumberFormat(paykuAmount,countryCode):currencyNumberFormat(0,countryCode)}
           </div>
         ) : (null)}

       
         {banktransferAmount && banktransferAmount !== 0 ? (
           <div className="gx-fs-xs">
             BankTransfer.:{" "}
             {banktransferAmount >0?currencyNumberFormat(banktransferAmount,countryCode):currencyNumberFormat(0,countryCode)}
             <br></br>
           </div>
         ) : (null)}
         {bankCardAmount && bankCardAmount !== 0 ? (
           <div className="gx-fs-xs">
             BankCard...:{" "}
             {bankCardAmount >0?currencyNumberFormat(bankCardAmount,countryCode):currencyNumberFormat(0,countryCode)}
             <br></br>
           </div>
         ) :
           (null)}
         {cashAmount && cashAmount !== 0 ? (
           <div className="gx-fs-xs">
             Cash.:{" "}
             {cashAmount >0?currencyNumberFormat(cashAmount,countryCode):currencyNumberFormat(0,countryCode)}
             <br></br>
           </div>

         ) : (null)}
           {skinsAmount && skinsAmount !== 0 ? (
           <div className="gx-fs-xs">
             Skins.:{" "}
             {skinsAmount >0?currencyNumberFormat(skinsAmount,countryCode):currencyNumberFormat(0,countryCode)}
             <br></br>
           </div>

         ) : (null)}
             {kushkiBankTarnsferAmount && kushkiBankTarnsferAmount !== 0 ? (
           <div className="gx-fs-xs">
             Kushki BankTarnsfer.:{" "}
             {kushkiBankTarnsferAmount >0?currencyNumberFormat(kushkiBankTarnsferAmount,countryCode):currencyNumberFormat(0,countryCode)}
             <br></br>
           </div>

         ) : (null)}
                    {kushkiWebpayAmount && kushkiWebpayAmount !== 0 ? (
           <div className="gx-fs-xs">
             Kushki Wp.:{" "}
             {kushkiWebpayAmount >0?currencyNumberFormat(kushkiWebpayAmount,countryCode):currencyNumberFormat(0,countryCode)}
             <br></br>
           </div>

         ) : (null)}
         {lastMonthAmount && lastMonthAmount != 0 ? (
             <div className="gx-fs-xs">
              Last Month:{" "}
              {!isNaN(lastMonthAmount) ? currencyNumberFormat(lastMonthAmount,countryCode) : currencyNumberFormat(0,countryCode)}
             </div>
           ) : (null)}
          
           <div className="gx-fs-xs">
           <p>--------------------------</p>

           </div>

         {payOutAmount && payOutAmount !== 0 ? (
           <div className="gx-fs-xs">
             PayOut.:{" "}
             {payOutAmount >0?currencyNumberFormat(payOutAmount,countryCode):currencyNumberFormat(0,countryCode)}
             <br></br>
           </div>

         ) : (null)}
           {cashOutAmount && cashOutAmount !== 0 ? (
           <div className="gx-fs-xs">
             Cash Out:{" "}
             {!isNaN(cashOutAmount) ? currencyNumberFormat(cashOutAmount,countryCode) : currencyNumberFormat(0,countryCode)}
           </div>
         ) : (null)}

          {earlyPayments && earlyPayments != 0 ? (
             <div className="gx-fs-xs">
              Early Payments:{" "}
              {!isNaN(earlyPayments) ? currencyNumberFormat(earlyPayments,countryCode) : currencyNumberFormat(0,countryCode)}
             </div>
           ) : (null)}
         {invoiceAmount || invoiceAmount === 0 ? (
           <div className="gx-fs-xs">

             Invoice.:
             {!isNaN(invoiceAmount) ? currencyNumberFormat(invoiceAmount,countryCode) : currencyNumberFormat(0,countryCode)}
             <br></br>
           </div>

         ) : (null)}
         {title !== 'Balance' ? (
           <>
             <div className="gx-fs-xs">
               Trx Avg.:{" "}
               {avg >0?currencyNumberFormat(avg,countryCode):currencyNumberFormat(0,countryCode)}
             </div>
             <div className="gx-fs-xs">
               Daily Avg.:{" "}
               {dailyAvg >0?currencyNumberFormat(dailyAvg,countryCode):currencyNumberFormat(0,countryCode)}
             </div>
             <br></br>
           </>
         ) : (null)}
         <br></br>
       </div>
     </Col>
   }
      </Row>
    </Metrics>
  );
};

export default GrowthCard;
