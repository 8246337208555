import React from "react";
import {
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  BarChart, Bar, YAxis, Legend
} from "recharts";
import NumberFormat from "react-number-format";

const SiteVisit = props => (
  <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
    <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">
      Deposits vs Withdrawals
    </h6>
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={props.siteVisit}
        margin={{ top: 10, right: 0, left: -25, bottom: 0 }}>
        <XAxis dataKey="date" />
     {/*    <YAxis /> */}
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar dataKey="Deposits" stroke="#038FDE" fill="#038FDE" />
        <Bar dataKey="Withdrawals" stroke="#FE9E15" fill="#FE9E15" />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

function CustomTooltip({ payload, label, active }) {
  if (active ) {
    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "4px",
          padding: "10px",
          border: "1px solid grey"
        }}
      >
       {/*  <p className="label">
          <b>{label}</b>
        </p> */}
        {payload?payload.map((element, index) => (
          <p key={index} style={{ marginTop: "5px", marginBottom: "5px" }}>
            <span style={{ color: element.stroke }}>{element.dataKey}:{" "}</span>
            <span style={{ display: 'block' }}>
              <NumberFormat
                value={element.value}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
              />
            </span>
          </p>
        )):""}
      </div>
    );
  }

  return null;
}

export default SiteVisit;
