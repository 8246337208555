import { Spin, Table, Card } from "antd";
import React, { useState, useEffect } from "react";
import API from "../../../util/api";
import utils from "./utils";

const OcupationalTrafficPayin = (props) => {

  const [occupationalTrafficPayin, setOccupationalTrafficPayin] = useState();
  const [filterCountries, setFilterCountries] = useState();
  const [filterMethodsPayin, setFilterMethodsPayin] = useState();
  const [filterIsActiveCommerce, setFilterIsActiveCommerce] = useState();
  const [filterProvidersPayin, setFilterProvidersPayin] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if(props.data && props.data.filter.countries[0] && props.data.filter.countries[0].hasOwnProperty("PAYIN") && props.data.data.hasOwnProperty("PAYIN")){
      setOccupationalTrafficPayin(props.data.data.PAYIN);
      setFilterCountries(props.data.filter.countries);
      setFilterMethodsPayin(props.data.filter.methodsPayin);
      setFilterIsActiveCommerce(props.data.filter.activeCommerce);
      setFilterProvidersPayin(props.data.filter.providersPayin);
      createTable();
    } else if(props.data && props.data.filter.countries[0] && (!props.data.filter.countries[0].hasOwnProperty("PAYIN") || !props.data.data.hasOwnProperty("PAYIN")) ) {
      setLoading(true);
    } else if(props.data && !props.data.filter.countries[0]){
      setLoading(true);
    }
    
  }, [props.data])
  
 
  

  const createTable = () => {
    const data = JSON.parse(localStorage.getItem("OccupationalTraffic"));
    const payin = data.data.PAYIN;
    const countries = data.filter.countries;
    const methodsPayin = data.filter.methodsPayin;
    const activeCommerce = data.filter.activeCommerce;
    // const payout = occupationalTrafficPayin;
    // const countries =  filterCountries
    // const activeCommerce = filterIsActiveCommerce
    const countriesKey = {};
    countries[0].PAYIN.map(country => {
      countriesKey[country] = country;
    });
    let dataPayin = [];
    let key = 1;
    let methodsCountry = {};
    let color = "white"
    payin.map(commerce => {
      for (let nameCommerce in commerce) {
        for (let country in commerce[nameCommerce]) {
          for (let method in commerce[nameCommerce][country]) {
            methodsCountry[country + method] =
              commerce[nameCommerce][country][method].provider;
          }
          
        }
        console.log("[LOG] [methods]", methodsCountry)
        dataPayin.push({
          key: key,
          commerce: nameCommerce,
          active: String(activeCommerce[nameCommerce]),
          ...methodsCountry
        });
        key++;
        methodsCountry = {};
      
      }
    });
    setDataSource([...dataPayin]);
    console.log("[LOG] [datapayin]", dataPayin)
    // COLUMNAS
    let subColumns = [];
    for (let clave in countriesKey) {
      const methodsCountry = methodsPayin[clave];
      let methods = [];
      methodsCountry.map(method => {
        methods.push({
          title: method.toUpperCase(),
          dataIndex: clave + method,
          key: clave + method,
          width: 150,
          render: (text, record) => { // Cambia '1' y 25 por los valores de la celda que deseas destacar
            const cellStyle = {
                'background':  utils.utils.colorProvider(text).background, 
                'padding': '8px', 
                'color': utils.utils.colorProvider(text).color, 
                'borderRadius': '30px',
                'boxShadow': '3px 3px 5px #454545'
              
              }
            return {
              children: <span 
              style={text ? cellStyle : null }>{text}</span>,
            };
          },
        });
      });
      subColumns.push({ title: utils.utils.getNameCountry(clave), children: methods });
    }
    setColumns([
      {
        title: "COMMERCE",
        dataIndex: "commerce",
        key: "commerce",
        fixed: "left",
        width: 200
      },
      { title: "ACTIVE", dataIndex: "active", key: "active", width: 200,
      render: (text, record) => { // Cambia '1' y 25 por los valores de la celda que deseas destacar
        const cellStyle = {
            'background':  utils.utils.colorActive(text).background, 
            'padding': '8px', 
            'color': utils.utils.colorActive(text).color, 
            'borderRadius': '30px',
            'boxShadow': '3px 3px 5px #454545'
          
          }
        return {
          children: <span 
          style={text ? cellStyle : null }>{text}</span>,
        };
      },
    },
      {
        title: "COUNTRIES",
        dataIndex: "countries",
        key: "countries",
        children: subColumns
      }
    ]);
    setLoading(true);
  };
 
  return (
    <Card>
      <h2>PayIn</h2>
      {loading ? (
        <Table
          className="gx-table-responsive"
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: 2000, y: 400 }}
          bordered
          size="middle"
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50", "100"]
          }}
        />
      ) : (
        <div>
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
};

export default OcupationalTrafficPayin;
