import React, { Component } from "react";
import { Button, Drawer } from "antd";
import CustomScrollbars from "util/CustomScrollbars";

import InvoiceList from "./InvoiceList";
import AppModuleHeader from "components/AppModuleHeader/index";
import InfoView from "components/InfoView/index";
import AddInvoice from "./AddInvoice";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import _ from "lodash";
import {
  onAddInvoice,
  onDeleteInvoice,
  onGetAllInvoice,
  onUpdateInvoice
} from "../../../appRedux/actions/Invoice";

let invoiceId = "2019CL1xbet-8k3y";

const filterOptions = [
  {
    id: 1,
    name: "All invoices",
    icon: "all-contacts"
  },
  {
    id: 2,
    name: "Frequently contacted",
    icon: "frequent"
  },
  {
    id: 3,
    name: "Starred contacts",
    icon: "star"
  }
];

class Billing extends Component {
  constructor() {
    super();
    this.state = {
      noContentFoundMessage: "No Invoice found in selected folder",
      alertMessage: "",
      showMessage: false,
      selectedSectionId: 1,
      drawerState: false,
      user: {
        name: "Robert Johnson",
        email: "robert.johnson@example.com",
        avatar: "https://via.placeholder.com/150x150"
      },
      searchUser: "",
      filterOption: "All invoices",
      allInvoice: [],
      invoiceList: [],
      selectedInvoice: null,
      addInvoiceState: false
    };
  }

  componentWillMount() {
    this.props.onGetAllInvoice();
  }

  componentWillReceiveProps(nextProps) {
    console.log("invoiceList", nextProps.invoiceList);
    if (nextProps.invoiceList) {
      this.setState({
        allInvoice: nextProps.invoiceList,
        invoiceList: nextProps.invoiceList
      });
    }
  }

  BillingSideBar = user => {
    return (
      <div className="gx-module-side">
        <div className="gx-module-side-header">
          <div className="gx-module-logo">
            <i className="icon icon-contacts gx-mr-4" />
            <span>
              <IntlMessages id="invoice.invoices" />
            </span>
          </div>
        </div>

        <div className="gx-module-side-content">
          <CustomScrollbars className="gx-module-side-scroll">
            <div className="gx-module-add-task">
              <Button
                className="gx-btn-block ant-btn"
                type="primary"
                aria-label="add"
                onClick={this.onAddInvoice}
              >
                <i className="icon icon-add gx-mr-2" />
                <span>Add New Invoice</span>
              </Button>
            </div>
            <div className="gx-module-side-nav">
              <ul className="gx-module-nav">
                {filterOptions.map(option => (
                  <li key={option.id} className="gx-nav-item">
                    <span
                      className={`gx-link ${
                        option.id === this.state.selectedSectionId
                          ? "active"
                          : ""
                      }`}
                      onClick={this.onFilterOptionSelect.bind(this, option)}
                    >
                      <i className={`icon icon-${option.icon}`} />
                      <span>{option.name}</span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  addFavourite = (id, data) => {
    let invoice = data;
    invoice.starred = !data.starred;
    this.props.onUpdateInvoice(id, invoice);
  };

  onAddInvoice = () => {
    this.setState({ addInvoiceState: true });
  };
  onInvoiceClose = () => {
    this.setState({ addInvoiceState: false });
  };

  onFilterOptionSelect = option => {
    switch (option.name) {
      case "All invoices": {
        this.setState({
          selectedSectionId: option.id,
          filterOption: option.name,
          invoiceList: this.state.allInvoice
        });
        break;
      }
      case "Frequently contacted": {
        this.setState({
          selectedSectionId: option.id,
          filterOption: option.name,
          invoiceList: _.filter(
            this.state.allInvoice,
            (invoice, key) => invoice.frequently
          )
        });
        break;
      }
      case "Starred contacts": {
        this.setState({
          selectedSectionId: option.id,
          filterOption: option.name,
          invoiceList: _.filter(
            this.state.allInvoice,
            invoice => invoice.starred
          )
        });
        break;
      }
      default:
        break;
    }
  };

  onSaveInvoice = (id, data) => {
    // if (id) {
    //   this.props.onUpdateContact(id, data);
    // } else {
    this.props.onAddInvoice(data);
    // }
  };

  onDeleteInvoice = data => {
    this.props.onDeleteInvoice(data);
  };

  filterInvoice = userName => {
    const { filterOption } = this.state;
    if (userName === "") {
      this.setState({ invoiceList: this.state.allInvoice });
    } else {
      const filterInvoice = _.filter(
        this.state.allInvoice,
        invoice =>
          invoice.name.toLowerCase().indexOf(userName.toLowerCase()) > -1
      );
      if (filterOption === "All invoices") {
        this.setState({ invoiceList: filterInvoice });
      } else if (filterOption === "Frequently contacted") {
        this.setState({
          invoiceList: filterInvoice.filter(invoice => invoice.frequently)
        });
      } else if (filterOption === "Starred contacts") {
        this.setState({
          invoiceList: filterInvoice.filter(invoice => invoice.starred)
        });
      }
    }
  };

  handleRequestClose = () => {
    this.setState({
      showMessage: false
    });
  };

  updateInvoiceUser(evt) {
    this.setState({
      searchUser: evt.target.value
    });
    this.filterInvoice(evt.target.value);
  }

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState
    });
  }

  render() {
    const {
      user,
      invoiceList,
      addInvoiceState,
      drawerState,
      noContentFoundMessage
    } = this.state;
    return (
      <div className="gx-main-content">
        <div className="gx-app-module">
          <div className="gx-d-block gx-d-lg-none">
            <Drawer
              placement="left"
              closable={false}
              visible={drawerState}
              onClose={this.onToggleDrawer.bind(this)}
            >
              {this.BillingSideBar()}
            </Drawer>
          </div>
          <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
            {this.BillingSideBar(user)}
          </div>

          <div className="gx-module-box">
            <div className="gx-module-box-header">
              <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                <i
                  className="icon icon-menu gx-icon-btn"
                  aria-label="Menu"
                  onClick={this.onToggleDrawer.bind(this)}
                />
              </span>

              <AppModuleHeader
                placeholder="Search invoice"
                notification={false}
                apps={false}
                user={this.state.user}
                onChange={this.updateInvoiceUser.bind(this)}
                value={this.state.searchUser}
              />
            </div>
            <div className="gx-module-box-content">
              <div className="gx-module-box-topbar"></div>
              <CustomScrollbars className="gx-module-content-scroll">
                {invoiceList.length === 0 ? (
                  <div className="gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center">
                    {noContentFoundMessage}
                  </div>
                ) : (
                  <InvoiceList
                    invoiceList={invoiceList}
                    addFavourite={this.addFavourite}
                    onDeleteInvoice={this.onDeleteInvoice.bind(this)}
                    onSaveInvoice={this.onSaveInvoice.bind(this)}
                  />
                )}
              </CustomScrollbars>
            </div>
          </div>
        </div>

        <AddInvoice
          open={addInvoiceState}
          invoice={{
            id: invoiceId,
            name: "",
            thumb: "",
            email: "",
            phone: "",
            designation: "",
            starred: false,
            frequently: false
          }}
          onSaveInvoice={this.onSaveInvoice}
          onInvoiceClose={this.onInvoiceClose}
          onDeleteInvoice={this.onDeleteInvoice}
        />
        <InfoView />
      </div>
    );
  }
}

const mapStateToProps = ({ invoice }) => {
  const { invoiceList, selectedInvoice } = invoice;
  return { invoiceList, selectedInvoice };
};
export default connect(mapStateToProps, {
  onGetAllInvoice,
  onAddInvoice,
  onUpdateInvoice,
  onDeleteInvoice
})(Billing);
