import React from "react";
import { Avatar, Input, Modal } from "antd";

import IntlMessages from "util/IntlMessages";

class AddInvoice extends React.Component {
  constructor(props) {
    super(props);

    const {
      id,
      thumb,
      name,
      email,
      phone,
      designation,
      starred,
      frequently
    } = props.invoice;
    this.state = {
      id,
      thumb,
      name,
      email,
      phone,
      designation,
      starred,
      frequently
    };
  }

  render() {
    const {
      invoiceId,
      onSaveInvoice,
      onInvoiceClose,
      open,
      invoice
    } = this.props;
    const {
      id,
      name,
      email,
      phone,
      designation,
      starred,
      frequently
    } = this.state;
    let { thumb } = this.state;
    if (!thumb) {
      thumb = require("assets/images/placeholder.jpg");
    }
    return (
      <Modal
        title={
          invoice.name === "" ? (
            <IntlMessages id="invoice.addInvoice" />
          ) : (
            <IntlMessages id="invoice.saveInvoice" />
          )
        }
        toggle={onInvoiceClose}
        visible={open}
        closable={false}
        onOk={() => {
          if (name === "") return;
          onInvoiceClose();
          onSaveInvoice(invoiceId, {
            id: id,
            name: name,
            thumb: thumb,
            email: email,
            phone: phone,
            designation: designation,
            starred: starred,
            frequently: frequently
          });
          this.setState({
            id: id,
            name: "",
            thumb: "",
            email: "",
            phone: "",
            designation: ""
          });
        }}
        onCancel={onInvoiceClose}
      >
        <div className="gx-modal-box-row">
          <div className="gx-modal-box-avatar">
            <Avatar size="large" src={thumb} />
          </div>

          <div className="gx-modal-box-form-item">
            <div className="gx-form-group">
              <Input
                required
                placeholder="Name"
                onChange={event => this.setState({ name: event.target.value })}
                defaultValue={name}
                margin="none"
              />
            </div>
            <div className="gx-form-group">
              <Input
                placeholder="Email"
                onChange={event => this.setState({ email: event.target.value })}
                value={email}
                margin="normal"
              />
            </div>
            <div className="gx-form-group">
              <Input
                placeholder="Phone"
                onChange={event => this.setState({ phone: event.target.value })}
                value={phone}
                margin="normal"
              />
            </div>
            <div className="gx-form-group">
              <Input
                placeholder="Designation"
                onChange={event =>
                  this.setState({ designation: event.target.value })
                }
                value={designation}
                autosize={{ minRows: 2, maxRows: 6 }}
                margin="normal"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddInvoice;
