import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Divider, Popconfirm, Icon, Modal, Button, Badge, Checkbox, Tooltip, Form, Input, Alert, Select } from "antd";

import { firebase } from "../../../firebase/firebase";
import copy from "copy-to-clipboard";
import Dynamic from "./Dynamic";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";
import {
  format as formatRut,
  clean as cleanRut
  // validate as validateRut,
} from "rut.js";
import Api from "../../../util/api";
import { AllHtmlEntities } from "html-entities";
import { relativeTimeThreshold } from "moment";
import { isActiveOldCashout } from "../../../util/check-availability-new-cashout";
const entities = new AllHtmlEntities();
const FormItem = Form.Item;
const db = firebase.firestore();
let query;
let queryReason;
let dataList = [];
let dataListOperation = [];

export class cashoutPendientesBajo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: "Cashout Pending",
      filename: `Cashout Pending ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      ModalText: "Preparando Cashout...",
      loadingParametrosGenerales: true,
      parametrosGenerales: {
        isActiveNewCashout: false,
        commerces: {}
      },
      visible: false,
      confirmLoading: false,
      blacklist: [],
      firstDay: "", lastDay: "",
      reasons: [],
      recordReject: [],
      textOrBox: false,
      averageAmount:0
    };

  }
  componentDidMount = async () => {
    debugger;
    await  this.getParameter();
    this.getBalcklist();
    this.getParametrosGenerales()


    moment.tz.setDefault(this.state.timeZone);
    let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
    let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();
    this.getData(firstDay, lastDay);
    this.setState({ firstDay, lastDay });


    ///carga combo reasons
    let reasons = [];
    var ret = await db.collection("parametrosGenerales")
      .doc("cashout")
      .collection("reasonReject").get().catch(console.log);
    ret.docs.map((e) => {
      reasons.push({ code: e.data().code, name: e.data().name });
    });
/* console.log("reasons", reasons) */
    this.setState({
      reasons: reasons
    });



  };

  componentDidUpdate(prevProps) {

    if (this.props.merchant !== prevProps.merchant) {

      this.getBalcklist();

      const merchant = localStorage.getItem("merchant_code");
      moment.tz.setDefault(this.state.timeZone);
      let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
      let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();

      query = db
        .collection("cashouts")
        .where("commerceId", "==", merchant)
        .where("code", "==", 9)
        .where(
          "dateRequest",
          ">=",
          moment(firstDay)
            .tz(this.state.timeZone)
            .toDate()
        )
        .where(
          "dateRequest",
          "<=",
          moment(lastDay)
            .tz(this.state.timeZone)
            .toDate()
        );
      this.updateQuery();

      this.setState({
        filename: `Cashout Pending ${localStorage.getItem("merchant_name")}`
      });
    }
  }

  getParametrosGenerales= () => {
    this.setState({
      loadingParametrosGenerales: true
    });
    db.collection("parametrosGenerales").doc('cashout').get()
    .then(parametrosGenerales => {
      const { commerces = {}, isActiveNewCashout = false  } = parametrosGenerales.data();
      this.setState({
        parametrosGenerales: {
          isActiveNewCashout,
          commerces
        }
      });
    })
    .catch(err => {
      console.log("ERROR IN getParametrosGenerales", err);
    })
    .finally(() => {
      this.setState({
        loadingParametrosGenerales: false
      });
    })
  }

  prepararTransferencia = record => {
    this.setState({
      ModalText: "Preparando Cashout...",
      visible: true,
      confirmLoading: true
    });
    let datosApi = {
      typeAccountId: record.typeAccountId,
      numAccount: record.numAccount.replace("-", ""),
      rut: cleanRut(record.rut),
      email: record.email,
      name: entities.decode(record.name),
      bankId: Number(record.bankId),
      amount: record.quantity,
      id: record.cashoutId
    };
    console.log("record.cashoutId " + datosApi.id);

    Api.cashout
      .preparar(datosApi)
      .then(resp => {
        if (resp.resRobot.status) {
          this.updateQuery();
          this.setState({
            ModalText: "✅ Cashout preparado con éxito ✅"
          });
        } else {
          this.setState({
            ModalText: "❗️ ERROR: " + resp.resRobot.error
          });
        }
        this.setState({ confirmLoading: false });
        console.log("resp", "=>", resp.resRobot);
      })
      .catch(error => {
        this.setState({
          ModalText: "BOT ERROR: " + error,
          confirmLoading: false
        });
      });
  };

  confirmAuthorize = record => {
    // message.success("Click on Yes");

    // LLAMAR API
    let datosApi = {
      id: record.cashoutId,
      message: "Retiro realizado con éxito",
      code: 0,
      status: "OK",
      quantity: record.quantity,
      merchantRequestId: record.commerceReqId,
      merchantId: record.commerceId,
      email: record.email,
      name: record.name,
      fecha: moment.tz("America/Santiago").toDate(),
      user: this.props.dataUser.idUser
    };

    let promesaValidate = new Promise(async (resolve, reject) => {
      try {
        await Api.cashout.validate(datosApi);
        resolve();
      } catch (error) {
        reject(error);
      }
    });

    promesaValidate
      .then(() => {
   /*  this.updateQuery(); */

        try{
          let fileListValidated = JSON.parse(localStorage.getItem("fileBch"));

          let filtro = fileListValidated.find(row => record.rut === row[3] && record.quantity.toString() === row[8].toString());//&& reg.numAccount === row[7]);//&&reg.name.trim()===row[6].toString().trim());
          console.log("filtro", filtro);

          if (typeof filtro !== "undefined") {

            fileListValidated.splice(fileListValidated.findIndex(e => e === filtro), 1);
            localStorage.setItem("fileBch", JSON.stringify(fileListValidated));
          }
        }catch (error) {
          console.log("Error in fileBch:", error);
        }




        try{
          let fileListValidated = JSON.parse(localStorage.getItem("regValSanta"));

          let filtro = fileListValidated.find( row =>
            record.rut === row[3].toString().replace(/[\. ,:-]+/g, "") &&
            record.quantity.toString() ===
              row[5].toString().replace(/[\. ,.$]+/g, ""));//&& reg.numAccount === row[7]);//&&reg.name.trim()===row[6].toString().trim());
          console.log("filtro", filtro);

          if (typeof filtro !== "undefined") {

            fileListValidated.splice(fileListValidated.findIndex(e => e === filtro), 1);
            localStorage.setItem("regValSanta", JSON.stringify(fileListValidated));
          }
        }catch (error) {
          console.log("Error in regValSanta:", error);
        }



        this.componentDidUpdate(this.state);
        /*  alert("Autorizado"); */
      })
      .catch(error => alert("Error al autorizar" + error));
  };
  // const FormItem = Form.Item;


  cancel = e => {
    console.log(e);
    //message.error("Click on No");
  };

  onChangeRange = (dates, dateStrings) => {
    // console.log("From: ", dates[0], ", to: ", dates[1]);
    console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    this.getData(dateStrings, "rango");
  };

  conLog(variable) {
    console.log("variable", "=>", variable);
  }

  getData = (firstDay, lastDay) => {
    query = db
      .collection("cashouts")
      .where("commerceId", "==", this.props.merchant.code)
      .where("code", "==", 9)
      .where(
        "dateRequest",
        ">=",
        moment(firstDay)
          .tz(this.state.timeZone)
          .toDate()
      )
      .where(
        "dateRequest",
        "<=",
        moment(lastDay)
          .tz(this.state.timeZone)
          .toDate()
      );
    this.updateQuery();
  };

  getParameter = async e => {
    console.log("getParameter");

    await db
      .collection("parametrosGenerales")
      .doc("cashout")

      .get()
      .then(doc => {
        this.setState({
          averageAmount: doc.data().averageAmount


        });
      })
      .catch(err => {
        console.log("ERROR IN getParameter", err);
      });


      console.log("fin getParameter");
    }

  getBalcklist = async () => {






    let blacklist = [];
    await db
      .collection("parametrosGenerales")
      .doc("seguridad")
      .collection("blacklistCashout")
      .get()
      .then(snapshot => {
        snapshot.docs.map(resp => {
          blacklist.push({
            ...resp.data()
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN blacklist", err);
      });

    /*    blacklist= this.getBlacklistTemp();
      console.log("blacklist",blacklist); */

    this.setState({ blacklist })

  }



  updateQuery = async () => {
  /* console.log("entre a updateQuery.");*/

    dataList = [];
    query
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          this.setState({
            dataList
          });
          return;
        }

        //get the data of all the documents into an array
        // var data = snapshot.docs.map(function(doc) {
        //   return doc.data();
        // });
        let bankName = "";
        let typeAccount = "";
        snapshot.forEach(doc => {
          let cashoutId = doc.id;

          switch (Number(doc.data().bankId).toString()) {
            case "12":
              bankName = "BANCO ESTADO";
              break;
            case "1":
              bankName = "BANCO DE CHILE";
              break;
            case "37":
              bankName = "BANCO SANTANDER";
              break;
            case "9":
              bankName = "BANCO INTERNACIONAL";
              break;
            case "14":
              bankName = "SCOTIABANK-DESARROLLO";
              break;
            case "16":
              bankName = "BCI";
              break;
            case "27":
              bankName = "CORP-BANCA";
              break;
            case "28":
              bankName = "BICE";
              break;
            case "31":
              bankName = "HSBC BANK CHILE";
              break;
            case "39":
              bankName = "BANCO ITAU";
              break;
            case "45":
              bankName = "MUFG BANK, LTD.";
              break;
            case "49":
              bankName = "BANCO SECURITY";
              break;
            case "51":
              bankName = "BANCO FALABELLA";
              break;
            case "53":
              bankName = "BANCO RIPLEY";
              break;
            case "55":
              bankName = "BANCO CONSORCIO";
              break;
            case "504":
              bankName = "BANCO BBVA";
              break;
            case "672":
              bankName = "COOPEUCH";
              break;
            default:
              bankName = "---";
              break;
          }

          switch (doc.data().typeAccountId) {
            case "CCT":
              typeAccount = "Cuenta Corriente";
              break;
            case "CTV":
              typeAccount = "Chequera Electrónica / Cuenta Vista";
              break;
            case "CRUT":
              typeAccount = "CuentaRUT";
              break;
            case "AHO":
              typeAccount = "Cuenta Ahorro";
              break;
            default:
              typeAccount = "---";
              break;
          }





          /******ALERT BLACKLIST***************************************** */
          let alertBlacklist = 0;

          alertBlacklist = this.state.blacklist.filter(
            reg => reg.valor === doc.data().rut
          )
          /*******ALERT BLACKLIST**************************************** */

          dataList.push({
            ...doc.data(),
            bankName,
            typeAccount,
            cashoutId,
            alertBlacklist: alertBlacklist.length
          });

          /* // console.log(doc.id, "=>", doc.data()); */


          dataListOperation.push({
            ...doc.data(),
            bankName,
            typeAccount,
            cashoutId,
            alertBlacklist: alertBlacklist.length
          });




        });
        debugger;
        console.log("this.state.averageAmoun",this.state.averageAmount);
        let dataListFinal = dataList.filter(reg =>reg.quantity < this.state.averageAmount );
        this.setState({
         dataList: dataListFinal
       });




      })
      .catch(console.log);
    this.setState({ visibleModalReject: false })
  };

  onPressOK = () => {
    this.setState({ visible: false });
  };


  onCopyClipBoard = record => {

    console.log(record);
    let objCopy = {
      "accountDestination": record.numAccount,// "7000515617",
      "accountType": this.getTypeAccount(record.typeAccount),//"CTV",
      "aliasDestination": record.name,// "Córnéjó Nicolás",
      "amount": Number(record.quantity),//cashoutPendientes.js 60000,
      "bankDestination": record.bankId,
      "cashoutId": record.cashoutId,// "test-dev-iEHWh6GzBrGx0e24G476",
      "emailDestination": record.email,// "nik00.cd@gmail.com",
      "rutDestination": record.rut,//"265465943"
    }
    copy(JSON.stringify(objCopy));

  };

  getTypeAccount = id => {
    let typeAccount = "";
    switch (id) {
      case "Cuenta Corriente":
        typeAccount = "CCT";
        break;
      case "Chequera Electrónica / Cuenta Vista":
        typeAccount = "CTV";
        break;
      case "CuentaRUT":
        typeAccount = "CRUT";
        break;
      case "Cuenta Ahorro":
        typeAccount = "AHO";
        break;
      default:
        typeAccount = "---";
        break;
    }
    return typeAccount;
  }
  getNameBank = bankId => {
    let bankName = "";


    switch (bankId) {
      case "12":
        bankName = "BANCO ESTADO";
        break;
      case "1":
        bankName = "BANCO DE CHILE";
        break;
      case "37":
        bankName = "BANCO SANTANDER";
        break;
      case "9":
        bankName = "BANCO INTERNACIONAL";
        break;
      case "14":
        bankName = "SCOTIABANK-DESARROLLO";
        break;
      case "16":
        bankName = "BCI";
        break;
      case "27":
        bankName = "CORP-BANCA";
        break;
      case "28":
        bankName = "BICE";
        break;
      case "31":
        bankName = "HSBC BANK CHILE";
        break;
      case "39":
        bankName = "BANCO ITAU";
        break;
      case "45":
        bankName = "MUFG BANK, LTD.";
        break;
      case "49":
        bankName = "BANCO SECURITY";
        break;
      case "51":
        bankName = "BANCO FALABELLA";
        break;
      case "53":
        bankName = "BANCO RIPLEY";
        break;
      case "55":
        bankName = "BANCO CONSORCIO";
        break;
      case "504":
        bankName = "BANCO BBVA";
        break;
      case "672":
        bankName = "COOPEUCH";
        break;
      default:
        bankName = "---";
        break;
    }


    return bankName;
  }

  /* onChange (record,checked) {
    console.log("onChange",record.cashoutId,checked);

    this.upadateDocumentation(record.cashoutId,"pending documentation")

  }; */

  onChangeCheck =async e => {

    console.log("onChange", e.target.checked, e.target.id);

    let data = e.target.id;
    let documentationPending = "";

    if (e.target.checked) { documentationPending = "document pending"; }
    else {
      documentationPending = "";
    }
      await this.upadateDocumentation(data.cashoutId, e.target.checked);
    this.setState({
      upadateComponet: true
    });
    this.componentDidUpdate(this.state);
  };

  upadateDocumentation = (id, documentation) => {
    new Promise(async (resolve, reject) => {
      query = db
        .collection("cashouts")
      await query
        .doc(id)
        .update({
          documentation: documentation,
        })
        .then(function () {
          resolve(true)
          console.log("Document successfully updated upadateDocumentation!");

        })
        .catch(err => {
          reject(false)
          console.log("ERROR IN upadateDocumentation", err);
        });
    })

  };



///REJECT///

  hideModalReject = () => {
    this.setState({
      visibleModalReject: false,
      messageModal: "",
      recordReject: []
    });
    this.props.form.setFieldsValue({
      ["Reason"]: "",

    });
  };

  showModalReject = record => {

    console.log("record", record);


    var test = [];
    test = record;

    console.log("test.cashoutId", test.cashoutId);

    this.setState({
      visibleModalReject: true,
      messageModal: "",
      recordReject: test,
      textOrBox: false
    });
    this.props.form.setFieldsValue({
      ["Reason"]: "",
    });
  };


  handleSubmitReject = async e => {
    e.preventDefault();
    let flag=true;
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("values in handleSubmitReject", values, values.Reason);
        let reason = values.Reason;
     /*    console.log("reason", reason); */
        if (!this.state.textOrBox) {
          let filter = this.state.reasons.filter(d => d.code === values.Reason)
          reason = filter[0];
        }
        else {
          reason = {
            code: values.Reason,
            name: values.Reason
          };
        //se agrega la nueva  reason a la coleccion
        flag=  await  this.addReason(reason);

        }
        if(flag)
        {
          console.log("flag addReason", flag);

          await this.confirmReject(this.state.recordReject,reason)
       setTimeout(async() => {
        await this.updateQuery();
       }, 1000);


  /*         let promesaValidate = new Promise(async (resolve, reject) => {
            try {
              await this.confirmReject(this.state.recordReject,reason);
              resolve();
            } catch (error) {
              reject(error);
            }
          });

          promesaValidate
            .then(() => {
              this.updateQuery();
               alert("Rechazado");
            })
            .catch(error => {
              this.setState(
                {messageModal:"Error al rechazar" ,
                 messageModalType:"error"}),
              console.log("Error al rechazar" + error)} );
 */
        }
        else{
         this.setState({messageModal:"Reason already exists, please enter a new one"})
        }
      }
    });
  }
  confirmReject  =async (record,reason) => {
    // message.success("Click on Yes");

    // LLAMAR API
    let datosApi = {
      id: record.cashoutId,
      message: reason,
      code: 12,
      status: "Failed",
      quantity: record.quantity,
      merchantRequestId: record.commerceReqId,
      merchantId: record.commerceId,
      email: record.email,
      name: record.name,
      fecha: moment.tz("America/Santiago").toDate(),
      user: this.props.dataUser.idUser
    };

    let promesaValidate =  await Api.cashout.validate(datosApi);
    await this.upadateReason(this.state.recordReject.cashoutId, reason);

   /*    try {
        await Api.cashout.validate(datosApi);
        resolve();
      } catch (error) {
        reject(error);
      }
    }); */

    /* promesaValidate
      .then(() => {


         alert("Rechazado");
      })
      .catch(error => {
        this.setState(
          {messageModal:"Error al rechazar" ,
           messageModalType:"error"}),
        console.log("Error al rechazar" + error)} ); */
  };

  addReason = async reasons => {
let resp=false;
let resFirestore;

await db.collection("parametrosGenerales")
.doc("cashout")
.collection("reasonReject")
.where("code","==",reasons.code)
.get()
.then(async snapshot => {
  if (snapshot.empty) {
    console.log("No matching documents.");
    resp=true

  }
  else{
    resp=false
  }
 /*  return resp; */
})
.catch(error => console.log("error en Existencia", error));

if(resp)
{
   /*  console.log("addReason", reasons,resFirestore) */
  await db.collection("parametrosGenerales")
  .doc("cashout")
  .collection("reasonReject")
  .add({
    code: reasons.code,
    name: reasons.name,
  })
  .then(function () {
    resp= true;
  }
  )
  .catch(function () {
    resp= false;
  }
  );
}


      return  resp;
  }

  upadateReason = (id, reason) => {

    console.log("id", id, "Reason", reason);

      new Promise(async (resolve, reject) => {
        queryReason = db
          .collection("cashouts")
        await queryReason
          .doc(id)
          .update({
            reason: reason,
          })
          .then(function () {

            resolve(true)

            console.log("Document successfully updated!");

          })
          .catch(err => {
            reject(false)
            console.log("ERROR IN saveLocalStore", err);
          });
      })

  };



  onChange = e => {
    console.log("onChange", e.target.checked);
    this.setState({ textOrBox: e.target.checked });
    if (e.target.checked) {
      /*   this.props.form.resetFields(
          ["operationCode"]
        ); */
      /*  this.props.form.setFieldsValue({
         ["codigo"]: '0',
       }); */
    }
  };


  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const { reasons, recordReject, visibleModalReject, messageModal } = this.state;

    // let { visible, confirmLoading, ModalText, sortedInfo } = this.state;
    let { visible, confirmLoading, ModalText } = this.state;
    // sortedInfo = sortedInfo || {};
    this.columns = [
      {
        title: "Date",
        dataIndex: "dateRequest",
        key: "dateRequest",

        width: 200,
        render: date =>
          date.toDate().toLocaleString("es-CL", {
            timeZone: this.state.timeZone,
            timeZoneName: "short"
          })
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",

        width: 150,
        render: text => entities.decode(text),
        sorter: (a, b) => a.name - b.name,
        sortOrder:
          this.state.sortedInfo.columnKey === "name" &&
          this.state.sortedInfo.order
      },
      {
        title: "Amount",
        dataIndex: "quantity",
        key: "quantity",

        width: 100,
        render: text => (
          <NumberFormat
            value={text}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        )
      },
      {
        title: `ID ${this.props.merchant.name}`,
        dataIndex: "commerceReqId",
        key: "commerceReqId",

        width: 100
      },
      {
        title: "Zippy ID",
        dataIndex: "cashoutId",
        key: "cashoutId",

        width: 100
      }


    ];

    this.columnsActions = [
      {
         title: (
          <Tooltip title="Pre Validation">
            <span>pre Val</span>{" "}
          </Tooltip>
        ),
        dataIndex: "Document",
        key: "Document",
        width: 50,
        render: (text, record) => (
          <span>
          {text === "Aceptada" ? (
              <div style={{ color: "#87d068" }}>{"Aceptada"}</div>
            ) : text === "INVALIDO" ? (
              <div style={{ color: "#ff0000" }}>
                <Tooltip title="INVALIDO!">
                  {" "}
                  <img
                    style={{ width: "100px", height: "100px" }}
                    alt=""
                    src={require("assets/images/not-valid.jpg")}
                  />
                </Tooltip>
              </div>
            ) : (
              <div>
                <Tooltip title="No encontrada!">
                  {" "}
                  <Badge color="grey" />
                </Tooltip>
              </div>
            )}
          </span>
        )
      },
      {
	title: (
          <Tooltip title="Validation Banco de Chile">
            <span style={{ color: "blue" }}>valBCH</span>{" "}
          </Tooltip>
        ),
        dataIndex: "valBCH",
        key: "valBCH",
        width: 100,
        render: text => (
          <span>
            {text === "Aceptada" ? (
              <div style={{ color: "#87d068" }}>
                {" "}
                <Tooltip title="Aceptada!">
                  {" "}
                  <img
                    style={{ width: "30px", height: "30px" }}
                    alt=""
                    src={require("assets/images/chile.jpg")}
                  />
                </Tooltip>
              </div>
            ) : text === "Rechazada" ? (
              <div style={{ color: "#ff0000" }}>
                <Tooltip title="Rechazada!">
                  {" "}
                  <img
                    style={{ width: "30px", height: "30px" }}
                    alt=""
                    src={require("assets/images/rechazoRed.png")}
                  />
                </Tooltip>
              </div>
            ) : (
              <div>
                <Tooltip title="No encontrada!">
                  {" "}
                  <Badge color="grey" />
                </Tooltip>
              </div>
            )}
          </span>
        )
      },
      {
        title: (
          <Tooltip title="Validation Banco santander">
            <span style={{ color: "red" }}>valSAN</span>{" "}
          </Tooltip>
        ),
        dataIndex: "valSAN",
        key: "valSAN",
        width: 10,
        render: text => (
          <span>
            {text === "Aceptada" ? (
              <div style={{ color: "#87d068" }}>
                {" "}
                <Tooltip title="Aceptada!">
                  {" "}
                  <img
                    style={{ width: "20px", height: "20px" }}
                    alt=""
                    src={require("assets/images/santa.jpg")}
                  />
                </Tooltip>{" "}
              </div>
            ) : text === "Rechazada" ? (
              <div style={{ color: "#ff0000" }}>
                <Tooltip title="Rechazada!">
                  {" "}
                  <img
                    style={{ width: "30px", height: "30px" }}
                    alt=""
                    src={require("assets/images/rechazoBlack.png")}
                  />
                </Tooltip>
              </div>
            ) : (
              <div>
                {" "}
                <Tooltip title="No encontrada!">
                  {" "}
                  <Badge color="grey" />
                </Tooltip>
              </div>
            )}
          </span>
        )
      },
      {
        title: () => <Tooltip title="Black List">🏴‍</Tooltip>,
        dataIndex: "alertBlacklist",
        key: "alertBlacklist",

        width: 10,
        render: (text) => (
          <span>
		{text === 0 ? (
              <Badge color="#87d068" />
            ) : (
              <Tooltip title="black list alert!!!!">
                {" "}
                <img
                  style={{ width: "30px", height: "30px" }}
                  alt=""
                  src={require("assets/images/alerta.gif")}
                />
              </Tooltip>
            )}
          </span>
        )
        ,
        sorter: (a, b) => a.alertBlacklist - b.alertBlacklist,
        sortOrder:
          this.state.sortedInfo.columnKey === "alertBlacklist" &&
          this.state.sortedInfo.order
      },
      /*   {
          title: "",
          dataIndex: "alertBlacklist",
          key: "alertBlacklist",
          width: 10,
          render: (text,record) => (
            <span className="gx-link" onClick={() => this.onCopyClipBoard(record)}><i  class="icon icon-copy"/></span>
          )

        }, */
      {
        title: "Action",
        key: "action",

        width: 150,
        render: (text, record) => (
          isActiveOldCashout(this.state.parametrosGenerales, this.props.merchant.code, this.state.loadingParametrosGenerales) && (

            <span>
          {/*    <Popconfirm
                title="Do you want prepare the transfer?"
                onConfirm={() => this.prepararTransferencia(record)} //aca se debe guardar en la base de datos , dentro de este metodo cuando se conteste ok
                onCancel={this.cancel}
                okText="Yes"
                cancelText="No"
              >
                <span className="gx-link">
                  {record.prepared ? "✅ " : ""}Prepare
                </span>
              </Popconfirm> */}
            {/*  <Divider type="vertical" /> */}
              <Popconfirm
                title="Are you sure Authorized this CashOut?"
                onConfirm={() => this.confirmAuthorize(record)}
                onCancel={this.cancel}
                okText="Yes"
                cancelText="No"
              >
                <span className="gx-link">Authorize</span>
              </Popconfirm>
          {/*    <Divider type="vertical" /> */}
          {/*     <Popconfirm
                title="Are you sure delete this CashOut?"
                onConfirm={() => this.confirmReject(record)}
                onCancel={this.cancel}
                okText="Yes"
                cancelText="No"
                icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
              >
                <span className="gx-link">Reject</span>
              </Popconfirm> */}
              <Divider type="vertical" />
              <div onClick={() => this.showModalReject(record)}>
                <span className="gx-link">Reject</span>
              </div>
            </span>
          )
        )
      }
    ];
   /* this.columnsActionsSoporte = [
      {
        title: () => <Tooltip title="Document awaiting validation">📄</Tooltip>,// ,//🔔
        dataIndex: "Document",
        key: "Document",
        width: 50,
        render: (text, record) => (
          <span>
            <Checkbox onChange={this.onChange} id={record} checked={record.documentation} ></Checkbox>
          </span>
        )
      },
    ];*/
    this.expandedRowRender = record => (
      <div>
        <p>
          RUT: <b>{formatRut(record.rut)}</b>
        </p>
        <p>
          Bank: <b>{record.bankName}</b>
        </p>
        <p>
          Account Type: <b>{record.typeAccount}</b>
        </p>
        <p>
          Account Number: <b>{record.numAccount}</b>
        </p>
        <p>
          Email: <b>{record.email}</b>
        </p>
      </div>
    );
    return (
      <Row>

        <Col span={24}>
          <Dynamic
            dataList={this.state.dataList}
            columns={
               this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporteCashout" || this.props.dataUser.typeUser === "soporteAdmin"
                ? (this.columns = this.columns.concat(this.columnsActions))
               // : this.props.dataUser.typeUser === "soporte"
              //    ? (this.columns = this.columns.concat(this.columnsActionsSoporte))
                  : this.columns
            }
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            filename={this.state.filename}
            title={this.state.title}
            merchant={this.props.merchant}
            typeUser={this.props.dataUser.typeUser}
				    merchants={this.props.dataUser.merchants}
            upadateComponet={this.state.upadateComponet}
            isActiveOldCashout={isActiveOldCashout(this.state.parametrosGenerales, this.props.merchant.code, this.state.loadingParametrosGenerales)}
          />

        </Col>
        <Modal
          title="Recarga - Transferencia Electrónica"
          visible={visible}
          closable={false}
          footer={[
            <Button
              key="submit"
              type="primary"
              loading={confirmLoading}
              onClick={this.onPressOK}
            >
              Ok
            </Button>
          ]}
        >
          <p>{ModalText}</p>
        </Modal>
        <Modal
          title={`⚠️Are you sure delete this CashOut?⚠️`}
          // icon: <i class="icon icon-exclamation"></i>
          visible={this.state.visibleModalReject}
          onCancel={this.hideModalReject}
          bodyStyle={{ backgroundColor: "#ffccc7" }}
          onOk={this.handleSubmitReject}
          footer={[
            <Button key="back" onClick={this.hideModalReject}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmitReject}>
              Reject
            </Button>,
          ]}
        >
          <p> Zippy Id :<b>{this.state.recordReject.cashoutId}</b> </p>
          <p> Amount :<b>   <NumberFormat
            value={this.state.recordReject.quantity}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          /></b> </p>
          <p> Name :<b>{this.state.recordReject.name}</b> </p>
          <Form
            className=""
            onSubmit={this.handleSubmitReject}
          >

            <hr></hr>
            <Checkbox checked={this.state.textOrBox} onChange={this.onChange}>
              new Reason
            </Checkbox>
            <FormItem label="Reason:">
              <div className="" style={{ width: "300px" }}>



                {!this.state.textOrBox ?
                  getFieldDecorator("Reason", {
                    validateTrigger: ["onChange", "onBlur"],
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Pleace, Enter the Reason!!"
                      }
                    ]
                  })(

                    <Select
                      style={{ width: "300px" }}
                      onChange={this.handleChange}
                    >
                      <Option value="">Select the Reason</Option>
                      {reasons.map(d => (
                        <Option key={d.code}>{d.name}</Option>
                      ))}
                    </Select>
                  )

                  :
                  getFieldDecorator("Reason", {

                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Pleace, Enter the Reason!!"
                      }
                    ]
                  })(

                    <Input placeholder="Pleace, Enter the Reason" />
                  )

                }


              </div>
            </FormItem>


           {/*  <div style={{ display: "flex", padding: "5px" }}> */}

              <br></br>
              {this.state.messageModal ? <Alert
                showIcon
                description={`${this.state.messageModal} `}
                type="error"
              /> : null}
          {/*   </div>
 */}
          </Form>

        </Modal>

      </Row>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { merchant, dataUser } = settings;
  return { merchant, dataUser };
};
const cashoutPendingForm = Form.create()(cashoutPendientesBajo);
export default connect(mapStateToProps)(cashoutPendingForm);
