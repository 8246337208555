import React, { Component } from "react";
import { Button, Form, Input, Alert, Row, Col, Select, DatePicker, Badge } from "antd";
import Widget from "components/Widget/index";
import { firebase, auth } from "../../firebase/firebase";
import Dynamic from "./Dynamic2";
import GridMerchants from "./GridMerchants";
import SelectM from "./Select";
import moment from "moment";
import API from "../../util/api";
import {
  // format as formatRut
  // validate as validateRut,
  clean as cleanRut
} from "rut.js";

const db = firebase.firestore();
/* const FormItem = Form.Item;
const { TextArea } = Input; */
/* let query; */
const { RangePicker } = DatePicker;
class MultySearchs extends Component {
  state = {
    resp: "",
    updateTable: false,
    typeAlert: "success",
    boxMerchant: [],
    type: "create",
    timeZone: "America/Santiago",
    firstDay: "",
    lastDay: "",
    defaultValueRange: "",
    loading: false,
    country: "CL"
  };
  componentDidMount = async e => {

    this.getCommerces();


  };

  handleSubmit = async e => {
    e.preventDefault();


    this.setState({ loading: true, dataList: [], suma: 0, totalRegister: 0 })
    this.props.form.validateFields((err, values) => {
      try {
        if (!err) {
          console.log("!!!!!!!!!!!!!! ");
          console.log("Received values of form: ", values, "firstDay", this.state.firstDay.toString(), "lastDay", this.state.lastDay.toString());
          const rangeValue = values['range-picker'];
          console.log("rangeValue ", rangeValue);


          let flag = false;
          let rut = values.rut.toString().replace('.', '').replace('-', '')

          /*   if (values.country!="") { */
          this.setState({ country: values.country })
          /*  }
                */
          try {
            let payload = {
              email: values.email,
              merchant: values.merchant,
              rut: cleanRut(rut),
              /*   firstday: {values.firstday!=""?values.firstday:""},
                lastday: values.lastday, */
              operationCode: values.operationCode,
              bankOperationCode: values.bankOperationCode,
              code: values.code,
              quantity: values.quantity,
              name: values.name,
              country: values.country,
              commerceReqId: values.commerceReqId,
              firstDay: this.state.firstDay,
              lastDay: this.state.lastDay,
              typeMethod: values.typeMethod,
            };
            console.log("payload", payload);
            API.utils.superSearchZippy(payload).then(trxData => {
              console.log("superSearchZippy :", trxData);
              if (trxData.status !== 400) {
                console.log("dataList", trxData.data, trxData.totalRegister);
                this.setState({ dataList: trxData.data, totalRegister: trxData.totalRegister, suma: trxData.suma, resp: "OK", typeAlert: "success", loading: false })
              } else {
                this.setState({ dataList: [], totalRegister: 0, suma: 0, resp: trxData.error, typeAlert: "error", loading: false })
              }
            })
              .catch(error => {
                console.log("Error en superSearchZippy :", error);
                this.setState({ dataList: [], totalRegister: 0, suma: 0, resp: err, typeAlert: "error", loading: false })
              })
          } catch (err) {
            console.log("Error en superSearchZippy :", err);
          }

          this.setState({ loading: false })
          setTimeout(
            function () {
              this.setState({ resp: "" });
            }.bind(this),
            20000
          );
        }
      } catch (error) {
        console.error(error);
        this.setState({
          resp: `Commerce: Error when trying to add a record .\nerror:: ${error}\n`,
          updateTable: !this.state.updateTable,
          typeAlert: "error",
          dataList: [], totalRegister: 0, suma: 0,
          loading: false
        });
        setTimeout(
          function () {
            this.setState({ resp: "" });
          }.bind(this),
          20000
        );
      }
    });
  };
  onChangeRange = (dates, dateStrings) => {
    let firstDay = ""; let lastDay = ""; let defaultValueRange = "";
    console.log("dates", dates)
    if (dates) {
      firstDay = moment(dateStrings[0], "DD/MM/YYYY").tz(this.state.timeZone).toDate();
      lastDay = moment(dateStrings[1], "DD/MM/YYYY").tz(this.state.timeZone).endOf("day").toDate();
    }

    console.log(firstDay)
    console.log(lastDay)
    this.setState({ firstDay, lastDay })

  }
  getCommerces = async () => {
    let merchant = [];
    await db
      .collection("commerces")
      .get()
      .then(snapshot => {
        snapshot.docs.map(async docCommerce => {
          merchant.push({
            code: docCommerce.id,
            name: docCommerce.data().name
          });
        });
      })
      .catch(err => {
        console.log("ERROR IN merchant", err);
      });
    this.setState({ boxMerchant: merchant });
  };

  clearFrom = () => {
    this.props.form.resetFields();
    this.onChangeRange("", "")

    this.setState({
      dataList: [], suma: 0, totalRegister: 0, country: "CL"
    })
  };


  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const rangeConfig = {
      rules: [
        {
          type: 'array',
          required: true,
          message: 'Please select time!',
        },
      ],
    };
    /*    const AutoCompleteOption = AutoComplete.Option; */

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 12
        },
        sm: {
          span: 8
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16
        }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    const children = [];

    if (this.state.boxMerchant) {
      this.state.boxMerchant.map(merchant => {
        children.push(
          <Option key={merchant.code.toString()}>
            {merchant.name.toString()}
          </Option>
        );
      });
    }
    this.expandedRowRender = record => (
      <div>

        <p>
          Email: <b>{record.email}</b>
        </p>
        {record.operationCode ? (
          <div>
            <p>
              Operation Code:<b>{record.operationCode}</b>
            </p>
          </div>
        ) : (
          ""
        )}
        {record.bankOperationCode ? (
          <div>
            <p>
              Bank Code:<b>{record.bankOperationCode}</b>
            </p>
          </div>
        ) : (
          ""
        )}

        <p>
          Country: <b>
            {record.country == "CL" ? <i className={`flag flag-24 gx-mr-2 flag-cl`}  ></i>
              : record.country == "PE" ? <i className={`flag flag-24 gx-mr-2 flag-pe`}  ></i>
                : record.country == "BR" ? <i className={`flag flag-24 gx-mr-2 flag-br`}  ></i>
                  : record.country == "CRC" ? <i className={`flag flag-24 gx-mr-2 flag-cr`}  ></i>
                    : record.country == "EC" ? <i className={`flag flag-24 gx-mr-2 flag-ec`}  ></i>
                      : <i className={`flag flag-24 gx-mr-2 flag-cl`}  ></i>
            }
          </b>
        </p>
        {record.adminCallBack ? (
          <div>
            <p>
              Support CallBack:<b>{record.adminCallBack}</b>
            </p>
          </div>
        ) : (
          ""
        )}
        {record.adminCallBackDate ? (
          <div>
            <p>
              Support CallBack Date:{/* <b>{record.adminCallBackDate}</b> */}

              <span>{moment(new Date(record.adminCallBackDate._seconds * 1000).toISOString()).toDate().toLocaleString("es-CL", {
                timeZone: this.state.timeZone,
                timeZoneName: "short"
              })}</span>


            </p>
          </div>
        ) : (
          ""
        )}
        {record.errorCode ? (
          <div>
            <p>
              Error Code:<b>{record.errorCode}</b>
            </p>
          </div>
        ) : (
          ""
        )}

        {record.errorInfo ? (
          <div>
            <p>
              Error Info:<b>{record.errorInfo}</b>
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    );

    return (
      <Row>

        <Col lg={24} md={24} sm={24} xs={24}>
          <Widget
            title={
              <h4
                className=" gx-text-capitalize gx-mb-0"
                style={{ color: "#070707" }}
              >
                <i className="icon icon-search" />
                <span>{" MultiSearch"}</span>
              </h4>
            }
          >
            <hr></hr>
            {this.state.resp ? (
              <Alert
                message="Resultado"
                description={this.state.resp}
                type="success"
              />
            ) : null}
            <br></br>
            <Form
              {...formItemLayout}
              onSubmit={this.handleSubmit}
              //form={form}
              name="register"
              scrollToFirstError
            >
              <Row>
                <Col lg={12} md={12} sm={24} xs={24}>
                  {/*  {this.state.type==="edit"? */}
                  <Form.Item name="rut" label="Rut">
                    {getFieldDecorator("rut", {
                      initialValue: "",
                      rules: [
                        {
                          /*   required: true, */
                          message: "Please enter the Rut!"
                        }
                      ]
                    })(<Input placeholder="Rut" />)}
                  </Form.Item>
                  {/*     :
                null
                } */}
                  <Form.Item name="email" label="Email">
                    {getFieldDecorator("email", {
                      initialValue: "",
                      rules: [
                        {
                          type: 'email',
                          /*  required: true, */
                          message: "Please enter the Email!"
                        }
                      ]
                    })(<Input placeholder="Email" disabled={this.state.type === "edit"} />)}
                  </Form.Item>
                  <Form.Item name="operationCode" label="Operation Code">
                    {getFieldDecorator("operationCode", {
                      initialValue: "",
                      rules: [
                        {

                          /*   required: true, */
                          message: "Please enter the Operation Code!"
                        }
                      ]
                    })(<Input placeholder="Operation Code" />)}
                  </Form.Item>

                  <Form.Item name="country" label="Country">
                    {getFieldDecorator("country", {
                      initialValue: "",
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          /*   required: true, */
                          message: "Please enter the Country!"
                        }
                      ]
                    })(
                      <Select onChange={this.onChangeID} >
                        <Option value={""}>Select Country </Option>
                        <Option value={"CL"}><i className={`flag flag-24 gx-mr-2 flag-cl`}></i>Chile</Option>
                        <Option value={"PE"}> <i className={`flag flag-24 gx-mr-2 flag-pe`}></i>Peru</Option>
                        <Option value={"BR"}><i className={`flag flag-24 gx-mr-2 flag-br`}></i>Brazil</Option>
                        <Option value={"EC"}><i className={`flag flag-24 gx-mr-2 flag-ec`}></i>Ecuador</Option>
                        <Option value={"CRC"}><i className={`flag flag-24 gx-mr-2 flag-cr`}></i>Costa Rica</Option>
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item name="status" label="Status">
                    {getFieldDecorator("code", {
                      initialValue: "",
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          /*   required: true, */
                          message: "Please enter the value!"
                        }
                      ]
                    })(
                      <Select onChange={this.onChangeID}>
                        <Option value={""}>Select Status </Option>
                        <Option value={"0"}>Validated <Badge status="success" /></Option>
                        <Option value={"9"}>Pending <Badge status="warning" /></Option>
                        <Option value={"12"}>Failed  <Badge status="error" /></Option>
                        <Option value={"1"}>Visitor <Badge status="processing" /></Option>

                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item name="typeMethod" label="Type Method">
                    {getFieldDecorator("typeMethod", {
                      initialValue: "",
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          /*   required: true, */
                          message: "Please enter the Type Method!"
                        }
                      ]
                    })(
                      <Select onChange={this.onChangeID} >
                        <Option value={""}>Select Type Method </Option>
                        <Option value={"cashin"}><i ></i>Cashin</Option>
                        <Option value={"cashout"}> <i ></i>Cashout</Option>
                        <Option value={"payku"}><i ></i>Payku</Option>
                        <Option value={"banktransfer"}><i ></i>Bank Transfer</Option>
                        <Option value={"cash"}><i ></i>Cash</Option>
                        <Option value={"kushkiWp"}><i ></i>Kushki Wp</Option>
                        <Option value={"airtm"}><i ></i>Airtm</Option>
                        <Option value={"airtmPayOut"}><i ></i>Airtm Pay OUT</Option>
                        <Option value={"payout"}><i ></i>Pay Out</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Form.Item name="name" label="Name">
                    {getFieldDecorator("name", {
                      initialValue: "",
                      rules: [
                        {
                          type: "string",
                          /*  required: true, */
                          message: "Please enter the Name!"
                        }
                      ]
                    })(<Input placeholder="Name" />)}
                  </Form.Item>
                  <Form.Item name="bankOperationCode" label="Bank Code">
                    {getFieldDecorator("bankOperationCode", {
                      initialValue: "",
                      rules: [
                        {
                          /*    required: true, */
                          message: "Please enter the Bank Code!"
                        }
                      ]
                    })(<Input placeholder="Bank Code" />)}
                  </Form.Item>
                  <Form.Item name="quantity" label="Amount">
                    {getFieldDecorator("quantity", {
                      initialValue: "",
                      rules: [
                        {
                          /*   required: true, */
                          message: "Please enter the Amount!"
                        }
                      ]
                    })(<Input placeholder="Amount" />)}
                  </Form.Item>
                  <Form.Item name="commerceReqId" label="ID Merchant">
                    {getFieldDecorator("commerceReqId", {
                      initialValue: "",
                      rules: [
                        {
                          /*   required: true, */
                          message: "Please enter the ID Merchant!"
                        }
                      ]
                    })(<Input placeholder="ID Merchant" />)}
                  </Form.Item>


                  <Form.Item name="merchant" label="Merchant">
                    {getFieldDecorator("merchant", {
                      initialValue: "",
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          /*   required: true, */
                          message: "Please enter the Merchant Default!"
                        }
                      ]
                    })(
                      <Select
                        //mode="tags"
                        // mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Please select Merchant "
                      // defaultValue={this.state.type !== "edit"?children:['a10', 'c12']}
                      >
                        <Option value={""}>Select Merchant </Option>
                        {children}
                      </Select>
                    )}
                  </Form.Item>

                  <Form.Item name="range-picker" label="Date" /* {...rangeConfig} */>
                    <RangePicker
                      /*  className="gx-mb-3 gx-w-50" */
                      format="DD/MM/YYYY"
                      ranges={{
                        All: [
                          moment("01-01-2019", "MM-DD-YYYY").tz(this.state.timeZone),
                          moment().tz(this.state.timeZone)
                        ],
                        Today: [
                          moment().tz(this.state.timeZone),
                          moment().tz(this.state.timeZone)
                        ],
                        Yesterday: [
                          moment()
                            .tz(this.state.timeZone)
                            .subtract(1, "days"),
                          moment()
                            .tz(this.state.timeZone)
                            .subtract(1, "days")
                        ],
                        "This week": [
                          moment()
                            .tz(this.state.timeZone)
                            .startOf("isoweek"),
                          moment()
                            .tz(this.state.timeZone)
                            .endOf("isoweek")
                        ],
                        "Last 7 Days": [
                          moment()
                            .tz(this.state.timeZone)
                            .subtract(6, "days"),
                          moment().tz(this.state.timeZone)
                        ],
                        "Last Month": [
                          moment()
                            .tz(this.state.timeZone)
                            .startOf("month")
                            .subtract(1, "months"),
                          moment()
                            .tz(this.state.timeZone)
                            .endOf("month")
                            .subtract(1, "months")
                        ],
                        "This Month": [
                          moment()
                            .tz(this.state.timeZone)
                            .startOf("month"),
                          moment()
                            .tz(this.state.timeZone)
                            .endOf("month")
                        ]
                      }}
                      onChange={this.onChangeRange}
                    //  defaultValue={this.state.defaultValueRange}
                    />
                  </Form.Item>
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item {...tailFormItemLayout}>
                    {this.state.type === "edit" ? (
                      <Button type="primary" htmlType="submit">
                        Edit user
                      </Button>
                    ) : (
                      <>
                        <Button type="primary" htmlType="submit">
                          Search
                        </Button>
                        <Button type="primary" onClick={this.clearFrom}>
                          Clean Search
                        </Button>
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Widget>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Widget
            title={
              <h4
                className=" gx-text-capitalize gx-mb-0"
                style={{ color: "#070707" }}
              >
                Result
              </h4>
            }
          >
            <Dynamic
              updateTable={this.state.updateTable}
              dataList={this.state.dataList}
              suma={this.state.suma}
              totalRegister={this.state.totalRegister}
              expandedRowRender={this.expandedRowRender}
              loading={this.state.loading}
              country={this.state.country}
            />
          </Widget>
        </Col>
      </Row>
    );
  }
}

const UsersForm = Form.create()(MultySearchs);

export default UsersForm;
