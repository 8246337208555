import React, { Component } from "react";
import { Col, Row,Button } from "antd";
import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebase";
import Dynamic from "./Dynamic";
import moment from "moment-timezone";
import GrowthCard from "components/dashboard/CRM/GrowthCardMerchant";
import _ from "lodash";
import Auxiliary from "util/Auxiliary";

const db = firebase.firestore();
let query;
let queryWeek;
let dataList = [];

export class paykuOk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: "payku Validated",
      filename: `payku Validated ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      indicadores: {
        dolar: {
          valor: ""
        }
      },
      date: "",
      datapayku: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        TotalSinCommission: 0
      },
      datapaykuWeek: {
        dataWeek: "",
        sumWeek: 0,
        countdocsWeek: 0
      },
      firstTime: true
    };
  }

  componentDidMount = () => {
    moment.tz.setDefault(this.state.timeZone);

 /*    let firstDay = moment
      .tz(this.state.timeZone)
      .startOf("month")
      .toDate();
    let lastDay = moment
      .tz(this.state.timeZone)
      .endOf("month")
      .toDate();
 */
    let firstDayWeek = moment
      .tz(this.state.timeZone)
      .startOf("isoWeek")
      .toDate();

    let lastDayWeek = moment
      .tz(this.state.timeZone)
      .endOf("isoWeek")
      .toDate();
      let now = moment().tz(this.state.timeZone).toDate();
      let yesterday = moment().tz(this.state.timeZone).subtract(1, "days").startOf('day').toDate();
    this.getData(yesterday, now, firstDayWeek, lastDayWeek);

    // CALCULO EL MES ACTUAL
    let mes = moment.tz(this.state.timeZone).format("MMMM");
    this.setState({
      mes: mes
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.merchant !== prevProps.merchant) {
      const merchant = localStorage.getItem("merchant_code");
      moment.tz.setDefault(this.state.timeZone);
/*
      let firstDay = moment
        .tz(this.state.timeZone)
        .startOf("month")
        .toDate();
      let lastDay = moment
        .tz(this.state.timeZone)
        .endOf("month")
        .toDate(); */

      let firstDayWeek = moment
        .tz(this.state.timeZone)
        .startOf("isoWeek")
        .toDate();

      let lastDayWeek = moment
        .tz(this.state.timeZone)
        .endOf("isoWeek")
        .toDate();
        let now = moment().tz(this.state.timeZone).toDate();
        let yesterday = moment().tz(this.state.timeZone).subtract(1, "days").startOf('day').toDate();
      // CALCULO EL MES ACTUAL
      let mes = moment.tz(this.state.timeZone).format("MMMM");
      this.setState({
        mes: mes
      });

      query = db
        .collection("paykus")
        .where("commerceId", "==", merchant)
        .where("code", "==", 0)
     /*    .where("dateRequest", ">=", moment(yesterday).toDate())
        .where("dateRequest", "<=", moment(now).toDate()); */

      if (firstDayWeek !== null && lastDayWeek !== null) {
        queryWeek = db
          .collection("paykus")
          .where("commerceId", "==", merchant)
          .where("code", "==", 0)
          .where(
            "dateRequest",
            ">=",
            moment(firstDayWeek)
              .tz(this.state.timeZone)
              .toDate()
          )
          .where(
            "dateRequest",
            "<=",
            moment(lastDayWeek)
              .tz(this.state.timeZone)
              .toDate()
          );
      }

      this.updateQuery();

      this.setState({
        filename: `payku Validated ${localStorage.getItem("merchant_name")}`
      });
    }
  }

  getData = (firstDay, lastDay, firstDayWeek = null, lastDayWeek = null) => {
    query = db
      .collection("paykus")
      .where("commerceId", "==", this.props.merchant.code)
      .where("code", "==", 0)
      .where("dateRequest", ">=", moment(firstDay).toDate())
      .where("dateRequest", "<=", moment(lastDay).toDate())
 /*      .startAfter(lastVisible) */
      /* .limit(10); */

    if (firstDayWeek !== null && lastDayWeek !== null) {
      queryWeek = db
        .collection("paykus")
        .where("commerceId", "==", this.props.merchant.code)
        .where("code", "==", 0)
        .where(
          "dateRequest",
          ">=",
          moment(firstDayWeek)
            .tz(this.state.timeZone)
            .toDate()
        )
        .where(
          "dateRequest",
          "<=",
          moment(lastDayWeek)
            .tz(this.state.timeZone)
            .toDate()
        );
    }

    this.updateQuery();
    console.log(dataList);
  };

  updateQuery = () => {
    let factor=0;
  /************************************COMISIONES******************************************* */
  let commissionesBd=this.props.comissionData?this.props.comissionData: JSON.parse(localStorage.getItem("comisiones"));
    commissionesBd = commissionesBd.filter(item=> item.merchant===this.props.merchant.code)
    commissionesBd = commissionesBd[0].commission.filter(item=> item.countrie===this.props.country.code)
    factor =commissionesBd[0].paykuCobro;

    // debugger;
  /************************************************************************************************ */
    dataList = [];
    query
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          this.setState({
            dataList
          });
          return;
        }
        let resultadosOk = [];
        snapshot.forEach(doc => {
          if (this.state.firstTime) {
            resultadosOk.push({
              date: moment(doc.data().dateRequest.toDate()).toLocaleString(
                "es-CL",
                {
                  timeZone: this.state.timeZone,
                  timeZoneName: "short"
                }
              ),
              quantity: doc.data().quantity
            });

            // OBJETO DE RESULTADO
            var docs = snapshot.docs.map(doc => doc.data());

            // SUMA TOTAL DEL MES

            let sum = _.sumBy(docs, item => Number(item.quantity));
            let count = docs.length;

            // CALCULO COMISIONES

            let commission = Number(
              parseFloat(Number(sum) * factor).toFixed(2)
            );
            let ivaCommission = Number(
              parseFloat(commission * 0.19).toFixed(2)
            );

            if (isNaN(commission)) {
              commission = 0;
              ivaCommission = 0;
            }

            let TotalSinCommission = 0;
            TotalSinCommission = sum - commission - ivaCommission;

            let response;
            console.log(
              "TotalSinCommission " +
                TotalSinCommission +
                "count: " +
                count +
                "suma: " +
                sum +
                "commission " +
                commission +
                "ivaCommission " +
                ivaCommission
            );
            response = {
              sum,
              count,
              TotalSinCommission
            };

            //Week
            queryWeek.get().then(async querySnapshotWeek => {
              let resultadosOkWeek = [];

              querySnapshotWeek.forEach(doc => {
                resultadosOkWeek.push({
                  date: moment(doc.data().dateRequest.toDate()).toLocaleString(
                    "es-CL",
                    {
                      timeZone: this.state.timeZone,
                      timeZoneName: "short"
                    }
                  ),
                  quantity: doc.data().quantity
                });
              });

              // OBJETO DE RESULTADO
              var docsWeek = querySnapshotWeek.docs.map(doc => doc.data());

              // SUMA TOTAL DE LA SEMANA
              let sumdocsWeek = _.sumBy(docsWeek, item =>
                Number(item.quantity)
              );

              let countdocsWeek = docsWeek.length;

              // OBTENGO DATA AGRUPADA POR FECHA
              let dataWeek = _(resultadosOkWeek)
                .groupBy("date")
                .map((objs, key) => {
                  return {
                    date: key,
                    value: _.sumBy(objs, item => Number(item.quantity))
                  };
                })
                .value();

              let responseWeek;
              responseWeek = {
                dataWeek,
                sumdocsWeek,
                countdocsWeek
              };

              if (dataWeek.length > 0) {
                this.setState({
                  datapaykuWeek: responseWeek
                });

                console.log(this.state.datapaykuWeek.sumdocsWeek);
              }
            });

            this.setState({
              datapayku: response,
              firstTime: false
            });
          }

          let paykuId = doc.id;
          let commision = Number(
            Number(doc.data().quantity * factor ).toFixed(0)
          );
          let iva = Number((commision * 0.19).toFixed(0));

          dataList.push({
            ...doc.data(),
            paykuId,
            commision,
            iva,
            totCommision: commision + iva,
            total: Number(doc.data().quantity) - (commision + iva)
          });
        });

        this.setState({
          dataList
        });
        console.log("DATALIST", "=>", dataList);
      })
      .catch(console.log);
  };

  onTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  setPrepaidCode = (event) => {

  /* event.preventDefault(); */
    this.state.inputPreaidCode = event.target.value
  /*   this.setState({ inputPreaidCode: event.target.value }) */
  }
  savePrepaidCode = async (e) => {
    e = e || window.event;
    e = e.target || e.srcElement;

    let data = {};
    data = JSON.parse(e.id);
    /*   console.log(data.cashinId) */
    if (this.state.inputPreaidCode) {
      await db.collection("paykus").doc(data.paykuId).update({
        prepaidCode: this.state.inputPreaidCode,
        userprepaidCode: this.props.authUser
      }).then(function () {
        console.log("Update  prepaidCode OK!");

      })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
      this.componentDidUpdate(this.state);
    }
  }
  update = async (e) => {
    await this.componentDidUpdate(this.state);
  }

  render() {
    this.expandedRowRender = record => (
      <div>
        <p>
          Operation Code: <b>{record.operationCode}</b>
        </p>
        <p>
          Card Number: <b>{record.cardNumber}</b>
        </p>
        <p>
          Email: <b>{record.email}</b>
        </p>
        <p>
          AdminCallBack: <b>{record.adminCallBack}</b>
        </p>
{/*         {record.prepaidCode ? (
          <div>
            {this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporte" ? (
              <div>
                {record.prepaidCode === "Por favor, Solicite su código de recarga a nuestro soporte en linea" || record.prepaidCode === ""?
                  (<p>
                    Prepaid Code:{"⚠️⚠️⚠️"}
                    <input onChange={this.setPrepaidCode} placeholder="insert Prepaid Code" ></input>
                    <Button onClick={this.savePrepaidCode} id={JSON.stringify(record)} className="Dashed" style={{ border: "none", paddingTop: "8px" }}>💾</Button>
                  </p>)
                  :
                  <div>
                    {record.userprepaidCode ?
                      <p>
                        Prepaid Code:
                        <input onChange={this.setPrepaidCode} placeholder={` ${record.prepaidCode} `} ></input>
                        <Button onClick={this.savePrepaidCode} id={JSON.stringify(record)} className="Dashed" style={{ border: "none", paddingTop: "8px" }}>📝</Button>
                      </p>
                      :
                      <p>
                        Prepaid Code:
                  {`✅ ${record.prepaidCode} `}</p>}
                  </div>
                }
              </div>
            ) : (
                <p>
                  Prepaid Code:
                  {`${record.prepaidCode} `}</p>
              )}
          </div>
        ) : (
            ""
          )} */}
          <div>
          {record.commerceId==="2020juegalo-5n2q"?

          this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporte"  || this.props.dataUser.typeUser === "soporteAdmin" ? (
              <div>
                {record.prepaidCode === "Por favor, Solicite su código de recarga a nuestro soporte en linea" || !(JSON.stringify(record.prepaidCode))==="" || JSON.stringify(record.prepaidCode) === null || !record.prepaidCode?

                  (<p>
                    Prepaid Code:{"⚠️⚠️⚠️"}
                    <input onChange={this.setPrepaidCode} placeholder="insert Prepaid Code" ></input>
                    <Button onClick={this.savePrepaidCode} id={JSON.stringify(record)} className="Dashed" style={{ border: "none", paddingTop: "8px" }}>💾</Button>
                  </p>)
                  :
                  <div>
                    {record.userprepaidCode ?
                      <p>
                        Prepaid Code:
                        <input onChange={this.setPrepaidCode} placeholder={` ${record.prepaidCode} `} ></input>
                        <Button onClick={this.savePrepaidCode} id={JSON.stringify(record)} className="Dashed" style={{ border: "none", paddingTop: "8px" }}>📝</Button>
                      </p>
                      :
                      <p>
                        Prepaid Code:
                  {`✅ ${record.prepaidCode} `}</p>}
                  </div>
                }
              </div>
            ) : (
                <p>
                  Prepaid Code:
                  {`${record.prepaidCode} `}</p>
              )
          :
          <> </>
          }

          </div>
          {this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporteAdmin" ? (
          <div>
            {record.userprepaidCode ?
              (<p>
                User Prepaid Code:<b>{record.userprepaidCode}</b>
              </p>) : ""
            }
          </div>
        ) : (
            ""
          )}
        {record.oneclick ? (
          <p>
            One Click:<b>{'✅'}</b>
          </p>
        ) : (
          ""
        )}
      </div>
    );

    let view;
    if (this.props.dataUser.typeUser === "merchant") {
      view = (
          <Row>
            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
              <GrowthCard
                avg={parseFloat(this.state.datapaykuWeek.sumdocsWeek).toFixed(
                  0
                )}
                title="Transaction Amount"
                month={this.state.mes}
                suma={this.state.datapayku.sum}
                prefix={"$"}
              />
            </Col>
            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
              <GrowthCard
                title="Total Transactions"
                month={this.state.mes}
                suma={this.state.datapayku.count}
                avg={this.state.datapaykuWeek.countdocsWeek}
              />
            </Col>
            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
              <GrowthCard
                param="0"
                title="Total without commision"
                month={this.state.mes}
                suma={this.state.datapayku.TotalSinCommission}
                prefix={"$"}
              />
            </Col>
            <Col span={24}>
              <Dynamic

                filename={this.state.filename}
                title={this.state.title}
                dataList={this.state.dataList}
                // columns={this.columns}
                expandedRowRender={this.expandedRowRender}
                getData={this.getData}
                merchant={this.props.merchant}
                // onTableChange={this.onTableChange}
              />
            </Col>
          </Row>
       );

      }
      else {
        view = (
          <Row>
            <Col span={24}>
              <Dynamic
               user={this.props.authUser}
               update={this.update}
                filename={this.state.filename}
                title={this.state.title}
                dataList={this.state.dataList}
                // columns={this.columns}
                expandedRowRender={this.expandedRowRender}
                getData={this.getData}
                merchant={this.props.merchant}
                // onTableChange={this.onTableChange}
              />
            </Col>
          </Row>
        );
    }
    return (
      <Auxiliary>
      {view}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant ,country,comissionData,dataUser } = settings;
  return { authUser, merchant ,country,comissionData,dataUser };
};
export default connect(mapStateToProps)(paykuOk);
