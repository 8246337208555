import React, { Component } from "react";
import { Col, Row, notification } from "antd";
import Auxiliary from "util/Auxiliary";
import { connect } from "react-redux";
import WelComeCard from "components/dashboard/CRM/WelComeCard";
import SiteVisit from "components/dashboard/CRM/SiteVisit";
import GrowthCard from "components/dashboard/CRM/GrowthCard";
import TaskByStatus from "components/dashboard/CRM/TaskByStatus";
import Comisiones from "components/dashboard/Crypto/PortfolioMx";
import Counter from "components/dashboard/Crypto/OrderHistory";
import { firebase } from "../../../firebase/firebase";
import _ from "lodash";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";

const db = firebase.firestore();

const colecciones = {
  mxCashin: "mxCashin",
  mxCashout: "mxCashout",
  mxBbvaPcts: "mxBbvaPcts"
};
class DashboardMx extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indicadores: {
        dolar: {
          valor: ""
        }
      },
      date: "",
      dataCashin: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0
      },
      datapct: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        credito: {
          sum: 0,
          count: 0,
          commission: 0,
          ivaCommission: 0
        },
        debito: { sum: 0, count: 0, commission: 0, ivaCommission: 0 }
      },
      datapctC: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0
      },
      datapctD: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0
      },
      dataCashout: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0
      }
    };
  }

  componentDidMount = async () => {
    await db
      .collection("parametrosGenerales")
      .doc("indicadores")
      .get()
      .then(doc => {
        this.setState({
          indicadores: { dolar: { valor: doc.data().USD_CLP } }
        });
      })
      .catch(console.log);

    // LLAMO LOS DATOS PARA SER MOSTRADO EN LOS WIDGETS
    this.getAllData();
  };

  componentDidUpdate(prevProps) {
    if (this.props.merchant !== prevProps.merchant) {
      const merchant = localStorage.getItem("merchant_code");
      this.getAllData(merchant);
    }
  }


  getAllData(merchant) {
    // CALCULO EL MES ACTUAL
    moment.tz.setDefault("America/Mexico_City");
    let mes = moment.tz("America/Mexico_City").format("MMMM");
    this.setState({
      mes: mes
    });


    // LLAMO LOS DATOS PARA SER MOSTRADO EN LOS WIDGETS
    /* this.gettingData(colecciones.mxBbvaPcts, 5, "pct"); */
    this.gettingData("transfers", 5, "cashin", merchant);
    this.gettingData("mxBbvaPcts", 5, "pct", merchant);
    this.gettingData("cashouts", 5, "cashout", merchant);


    this.gettingPendFail();
  }

  gettingPendFail() {
    this.setState({
      cashinPendientes:0,
      cashinFailed:0

    });
    // OTRO SERVICIO APARTE PARA CONSULTAR LOS DATOS DE LAS OPERACIONES
    // PENDIENTES Y FALLIDAS
    // PARA METROS DE ENTRADA :
    // LA ZONA HORARIA
    // EL MERCHANT Y EL PAIS

    //  ¿DEBERIA SER UN ENDPOINT DIFERENTE POR CADA UNO DE LOS PAISES?

    let firstDay = moment
      .tz("America/Santiago")
      .startOf("month")
      .toDate();

    // CASHIN PENDIENTE
    let queryPending;
    queryPending = db
      .collection(colecciones.mxCashin)
      .where("code", "==", 9)
      .where("merchantId", "==", this.props.merchant.code)
      .where("dateRequest", ">", firstDay);

    queryPending.get()
      .then(
        querySnapshot => {
          this.setState({
            cashinPendientes: querySnapshot.docs.map(doc => doc.data()).length
          });
        },
        err => {
          console.log(`Encountered error: ${err}`);
        }
      );
    // CASHIN FAILED
    let queryFailed;
    queryFailed = db
      .collection(colecciones.mxCashin)
      .where("code", "==", 12)
      .where("merchantId", "==", this.props.merchant.code)
      .where("dateRequest", ">", firstDay);

    queryFailed.get()
      .then(
        querySnapshot => {
          this.setState({
            cashinFailed: querySnapshot.docs.map(doc => doc.data()).length
          });
        },
        err => {
          console.log(`Encountered error: ${err}`);
        }
      );

    // pct PENDING
    let queryWpPending;
    queryWpPending = db
      .collection(colecciones.mxBbvaPcts)
      .where("code", "in", [9, 1])
      .where("merchantId", "==", this.props.merchant.code)
      .where("dateRequest", ">", firstDay);

    queryWpPending.get()
      .then(
        querySnapshot => {
          this.setState({
            pctPending: querySnapshot.docs.map(doc => doc.data()).length
          });
        },
        err => {
          console.log(`Encountered error: ${err}`);
        }
      );

    // pct  FAILED
    let queryWpFailed;
    queryWpFailed = db
      .collection(colecciones.mxBbvaPcts)
      .where("code", "==", 12)
      .where("merchantId", "==", this.props.merchant.code)
      .where("dateRequest", ">", firstDay);

    queryWpFailed.get()
      .then(
        querySnapshot => {
          this.setState({
            pctFailed: querySnapshot.docs.map(doc => doc.data()).length
          });
        },
        err => {
          console.log(`Encountered error: ${err}`);
        }
      );

    // CASHOUT PENDIENTE
    let queryCoPending;
    queryCoPending = db
      .collection(colecciones.mxCashout)
      .where("code", "==", 9)
      .where("merchantId", "==", this.props.merchant.code)
      .where("dateRequest", ">", firstDay);

    queryCoPending.get()
      .then(
        querySnapshot => {
          this.setState({
            cashoutPendientes: querySnapshot.docs.map(doc => doc.data()).length
          });
        },
        err => {
          console.log(`Encountered error: ${err}`);
        }
      );
    // CASHOUT FAILED
    let queryCoFailed;
    queryCoFailed = db
      .collection(colecciones.mxCashout)
      .where("code", "==", 12)
      .where("merchantId", "==", this.props.merchant.code)
      .where("dateRequest", ">", firstDay);

    queryCoFailed.get()
      .then(
        querySnapshot => {
          this.setState({
            cashoutFailed: querySnapshot.docs.map(doc => doc.data()).length
          });
        },
        err => {
          console.log(`Encountered error: ${err}`);
        }
      );
  }

  gettingData(collection, numReg, nomState) {
    // ESTA FUNCION COMPLETA DEBERÍA SER UN SERVICIO
    // EL MERCHANT Y EL PAIS DEBE SER UN PARAMETRO DE ENTRADA
    // EL TIME ZONE DEBE SER TAMBIEN UN PARAMETRO DE ENTRADA

    // ¿DEBERIA TENER UN ENDPOINT DIFERENTE POR CADA UNO DE LOS PAISES?
    // SUPONIENDO QUE CADA UNO DE LOS PAISES TIENE DIFERENTES METODOS DE PAGO
    // POR LO TANTO LAS CONSULTAS SERIAN DIFERENTES


    /****************************************COMISIONES************************************ */

    let commissionesBd = this.props.comissionData ? this.props.comissionData : JSON.parse(localStorage.getItem("comisiones"));
    commissionesBd = commissionesBd.filter(item => item.merchant === this.props.merchant.code)
    commissionesBd = commissionesBd[0].commission.filter(item => item.countrie === this.props.country.code)

    /************************************************************************************************ */
    let firstDay = moment
      .tz("America/Mexico_City")
      .startOf("month")
      .toDate();

    // if(collection==="mxBbvaPcts"){debugger;}

    let query;
    query = db
      .collection(collection)
      .where("code", "==", 0)
      .where("merchantId", "==", this.props.merchant.code)
      .where("dateRequest", ">=", firstDay);

    query.get()
      .then(
        async querySnapshot => {
          let resultadosOk = [];
          querySnapshot.forEach(doc => {
            resultadosOk.push({
              date: moment(doc.data().dateRequest.toDate()).format("DD/MM"),
              amount: doc.data().amount
            });
          });

          // OBJETO DE RESULTADO
          var docs = querySnapshot.docs.map(doc => doc.data());
          console.log("DATA " + nomState, "=>", docs);

          // IF PCT (Pago con Tarjeta) SEPARO POR CREDITO Y DEBITO
          // pct === PCT
          let pctC,
            pctD,
            sumPctD,
            sumPctC,
            avgPctD,
            avgPctC,
            countPctD,
            countPctC;
          if (nomState === "pct") {
            pctD = docs.filter(reg => reg.card_type === "debit");
            pctC = docs.filter(reg => reg.card_type === "credit");

            sumPctD = _.sumBy(pctD, item => Number(item.amount));
            sumPctC = _.sumBy(pctC, item => Number(item.amount));
            avgPctD = _.meanBy(pctD, item => Number(item.amount));
            avgPctC = _.meanBy(pctC, item => Number(item.amount));
            countPctD = pctD.length;
            countPctC = pctC.length;
          }

          // SUMA TOTAL DEL MES
          let sum = _.sumBy(docs, item => Number(item.amount));
          let avg = _.meanBy(docs, item => Number(item.amount));
          let count = docs.length;

          // CALCULO COMISIONES
          // Esto lo debo sacar de la base de datos.
          let factor,
            factorC,
            factorD,
            commissionD,
            commissionC,
            ivaCommissionD,
            ivaCommissionC;

          if (nomState === "cashin") {
            factor = commissionesBd[0].cashin;//0.045;
          } else if (nomState === "pct") {
            /*  console.log("ommissionesBd[0].pct",commissionesBd[0].pct); */
            factorD = commissionesBd[0].pctD;// factorD = 0.0205;
            factorC = commissionesBd[0].pctC;//0.0142;
            commissionD = Number(parseFloat(Number(sumPctD) * factorD).toFixed(2));
            commissionC = Number(parseFloat(Number(sumPctC) * factorC).toFixed(2));
            ivaCommissionD = Number(parseFloat(commissionD * 0.16).toFixed(2));
            ivaCommissionC = Number(parseFloat(commissionC * 0.16).toFixed(2));
          } else if (nomState === "cashout") {
            factor = commissionesBd[0].cashout;// 0.02;
          }

          let commission = Number(parseFloat(Number(sum) * factor).toFixed(2));
          let ivaCommission = Number(parseFloat(commission * 0.16).toFixed(2));

          // OBTENGO DATA AGRUPADA POR FECHA
          let data = _(resultadosOk)
            .groupBy("date")
            .map((objs, key) => {
              return {
                date: key,
                value: _.sumBy(objs, item => Number(item.amount))
              };
            })
            .value();

          let dailyAvg = _.meanBy(data, item => Number(item.value));

          if (isNaN(commission)) {
            commission = 0;
            ivaCommission = 0;
          }

          let response;
          if (nomState === "pct") {
            response = {
              data,
              sum,
              avg,
              dailyAvg,
              count,
              credito: {
                sum: sumPctC,
                avg: avgPctC,
                count: countPctC,
                commission: commissionC,
                ivaCommission: ivaCommissionC
              },
              debito: {
                sum: sumPctD,
                avg: avgPctD,
                count: countPctD,
                commission: commissionD,
                ivaCommission: ivaCommissionD
              },
              commission: commissionC + commissionD,
              ivaCommission: ivaCommissionC + ivaCommissionD
            };
          } else {
            response = {
              data,
              sum,
              count,
              commission,
              ivaCommission,
              avg,
              dailyAvg
            };
          }

          if (data.length > 0) {
            switch (nomState) {
              case "cashin":
                this.setState({
                  dataCashin: response
                });
                break;
              case "pct":

                this.setState({
                  datapct: response
                });
                break;
              case "cashout":
                this.setState({
                  dataCashout: response
                });
                break;
              default:
                break;
            }
            this.updateTotal();
            this.updateDepVsWith();
            this.updateCommissions();
          }
        },
        err => {
          console.log(`Encountered error: ${err}`);
        }
      );
  }

  updateCommissions() {
    // DEBERIA EXISTIR OTRO ENDPOINT QUE CALCULE LAS COMISIONES DEL MERCHANT PAIS Y RESUMENES POR MERCHANT Y POR PAIS.

    this.setState({
      totalNeto:
        this.state.dataCashin.commission +
        this.state.dataCashout.commission +
        this.state.datapct.commission,
      totalIva:
        this.state.dataCashin.ivaCommission +
        this.state.dataCashout.ivaCommission +
        this.state.datapct.ivaCommission
    });
  }

  updateTotal() {
    this.setState({
      totalDepositos:
        Number(this.state.dataCashin.sum) + Number(this.state.datapct.sum),
      numeroOperaciones:
        Number(this.state.dataCashin.count) +
        Number(this.state.datapct.count) +
        Number(this.state.dataCashout.count)
    });
  }

  updateDepVsWith() {
    let mergedDep = _.unionWith(
      this.state.dataCashin.data,
      this.state.datapct.data
    );

    // OBTENGO DATA AGRUPADA POR FECHA
    let dataDep = [];
    dataDep = _(mergedDep)
      .groupBy("date")
      .map((objs, key) => {
        return {
          date: key,
          Deposits: _.sumBy(objs, item => Number(item.value))
        };
      })
      .value();

    let dataWith = _(this.state.dataCashout.data)
      .groupBy("date")
      .map((objs, key) => {
        return {
          date: key,
          Withdrawals: _.sumBy(objs, item => Number(item.value))
        };
      })
      .value();

    let dataDepVsWith = _.unionWith(dataDep, dataWith);

    //SOLUCION 1 DE STACKOVERFLOW (GRACIAS a NICK PARSONS)
    // dataDepVsWith = Object.values(
    //   dataDepVsWith.reduce((acc, { date, ...rest }) => {
    //     acc[date] = { ...(acc[date] || { date }), ...rest };
    //     return acc;
    //   }, {})
    // );

    //SOLUCION 2 DE STACKOVERFLOW (GRACIAS A INDIANCODING)
    dataDepVsWith = _.map(_.groupBy(dataDepVsWith, "date"), value =>
      _.assign(...value)
    );

    dataDepVsWith.sort(function compare(a, b) {
      var dateA = Number(
        moment(a.date, "DD/MM")
          .tz("America/Santiago")
          .toDate()
      );
      var dateB = Number(
        moment(b.date, "DD/MM")
          .tz("America/Santiago")
          .toDate()
      );
      // console.log("dateA", "=>", dateA);
      // console.log("dateB", "=>", dateB);
      return dateA - dateB;
    });

    this.setState({
      dataDepVsWith: dataDepVsWith
    });
  }

  openNotification = (name, quantity, method) => {
    notification["success"]({
      message: (
        <div>
          Nuevo Deposito de <b>{name}</b>
        </div>
      ),
      description: (
        <div>
          Por una suma de{" "}
          <span className="gx-chart-up">
            <NumberFormat
              value={quantity}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
            />
          </span>{" "}
          mediante <b>{method}</b>{" "}
        </div>
      ),

      duration: 0
    });
  };

  render() {
    let comisionesWidget;
    // let commerceSelector;
    switch (this.props.authUser) {
      case "ncornejo@me.com":
      case "johnkaroka@gmail.com":
      case "zeroray@gmail.com":
      case "alonso.marchant@hotmail.com":
      case "pabloignacio.lan.tus@gmail.com":
      case "fmansilla@stratechcorp.com":
        comisionesWidget = (
          <Col xl={12} lg={24} md={12} sm={24} xs={24}>
            <Comisiones
              totalNeto={this.state.totalNeto}
              totalIva={this.state.totalIva}
              cashin={this.state.dataCashin.commission}
              ivaCashin={this.state.dataCashin.ivaCommission}
              cashout={this.state.dataCashout.commission}
              ivaCashout={this.state.dataCashout.ivaCommission}

              pctC={this.state.datapct.credito.commission}
              ivapctC={this.state.datapct.credito.ivaCommission}
              pctD={this.state.datapct.debito.commission}
              ivapctD={this.state.datapct.debito.ivaCommission}
            />
          </Col>
        );
        break;
      default:
      // Show regular user UI.
    }
    return (
      <Auxiliary>
        <Row>
          {comisionesWidget}
          <Col xl={12} lg={24} md={12} sm={24} xs={24}>
            <Counter
              cashinVal={this.state.dataCashin.count}
              cashinPen={this.state.cashinPendientes}
              cashinFail={this.state.cashinFailed}
              webpayVal={this.state.datapct.count}
              webpayPen={this.state.pctPending}
              webpayFail={this.state.pctFailed}
              cashoutVal={this.state.dataCashout.count}
              cashoutPen={this.state.cashoutPendientes}
              cashoutFail={this.state.cashoutFailed}
              name1="CashIn SPEI"
              name2="PCT"
              name3="CashOut SPEI"
            />
          </Col>
          <Col span={24}>
            <div className="gx-card">
              <div className="gx-card-body">
                <Row>
                  <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                    <WelComeCard
                      dolar={
                        parseFloat(this.state.indicadores.dolar.valor).toFixed(
                          2
                        ) || "N/D"
                      }
                      currency="MXN"
                      name="🇲🇽 Mexico"
                      deposits={this.state.totalDepositos}
                      Withdrawals={this.state.dataCashout.sum}
                      pending={this.state.cashinPendientes}
                      pendingCashout={this.state.cashoutPendientes}
                    />
                  </Col>

                  <Col
                    xl={12}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="gx-visit-col"
                  >
                    <SiteVisit
                      siteVisit={_.takeRight(this.state.dataDepVsWith, 7)}
                    />
                  </Col>

                  <Col
                    xl={6}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={24}
                    className="gx-audi-col"
                  >
                    {/* <SiteAudience /> */}
                    <TaskByStatus
                      total={this.state.numeroOperaciones}
                      numCashin={this.state.dataCashin.count}
                      numpct={this.state.datapct.count}
                      numCashout={this.state.dataCashout.count}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xl={8} lg={24} md={8} sm={24} xs={24}>
            <GrowthCard
              trafficData={_.takeRight(this.state.dataCashin.data, 5)}
              title="Cash In SPEI"
              month={this.state.mes}
              suma={this.state.dataCashin.sum}
              avg={parseFloat(this.state.dataCashin.avg).toFixed(0)}
              dailyAvg={parseFloat(this.state.dataCashin.dailyAvg).toFixed(0)}
            />
          </Col>
          <Col xl={8} lg={12} md={8} sm={24} xs={24}>
            <GrowthCard
              trafficData={_.takeRight(this.state.datapct.data, 5)}
              title="PCT"
              month={this.state.mes}
              suma={this.state.datapct.sum}
              avg={parseFloat(this.state.datapct.avg).toFixed(0)}
              dailyAvg={parseFloat(this.state.datapct.dailyAvg).toFixed(0)}
            />
          </Col>
          <Col xl={8} lg={12} md={8} sm={24} xs={24}>
            <GrowthCard
              trafficData={_.takeRight(this.state.dataCashout.data, 5)}
              title="Cash out SPEI"
              month={this.state.mes}
              suma={this.state.dataCashout.sum}
              avg={parseFloat(this.state.dataCashout.avg).toFixed(0)}
              dailyAvg={parseFloat(this.state.dataCashout.dailyAvg).toFixed(0)}
            />
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, comissionData, country } = settings;
  return { authUser, merchant, comissionData, country };
};

export default connect(mapStateToProps)(DashboardMx);
