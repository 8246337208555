import keyGenerator from "../../../util/keyGenerator";
import config_fields from "../../../util/configFields";
export const generateObjectMethodsPay = methodsPay => {
  let newArray = [];
  methodsPay.forEach(methodPay => {
    let newObject = {};
    newObject.key = keyGenerator();
    newObject.level = 1;
    newObject.id = methodPay.id;
    if (Object.keys(methodPay.methodsPay).length !== 0) {
      newObject.data = generateDataInicial(methodPay, config_fields);
    } else {
      newObject.data = [];
    }
    newObject.children = [];
    newArray.push(newObject);
  });
  return newArray;
};
const generateDataInicial = (method, config_fields) => {
  let nombres = Object.keys(method.methodsPay);
  let object = {};
  let arrayInterior = [];
  nombres.forEach(nombre => {
    let nameField = config_fields.methodsPay.fields.filter(
      field => field.field === nombre
    );
    nameField = nameField[0];
    let objectInterior = {};
    objectInterior.key = keyGenerator();
    objectInterior.field = nameField.field;
    objectInterior.value = method.methodsPay[nombre];
    //console.log("Llegue aca",nameField.field,nameField,nombres);
    objectInterior.type = nameField.type;
    arrayInterior.push(objectInterior);
  });

  return arrayInterior;
};
