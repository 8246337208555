import React, { Component } from "react";
import { firebase, auth } from "../../../firebase/firebase";
import { connect } from "react-redux";
import moment from "moment-timezone";
import Widget from "components/Widget/index";
import { Switch, Badge, Popconfirm, Button, Row, Col, Modal, Form, Input, Alert } from "antd";
import { switchMerchant } from "../../../appRedux/actions/Setting";
import { withRouter } from "react-router-dom";
import API from "../../../util/api";
import Lottie from "react-lottie";
import offline from "../../../assets/animation/offline.json";
import online from "../../../assets/animation/online.json";
import unlocking from "../../../assets/animation/unlocking.json";


const db = firebase.firestore();
const FormItem = Form.Item;
class PowerProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      powerCashin: false,
      powerCashout: false,
      powerWebpay: false,
      powerPayku: false,
      powerFlow: false,
      powerBankCard: false,
      powerBankTransfer: false,
      powerBankCard: false,
      powerCash: false,
      powerSkinsBacks: false,
      powerPayOut: false,
      /*   isStopped: true,
        isPaused: true, */
      messageModal: ""
    };
  }
  componentDidMount = () => {
    /*  console.log("this.props.country", "=>", this.props.country); */
    this.getStatePowerProduct();
  };
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      console.log("this.props !== prevProps");
      this.getStatePowerProduct();
    }
  }


  getStatePowerProduct = async () => {

    let useCashin = false
    let useCashout = false
    let usePayku = false
    let useWebpay = false
    let useBankCard = false
    let useBankTransfer = false
    let useCash = false
    let usePayOut = false
    let useSkinsBacks = false
    let useAirtm=false;
    if (this.props.country.code === "CL") {


      let product = [];
      console.log("merchantContr", this.props.merchantContr)
      if (this.props.merchantContr) {

        product = this.props.merchantContr.filter(e => e.merchant === this.props.merchant.code.trim());
       /*  console.log("product[0]", product) */
        /*   this.setState({ productMerchant: product[0] }) */
        //console.log("product[0]", product[0].useCashin)
        useCashin = product[0].useCashin
        useCashout = product[0].useCashout
        usePayku = product[0].usePayku
        useWebpay = product[0].useWebpay
        /*   useBankCard = false
          useBankTransfer = false
          useCash = false */
      /*   console.log("powerSkinsBacks", this.state.powerSkinsBacks, useSkinsBacks) */
      }
    }

    let paymentsMethod = [];
    let payments = [];
    let payouts = [];



    let commissionesBd = this.props.comissionData
      ? this.props.comissionData
      : JSON.parse(localStorage.getItem("comisiones"));

    if (commissionesBd) {
      console.log("commissionesBd", commissionesBd);
      paymentsMethod = commissionesBd.filter(e => e.merchant === this.props.merchant.code);
      console.log("paymentsMethod", paymentsMethod);
      if (paymentsMethod.length > 0) {
        payments = paymentsMethod[0].payments.filter(e => e.country === this.props.country.code).map(e => {
          console.log("e", e);
          useBankCard = e.methodUse.filter(e => e == "bankCard")
          if (useBankCard.length != 0) { useBankCard = true } else { useBankCard = false }
          useBankTransfer = e.methodUse.filter(e => e == "bankTransfer")
          console.log("useBankTransfer", useBankTransfer);
          if (useBankTransfer.length != 0) { useBankTransfer = true } else { useBankTransfer = false }
          console.log("useBankTransfer", useBankTransfer);
          useCash = e.methodUse.filter(e => e == "cash")
          if (useCash.length != 0) { useCash = true } else { useCash = false }

          useSkinsBacks = e.methodUse.filter(e => e == "skin")
          if (useSkinsBacks.length != 0) { useSkinsBacks = true } else { useSkinsBacks = false }
          console.log("skin", useSkinsBacks);
          /*  this.setState({ useBankCard, useBankTransfer, useCash, useCashin: useCashin, useCashout, usePayku, useWebpay }) */

          useAirtm = e.methodUse.filter(e => e == "airtm")
          if (useAirtm.length != 0) { useAirtm = true } else { useAirtm = false }
          console.log("useAirtm", useAirtm);
        })
 /*        console.log("paymentsMethod[0]", paymentsMethod[0]); */
        payouts = paymentsMethod[0].payouts.filter(e => e.country === this.props.country.code)
        console.log("payouts", payouts);
        if (!payouts) { usePayOut = false }
        else {
            usePayOut = true
        }

      }
    }

    this.setState({ powerBankCard: useBankCard, powerBankTransfer: useBankTransfer, powerCash: useCash, powerCashin: useCashin, powerCashout: useCashout, powerPayku: usePayku, powerWebpay: useWebpay, powerSkinsBacks: useSkinsBacks ,powerPayOut:usePayOut,powerAirtm:useAirtm})

    console.log("use", "useBankCard", useBankCard, "useBankTransfer", useBankTransfer, "useCash", useCash, "useCashin", useCashin, "usePayOut", usePayOut, "useCashout", useCashout, "usePayku", usePayku, "useWebpay", useWebpay, "useSkinsBacks", useSkinsBacks);

  };


  getAllData = async () => {
    let getPowerProduct = await API.utils
      .getPowerProduct(this.props.merchant.code)
      .then(Product => {
        /*   console.log("getPowerProduct", Product[0].useCashin) */

        /*   console.log("powerCashin", Product[0].useCashin);
          console.log("powerCashout", Product[0].useCashout);
          console.log("powerWebpay", Product[0].useWebpay); */

        this.setState({
          powerCashin: Product[0].useCashin,
          powerCashout: Product[0].useCashout,
          powerWebpay: Product[0].useWebpay,
          powerFlow: Product[0].useFlow,
          powerPayku: Product[0].usePayku,
          hola: "hola"
        });
      })
      .catch(console.log);

    /*   console.log("this.state.powerCashin", this.state.powerCashin); */
  };
  handleChnage = () => {
    console.log("I am here");
  };

  confirmChange = async (status, type, token) => {
    /*  console.log("I am here", status, type); */

    let payload = {
      merchant: this.props.merchant.code,
      power: !status,
      type: type,
      token: token
    };
    console.log("payload", payload);
    let getPowerProduct = await API.utils
      .updatePowerProduct(payload)
      .then(Product => {
        /*   console.log("updatePowerProduct", Product); */

        this.setState({ isStopped: false });

        this.getAllData();

        this.hideModal(); this.hideModalWebpay(); this.hideModalCashout();
        this.props.form.setFieldsValue({
          ["Password"]: "",

        });
      })
      .catch(error => { console.log("error en confirmChange" + error) });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ messageModal: "" });

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log(values)


        let validate = true;
        if (values.Password === "Zippy0515") {


          auth.onAuthStateChanged(async user => {
            let token = await auth.currentUser.getIdToken();
            /*  console.log("tokenData", "=>", token); */
            this.confirmChange(this.state.powerCashin, "cashin", token)
          });
        }
        else {
          console.log("err", err)
          setTimeout(
            function () {
              this.setState({ messageModal: " Incorrect password" });
            }.bind(this),
            1000
          );
        }
      }

    });
  }
  handleSubmitWebpay = async (e) => {
    e.preventDefault();
    this.setState({ messageModal: "" });

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log(values)


        let validate = true;
        if (values.Password === "Zippy0515") {


          auth.onAuthStateChanged(async user => {
            let dataS = await auth.currentUser.getIdToken();
            console.log("tokenData", "=>", dataS);
            this.confirmChange(this.state.powerWebpay, "webpay", dataS)
          });
        }
        else {
          console.log("err", err)
          setTimeout(
            function () {
              this.setState({ messageModal: " Incorrect password" });
            }.bind(this),
            1000
          );
        }
      }

    });
  }
  handleSubmitCashout = async (e) => {
    e.preventDefault();
    this.setState({ messageModal: "" });

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log(values)


        let validate = true;
        if (values.Password === "Zippy0515") {


          auth.onAuthStateChanged(async user => {
            let dataS = await auth.currentUser.getIdToken();
            console.log("tokenData", "=>", dataS);
            this.confirmChange(this.state.powerCashout, "cashout", dataS)
          });
        }
        else {
          console.log("err", err)
          setTimeout(
            function () {
              this.setState({ messageModal: " Incorrect password" });
            }.bind(this),
            1000
          );
        }
      }

    });
  }
  handleSubmitPayku = async (e) => {
    e.preventDefault();
    this.setState({ messageModal: "" });

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log(values)


        let validate = true;
        if (values.Password === "Zippy0515") {


          auth.onAuthStateChanged(async user => {
            let dataS = await auth.currentUser.getIdToken();
            console.log("tokenData", "=>", dataS);
            this.confirmChange(this.state.powerCashout, "cashout", dataS)
          });
        }
        else {
          console.log("err", err)
          setTimeout(
            function () {
              this.setState({ messageModal: " Incorrect password" });
            }.bind(this),
            1000
          );
        }
      }

    });
  }
  handleSubmitFlow = async (e) => {
    e.preventDefault();
    this.setState({ messageModal: "" });

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log(values)


        let validate = true;
        if (values.Password === "Zippy0515") {


          auth.onAuthStateChanged(async user => {
            let dataS = await auth.currentUser.getIdToken();
            console.log("tokenData", "=>", dataS);
            this.confirmChange(this.state.powerCashout, "cashout", dataS)
          });
        }
        else {
          console.log("err", err)
          setTimeout(
            function () {
              this.setState({ messageModal: " Incorrect password" });
            }.bind(this),
            1000
          );
        }
      }

    });
  }
  showModal = () => {
    this.setState({
      visible: true,
      messageModal: ""
    });
    this.props.form.setFieldsValue({
      ["Password"]: "",

    });




  };
  showModalWebpay = () => {
    this.setState({
      visibleWebpay: true,
      messageModal: ""
    });
    this.props.form.setFieldsValue({
      ["Password"]: "",

    });

  };
  showModalCashout = () => {
    this.setState({
      visibleCashout: true,
      messageModal: ""
    });
    this.props.form.setFieldsValue({
      ["Password"]: "",

    });
  };
  showModalPayku = () => {
    this.setState({
      visiblePayku: true,
      messageModal: ""
    });
    this.props.form.setFieldsValue({
      ["Password"]: "",

    });
  };
  showModalFlow = () => {
    this.setState({
      visibleFlow: true,
      messageModal: ""
    });
    this.props.form.setFieldsValue({
      ["Password"]: "",

    });
  };
  hideModal = () => {
    this.setState({
      visible: false,
      messageModal: ""
    });
    this.props.form.setFieldsValue({
      ["Password"]: "",

    });
  };
  hideModalWebpay = () => {
    this.setState({
      visibleWebpay: false,
      messageModal: ""
    });
    this.props.form.setFieldsValue({
      ["Password"]: "",

    });
  };
  hideModalCashout = () => {
    this.setState({
      visibleCashout: false,
      messageModal: ""
    });
    this.props.form.setFieldsValue({
      ["Password"]: "",

    });
  };
  hideModalPayku = () => {
    this.setState({
      visiblePayku: false,
      messageModal: ""
    });
    this.props.form.setFieldsValue({
      ["Password"]: "",

    });
  };
  hideModalFlow = () => {
    this.setState({
      visibleFlow: false,
      messageModal: ""
    });
    this.props.form.setFieldsValue({
      ["Password"]: "",

    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const buttonStyle = {
      display: "block",
      margin: "10px auto"
    };

    const defaultOptionsOnline = {
      loop: true,
      autoplay: true,
      animationData: online,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: offline,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const unlockingOptions = {
      loop: true,
      autoplay: false,
      animationData: unlocking,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };


    /*  const { powerCashin } = this.state; */
    return (
      <>
        <Widget
          styleName="gx-order-history"
          title={
            <h2 className="h4 gx-text-capitalize gx-mb-0">
              Product Status{" "}
            </h2>
          }
        >
          <Row style={{ textAlign: "center", alignItems: "center" }}>
            <Col
              lg={4}
              md={4}
              sm={24}
              xs={24}
            //style={{ textAlign: "center", alignItems: "center" }}
            >
              <>
                <div >
                  CASHIN{" "}
                  {" "}
                  {this.state.powerCashin ? (
                    <Lottie
                      options={defaultOptionsOnline}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  ) : (
                    <Lottie
                      options={defaultOptions}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  )}
                </div>
                <Modal
                  title={`⚠️⚠️Are you sure to change the state of the Cashin?⚠️⚠️`}
                  // icon: <i class="icon icon-exclamation"></i>
                  visible={this.state.visible}
                  onCancel={this.hideModal}


                >
                  <p> Enter de Password for the change Status </p>
                  <Form
                    className="gx-signup-form gx-form-row0 gx-mb-0"
                    onSubmit={this.handleSubmit}
                  >
                    <FormItem label="Password:">
                      <div className="gx-mb-3" style={{ width: "200px" }}>
                        {getFieldDecorator("Password", {
                          rules: [
                            {
                              required: true,
                              message: "Pleace, Enter de Password!"
                            }
                          ]
                        })(
                          <Input placeholder="Password" type="password" />
                        )}
                      </div>
                    </FormItem>

                    <div style={{ display: "flex", padding: "5px" }}> <Button type="primary" className="gx-mb-0" htmlType="submit">
                      Change Status
                    </Button>
                      <br></br>
                      {this.state.messageModal ? <Alert
                        showIcon
                        description={`${this.state.messageModal} `}
                        type="error"
                      /> : null}
                    </div>

                  </Form>
                </Modal>
              </>
            </Col>
            <Col
              lg={4}
              md={4}
              sm={24}
              xs={24}

            >
              <>
                <div >
                  CASHOUT

                  {this.state.powerCashout ? (
                    <Lottie
                      options={defaultOptionsOnline}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  ) : (
                    <Lottie
                      options={defaultOptions}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  )}
                </div>
                <Modal
                  title={`⚠️Are you sure to change the state of the Cashout?⚠️`}
                  // icon: <i class="icon icon-exclamation"></i>
                  visible={this.state.visibleCashout}
                  onCancel={this.hideModalCashout}

                >
                  <p> Enter de Password for the change Status </p>
                  <Form
                    className="gx-signup-form gx-form-row0 gx-mb-0"
                    onSubmit={this.handleSubmitCashout}
                  >
                    <FormItem label="Password:">
                      <div className="gx-mb-3" style={{ width: "200px" }}>
                        {getFieldDecorator("Password", {
                          rules: [
                            {
                              required: true,
                              message: "Pleace, Enter de Password!"
                            }
                          ]
                        })(
                          <div style={{ display: "flex", padding: "5px" }} >
                            <Input placeholder="Password" type="password" />

                          </div>
                        )}
                      </div>
                    </FormItem>

                    <div style={{ display: "flex", padding: "5px" }}> <Button type="primary" className="gx-mb-0" htmlType="submit">
                      Change Status
                    </Button>
                      <br></br>
                      {this.state.messageModal ? <Alert
                        showIcon
                        description={`${this.state.messageModal} `}
                        type="error"
                      /> : null}
                    </div>
                  </Form>
                </Modal>
              </>
            </Col>
            <Col
              lg={4}
              md={4}
              sm={24}
              xs={24}
            //style={{ textAlign: "center", alignItems: "center" }}
            >
              <>
                <div >
                  PAYKU{" "}
                  {" "}
                  {this.state.powerPayku || this.state.powerWebpay ? (
                    <Lottie
                      options={defaultOptionsOnline}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  ) : (
                    <Lottie
                      options={defaultOptions}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  )}
                </div>
                <Modal
                  title={`⚠️⚠️Are you sure to change the state of the Payku?⚠️⚠️`}
                  // icon: <i class="icon icon-exclamation"></i>
                  visible={this.state.visiblePayku}
                  onCancel={this.hideModalPayku}


                >
                  <p> Enter de Password for the change Status </p>
                  <Form
                    className="gx-signup-form gx-form-row0 gx-mb-0"
                    onSubmit={this.handleSubmitPayku}
                  >
                    <FormItem label="Password:">
                      <div className="gx-mb-3" style={{ width: "200px" }}>
                        {getFieldDecorator("Password", {
                          rules: [
                            {
                              required: true,
                              message: "Pleace, Enter de Password!"
                            }
                          ]
                        })(
                          <Input placeholder="Password" type="password" />
                        )}
                      </div>
                    </FormItem>

                    <div style={{ display: "flex", padding: "5px" }}> <Button type="primary" className="gx-mb-0" htmlType="submit">
                      Change Status
                    </Button>
                      <br></br>
                      {this.state.messageModal ? <Alert
                        showIcon
                        description={`${this.state.messageModal} `}
                        type="error"
                      /> : null}
                    </div>

                  </Form>
                </Modal>
              </>
            </Col>
            <Col
              lg={4}
              md={4}
              sm={24}
              xs={24}
            //style={{ textAlign: "center", alignItems: "center" }}
            >
              <>
                <div >
                  BANK TRANSFER{" "}
                  {" "}
                  {this.state.powerBankTransfer ? (
                    <Lottie
                      options={defaultOptionsOnline}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  ) : (
                    <Lottie
                      options={defaultOptions}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  )}
                </div>

              </>
            </Col>
            <Col
              lg={4}
              md={4}
              sm={24}
              xs={24}
            //style={{ textAlign: "center", alignItems: "center" }}
            >
              <>
                <div >
                  BANK CARD{" "}
                  {" "}
                  {this.state.powerBankCard ? (
                    <Lottie
                      options={defaultOptionsOnline}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  ) : (
                    <Lottie
                      options={defaultOptions}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  )}
                </div>

              </>
            </Col>
            <Col
              lg={4}
              md={4}
              sm={24}
              xs={24}
            //style={{ textAlign: "center", alignItems: "center" }}
            >
              <>
                <div >
                  CASH{" "}
                  {" "}
                  {this.state.powerCash ? (
                    <Lottie
                      options={defaultOptionsOnline}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  ) : (
                    <Lottie
                      options={defaultOptions}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  )}
                </div>
              </>
            </Col>
            <Col
              lg={4}
              md={4}
              sm={24}
              xs={24}
            //style={{ textAlign: "center", alignItems: "center" }}
            >
              <>
                <div >
                  SKINS BACKS

                  {String(this.state.powerSkinsBacks) === "true" ? (
                    <Lottie
                      options={defaultOptionsOnline}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  ) : (
                    <Lottie
                      options={defaultOptions}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  )}
                </div>

              </>
            </Col>
            <Col
              lg={4}
              md={4}
              sm={24}
              xs={24}
            //style={{ textAlign: "center", alignItems: "center" }}
            >
              <>
                <div >
                  PAY OUT

                  {String(this.state.powerPayOut) === "true" ? (
                    <Lottie
                      options={defaultOptionsOnline}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  ) : (
                    <Lottie
                      options={defaultOptions}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  )}
                </div>
            
              </>
            </Col>
         
            <Col
              lg={4}
              md={4}
              sm={24}
              xs={24}
            //style={{ textAlign: "center", alignItems: "center" }}
            >
              <>
                <div >
                  AIRTM
                  {String(this.state.powerAirtm) === "true" ? (
                    <Lottie
                      options={defaultOptionsOnline}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  ) : (
                    <Lottie
                      options={defaultOptions}
                      height={100}
                      width={100}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  )}
                </div>
            
              </>
            </Col>
          </Row>
        </Widget>

      </>
    );
  }
}
const PowerProductsForm = Form.create()(PowerProducts);
const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, country, merchantContr } = settings;
  return { authUser, merchant, country, merchantContr };
};

export default withRouter(
  connect(mapStateToProps, { switchMerchant })(PowerProductsForm)
);
