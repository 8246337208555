import React, { Component } from "react";
import CartolaBancoEstado from "../../../components/Cartolas/cartolaBancoEstado";
import CartolaBancoChile from "../../../components/Cartolas/cartolaBancoChile";
import { Button, Row, Col } from "antd";

class Cartola extends Component {
  state = {
    resp: "",
    updateTable: false,
    type: "BE"
  };

  getPage = type => {
    switch (type) {
      case "BE":
        return <CartolaBancoChile />;
      case "BC":
        return <CartolaBancoChile />;
      default:
        return null;
    }
  };

  onchangeToBE = () => {
    this.setState({
      type: "BE"
    });
  };
  onchangeToBC = () => {
    this.setState({
      type: "BC"
    });
  };

  render() {
    return (
      <Row className="container p-0">
        <Col lg={24} md={24} sm={24} xs={24}>
          <h1>{`Cartola Consolidada`}</h1>
          {/* <Button
            type="primary"
            className="gx-mb-0"
            onClick={this.onchangeToBE}
          >
            Banco Estado
          </Button>
          <Button
            type="primary"
            className="gx-mb-0"
            onClick={this.onchangeToBC}
          >
            Banco de Chile
          </Button> */}
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div style={{ marginTop: "10px" }}>
            {" "}
            { this.getPage(this.state.type) }
          </div>
        </Col>
      </Row>
    );
  }
}

export default Cartola;
